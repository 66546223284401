// reactstrap components
import React, {
  useState,
  ChangeEventHandler,
  MouseEventHandler,
  SetStateAction,
  useEffect,
} from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Col,
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Label,
  Tooltip
} from "reactstrap";
import Modal from "../Modal/Modal";
import Select from "react-select";
import { useQuery } from "utils/router";
import styles from "./Table.module.scss";
import empty from "assets/img/icons/common/empty.svg";
import Loading from "assets/img/icons/common/Loading.gif";
import Avatar from "react-avatar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// core components

type Generic = { id: string };

export type ColumnDefinitionType<T extends Generic, K extends keyof T> = {
  key: K;
  header: string;
  width?: number;
  isImage?: boolean;
};

export enum TableActionType {
  BUTTON,
  TEXT,
  SELECT,
  CHECK,
}

export enum TableCheckActionValue {
  CHECKED = "CHECKED",
  UNCHECKED = "UNCHECKED",
}

type TableActionOption<T> = {
  label?: string;
  value: T;
};

type TableAction<T> = {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onSelectChange?: (value: TableActionOption<T> | null) => void;
  value?: TableActionOption<T>;
  options?: TableActionOption<T>[];
  type?: TableActionType;
  onClickCondition?: boolean;
  href?: string;
  color?: string;
  hide?: boolean;
  tooltip?: string;
};

type ItemAction<T extends Generic, K extends keyof T> = {
  heading: string;
  text?: string;
  key?: K;
  isDisabled?: (item: T) => boolean;
  onClick?: (item: T) => void;
  href?: string;
  color: string;
  requiresConfirmation?: boolean;
  confirmationKey?: (item: T) => string;
  setConfirmationMessage?: (item: T) => string;
};

interface TableProps<T extends Generic, K extends keyof T, T2> {
  title?: string;
  columns?: Array<ColumnDefinitionType<T, K>>;
  keys?: string[];
  totalPages?: number;
  itemsPerPage?: number;
  data: Array<T>;
  dataLoading?: boolean;
  actions?: TableAction<T2>[];
  itemActions?: ItemAction<T, K>[];
  onClickItem?: (item: T) => void;
}

const Tables = <T extends Generic, K extends keyof T, T2>(
  props: TableProps<T, K, T2>
): JSX.Element => {
  let query = useQuery();
  let { pathname } = useLocation();

  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [toggle, setToggle] = useState(false);

  const [isModalVisible, showModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [onSubmit, setSubmitFunction] = useState(() => () => {});
  const [modalMessage, setModalMessage] = useState<String>();
  const [modalConfirmationKey, setModalConfirmationKey] =
    useState<String | null>();
  const [isImageOpened, openImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      {isImageOpened && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => openImage(false)} />
      )}
      <Modal
        item={selectedItem}
        message={modalMessage}
        isVisible={isModalVisible}
        onCancel={() => {
          showModal(false);
          setModalConfirmationKey(null);
        }}
        confirmationKey={modalConfirmationKey}
        onSubmit={onSubmit}
      />
      <Row>
        <div className="col">
          <Card className="shadow">
            {props.title && (
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="4">
                    <h3 className="mb-0">{props.title}</h3>
                  </Col>
                  <Col className="text-right" xs="8">
                    <Row className="justify-content-end align-items-center">
                      {props.actions
                        ?.filter((item) => !item?.hide)
                        .map(
                          ({
                            color = "primary",
                            href,
                            onClick,
                            onChange,
                            onSelectChange,
                            value,
                            options,
                            text,
                            tooltip,
                            type = TableActionType.BUTTON,
                          }) => (
                            <div className={styles.tableOptions}>
                              {tooltip !== undefined ? (
                                <>
                                  <i id="iconInfo" className={"fas fa-info-circle " + styles.infoIcon}/>
                                  <Tooltip
                                    isOpen={tooltipOpen}
                                    target="iconInfo"
                                    placement={"left"}
                                    toggle={() => {
                                      setTooltipOpen(!tooltipOpen);
                                    }}
                                  >
                                    {tooltip}
                                  </Tooltip>
                                </>
                              ) : null}
                              {type === TableActionType.BUTTON ? (
                                <Button
                                  tag={Link}
                                  key={text}
                                  color={color}
                                  to={href}
                                  onClick={onClick}
                                  size="sm"
                                >
                                  {text}
                                </Button>
                              ) : type === TableActionType.TEXT ? (
                                <Input
                                  key={text}
                                  className={`${styles.tableInputAction}`}
                                  onChange={onChange}
                                  placeholder={text}
                                  type="text"
                                />
                              ) : type === TableActionType.SELECT ? (
                                <Select
                                  value={value}
                                  placeholder={text}
                                  className={styles.select}
                                  options={options}
                                  onChange={onSelectChange && onSelectChange}
                                />
                              ) : type === TableActionType.CHECK ? (
                                <Form>
                                  <FormGroup check inline>
                                    <Input
                                      type="checkbox"
                                      onChange={onChange}
                                    />
                                    <Label check>{text}</Label>
                                  </FormGroup>
                                </Form>
                              ) : null}
                            </div>
                          )

                        )}
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
            )}
            <Table className={styles.height} responsive hover>
              <thead className="thead-light">
                <tr>
                  {props.columns?.map(({ header }) => (
                    <th key={header} scope="col">
                      {header}
                    </th>
                  ))}
                  <th key="Options">Options</th>
                </tr>
              </thead>
              <tbody>
                {props.data.map((row) => (
                  <tr
                    key={row.id}
                    onClick={() => props.onClickItem && props.onClickItem(row)}
                    className={props.onClickItem ? styles.clickableTable : ""}
                  >
                    {props.columns?.map(({ key }) => (
                      <td key={key.toString()}>
                        {key === "image" ? (
                          <Avatar
                            src={String(row[key])}
                            size="32"
                            color="red"
                            round
                            onClick={(e) => {
                              openImage(true);
                              e.preventDefault();
                              e.stopPropagation();
                              setImageSrc(String(row[key]));
                            }}
                          />
                        ) : (
                          row[key]
                        )}
                      </td>
                    ))}
                    {
                      <td className={styles.dropdownColWidth}>
                        <Dropdown
                          key={row.id}
                          direction="down"
                          isOpen={selectedDropdown === row.id}
                          color="red"
                          toggle={() => {
                            if (toggle === false) {
                              setSelectedDropdown(row.id);
                              setToggle(true);
                            } else {
                              setSelectedDropdown("");
                              setToggle(false);
                            }
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <DropdownToggle className={styles.dropDownIcon}>
                            <i className="fas fa-ellipsis-v"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            {props.itemActions?.map(
                              ({
                                color = "primary",
                                href,
                                onClick,
                                text,
                                key,
                                isDisabled = () => false,
                                requiresConfirmation,
                                setConfirmationMessage,
                                confirmationKey
                              }) => (
                                <DropdownItem
                                  className={styles.dropdownItem}
                                  key={text}
                                  disabled={isDisabled(row)}
                                  href={href}
                                  onClick={
                                    onClick &&
                                    ((e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if(confirmationKey){
                                        setModalConfirmationKey(confirmationKey(row))
                                      }
                                      if (requiresConfirmation) {
                                        setModalMessage(
                                          setConfirmationMessage &&
                                            setConfirmationMessage(row)
                                        );
                                        setSelectedItem(row);
                                        setSubmitFunction(
                                          () => () => onClick(row)
                                        );
                                        showModal(true);
                                      } else {
                                        onClick(row);
                                      }
                                    })
                                  }
                                >
                                  <td key={text}>{key ? row[key] : text}</td>
                                </DropdownItem>
                              )
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
            {props.dataLoading ? (
              <img src={Loading} className={styles.placeholder} alt="loading" />
            ) : props.data.length === 0 ? (
              <>
                <img src={empty} className={styles.placeholder} alt="empty" />
                <p className={styles.text}>There is nothing here</p>{" "}
              </>
            ) : (
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      disabled={parseInt(query.get("page") || "1") === 1}
                    >
                      <PaginationLink
                        tag={Link}
                        to={`${pathname}?page=${
                          parseInt(query.get("page") || "1") - 1
                        }`}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <div className="d-flex align-items-center">
                      {query.get("page") || "1"} / {props.totalPages}
                    </div>
                    <PaginationItem
                      disabled={
                        parseInt(query.get("page") || "1") === props.totalPages
                      }
                    >
                      <PaginationLink
                        tag={Link}
                        to={`${pathname}?page=${
                          parseInt(query.get("page") || "1") + 1
                        }`}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            )}
          </Card>
        </div>
      </Row>
    </>
  );
};

export default Tables;
