import Auth from "@aws-amplify/auth";
import { ProfileResponseFragment, useProfileLazyQuery } from "generated/graphql";
import React, { createContext, FC, useEffect } from "react";

interface IAppContext {
  user?: ProfileResponseFragment
  setRole?: React.Dispatch<React.SetStateAction<String | undefined>>
}

const AppContext = createContext<IAppContext>({});

export const AppProvider: FC = ({ children }) => {
  const [getProfile, { data }] = useProfileLazyQuery();
  
  useEffect(() => {
    ;(async () => {
      try {
        await Auth.currentAuthenticatedUser();
        getProfile()
      } catch {
        
      }
    })()
  }, []);
  
  return <AppContext.Provider value={{ user: data?.profile[0] }}>
    {children}
  </AppContext.Provider>
}

export default AppContext;