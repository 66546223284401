import React from "react";
import {
  useListIncidentsQuery,
  useAggregateIncidentsQuery,
  useDeletePlateIncidentMutation,
  ListPlatesDocument,
} from "generated/graphql";
import { useParams } from "react-router";
import Table, { ColumnDefinitionType } from "components/atoms/Table/Table";
import { useHistory } from "react-router";
import toast from "react-hot-toast";

type PlateRow = {
  id: string;
  property: string;
  plateNumber: string;
  createdBy: string;
};

const PLATECOLUMNS: ColumnDefinitionType<PlateRow, keyof PlateRow>[] = [
  {
    key: "property",
    header: "Property",
  },
  {
    key: "plateNumber",
    header: "Plate Number",
  },
  {
    key: "createdBy",
    header: "Created By",
  },
];

const PlateIncidents = () => {
  const LIMIT = 10;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: plateIncidents } = useListIncidentsQuery({
    variables: {
      plateId: id,
      limit: 10,
      offset: 0,
    },
  });
  const { data: aggregateIncidents } = useAggregateIncidentsQuery({
    variables: {
      plateId: id,
    },
  });
  const [deletePlateIncident] = useDeletePlateIncidentMutation({
    refetchQueries: [
      {
        query: ListPlatesDocument,
        variables: {
          query: `%%`,
          limit: 10,
          offset: 0,
        }
      }
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "lpr_incidents",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "plates",
      });
    },
  })

  return (
    <div>
      <Table
        title="Incidents"
        columns={PLATECOLUMNS}
        data={
          plateIncidents?.lpr_incidents.map((incident) => ({
            id: incident.id,
            property: incident.property?.name,
            plateNumber: incident.plate?.displayNumber,
            createdBy: incident.user
              ? `${incident.user?.firstName} ${incident.user?.lastName}`
              : "--",
          })) || []
        }
        onClickItem={(item) => {
          history.push(`/admin/plate/${id}/incident/${item.id}`);
        }}
        itemsPerPage={
          aggregateIncidents?.lpr_incidents_aggregate.aggregate?.count || 1
        }
        totalPages={Math.ceil(
          (aggregateIncidents?.lpr_incidents_aggregate.aggregate?.count || 0) /
            LIMIT
        )}
        actions={[
          {
            text: "Add",
            href: `/admin/plate/${id}/incident/add`,
            color: "primary",
          },
        ]}
        itemActions={[
          {
            heading: "Delete",
            text: "Delete",
            color: "danger",
            requiresConfirmation: true,
            setConfirmationMessage: ({ id }) =>
              `Are you sure you want to Delete this incident?`,
            onClick: ({ id }) => {
              toast.promise(
                deletePlateIncident({
                  variables: {
                    iid: id
                  },
                }),
                {
                  loading: "Deleting...",
                  success: "Deleted successfully!",
                  error: "Failed to delete!",
                }
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default PlateIncidents;
