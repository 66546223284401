import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _bool: any;
  _float8: any;
  _text: any;
  agencies_subscription_enum: any;
  bigint: any;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  name: any;
  numeric: any;
  persons_listedas_enum: any;
  plates_listedas_enum: any;
  timestamp: any;
  users_role_enum: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CreateFtpUserInput = {
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CreateFtpUserOutput = {
  __typename?: 'CreateFTPUserOutput';
  username: Scalars['String'];
};

export type GenerateReportInput = {
  incidentId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

export type GenerateReportOutput = {
  __typename?: 'GenerateReportOutput';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type RegisterDeviceOutput = {
  __typename?: 'RegisterDeviceOutput';
  message: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  message: Scalars['String'];
};

export type SearchFaceInput = {
  s3ImageKey: Scalars['String'];
};

export type SearchFaceOutput = {
  __typename?: 'SearchFaceOutput';
  accuracy: Scalars['Float'];
  faceFound: Scalars['Boolean'];
  /** An object relationship */
  info: Persons;
  personId: Scalars['uuid'];
};

export type SearchPlateInput = {
  s3ImageKey: Scalars['String'];
};

export type SearchPlateOutput = {
  __typename?: 'SearchPlateOutput';
  /** An object relationship */
  plate: Plates;
  plateId: Scalars['uuid'];
  textDetections: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type UpdateFtpUserInput = {
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateFtpUserOutput = {
  __typename?: 'UpdateFTPUserOutput';
  isUpdated: Scalars['Boolean'];
};


/** Boolean expression to compare columns of type "_bool". All fields are combined with logical 'AND'. */
export type _Bool_Comparison_Exp = {
  _eq?: Maybe<Scalars['_bool']>;
  _gt?: Maybe<Scalars['_bool']>;
  _gte?: Maybe<Scalars['_bool']>;
  _in?: Maybe<Array<Scalars['_bool']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_bool']>;
  _lte?: Maybe<Scalars['_bool']>;
  _neq?: Maybe<Scalars['_bool']>;
  _nin?: Maybe<Array<Scalars['_bool']>>;
};


/** Boolean expression to compare columns of type "_float8". All fields are combined with logical 'AND'. */
export type _Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['_float8']>;
  _gt?: Maybe<Scalars['_float8']>;
  _gte?: Maybe<Scalars['_float8']>;
  _in?: Maybe<Array<Scalars['_float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_float8']>;
  _lte?: Maybe<Scalars['_float8']>;
  _neq?: Maybe<Scalars['_float8']>;
  _nin?: Maybe<Array<Scalars['_float8']>>;
};


/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "agencies" */
export type Agencies = {
  __typename?: 'agencies';
  /** An array relationship */
  agency_lpr_datastores: Array<Agency_Lpr_Datastores>;
  /** An aggregate relationship */
  agency_lpr_datastores_aggregate: Agency_Lpr_Datastores_Aggregate;
  /** fetch data from the table: "agency_metadata" */
  agency_metadata: Array<Agency_Metadata>;
  /** An aggregate relationship */
  agency_metadata_aggregate: Agency_Metadata_Aggregate;
  /** An array relationship */
  agency_person_datastores: Array<Agency_Person_Datastores>;
  /** An aggregate relationship */
  agency_person_datastores_aggregate: Agency_Person_Datastores_Aggregate;
  createdAt: Scalars['timestamp'];
  disabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An array relationship */
  incident_types: Array<Incident_Type>;
  /** An aggregate relationship */
  incident_types_aggregate: Incident_Type_Aggregate;
  /** An array relationship */
  lpr_datastores: Array<Lpr_Datastores>;
  /** An aggregate relationship */
  lpr_datastores_aggregate: Lpr_Datastores_Aggregate;
  /** An array relationship */
  lpr_incident_types: Array<Lpr_Incident_Type>;
  /** An aggregate relationship */
  lpr_incident_types_aggregate: Lpr_Incident_Type_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  person_datastores: Array<Person_Datastores>;
  /** An aggregate relationship */
  person_datastores_aggregate: Person_Datastores_Aggregate;
  /** An array relationship */
  properties: Array<Properties>;
  /** An aggregate relationship */
  properties_aggregate: Properties_Aggregate;
  subscription: Scalars['agencies_subscription_enum'];
  topicArn?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An object relationship */
  users_metadata?: Maybe<Agency_Users_View>;
};


/** columns and relationships of "agencies" */
export type AgenciesAgency_Lpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesAgency_Lpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesAgency_MetadataArgs = {
  distinct_on?: Maybe<Array<Agency_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Metadata_Order_By>>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesAgency_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Metadata_Order_By>>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesAgency_Person_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesAgency_Person_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesIncident_TypesArgs = {
  distinct_on?: Maybe<Array<Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incident_Type_Order_By>>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesIncident_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incident_Type_Order_By>>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesLpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Datastores_Order_By>>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesLpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Datastores_Order_By>>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesLpr_Incident_TypesArgs = {
  distinct_on?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incident_Type_Order_By>>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesLpr_Incident_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incident_Type_Order_By>>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesPerson_DatastoresArgs = {
  distinct_on?: Maybe<Array<Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Person_Datastores_Order_By>>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesPerson_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Person_Datastores_Order_By>>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesPropertiesArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Properties_Order_By>>;
  where?: Maybe<Properties_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Properties_Order_By>>;
  where?: Maybe<Properties_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "agencies" */
export type AgenciesUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "agencies" */
export type Agencies_Aggregate = {
  __typename?: 'agencies_aggregate';
  aggregate?: Maybe<Agencies_Aggregate_Fields>;
  nodes: Array<Agencies>;
};

/** aggregate fields of "agencies" */
export type Agencies_Aggregate_Fields = {
  __typename?: 'agencies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Agencies_Max_Fields>;
  min?: Maybe<Agencies_Min_Fields>;
};


/** aggregate fields of "agencies" */
export type Agencies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agencies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "agencies". All fields are combined with a logical 'AND'. */
export type Agencies_Bool_Exp = {
  _and?: Maybe<Array<Agencies_Bool_Exp>>;
  _not?: Maybe<Agencies_Bool_Exp>;
  _or?: Maybe<Array<Agencies_Bool_Exp>>;
  agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
  agency_metadata?: Maybe<Agency_Metadata_Bool_Exp>;
  agency_person_datastores?: Maybe<Agency_Person_Datastores_Bool_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  incident_types?: Maybe<Incident_Type_Bool_Exp>;
  lpr_datastores?: Maybe<Lpr_Datastores_Bool_Exp>;
  lpr_incident_types?: Maybe<Lpr_Incident_Type_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  person_datastores?: Maybe<Person_Datastores_Bool_Exp>;
  properties?: Maybe<Properties_Bool_Exp>;
  subscription?: Maybe<Agencies_Subscription_Enum_Comparison_Exp>;
  topicArn?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  users_metadata?: Maybe<Agency_Users_View_Bool_Exp>;
};

/** unique or primary key constraints on table "agencies" */
export enum Agencies_Constraint {
  /** unique or primary key constraint */
  Pk_8ab1f1f53f56c8255b0d7e68b28 = 'PK_8ab1f1f53f56c8255b0d7e68b28'
}

/** input type for inserting data into table "agencies" */
export type Agencies_Insert_Input = {
  agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Arr_Rel_Insert_Input>;
  agency_metadata?: Maybe<Agency_Metadata_Arr_Rel_Insert_Input>;
  agency_person_datastores?: Maybe<Agency_Person_Datastores_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamp']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  incident_types?: Maybe<Incident_Type_Arr_Rel_Insert_Input>;
  lpr_datastores?: Maybe<Lpr_Datastores_Arr_Rel_Insert_Input>;
  lpr_incident_types?: Maybe<Lpr_Incident_Type_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  person_datastores?: Maybe<Person_Datastores_Arr_Rel_Insert_Input>;
  properties?: Maybe<Properties_Arr_Rel_Insert_Input>;
  subscription?: Maybe<Scalars['agencies_subscription_enum']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  users_metadata?: Maybe<Agency_Users_View_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Agencies_Max_Fields = {
  __typename?: 'agencies_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Agencies_Min_Fields = {
  __typename?: 'agencies_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "agencies" */
export type Agencies_Mutation_Response = {
  __typename?: 'agencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agencies>;
};

/** input type for inserting object relation for remote table "agencies" */
export type Agencies_Obj_Rel_Insert_Input = {
  data: Agencies_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Agencies_On_Conflict>;
};

/** on conflict condition type for table "agencies" */
export type Agencies_On_Conflict = {
  constraint: Agencies_Constraint;
  update_columns: Array<Agencies_Update_Column>;
  where?: Maybe<Agencies_Bool_Exp>;
};

/** Ordering options when selecting data from "agencies". */
export type Agencies_Order_By = {
  agency_lpr_datastores_aggregate?: Maybe<Agency_Lpr_Datastores_Aggregate_Order_By>;
  agency_metadata_aggregate?: Maybe<Agency_Metadata_Aggregate_Order_By>;
  agency_person_datastores_aggregate?: Maybe<Agency_Person_Datastores_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incident_types_aggregate?: Maybe<Incident_Type_Aggregate_Order_By>;
  lpr_datastores_aggregate?: Maybe<Lpr_Datastores_Aggregate_Order_By>;
  lpr_incident_types_aggregate?: Maybe<Lpr_Incident_Type_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  person_datastores_aggregate?: Maybe<Person_Datastores_Aggregate_Order_By>;
  properties_aggregate?: Maybe<Properties_Aggregate_Order_By>;
  subscription?: Maybe<Order_By>;
  topicArn?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  users_metadata?: Maybe<Agency_Users_View_Order_By>;
};

/** primary key columns input for table: agencies */
export type Agencies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "agencies" */
export enum Agencies_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  TopicArn = 'topicArn',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "agencies" */
export type Agencies_Set_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['agencies_subscription_enum']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};


/** Boolean expression to compare columns of type "agencies_subscription_enum". All fields are combined with logical 'AND'. */
export type Agencies_Subscription_Enum_Comparison_Exp = {
  _eq?: Maybe<Scalars['agencies_subscription_enum']>;
  _gt?: Maybe<Scalars['agencies_subscription_enum']>;
  _gte?: Maybe<Scalars['agencies_subscription_enum']>;
  _in?: Maybe<Array<Scalars['agencies_subscription_enum']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['agencies_subscription_enum']>;
  _lte?: Maybe<Scalars['agencies_subscription_enum']>;
  _neq?: Maybe<Scalars['agencies_subscription_enum']>;
  _nin?: Maybe<Array<Scalars['agencies_subscription_enum']>>;
};

/** update columns of table "agencies" */
export enum Agencies_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  TopicArn = 'topicArn',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "agency_lpr_datastores" */
export type Agency_Lpr_Datastores = {
  __typename?: 'agency_lpr_datastores';
  agenciesId: Scalars['uuid'];
  /** An object relationship */
  agency: Agencies;
  lprDatastoresId: Scalars['uuid'];
  /** An object relationship */
  lpr_datastore: Lpr_Datastores;
};

/** aggregated selection of "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Aggregate = {
  __typename?: 'agency_lpr_datastores_aggregate';
  aggregate?: Maybe<Agency_Lpr_Datastores_Aggregate_Fields>;
  nodes: Array<Agency_Lpr_Datastores>;
};

/** aggregate fields of "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Aggregate_Fields = {
  __typename?: 'agency_lpr_datastores_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Agency_Lpr_Datastores_Max_Fields>;
  min?: Maybe<Agency_Lpr_Datastores_Min_Fields>;
};


/** aggregate fields of "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Agency_Lpr_Datastores_Max_Order_By>;
  min?: Maybe<Agency_Lpr_Datastores_Min_Order_By>;
};

/** input type for inserting array relation for remote table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Arr_Rel_Insert_Input = {
  data: Array<Agency_Lpr_Datastores_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Agency_Lpr_Datastores_On_Conflict>;
};

/** Boolean expression to filter rows from the table "agency_lpr_datastores". All fields are combined with a logical 'AND'. */
export type Agency_Lpr_Datastores_Bool_Exp = {
  _and?: Maybe<Array<Agency_Lpr_Datastores_Bool_Exp>>;
  _not?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
  _or?: Maybe<Array<Agency_Lpr_Datastores_Bool_Exp>>;
  agenciesId?: Maybe<Uuid_Comparison_Exp>;
  agency?: Maybe<Agencies_Bool_Exp>;
  lprDatastoresId?: Maybe<Uuid_Comparison_Exp>;
  lpr_datastore?: Maybe<Lpr_Datastores_Bool_Exp>;
};

/** unique or primary key constraints on table "agency_lpr_datastores" */
export enum Agency_Lpr_Datastores_Constraint {
  /** unique or primary key constraint */
  Pk_2cf0735d9e02ae04aa8bbf396b2 = 'PK_2cf0735d9e02ae04aa8bbf396b2'
}

/** input type for inserting data into table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Insert_Input = {
  agenciesId?: Maybe<Scalars['uuid']>;
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  lprDatastoresId?: Maybe<Scalars['uuid']>;
  lpr_datastore?: Maybe<Lpr_Datastores_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Agency_Lpr_Datastores_Max_Fields = {
  __typename?: 'agency_lpr_datastores_max_fields';
  agenciesId?: Maybe<Scalars['uuid']>;
  lprDatastoresId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Max_Order_By = {
  agenciesId?: Maybe<Order_By>;
  lprDatastoresId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Agency_Lpr_Datastores_Min_Fields = {
  __typename?: 'agency_lpr_datastores_min_fields';
  agenciesId?: Maybe<Scalars['uuid']>;
  lprDatastoresId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Min_Order_By = {
  agenciesId?: Maybe<Order_By>;
  lprDatastoresId?: Maybe<Order_By>;
};

/** response of any mutation on the table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Mutation_Response = {
  __typename?: 'agency_lpr_datastores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agency_Lpr_Datastores>;
};

/** on conflict condition type for table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_On_Conflict = {
  constraint: Agency_Lpr_Datastores_Constraint;
  update_columns: Array<Agency_Lpr_Datastores_Update_Column>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};

/** Ordering options when selecting data from "agency_lpr_datastores". */
export type Agency_Lpr_Datastores_Order_By = {
  agenciesId?: Maybe<Order_By>;
  agency?: Maybe<Agencies_Order_By>;
  lprDatastoresId?: Maybe<Order_By>;
  lpr_datastore?: Maybe<Lpr_Datastores_Order_By>;
};

/** primary key columns input for table: agency_lpr_datastores */
export type Agency_Lpr_Datastores_Pk_Columns_Input = {
  agenciesId: Scalars['uuid'];
  lprDatastoresId: Scalars['uuid'];
};

/** select columns of table "agency_lpr_datastores" */
export enum Agency_Lpr_Datastores_Select_Column {
  /** column name */
  AgenciesId = 'agenciesId',
  /** column name */
  LprDatastoresId = 'lprDatastoresId'
}

/** input type for updating data in table "agency_lpr_datastores" */
export type Agency_Lpr_Datastores_Set_Input = {
  agenciesId?: Maybe<Scalars['uuid']>;
  lprDatastoresId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "agency_lpr_datastores" */
export enum Agency_Lpr_Datastores_Update_Column {
  /** column name */
  AgenciesId = 'agenciesId',
  /** column name */
  LprDatastoresId = 'lprDatastoresId'
}

/** columns and relationships of "agency_metadata" */
export type Agency_Metadata = {
  __typename?: 'agency_metadata';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  agencyId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  noOfAgents: Scalars['Int'];
  trespassStatement: Scalars['String'];
};

/** aggregated selection of "agency_metadata" */
export type Agency_Metadata_Aggregate = {
  __typename?: 'agency_metadata_aggregate';
  aggregate?: Maybe<Agency_Metadata_Aggregate_Fields>;
  nodes: Array<Agency_Metadata>;
};

/** aggregate fields of "agency_metadata" */
export type Agency_Metadata_Aggregate_Fields = {
  __typename?: 'agency_metadata_aggregate_fields';
  avg?: Maybe<Agency_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agency_Metadata_Max_Fields>;
  min?: Maybe<Agency_Metadata_Min_Fields>;
  stddev?: Maybe<Agency_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Agency_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agency_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Agency_Metadata_Sum_Fields>;
  var_pop?: Maybe<Agency_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Agency_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Agency_Metadata_Variance_Fields>;
};


/** aggregate fields of "agency_metadata" */
export type Agency_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agency_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "agency_metadata" */
export type Agency_Metadata_Aggregate_Order_By = {
  avg?: Maybe<Agency_Metadata_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Agency_Metadata_Max_Order_By>;
  min?: Maybe<Agency_Metadata_Min_Order_By>;
  stddev?: Maybe<Agency_Metadata_Stddev_Order_By>;
  stddev_pop?: Maybe<Agency_Metadata_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Agency_Metadata_Stddev_Samp_Order_By>;
  sum?: Maybe<Agency_Metadata_Sum_Order_By>;
  var_pop?: Maybe<Agency_Metadata_Var_Pop_Order_By>;
  var_samp?: Maybe<Agency_Metadata_Var_Samp_Order_By>;
  variance?: Maybe<Agency_Metadata_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "agency_metadata" */
export type Agency_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Agency_Metadata_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Agency_Metadata_On_Conflict>;
};

/** aggregate avg on columns */
export type Agency_Metadata_Avg_Fields = {
  __typename?: 'agency_metadata_avg_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "agency_metadata" */
export type Agency_Metadata_Avg_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "agency_metadata". All fields are combined with a logical 'AND'. */
export type Agency_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Agency_Metadata_Bool_Exp>>;
  _not?: Maybe<Agency_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Agency_Metadata_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  noOfAgents?: Maybe<Int_Comparison_Exp>;
  trespassStatement?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "agency_metadata" */
export enum Agency_Metadata_Constraint {
  /** unique or primary key constraint */
  PkF5db4eff8a2096bd02323a1045b = 'PK_f5db4eff8a2096bd02323a1045b',
  /** unique or primary key constraint */
  RelD19a55ce25ba07b1b15658774c = 'REL_d19a55ce25ba07b1b15658774c'
}

/** input type for incrementing numeric columns in table "agency_metadata" */
export type Agency_Metadata_Inc_Input = {
  noOfAgents?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "agency_metadata" */
export type Agency_Metadata_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  trespassStatement?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Agency_Metadata_Max_Fields = {
  __typename?: 'agency_metadata_max_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  trespassStatement?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "agency_metadata" */
export type Agency_Metadata_Max_Order_By = {
  agencyId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  noOfAgents?: Maybe<Order_By>;
  trespassStatement?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Agency_Metadata_Min_Fields = {
  __typename?: 'agency_metadata_min_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  trespassStatement?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "agency_metadata" */
export type Agency_Metadata_Min_Order_By = {
  agencyId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  noOfAgents?: Maybe<Order_By>;
  trespassStatement?: Maybe<Order_By>;
};

/** response of any mutation on the table "agency_metadata" */
export type Agency_Metadata_Mutation_Response = {
  __typename?: 'agency_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agency_Metadata>;
};

/** on conflict condition type for table "agency_metadata" */
export type Agency_Metadata_On_Conflict = {
  constraint: Agency_Metadata_Constraint;
  update_columns: Array<Agency_Metadata_Update_Column>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "agency_metadata". */
export type Agency_Metadata_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agencyId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  noOfAgents?: Maybe<Order_By>;
  trespassStatement?: Maybe<Order_By>;
};

/** primary key columns input for table: agency_metadata */
export type Agency_Metadata_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "agency_metadata" */
export enum Agency_Metadata_Select_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  NoOfAgents = 'noOfAgents',
  /** column name */
  TrespassStatement = 'trespassStatement'
}

/** input type for updating data in table "agency_metadata" */
export type Agency_Metadata_Set_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  trespassStatement?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Agency_Metadata_Stddev_Fields = {
  __typename?: 'agency_metadata_stddev_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "agency_metadata" */
export type Agency_Metadata_Stddev_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Agency_Metadata_Stddev_Pop_Fields = {
  __typename?: 'agency_metadata_stddev_pop_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "agency_metadata" */
export type Agency_Metadata_Stddev_Pop_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Agency_Metadata_Stddev_Samp_Fields = {
  __typename?: 'agency_metadata_stddev_samp_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "agency_metadata" */
export type Agency_Metadata_Stddev_Samp_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Agency_Metadata_Sum_Fields = {
  __typename?: 'agency_metadata_sum_fields';
  noOfAgents?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "agency_metadata" */
export type Agency_Metadata_Sum_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** update columns of table "agency_metadata" */
export enum Agency_Metadata_Update_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  NoOfAgents = 'noOfAgents',
  /** column name */
  TrespassStatement = 'trespassStatement'
}

/** aggregate var_pop on columns */
export type Agency_Metadata_Var_Pop_Fields = {
  __typename?: 'agency_metadata_var_pop_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "agency_metadata" */
export type Agency_Metadata_Var_Pop_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Agency_Metadata_Var_Samp_Fields = {
  __typename?: 'agency_metadata_var_samp_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "agency_metadata" */
export type Agency_Metadata_Var_Samp_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Agency_Metadata_Variance_Fields = {
  __typename?: 'agency_metadata_variance_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "agency_metadata" */
export type Agency_Metadata_Variance_Order_By = {
  noOfAgents?: Maybe<Order_By>;
};

/** columns and relationships of "agency_person_datastores" */
export type Agency_Person_Datastores = {
  __typename?: 'agency_person_datastores';
  agenciesId: Scalars['uuid'];
  /** An object relationship */
  agency: Agencies;
  personDatastoresId: Scalars['uuid'];
  /** An object relationship */
  person_datastore: Person_Datastores;
};

/** aggregated selection of "agency_person_datastores" */
export type Agency_Person_Datastores_Aggregate = {
  __typename?: 'agency_person_datastores_aggregate';
  aggregate?: Maybe<Agency_Person_Datastores_Aggregate_Fields>;
  nodes: Array<Agency_Person_Datastores>;
};

/** aggregate fields of "agency_person_datastores" */
export type Agency_Person_Datastores_Aggregate_Fields = {
  __typename?: 'agency_person_datastores_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Agency_Person_Datastores_Max_Fields>;
  min?: Maybe<Agency_Person_Datastores_Min_Fields>;
};


/** aggregate fields of "agency_person_datastores" */
export type Agency_Person_Datastores_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "agency_person_datastores" */
export type Agency_Person_Datastores_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Agency_Person_Datastores_Max_Order_By>;
  min?: Maybe<Agency_Person_Datastores_Min_Order_By>;
};

/** input type for inserting array relation for remote table "agency_person_datastores" */
export type Agency_Person_Datastores_Arr_Rel_Insert_Input = {
  data: Array<Agency_Person_Datastores_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Agency_Person_Datastores_On_Conflict>;
};

/** Boolean expression to filter rows from the table "agency_person_datastores". All fields are combined with a logical 'AND'. */
export type Agency_Person_Datastores_Bool_Exp = {
  _and?: Maybe<Array<Agency_Person_Datastores_Bool_Exp>>;
  _not?: Maybe<Agency_Person_Datastores_Bool_Exp>;
  _or?: Maybe<Array<Agency_Person_Datastores_Bool_Exp>>;
  agenciesId?: Maybe<Uuid_Comparison_Exp>;
  agency?: Maybe<Agencies_Bool_Exp>;
  personDatastoresId?: Maybe<Uuid_Comparison_Exp>;
  person_datastore?: Maybe<Person_Datastores_Bool_Exp>;
};

/** unique or primary key constraints on table "agency_person_datastores" */
export enum Agency_Person_Datastores_Constraint {
  /** unique or primary key constraint */
  Pk_98cdb1e30bcf19afceb0d33e9ca = 'PK_98cdb1e30bcf19afceb0d33e9ca'
}

/** input type for inserting data into table "agency_person_datastores" */
export type Agency_Person_Datastores_Insert_Input = {
  agenciesId?: Maybe<Scalars['uuid']>;
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  personDatastoresId?: Maybe<Scalars['uuid']>;
  person_datastore?: Maybe<Person_Datastores_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Agency_Person_Datastores_Max_Fields = {
  __typename?: 'agency_person_datastores_max_fields';
  agenciesId?: Maybe<Scalars['uuid']>;
  personDatastoresId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "agency_person_datastores" */
export type Agency_Person_Datastores_Max_Order_By = {
  agenciesId?: Maybe<Order_By>;
  personDatastoresId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Agency_Person_Datastores_Min_Fields = {
  __typename?: 'agency_person_datastores_min_fields';
  agenciesId?: Maybe<Scalars['uuid']>;
  personDatastoresId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "agency_person_datastores" */
export type Agency_Person_Datastores_Min_Order_By = {
  agenciesId?: Maybe<Order_By>;
  personDatastoresId?: Maybe<Order_By>;
};

/** response of any mutation on the table "agency_person_datastores" */
export type Agency_Person_Datastores_Mutation_Response = {
  __typename?: 'agency_person_datastores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agency_Person_Datastores>;
};

/** on conflict condition type for table "agency_person_datastores" */
export type Agency_Person_Datastores_On_Conflict = {
  constraint: Agency_Person_Datastores_Constraint;
  update_columns: Array<Agency_Person_Datastores_Update_Column>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};

/** Ordering options when selecting data from "agency_person_datastores". */
export type Agency_Person_Datastores_Order_By = {
  agenciesId?: Maybe<Order_By>;
  agency?: Maybe<Agencies_Order_By>;
  personDatastoresId?: Maybe<Order_By>;
  person_datastore?: Maybe<Person_Datastores_Order_By>;
};

/** primary key columns input for table: agency_person_datastores */
export type Agency_Person_Datastores_Pk_Columns_Input = {
  agenciesId: Scalars['uuid'];
  personDatastoresId: Scalars['uuid'];
};

/** select columns of table "agency_person_datastores" */
export enum Agency_Person_Datastores_Select_Column {
  /** column name */
  AgenciesId = 'agenciesId',
  /** column name */
  PersonDatastoresId = 'personDatastoresId'
}

/** input type for updating data in table "agency_person_datastores" */
export type Agency_Person_Datastores_Set_Input = {
  agenciesId?: Maybe<Scalars['uuid']>;
  personDatastoresId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "agency_person_datastores" */
export enum Agency_Person_Datastores_Update_Column {
  /** column name */
  AgenciesId = 'agenciesId',
  /** column name */
  PersonDatastoresId = 'personDatastoresId'
}

/** columns and relationships of "agency_users_view" */
export type Agency_Users_View = {
  __typename?: 'agency_users_view';
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  user_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "agency_users_view" */
export type Agency_Users_View_Aggregate = {
  __typename?: 'agency_users_view_aggregate';
  aggregate?: Maybe<Agency_Users_View_Aggregate_Fields>;
  nodes: Array<Agency_Users_View>;
};

/** aggregate fields of "agency_users_view" */
export type Agency_Users_View_Aggregate_Fields = {
  __typename?: 'agency_users_view_aggregate_fields';
  avg?: Maybe<Agency_Users_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agency_Users_View_Max_Fields>;
  min?: Maybe<Agency_Users_View_Min_Fields>;
  stddev?: Maybe<Agency_Users_View_Stddev_Fields>;
  stddev_pop?: Maybe<Agency_Users_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agency_Users_View_Stddev_Samp_Fields>;
  sum?: Maybe<Agency_Users_View_Sum_Fields>;
  var_pop?: Maybe<Agency_Users_View_Var_Pop_Fields>;
  var_samp?: Maybe<Agency_Users_View_Var_Samp_Fields>;
  variance?: Maybe<Agency_Users_View_Variance_Fields>;
};


/** aggregate fields of "agency_users_view" */
export type Agency_Users_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agency_Users_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Agency_Users_View_Avg_Fields = {
  __typename?: 'agency_users_view_avg_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "agency_users_view". All fields are combined with a logical 'AND'. */
export type Agency_Users_View_Bool_Exp = {
  _and?: Maybe<Array<Agency_Users_View_Bool_Exp>>;
  _not?: Maybe<Agency_Users_View_Bool_Exp>;
  _or?: Maybe<Array<Agency_Users_View_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  noOfAgents?: Maybe<Int_Comparison_Exp>;
  user_count?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "agency_users_view" */
export type Agency_Users_View_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  user_count?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Agency_Users_View_Max_Fields = {
  __typename?: 'agency_users_view_max_fields';
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  user_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Agency_Users_View_Min_Fields = {
  __typename?: 'agency_users_view_min_fields';
  id?: Maybe<Scalars['uuid']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  user_count?: Maybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "agency_users_view" */
export type Agency_Users_View_Obj_Rel_Insert_Input = {
  data: Agency_Users_View_Insert_Input;
};

/** Ordering options when selecting data from "agency_users_view". */
export type Agency_Users_View_Order_By = {
  id?: Maybe<Order_By>;
  noOfAgents?: Maybe<Order_By>;
  user_count?: Maybe<Order_By>;
};

/** select columns of table "agency_users_view" */
export enum Agency_Users_View_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NoOfAgents = 'noOfAgents',
  /** column name */
  UserCount = 'user_count'
}

/** aggregate stddev on columns */
export type Agency_Users_View_Stddev_Fields = {
  __typename?: 'agency_users_view_stddev_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Agency_Users_View_Stddev_Pop_Fields = {
  __typename?: 'agency_users_view_stddev_pop_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Agency_Users_View_Stddev_Samp_Fields = {
  __typename?: 'agency_users_view_stddev_samp_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Agency_Users_View_Sum_Fields = {
  __typename?: 'agency_users_view_sum_fields';
  noOfAgents?: Maybe<Scalars['Int']>;
  user_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Agency_Users_View_Var_Pop_Fields = {
  __typename?: 'agency_users_view_var_pop_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Agency_Users_View_Var_Samp_Fields = {
  __typename?: 'agency_users_view_var_samp_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Agency_Users_View_Variance_Fields = {
  __typename?: 'agency_users_view_variance_fields';
  noOfAgents?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "ftp_user" */
export type Ftp_User = {
  __typename?: 'ftp_user';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  passwordHash: Scalars['String'];
  updatedAt: Scalars['timestamp'];
  username: Scalars['String'];
};

/** aggregated selection of "ftp_user" */
export type Ftp_User_Aggregate = {
  __typename?: 'ftp_user_aggregate';
  aggregate?: Maybe<Ftp_User_Aggregate_Fields>;
  nodes: Array<Ftp_User>;
};

/** aggregate fields of "ftp_user" */
export type Ftp_User_Aggregate_Fields = {
  __typename?: 'ftp_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ftp_User_Max_Fields>;
  min?: Maybe<Ftp_User_Min_Fields>;
};


/** aggregate fields of "ftp_user" */
export type Ftp_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ftp_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ftp_user". All fields are combined with a logical 'AND'. */
export type Ftp_User_Bool_Exp = {
  _and?: Maybe<Array<Ftp_User_Bool_Exp>>;
  _not?: Maybe<Ftp_User_Bool_Exp>;
  _or?: Maybe<Array<Ftp_User_Bool_Exp>>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  passwordHash?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ftp_user" */
export enum Ftp_User_Constraint {
  /** unique or primary key constraint */
  Pk_262660a82d59c66b2057f8d43c5 = 'PK_262660a82d59c66b2057f8d43c5'
}

/** input type for inserting data into table "ftp_user" */
export type Ftp_User_Insert_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  passwordHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ftp_User_Max_Fields = {
  __typename?: 'ftp_user_max_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  passwordHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ftp_User_Min_Fields = {
  __typename?: 'ftp_user_min_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  passwordHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ftp_user" */
export type Ftp_User_Mutation_Response = {
  __typename?: 'ftp_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ftp_User>;
};

/** on conflict condition type for table "ftp_user" */
export type Ftp_User_On_Conflict = {
  constraint: Ftp_User_Constraint;
  update_columns: Array<Ftp_User_Update_Column>;
  where?: Maybe<Ftp_User_Bool_Exp>;
};

/** Ordering options when selecting data from "ftp_user". */
export type Ftp_User_Order_By = {
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  passwordHash?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** primary key columns input for table: ftp_user */
export type Ftp_User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ftp_user" */
export enum Ftp_User_Select_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "ftp_user" */
export type Ftp_User_Set_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  passwordHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** update columns of table "ftp_user" */
export enum Ftp_User_Update_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geography_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['name']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};


/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Geography_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: Maybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: Maybe<Geography_Columns_Bool_Exp>;
  _or?: Maybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: Maybe<Int_Comparison_Exp>;
  f_geography_column?: Maybe<Name_Comparison_Exp>;
  f_table_catalog?: Maybe<Name_Comparison_Exp>;
  f_table_name?: Maybe<Name_Comparison_Exp>;
  f_table_schema?: Maybe<Name_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: Maybe<Order_By>;
  f_geography_column?: Maybe<Order_By>;
  f_table_catalog?: Maybe<Order_By>;
  f_table_name?: Maybe<Order_By>;
  f_table_schema?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeographyColumn = 'f_geography_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};


/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: Maybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: Maybe<Geometry_Columns_Bool_Exp>;
  _or?: Maybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: Maybe<Int_Comparison_Exp>;
  f_geometry_column?: Maybe<Name_Comparison_Exp>;
  f_table_catalog?: Maybe<String_Comparison_Exp>;
  f_table_name?: Maybe<Name_Comparison_Exp>;
  f_table_schema?: Maybe<Name_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: Maybe<Order_By>;
  f_geometry_column?: Maybe<Order_By>;
  f_table_catalog?: Maybe<Order_By>;
  f_table_name?: Maybe<Order_By>;
  f_table_schema?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeometryColumn = 'f_geometry_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

/** columns and relationships of "incident_type" */
export type Incident_Type = {
  __typename?: 'incident_type';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An array relationship */
  incidents: Array<Incidents>;
  /** An aggregate relationship */
  incidents_aggregate: Incidents_Aggregate;
  name: Scalars['String'];
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "incident_type" */
export type Incident_TypeIncidentsArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


/** columns and relationships of "incident_type" */
export type Incident_TypeIncidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};

/** aggregated selection of "incident_type" */
export type Incident_Type_Aggregate = {
  __typename?: 'incident_type_aggregate';
  aggregate?: Maybe<Incident_Type_Aggregate_Fields>;
  nodes: Array<Incident_Type>;
};

/** aggregate fields of "incident_type" */
export type Incident_Type_Aggregate_Fields = {
  __typename?: 'incident_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Incident_Type_Max_Fields>;
  min?: Maybe<Incident_Type_Min_Fields>;
};


/** aggregate fields of "incident_type" */
export type Incident_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Incident_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "incident_type" */
export type Incident_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Incident_Type_Max_Order_By>;
  min?: Maybe<Incident_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "incident_type" */
export type Incident_Type_Arr_Rel_Insert_Input = {
  data: Array<Incident_Type_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Incident_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "incident_type". All fields are combined with a logical 'AND'. */
export type Incident_Type_Bool_Exp = {
  _and?: Maybe<Array<Incident_Type_Bool_Exp>>;
  _not?: Maybe<Incident_Type_Bool_Exp>;
  _or?: Maybe<Array<Incident_Type_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  incidents?: Maybe<Incidents_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "incident_type" */
export enum Incident_Type_Constraint {
  /** unique or primary key constraint */
  Pk_5a8002731cddeb0825c3ae17c79 = 'PK_5a8002731cddeb0825c3ae17c79'
}

/** input type for inserting data into table "incident_type" */
export type Incident_Type_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  incidents?: Maybe<Incidents_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Incident_Type_Max_Fields = {
  __typename?: 'incident_type_max_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "incident_type" */
export type Incident_Type_Max_Order_By = {
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Incident_Type_Min_Fields = {
  __typename?: 'incident_type_min_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "incident_type" */
export type Incident_Type_Min_Order_By = {
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "incident_type" */
export type Incident_Type_Mutation_Response = {
  __typename?: 'incident_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Incident_Type>;
};

/** input type for inserting object relation for remote table "incident_type" */
export type Incident_Type_Obj_Rel_Insert_Input = {
  data: Incident_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Incident_Type_On_Conflict>;
};

/** on conflict condition type for table "incident_type" */
export type Incident_Type_On_Conflict = {
  constraint: Incident_Type_Constraint;
  update_columns: Array<Incident_Type_Update_Column>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "incident_type". */
export type Incident_Type_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incidents_aggregate?: Maybe<Incidents_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: incident_type */
export type Incident_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "incident_type" */
export enum Incident_Type_Select_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "incident_type" */
export type Incident_Type_Set_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "incident_type" */
export enum Incident_Type_Update_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "incidents" */
export type Incidents = {
  __typename?: 'incidents';
  accuracy?: Maybe<Scalars['numeric']>;
  automatedReportStatus: Scalars['String'];
  createdAt: Scalars['timestamp'];
  generatedReport?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  incident_type?: Maybe<Incident_Type>;
  location?: Maybe<Scalars['geometry']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note: Scalars['String'];
  person?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  personByPerson?: Maybe<Persons>;
  /** An object relationship */
  property?: Maybe<Properties>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "incidents" */
export type Incidents_Aggregate = {
  __typename?: 'incidents_aggregate';
  aggregate?: Maybe<Incidents_Aggregate_Fields>;
  nodes: Array<Incidents>;
};

/** aggregate fields of "incidents" */
export type Incidents_Aggregate_Fields = {
  __typename?: 'incidents_aggregate_fields';
  avg?: Maybe<Incidents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Incidents_Max_Fields>;
  min?: Maybe<Incidents_Min_Fields>;
  stddev?: Maybe<Incidents_Stddev_Fields>;
  stddev_pop?: Maybe<Incidents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Incidents_Stddev_Samp_Fields>;
  sum?: Maybe<Incidents_Sum_Fields>;
  var_pop?: Maybe<Incidents_Var_Pop_Fields>;
  var_samp?: Maybe<Incidents_Var_Samp_Fields>;
  variance?: Maybe<Incidents_Variance_Fields>;
};


/** aggregate fields of "incidents" */
export type Incidents_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Incidents_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "incidents" */
export type Incidents_Aggregate_Order_By = {
  avg?: Maybe<Incidents_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Incidents_Max_Order_By>;
  min?: Maybe<Incidents_Min_Order_By>;
  stddev?: Maybe<Incidents_Stddev_Order_By>;
  stddev_pop?: Maybe<Incidents_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Incidents_Stddev_Samp_Order_By>;
  sum?: Maybe<Incidents_Sum_Order_By>;
  var_pop?: Maybe<Incidents_Var_Pop_Order_By>;
  var_samp?: Maybe<Incidents_Var_Samp_Order_By>;
  variance?: Maybe<Incidents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "incidents" */
export type Incidents_Arr_Rel_Insert_Input = {
  data: Array<Incidents_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Incidents_On_Conflict>;
};

/** aggregate avg on columns */
export type Incidents_Avg_Fields = {
  __typename?: 'incidents_avg_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "incidents" */
export type Incidents_Avg_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "incidents". All fields are combined with a logical 'AND'. */
export type Incidents_Bool_Exp = {
  _and?: Maybe<Array<Incidents_Bool_Exp>>;
  _not?: Maybe<Incidents_Bool_Exp>;
  _or?: Maybe<Array<Incidents_Bool_Exp>>;
  accuracy?: Maybe<Numeric_Comparison_Exp>;
  automatedReportStatus?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  generatedReport?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  incident_type?: Maybe<Incident_Type_Bool_Exp>;
  location?: Maybe<Geometry_Comparison_Exp>;
  loggedBy?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  person?: Maybe<Uuid_Comparison_Exp>;
  personByPerson?: Maybe<Persons_Bool_Exp>;
  property?: Maybe<Properties_Bool_Exp>;
  propertyId?: Maybe<Uuid_Comparison_Exp>;
  s3ImageKey?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  typeId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "incidents" */
export enum Incidents_Constraint {
  /** unique or primary key constraint */
  PkCcb34c01719889017e2246469f9 = 'PK_ccb34c01719889017e2246469f9'
}

/** input type for incrementing numeric columns in table "incidents" */
export type Incidents_Inc_Input = {
  accuracy?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "incidents" */
export type Incidents_Insert_Input = {
  accuracy?: Maybe<Scalars['numeric']>;
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  generatedReport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  incident_type?: Maybe<Incident_Type_Obj_Rel_Insert_Input>;
  location?: Maybe<Scalars['geometry']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['uuid']>;
  personByPerson?: Maybe<Persons_Obj_Rel_Insert_Input>;
  property?: Maybe<Properties_Obj_Rel_Insert_Input>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Incidents_Max_Fields = {
  __typename?: 'incidents_max_fields';
  accuracy?: Maybe<Scalars['numeric']>;
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  generatedReport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "incidents" */
export type Incidents_Max_Order_By = {
  accuracy?: Maybe<Order_By>;
  automatedReportStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  generatedReport?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  loggedBy?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  person?: Maybe<Order_By>;
  propertyId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  typeId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Incidents_Min_Fields = {
  __typename?: 'incidents_min_fields';
  accuracy?: Maybe<Scalars['numeric']>;
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  generatedReport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "incidents" */
export type Incidents_Min_Order_By = {
  accuracy?: Maybe<Order_By>;
  automatedReportStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  generatedReport?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  loggedBy?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  person?: Maybe<Order_By>;
  propertyId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  typeId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "incidents" */
export type Incidents_Mutation_Response = {
  __typename?: 'incidents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Incidents>;
};

/** on conflict condition type for table "incidents" */
export type Incidents_On_Conflict = {
  constraint: Incidents_Constraint;
  update_columns: Array<Incidents_Update_Column>;
  where?: Maybe<Incidents_Bool_Exp>;
};

/** Ordering options when selecting data from "incidents". */
export type Incidents_Order_By = {
  accuracy?: Maybe<Order_By>;
  automatedReportStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  generatedReport?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incident_type?: Maybe<Incident_Type_Order_By>;
  location?: Maybe<Order_By>;
  loggedBy?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  person?: Maybe<Order_By>;
  personByPerson?: Maybe<Persons_Order_By>;
  property?: Maybe<Properties_Order_By>;
  propertyId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  typeId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: incidents */
export type Incidents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "incidents" */
export enum Incidents_Select_Column {
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  AutomatedReportStatus = 'automatedReportStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GeneratedReport = 'generatedReport',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LoggedBy = 'loggedBy',
  /** column name */
  Note = 'note',
  /** column name */
  Person = 'person',
  /** column name */
  PropertyId = 'propertyId',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "incidents" */
export type Incidents_Set_Input = {
  accuracy?: Maybe<Scalars['numeric']>;
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  generatedReport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['geometry']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Incidents_Stddev_Fields = {
  __typename?: 'incidents_stddev_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "incidents" */
export type Incidents_Stddev_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Incidents_Stddev_Pop_Fields = {
  __typename?: 'incidents_stddev_pop_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "incidents" */
export type Incidents_Stddev_Pop_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Incidents_Stddev_Samp_Fields = {
  __typename?: 'incidents_stddev_samp_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "incidents" */
export type Incidents_Stddev_Samp_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Incidents_Sum_Fields = {
  __typename?: 'incidents_sum_fields';
  accuracy?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "incidents" */
export type Incidents_Sum_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** update columns of table "incidents" */
export enum Incidents_Update_Column {
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  AutomatedReportStatus = 'automatedReportStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GeneratedReport = 'generatedReport',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LoggedBy = 'loggedBy',
  /** column name */
  Note = 'note',
  /** column name */
  Person = 'person',
  /** column name */
  PropertyId = 'propertyId',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Incidents_Var_Pop_Fields = {
  __typename?: 'incidents_var_pop_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "incidents" */
export type Incidents_Var_Pop_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Incidents_Var_Samp_Fields = {
  __typename?: 'incidents_var_samp_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "incidents" */
export type Incidents_Var_Samp_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Incidents_Variance_Fields = {
  __typename?: 'incidents_variance_fields';
  accuracy?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "incidents" */
export type Incidents_Variance_Order_By = {
  accuracy?: Maybe<Order_By>;
};

/** columns and relationships of "lpr_datastores" */
export type Lpr_Datastores = {
  __typename?: 'lpr_datastores';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  /** An array relationship */
  agency_lpr_datastores: Array<Agency_Lpr_Datastores>;
  /** An aggregate relationship */
  agency_lpr_datastores_aggregate: Agency_Lpr_Datastores_Aggregate;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  isPublic: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  plates: Array<Plates>;
  /** An aggregate relationship */
  plates_aggregate: Plates_Aggregate;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "lpr_datastores" */
export type Lpr_DatastoresAgency_Lpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


/** columns and relationships of "lpr_datastores" */
export type Lpr_DatastoresAgency_Lpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


/** columns and relationships of "lpr_datastores" */
export type Lpr_DatastoresPlatesArgs = {
  distinct_on?: Maybe<Array<Plates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plates_Order_By>>;
  where?: Maybe<Plates_Bool_Exp>;
};


/** columns and relationships of "lpr_datastores" */
export type Lpr_DatastoresPlates_AggregateArgs = {
  distinct_on?: Maybe<Array<Plates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plates_Order_By>>;
  where?: Maybe<Plates_Bool_Exp>;
};

/** aggregated selection of "lpr_datastores" */
export type Lpr_Datastores_Aggregate = {
  __typename?: 'lpr_datastores_aggregate';
  aggregate?: Maybe<Lpr_Datastores_Aggregate_Fields>;
  nodes: Array<Lpr_Datastores>;
};

/** aggregate fields of "lpr_datastores" */
export type Lpr_Datastores_Aggregate_Fields = {
  __typename?: 'lpr_datastores_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lpr_Datastores_Max_Fields>;
  min?: Maybe<Lpr_Datastores_Min_Fields>;
};


/** aggregate fields of "lpr_datastores" */
export type Lpr_Datastores_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lpr_datastores" */
export type Lpr_Datastores_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Lpr_Datastores_Max_Order_By>;
  min?: Maybe<Lpr_Datastores_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lpr_datastores" */
export type Lpr_Datastores_Arr_Rel_Insert_Input = {
  data: Array<Lpr_Datastores_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lpr_Datastores_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lpr_datastores". All fields are combined with a logical 'AND'. */
export type Lpr_Datastores_Bool_Exp = {
  _and?: Maybe<Array<Lpr_Datastores_Bool_Exp>>;
  _not?: Maybe<Lpr_Datastores_Bool_Exp>;
  _or?: Maybe<Array<Lpr_Datastores_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isPublic?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  ownerId?: Maybe<Uuid_Comparison_Exp>;
  plates?: Maybe<Plates_Bool_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "lpr_datastores" */
export enum Lpr_Datastores_Constraint {
  /** unique or primary key constraint */
  Pk_9edf6424f4eb2b542aa4c62f017 = 'PK_9edf6424f4eb2b542aa4c62f017',
  /** unique or primary key constraint */
  Rel_071befd730babb53ec0950fb42 = 'REL_071befd730babb53ec0950fb42'
}

/** input type for inserting data into table "lpr_datastores" */
export type Lpr_Datastores_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  plates?: Maybe<Plates_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Lpr_Datastores_Max_Fields = {
  __typename?: 'lpr_datastores_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "lpr_datastores" */
export type Lpr_Datastores_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lpr_Datastores_Min_Fields = {
  __typename?: 'lpr_datastores_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "lpr_datastores" */
export type Lpr_Datastores_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "lpr_datastores" */
export type Lpr_Datastores_Mutation_Response = {
  __typename?: 'lpr_datastores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lpr_Datastores>;
};

/** input type for inserting object relation for remote table "lpr_datastores" */
export type Lpr_Datastores_Obj_Rel_Insert_Input = {
  data: Lpr_Datastores_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Lpr_Datastores_On_Conflict>;
};

/** on conflict condition type for table "lpr_datastores" */
export type Lpr_Datastores_On_Conflict = {
  constraint: Lpr_Datastores_Constraint;
  update_columns: Array<Lpr_Datastores_Update_Column>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};

/** Ordering options when selecting data from "lpr_datastores". */
export type Lpr_Datastores_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agency_lpr_datastores_aggregate?: Maybe<Agency_Lpr_Datastores_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isPublic?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  plates_aggregate?: Maybe<Plates_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: lpr_datastores */
export type Lpr_Datastores_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lpr_datastores" */
export enum Lpr_Datastores_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "lpr_datastores" */
export type Lpr_Datastores_Set_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "lpr_datastores" */
export enum Lpr_Datastores_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "lpr_image" */
export type Lpr_Image = {
  __typename?: 'lpr_image';
  id: Scalars['uuid'];
  /** An object relationship */
  plate: Plates;
  plateId: Scalars['uuid'];
  s3ImageKey: Scalars['String'];
};

/** aggregated selection of "lpr_image" */
export type Lpr_Image_Aggregate = {
  __typename?: 'lpr_image_aggregate';
  aggregate?: Maybe<Lpr_Image_Aggregate_Fields>;
  nodes: Array<Lpr_Image>;
};

/** aggregate fields of "lpr_image" */
export type Lpr_Image_Aggregate_Fields = {
  __typename?: 'lpr_image_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lpr_Image_Max_Fields>;
  min?: Maybe<Lpr_Image_Min_Fields>;
};


/** aggregate fields of "lpr_image" */
export type Lpr_Image_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lpr_Image_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lpr_image" */
export type Lpr_Image_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Lpr_Image_Max_Order_By>;
  min?: Maybe<Lpr_Image_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lpr_image" */
export type Lpr_Image_Arr_Rel_Insert_Input = {
  data: Array<Lpr_Image_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lpr_Image_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lpr_image". All fields are combined with a logical 'AND'. */
export type Lpr_Image_Bool_Exp = {
  _and?: Maybe<Array<Lpr_Image_Bool_Exp>>;
  _not?: Maybe<Lpr_Image_Bool_Exp>;
  _or?: Maybe<Array<Lpr_Image_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  plate?: Maybe<Plates_Bool_Exp>;
  plateId?: Maybe<Uuid_Comparison_Exp>;
  s3ImageKey?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lpr_image" */
export enum Lpr_Image_Constraint {
  /** unique or primary key constraint */
  PkDff358afa6bad3fcd2edd8c4a4f = 'PK_dff358afa6bad3fcd2edd8c4a4f'
}

/** input type for inserting data into table "lpr_image" */
export type Lpr_Image_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  plate?: Maybe<Plates_Obj_Rel_Insert_Input>;
  plateId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lpr_Image_Max_Fields = {
  __typename?: 'lpr_image_max_fields';
  id?: Maybe<Scalars['uuid']>;
  plateId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lpr_image" */
export type Lpr_Image_Max_Order_By = {
  id?: Maybe<Order_By>;
  plateId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lpr_Image_Min_Fields = {
  __typename?: 'lpr_image_min_fields';
  id?: Maybe<Scalars['uuid']>;
  plateId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lpr_image" */
export type Lpr_Image_Min_Order_By = {
  id?: Maybe<Order_By>;
  plateId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
};

/** response of any mutation on the table "lpr_image" */
export type Lpr_Image_Mutation_Response = {
  __typename?: 'lpr_image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lpr_Image>;
};

/** on conflict condition type for table "lpr_image" */
export type Lpr_Image_On_Conflict = {
  constraint: Lpr_Image_Constraint;
  update_columns: Array<Lpr_Image_Update_Column>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};

/** Ordering options when selecting data from "lpr_image". */
export type Lpr_Image_Order_By = {
  id?: Maybe<Order_By>;
  plate?: Maybe<Plates_Order_By>;
  plateId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
};

/** primary key columns input for table: lpr_image */
export type Lpr_Image_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lpr_image" */
export enum Lpr_Image_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PlateId = 'plateId',
  /** column name */
  S3ImageKey = 's3ImageKey'
}

/** input type for updating data in table "lpr_image" */
export type Lpr_Image_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  plateId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
};

/** update columns of table "lpr_image" */
export enum Lpr_Image_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PlateId = 'plateId',
  /** column name */
  S3ImageKey = 's3ImageKey'
}

/** columns and relationships of "lpr_incident_type" */
export type Lpr_Incident_Type = {
  __typename?: 'lpr_incident_type';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An array relationship */
  lpr_incidents: Array<Lpr_Incidents>;
  /** An aggregate relationship */
  lpr_incidents_aggregate: Lpr_Incidents_Aggregate;
  name: Scalars['String'];
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "lpr_incident_type" */
export type Lpr_Incident_TypeLpr_IncidentsArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


/** columns and relationships of "lpr_incident_type" */
export type Lpr_Incident_TypeLpr_Incidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};

/** aggregated selection of "lpr_incident_type" */
export type Lpr_Incident_Type_Aggregate = {
  __typename?: 'lpr_incident_type_aggregate';
  aggregate?: Maybe<Lpr_Incident_Type_Aggregate_Fields>;
  nodes: Array<Lpr_Incident_Type>;
};

/** aggregate fields of "lpr_incident_type" */
export type Lpr_Incident_Type_Aggregate_Fields = {
  __typename?: 'lpr_incident_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lpr_Incident_Type_Max_Fields>;
  min?: Maybe<Lpr_Incident_Type_Min_Fields>;
};


/** aggregate fields of "lpr_incident_type" */
export type Lpr_Incident_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lpr_incident_type" */
export type Lpr_Incident_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Lpr_Incident_Type_Max_Order_By>;
  min?: Maybe<Lpr_Incident_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lpr_incident_type" */
export type Lpr_Incident_Type_Arr_Rel_Insert_Input = {
  data: Array<Lpr_Incident_Type_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lpr_Incident_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lpr_incident_type". All fields are combined with a logical 'AND'. */
export type Lpr_Incident_Type_Bool_Exp = {
  _and?: Maybe<Array<Lpr_Incident_Type_Bool_Exp>>;
  _not?: Maybe<Lpr_Incident_Type_Bool_Exp>;
  _or?: Maybe<Array<Lpr_Incident_Type_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lpr_incidents?: Maybe<Lpr_Incidents_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "lpr_incident_type" */
export enum Lpr_Incident_Type_Constraint {
  /** unique or primary key constraint */
  Pk_4ef47af7e6aad0b6389bb71b5b7 = 'PK_4ef47af7e6aad0b6389bb71b5b7'
}

/** input type for inserting data into table "lpr_incident_type" */
export type Lpr_Incident_Type_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  lpr_incidents?: Maybe<Lpr_Incidents_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Lpr_Incident_Type_Max_Fields = {
  __typename?: 'lpr_incident_type_max_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "lpr_incident_type" */
export type Lpr_Incident_Type_Max_Order_By = {
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lpr_Incident_Type_Min_Fields = {
  __typename?: 'lpr_incident_type_min_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "lpr_incident_type" */
export type Lpr_Incident_Type_Min_Order_By = {
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "lpr_incident_type" */
export type Lpr_Incident_Type_Mutation_Response = {
  __typename?: 'lpr_incident_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lpr_Incident_Type>;
};

/** input type for inserting object relation for remote table "lpr_incident_type" */
export type Lpr_Incident_Type_Obj_Rel_Insert_Input = {
  data: Lpr_Incident_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Lpr_Incident_Type_On_Conflict>;
};

/** on conflict condition type for table "lpr_incident_type" */
export type Lpr_Incident_Type_On_Conflict = {
  constraint: Lpr_Incident_Type_Constraint;
  update_columns: Array<Lpr_Incident_Type_Update_Column>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "lpr_incident_type". */
export type Lpr_Incident_Type_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lpr_incidents_aggregate?: Maybe<Lpr_Incidents_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: lpr_incident_type */
export type Lpr_Incident_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lpr_incident_type" */
export enum Lpr_Incident_Type_Select_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "lpr_incident_type" */
export type Lpr_Incident_Type_Set_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "lpr_incident_type" */
export enum Lpr_Incident_Type_Update_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "lpr_incidents" */
export type Lpr_Incidents = {
  __typename?: 'lpr_incidents';
  automatedReportStatus: Scalars['String'];
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  location?: Maybe<Scalars['geometry']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  lpr_incident_type?: Maybe<Lpr_Incident_Type>;
  note: Scalars['String'];
  /** An object relationship */
  plate?: Maybe<Plates>;
  plateId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  property?: Maybe<Properties>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "lpr_incidents" */
export type Lpr_Incidents_Aggregate = {
  __typename?: 'lpr_incidents_aggregate';
  aggregate?: Maybe<Lpr_Incidents_Aggregate_Fields>;
  nodes: Array<Lpr_Incidents>;
};

/** aggregate fields of "lpr_incidents" */
export type Lpr_Incidents_Aggregate_Fields = {
  __typename?: 'lpr_incidents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lpr_Incidents_Max_Fields>;
  min?: Maybe<Lpr_Incidents_Min_Fields>;
};


/** aggregate fields of "lpr_incidents" */
export type Lpr_Incidents_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lpr_incidents" */
export type Lpr_Incidents_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Lpr_Incidents_Max_Order_By>;
  min?: Maybe<Lpr_Incidents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lpr_incidents" */
export type Lpr_Incidents_Arr_Rel_Insert_Input = {
  data: Array<Lpr_Incidents_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lpr_Incidents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lpr_incidents". All fields are combined with a logical 'AND'. */
export type Lpr_Incidents_Bool_Exp = {
  _and?: Maybe<Array<Lpr_Incidents_Bool_Exp>>;
  _not?: Maybe<Lpr_Incidents_Bool_Exp>;
  _or?: Maybe<Array<Lpr_Incidents_Bool_Exp>>;
  automatedReportStatus?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Geometry_Comparison_Exp>;
  loggedBy?: Maybe<Uuid_Comparison_Exp>;
  lpr_incident_type?: Maybe<Lpr_Incident_Type_Bool_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  plate?: Maybe<Plates_Bool_Exp>;
  plateId?: Maybe<Uuid_Comparison_Exp>;
  property?: Maybe<Properties_Bool_Exp>;
  propertyId?: Maybe<Uuid_Comparison_Exp>;
  s3ImageKey?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  typeId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "lpr_incidents" */
export enum Lpr_Incidents_Constraint {
  /** unique or primary key constraint */
  PkDb3178cce833d21a0858d87f378 = 'PK_db3178cce833d21a0858d87f378'
}

/** input type for inserting data into table "lpr_incidents" */
export type Lpr_Incidents_Insert_Input = {
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['geometry']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  lpr_incident_type?: Maybe<Lpr_Incident_Type_Obj_Rel_Insert_Input>;
  note?: Maybe<Scalars['String']>;
  plate?: Maybe<Plates_Obj_Rel_Insert_Input>;
  plateId?: Maybe<Scalars['uuid']>;
  property?: Maybe<Properties_Obj_Rel_Insert_Input>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lpr_Incidents_Max_Fields = {
  __typename?: 'lpr_incidents_max_fields';
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  plateId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "lpr_incidents" */
export type Lpr_Incidents_Max_Order_By = {
  automatedReportStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  loggedBy?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  plateId?: Maybe<Order_By>;
  propertyId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  typeId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lpr_Incidents_Min_Fields = {
  __typename?: 'lpr_incidents_min_fields';
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  plateId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "lpr_incidents" */
export type Lpr_Incidents_Min_Order_By = {
  automatedReportStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  loggedBy?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  plateId?: Maybe<Order_By>;
  propertyId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  typeId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "lpr_incidents" */
export type Lpr_Incidents_Mutation_Response = {
  __typename?: 'lpr_incidents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lpr_Incidents>;
};

/** on conflict condition type for table "lpr_incidents" */
export type Lpr_Incidents_On_Conflict = {
  constraint: Lpr_Incidents_Constraint;
  update_columns: Array<Lpr_Incidents_Update_Column>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};

/** Ordering options when selecting data from "lpr_incidents". */
export type Lpr_Incidents_Order_By = {
  automatedReportStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  loggedBy?: Maybe<Order_By>;
  lpr_incident_type?: Maybe<Lpr_Incident_Type_Order_By>;
  note?: Maybe<Order_By>;
  plate?: Maybe<Plates_Order_By>;
  plateId?: Maybe<Order_By>;
  property?: Maybe<Properties_Order_By>;
  propertyId?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  typeId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: lpr_incidents */
export type Lpr_Incidents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lpr_incidents" */
export enum Lpr_Incidents_Select_Column {
  /** column name */
  AutomatedReportStatus = 'automatedReportStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LoggedBy = 'loggedBy',
  /** column name */
  Note = 'note',
  /** column name */
  PlateId = 'plateId',
  /** column name */
  PropertyId = 'propertyId',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "lpr_incidents" */
export type Lpr_Incidents_Set_Input = {
  automatedReportStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['geometry']>;
  loggedBy?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  plateId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "lpr_incidents" */
export enum Lpr_Incidents_Update_Column {
  /** column name */
  AutomatedReportStatus = 'automatedReportStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LoggedBy = 'loggedBy',
  /** column name */
  Note = 'note',
  /** column name */
  PlateId = 'plateId',
  /** column name */
  PropertyId = 'propertyId',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "migrations" */
export type Migrations = {
  __typename?: 'migrations';
  id: Scalars['Int'];
  name: Scalars['String'];
  timestamp: Scalars['bigint'];
};

/** aggregated selection of "migrations" */
export type Migrations_Aggregate = {
  __typename?: 'migrations_aggregate';
  aggregate?: Maybe<Migrations_Aggregate_Fields>;
  nodes: Array<Migrations>;
};

/** aggregate fields of "migrations" */
export type Migrations_Aggregate_Fields = {
  __typename?: 'migrations_aggregate_fields';
  avg?: Maybe<Migrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Migrations_Max_Fields>;
  min?: Maybe<Migrations_Min_Fields>;
  stddev?: Maybe<Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Migrations_Sum_Fields>;
  var_pop?: Maybe<Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Migrations_Var_Samp_Fields>;
  variance?: Maybe<Migrations_Variance_Fields>;
};


/** aggregate fields of "migrations" */
export type Migrations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Migrations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Migrations_Avg_Fields = {
  __typename?: 'migrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "migrations". All fields are combined with a logical 'AND'. */
export type Migrations_Bool_Exp = {
  _and?: Maybe<Array<Migrations_Bool_Exp>>;
  _not?: Maybe<Migrations_Bool_Exp>;
  _or?: Maybe<Array<Migrations_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  timestamp?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "migrations" */
export enum Migrations_Constraint {
  /** unique or primary key constraint */
  Pk_8c82d7f526340ab734260ea46be = 'PK_8c82d7f526340ab734260ea46be'
}

/** input type for incrementing numeric columns in table "migrations" */
export type Migrations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "migrations" */
export type Migrations_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Migrations_Max_Fields = {
  __typename?: 'migrations_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Migrations_Min_Fields = {
  __typename?: 'migrations_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "migrations" */
export type Migrations_Mutation_Response = {
  __typename?: 'migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Migrations>;
};

/** on conflict condition type for table "migrations" */
export type Migrations_On_Conflict = {
  constraint: Migrations_Constraint;
  update_columns: Array<Migrations_Update_Column>;
  where?: Maybe<Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "migrations". */
export type Migrations_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** primary key columns input for table: migrations */
export type Migrations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "migrations" */
export enum Migrations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "migrations" */
export type Migrations_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Migrations_Stddev_Fields = {
  __typename?: 'migrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Migrations_Stddev_Pop_Fields = {
  __typename?: 'migrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Migrations_Stddev_Samp_Fields = {
  __typename?: 'migrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Migrations_Sum_Fields = {
  __typename?: 'migrations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** update columns of table "migrations" */
export enum Migrations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** aggregate var_pop on columns */
export type Migrations_Var_Pop_Fields = {
  __typename?: 'migrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Migrations_Var_Samp_Fields = {
  __typename?: 'migrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Migrations_Variance_Fields = {
  __typename?: 'migrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createFTPUser?: Maybe<CreateFtpUserOutput>;
  /** delete data from the table: "agencies" */
  delete_agencies?: Maybe<Agencies_Mutation_Response>;
  /** delete single row from the table: "agencies" */
  delete_agencies_by_pk?: Maybe<Agencies>;
  /** delete data from the table: "agency_lpr_datastores" */
  delete_agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Mutation_Response>;
  /** delete single row from the table: "agency_lpr_datastores" */
  delete_agency_lpr_datastores_by_pk?: Maybe<Agency_Lpr_Datastores>;
  /** delete data from the table: "agency_metadata" */
  delete_agency_metadata?: Maybe<Agency_Metadata_Mutation_Response>;
  /** delete single row from the table: "agency_metadata" */
  delete_agency_metadata_by_pk?: Maybe<Agency_Metadata>;
  /** delete data from the table: "agency_person_datastores" */
  delete_agency_person_datastores?: Maybe<Agency_Person_Datastores_Mutation_Response>;
  /** delete single row from the table: "agency_person_datastores" */
  delete_agency_person_datastores_by_pk?: Maybe<Agency_Person_Datastores>;
  /** delete data from the table: "ftp_user" */
  delete_ftp_user?: Maybe<Ftp_User_Mutation_Response>;
  /** delete single row from the table: "ftp_user" */
  delete_ftp_user_by_pk?: Maybe<Ftp_User>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "incident_type" */
  delete_incident_type?: Maybe<Incident_Type_Mutation_Response>;
  /** delete single row from the table: "incident_type" */
  delete_incident_type_by_pk?: Maybe<Incident_Type>;
  /** delete data from the table: "incidents" */
  delete_incidents?: Maybe<Incidents_Mutation_Response>;
  /** delete single row from the table: "incidents" */
  delete_incidents_by_pk?: Maybe<Incidents>;
  /** delete data from the table: "lpr_datastores" */
  delete_lpr_datastores?: Maybe<Lpr_Datastores_Mutation_Response>;
  /** delete single row from the table: "lpr_datastores" */
  delete_lpr_datastores_by_pk?: Maybe<Lpr_Datastores>;
  /** delete data from the table: "lpr_image" */
  delete_lpr_image?: Maybe<Lpr_Image_Mutation_Response>;
  /** delete single row from the table: "lpr_image" */
  delete_lpr_image_by_pk?: Maybe<Lpr_Image>;
  /** delete data from the table: "lpr_incident_type" */
  delete_lpr_incident_type?: Maybe<Lpr_Incident_Type_Mutation_Response>;
  /** delete single row from the table: "lpr_incident_type" */
  delete_lpr_incident_type_by_pk?: Maybe<Lpr_Incident_Type>;
  /** delete data from the table: "lpr_incidents" */
  delete_lpr_incidents?: Maybe<Lpr_Incidents_Mutation_Response>;
  /** delete single row from the table: "lpr_incidents" */
  delete_lpr_incidents_by_pk?: Maybe<Lpr_Incidents>;
  /** delete data from the table: "migrations" */
  delete_migrations?: Maybe<Migrations_Mutation_Response>;
  /** delete single row from the table: "migrations" */
  delete_migrations_by_pk?: Maybe<Migrations>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "person_datastores" */
  delete_person_datastores?: Maybe<Person_Datastores_Mutation_Response>;
  /** delete single row from the table: "person_datastores" */
  delete_person_datastores_by_pk?: Maybe<Person_Datastores>;
  /** delete data from the table: "persons" */
  delete_persons?: Maybe<Persons_Mutation_Response>;
  /** delete single row from the table: "persons" */
  delete_persons_by_pk?: Maybe<Persons>;
  /** delete data from the table: "plates" */
  delete_plates?: Maybe<Plates_Mutation_Response>;
  /** delete single row from the table: "plates" */
  delete_plates_by_pk?: Maybe<Plates>;
  /** delete data from the table: "profile" */
  delete_profile?: Maybe<Profile_Mutation_Response>;
  /** delete data from the table: "properties" */
  delete_properties?: Maybe<Properties_Mutation_Response>;
  /** delete single row from the table: "properties" */
  delete_properties_by_pk?: Maybe<Properties>;
  /** delete data from the table: "property_contacts" */
  delete_property_contacts?: Maybe<Property_Contacts_Mutation_Response>;
  /** delete single row from the table: "property_contacts" */
  delete_property_contacts_by_pk?: Maybe<Property_Contacts>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  generateReport?: Maybe<GenerateReportOutput>;
  /** insert data into the table: "agencies" */
  insert_agencies?: Maybe<Agencies_Mutation_Response>;
  /** insert a single row into the table: "agencies" */
  insert_agencies_one?: Maybe<Agencies>;
  /** insert data into the table: "agency_lpr_datastores" */
  insert_agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Mutation_Response>;
  /** insert a single row into the table: "agency_lpr_datastores" */
  insert_agency_lpr_datastores_one?: Maybe<Agency_Lpr_Datastores>;
  /** insert data into the table: "agency_metadata" */
  insert_agency_metadata?: Maybe<Agency_Metadata_Mutation_Response>;
  /** insert a single row into the table: "agency_metadata" */
  insert_agency_metadata_one?: Maybe<Agency_Metadata>;
  /** insert data into the table: "agency_person_datastores" */
  insert_agency_person_datastores?: Maybe<Agency_Person_Datastores_Mutation_Response>;
  /** insert a single row into the table: "agency_person_datastores" */
  insert_agency_person_datastores_one?: Maybe<Agency_Person_Datastores>;
  /** insert data into the table: "ftp_user" */
  insert_ftp_user?: Maybe<Ftp_User_Mutation_Response>;
  /** insert a single row into the table: "ftp_user" */
  insert_ftp_user_one?: Maybe<Ftp_User>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "incident_type" */
  insert_incident_type?: Maybe<Incident_Type_Mutation_Response>;
  /** insert a single row into the table: "incident_type" */
  insert_incident_type_one?: Maybe<Incident_Type>;
  /** insert data into the table: "incidents" */
  insert_incidents?: Maybe<Incidents_Mutation_Response>;
  /** insert a single row into the table: "incidents" */
  insert_incidents_one?: Maybe<Incidents>;
  /** insert data into the table: "lpr_datastores" */
  insert_lpr_datastores?: Maybe<Lpr_Datastores_Mutation_Response>;
  /** insert a single row into the table: "lpr_datastores" */
  insert_lpr_datastores_one?: Maybe<Lpr_Datastores>;
  /** insert data into the table: "lpr_image" */
  insert_lpr_image?: Maybe<Lpr_Image_Mutation_Response>;
  /** insert a single row into the table: "lpr_image" */
  insert_lpr_image_one?: Maybe<Lpr_Image>;
  /** insert data into the table: "lpr_incident_type" */
  insert_lpr_incident_type?: Maybe<Lpr_Incident_Type_Mutation_Response>;
  /** insert a single row into the table: "lpr_incident_type" */
  insert_lpr_incident_type_one?: Maybe<Lpr_Incident_Type>;
  /** insert data into the table: "lpr_incidents" */
  insert_lpr_incidents?: Maybe<Lpr_Incidents_Mutation_Response>;
  /** insert a single row into the table: "lpr_incidents" */
  insert_lpr_incidents_one?: Maybe<Lpr_Incidents>;
  /** insert data into the table: "migrations" */
  insert_migrations?: Maybe<Migrations_Mutation_Response>;
  /** insert a single row into the table: "migrations" */
  insert_migrations_one?: Maybe<Migrations>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "person_datastores" */
  insert_person_datastores?: Maybe<Person_Datastores_Mutation_Response>;
  /** insert a single row into the table: "person_datastores" */
  insert_person_datastores_one?: Maybe<Person_Datastores>;
  /** insert data into the table: "persons" */
  insert_persons?: Maybe<Persons_Mutation_Response>;
  /** insert a single row into the table: "persons" */
  insert_persons_one?: Maybe<Persons>;
  /** insert data into the table: "plates" */
  insert_plates?: Maybe<Plates_Mutation_Response>;
  /** insert a single row into the table: "plates" */
  insert_plates_one?: Maybe<Plates>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "properties" */
  insert_properties?: Maybe<Properties_Mutation_Response>;
  /** insert a single row into the table: "properties" */
  insert_properties_one?: Maybe<Properties>;
  /** insert data into the table: "property_contacts" */
  insert_property_contacts?: Maybe<Property_Contacts_Mutation_Response>;
  /** insert a single row into the table: "property_contacts" */
  insert_property_contacts_one?: Maybe<Property_Contacts>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  registerDeviceForNotifications?: Maybe<RegisterDeviceOutput>;
  resetPassword?: Maybe<ResetPasswordOutput>;
  searchFace?: Maybe<SearchFaceOutput>;
  searchPlate?: Maybe<SearchPlateOutput>;
  updateFTPUser?: Maybe<UpdateFtpUserOutput>;
  /** update data of the table: "agencies" */
  update_agencies?: Maybe<Agencies_Mutation_Response>;
  /** update single row of the table: "agencies" */
  update_agencies_by_pk?: Maybe<Agencies>;
  /** update data of the table: "agency_lpr_datastores" */
  update_agency_lpr_datastores?: Maybe<Agency_Lpr_Datastores_Mutation_Response>;
  /** update single row of the table: "agency_lpr_datastores" */
  update_agency_lpr_datastores_by_pk?: Maybe<Agency_Lpr_Datastores>;
  /** update data of the table: "agency_metadata" */
  update_agency_metadata?: Maybe<Agency_Metadata_Mutation_Response>;
  /** update single row of the table: "agency_metadata" */
  update_agency_metadata_by_pk?: Maybe<Agency_Metadata>;
  /** update data of the table: "agency_person_datastores" */
  update_agency_person_datastores?: Maybe<Agency_Person_Datastores_Mutation_Response>;
  /** update single row of the table: "agency_person_datastores" */
  update_agency_person_datastores_by_pk?: Maybe<Agency_Person_Datastores>;
  /** update data of the table: "ftp_user" */
  update_ftp_user?: Maybe<Ftp_User_Mutation_Response>;
  /** update single row of the table: "ftp_user" */
  update_ftp_user_by_pk?: Maybe<Ftp_User>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update data of the table: "incident_type" */
  update_incident_type?: Maybe<Incident_Type_Mutation_Response>;
  /** update single row of the table: "incident_type" */
  update_incident_type_by_pk?: Maybe<Incident_Type>;
  /** update data of the table: "incidents" */
  update_incidents?: Maybe<Incidents_Mutation_Response>;
  /** update single row of the table: "incidents" */
  update_incidents_by_pk?: Maybe<Incidents>;
  /** update data of the table: "lpr_datastores" */
  update_lpr_datastores?: Maybe<Lpr_Datastores_Mutation_Response>;
  /** update single row of the table: "lpr_datastores" */
  update_lpr_datastores_by_pk?: Maybe<Lpr_Datastores>;
  /** update data of the table: "lpr_image" */
  update_lpr_image?: Maybe<Lpr_Image_Mutation_Response>;
  /** update single row of the table: "lpr_image" */
  update_lpr_image_by_pk?: Maybe<Lpr_Image>;
  /** update data of the table: "lpr_incident_type" */
  update_lpr_incident_type?: Maybe<Lpr_Incident_Type_Mutation_Response>;
  /** update single row of the table: "lpr_incident_type" */
  update_lpr_incident_type_by_pk?: Maybe<Lpr_Incident_Type>;
  /** update data of the table: "lpr_incidents" */
  update_lpr_incidents?: Maybe<Lpr_Incidents_Mutation_Response>;
  /** update single row of the table: "lpr_incidents" */
  update_lpr_incidents_by_pk?: Maybe<Lpr_Incidents>;
  /** update data of the table: "migrations" */
  update_migrations?: Maybe<Migrations_Mutation_Response>;
  /** update single row of the table: "migrations" */
  update_migrations_by_pk?: Maybe<Migrations>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update data of the table: "person_datastores" */
  update_person_datastores?: Maybe<Person_Datastores_Mutation_Response>;
  /** update single row of the table: "person_datastores" */
  update_person_datastores_by_pk?: Maybe<Person_Datastores>;
  /** update data of the table: "persons" */
  update_persons?: Maybe<Persons_Mutation_Response>;
  /** update single row of the table: "persons" */
  update_persons_by_pk?: Maybe<Persons>;
  /** update data of the table: "plates" */
  update_plates?: Maybe<Plates_Mutation_Response>;
  /** update single row of the table: "plates" */
  update_plates_by_pk?: Maybe<Plates>;
  /** update data of the table: "profile" */
  update_profile?: Maybe<Profile_Mutation_Response>;
  /** update data of the table: "properties" */
  update_properties?: Maybe<Properties_Mutation_Response>;
  /** update single row of the table: "properties" */
  update_properties_by_pk?: Maybe<Properties>;
  /** update data of the table: "property_contacts" */
  update_property_contacts?: Maybe<Property_Contacts_Mutation_Response>;
  /** update single row of the table: "property_contacts" */
  update_property_contacts_by_pk?: Maybe<Property_Contacts>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootCreateFtpUserArgs = {
  params: CreateFtpUserInput;
};


/** mutation root */
export type Mutation_RootDelete_AgenciesArgs = {
  where: Agencies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agencies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Agency_Lpr_DatastoresArgs = {
  where: Agency_Lpr_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agency_Lpr_Datastores_By_PkArgs = {
  agenciesId: Scalars['uuid'];
  lprDatastoresId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Agency_MetadataArgs = {
  where: Agency_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agency_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Agency_Person_DatastoresArgs = {
  where: Agency_Person_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agency_Person_Datastores_By_PkArgs = {
  agenciesId: Scalars['uuid'];
  personDatastoresId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ftp_UserArgs = {
  where: Ftp_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ftp_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Incident_TypeArgs = {
  where: Incident_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Incident_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IncidentsArgs = {
  where: Incidents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Incidents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lpr_DatastoresArgs = {
  where: Lpr_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lpr_Datastores_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lpr_ImageArgs = {
  where: Lpr_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lpr_Image_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lpr_Incident_TypeArgs = {
  where: Lpr_Incident_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lpr_Incident_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lpr_IncidentsArgs = {
  where: Lpr_Incidents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lpr_Incidents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MigrationsArgs = {
  where: Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Person_DatastoresArgs = {
  where: Person_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Person_Datastores_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PersonsArgs = {
  where: Persons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Persons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PlatesArgs = {
  where: Plates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PropertiesArgs = {
  where: Properties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Properties_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Property_ContactsArgs = {
  where: Property_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGenerateReportArgs = {
  params: GenerateReportInput;
};


/** mutation root */
export type Mutation_RootInsert_AgenciesArgs = {
  objects: Array<Agencies_Insert_Input>;
  on_conflict?: Maybe<Agencies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agencies_OneArgs = {
  object: Agencies_Insert_Input;
  on_conflict?: Maybe<Agencies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_Lpr_DatastoresArgs = {
  objects: Array<Agency_Lpr_Datastores_Insert_Input>;
  on_conflict?: Maybe<Agency_Lpr_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_Lpr_Datastores_OneArgs = {
  object: Agency_Lpr_Datastores_Insert_Input;
  on_conflict?: Maybe<Agency_Lpr_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_MetadataArgs = {
  objects: Array<Agency_Metadata_Insert_Input>;
  on_conflict?: Maybe<Agency_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_Metadata_OneArgs = {
  object: Agency_Metadata_Insert_Input;
  on_conflict?: Maybe<Agency_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_Person_DatastoresArgs = {
  objects: Array<Agency_Person_Datastores_Insert_Input>;
  on_conflict?: Maybe<Agency_Person_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_Person_Datastores_OneArgs = {
  object: Agency_Person_Datastores_Insert_Input;
  on_conflict?: Maybe<Agency_Person_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ftp_UserArgs = {
  objects: Array<Ftp_User_Insert_Input>;
  on_conflict?: Maybe<Ftp_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ftp_User_OneArgs = {
  object: Ftp_User_Insert_Input;
  on_conflict?: Maybe<Ftp_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Incident_TypeArgs = {
  objects: Array<Incident_Type_Insert_Input>;
  on_conflict?: Maybe<Incident_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Incident_Type_OneArgs = {
  object: Incident_Type_Insert_Input;
  on_conflict?: Maybe<Incident_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IncidentsArgs = {
  objects: Array<Incidents_Insert_Input>;
  on_conflict?: Maybe<Incidents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Incidents_OneArgs = {
  object: Incidents_Insert_Input;
  on_conflict?: Maybe<Incidents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_DatastoresArgs = {
  objects: Array<Lpr_Datastores_Insert_Input>;
  on_conflict?: Maybe<Lpr_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_Datastores_OneArgs = {
  object: Lpr_Datastores_Insert_Input;
  on_conflict?: Maybe<Lpr_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_ImageArgs = {
  objects: Array<Lpr_Image_Insert_Input>;
  on_conflict?: Maybe<Lpr_Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_Image_OneArgs = {
  object: Lpr_Image_Insert_Input;
  on_conflict?: Maybe<Lpr_Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_Incident_TypeArgs = {
  objects: Array<Lpr_Incident_Type_Insert_Input>;
  on_conflict?: Maybe<Lpr_Incident_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_Incident_Type_OneArgs = {
  object: Lpr_Incident_Type_Insert_Input;
  on_conflict?: Maybe<Lpr_Incident_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_IncidentsArgs = {
  objects: Array<Lpr_Incidents_Insert_Input>;
  on_conflict?: Maybe<Lpr_Incidents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lpr_Incidents_OneArgs = {
  object: Lpr_Incidents_Insert_Input;
  on_conflict?: Maybe<Lpr_Incidents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MigrationsArgs = {
  objects: Array<Migrations_Insert_Input>;
  on_conflict?: Maybe<Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Migrations_OneArgs = {
  object: Migrations_Insert_Input;
  on_conflict?: Maybe<Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: Maybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: Maybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_DatastoresArgs = {
  objects: Array<Person_Datastores_Insert_Input>;
  on_conflict?: Maybe<Person_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_Datastores_OneArgs = {
  object: Person_Datastores_Insert_Input;
  on_conflict?: Maybe<Person_Datastores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonsArgs = {
  objects: Array<Persons_Insert_Input>;
  on_conflict?: Maybe<Persons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Persons_OneArgs = {
  object: Persons_Insert_Input;
  on_conflict?: Maybe<Persons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlatesArgs = {
  objects: Array<Plates_Insert_Input>;
  on_conflict?: Maybe<Plates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plates_OneArgs = {
  object: Plates_Insert_Input;
  on_conflict?: Maybe<Plates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PropertiesArgs = {
  objects: Array<Properties_Insert_Input>;
  on_conflict?: Maybe<Properties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Properties_OneArgs = {
  object: Properties_Insert_Input;
  on_conflict?: Maybe<Properties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_ContactsArgs = {
  objects: Array<Property_Contacts_Insert_Input>;
  on_conflict?: Maybe<Property_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Contacts_OneArgs = {
  object: Property_Contacts_Insert_Input;
  on_conflict?: Maybe<Property_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: Maybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: Maybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRegisterDeviceForNotificationsArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  params: ResetPasswordInput;
};


/** mutation root */
export type Mutation_RootSearchFaceArgs = {
  params: SearchFaceInput;
};


/** mutation root */
export type Mutation_RootSearchPlateArgs = {
  params: SearchPlateInput;
};


/** mutation root */
export type Mutation_RootUpdateFtpUserArgs = {
  params: UpdateFtpUserInput;
};


/** mutation root */
export type Mutation_RootUpdate_AgenciesArgs = {
  _set?: Maybe<Agencies_Set_Input>;
  where: Agencies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agencies_By_PkArgs = {
  _set?: Maybe<Agencies_Set_Input>;
  pk_columns: Agencies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_Lpr_DatastoresArgs = {
  _set?: Maybe<Agency_Lpr_Datastores_Set_Input>;
  where: Agency_Lpr_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_Lpr_Datastores_By_PkArgs = {
  _set?: Maybe<Agency_Lpr_Datastores_Set_Input>;
  pk_columns: Agency_Lpr_Datastores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_MetadataArgs = {
  _inc?: Maybe<Agency_Metadata_Inc_Input>;
  _set?: Maybe<Agency_Metadata_Set_Input>;
  where: Agency_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_Metadata_By_PkArgs = {
  _inc?: Maybe<Agency_Metadata_Inc_Input>;
  _set?: Maybe<Agency_Metadata_Set_Input>;
  pk_columns: Agency_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_Person_DatastoresArgs = {
  _set?: Maybe<Agency_Person_Datastores_Set_Input>;
  where: Agency_Person_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_Person_Datastores_By_PkArgs = {
  _set?: Maybe<Agency_Person_Datastores_Set_Input>;
  pk_columns: Agency_Person_Datastores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ftp_UserArgs = {
  _set?: Maybe<Ftp_User_Set_Input>;
  where: Ftp_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ftp_User_By_PkArgs = {
  _set?: Maybe<Ftp_User_Set_Input>;
  pk_columns: Ftp_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: Maybe<Geometry_Columns_Inc_Input>;
  _set?: Maybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Incident_TypeArgs = {
  _set?: Maybe<Incident_Type_Set_Input>;
  where: Incident_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Incident_Type_By_PkArgs = {
  _set?: Maybe<Incident_Type_Set_Input>;
  pk_columns: Incident_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IncidentsArgs = {
  _inc?: Maybe<Incidents_Inc_Input>;
  _set?: Maybe<Incidents_Set_Input>;
  where: Incidents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Incidents_By_PkArgs = {
  _inc?: Maybe<Incidents_Inc_Input>;
  _set?: Maybe<Incidents_Set_Input>;
  pk_columns: Incidents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_DatastoresArgs = {
  _set?: Maybe<Lpr_Datastores_Set_Input>;
  where: Lpr_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_Datastores_By_PkArgs = {
  _set?: Maybe<Lpr_Datastores_Set_Input>;
  pk_columns: Lpr_Datastores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_ImageArgs = {
  _set?: Maybe<Lpr_Image_Set_Input>;
  where: Lpr_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_Image_By_PkArgs = {
  _set?: Maybe<Lpr_Image_Set_Input>;
  pk_columns: Lpr_Image_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_Incident_TypeArgs = {
  _set?: Maybe<Lpr_Incident_Type_Set_Input>;
  where: Lpr_Incident_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_Incident_Type_By_PkArgs = {
  _set?: Maybe<Lpr_Incident_Type_Set_Input>;
  pk_columns: Lpr_Incident_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_IncidentsArgs = {
  _set?: Maybe<Lpr_Incidents_Set_Input>;
  where: Lpr_Incidents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lpr_Incidents_By_PkArgs = {
  _set?: Maybe<Lpr_Incidents_Set_Input>;
  pk_columns: Lpr_Incidents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MigrationsArgs = {
  _inc?: Maybe<Migrations_Inc_Input>;
  _set?: Maybe<Migrations_Set_Input>;
  where: Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Migrations_By_PkArgs = {
  _inc?: Maybe<Migrations_Inc_Input>;
  _set?: Maybe<Migrations_Set_Input>;
  pk_columns: Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: Maybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: Maybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_DatastoresArgs = {
  _set?: Maybe<Person_Datastores_Set_Input>;
  where: Person_Datastores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Person_Datastores_By_PkArgs = {
  _set?: Maybe<Person_Datastores_Set_Input>;
  pk_columns: Person_Datastores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PersonsArgs = {
  _set?: Maybe<Persons_Set_Input>;
  where: Persons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Persons_By_PkArgs = {
  _set?: Maybe<Persons_Set_Input>;
  pk_columns: Persons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PlatesArgs = {
  _set?: Maybe<Plates_Set_Input>;
  where: Plates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plates_By_PkArgs = {
  _set?: Maybe<Plates_Set_Input>;
  pk_columns: Plates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _set?: Maybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PropertiesArgs = {
  _inc?: Maybe<Properties_Inc_Input>;
  _set?: Maybe<Properties_Set_Input>;
  where: Properties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Properties_By_PkArgs = {
  _inc?: Maybe<Properties_Inc_Input>;
  _set?: Maybe<Properties_Set_Input>;
  pk_columns: Properties_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ContactsArgs = {
  _set?: Maybe<Property_Contacts_Set_Input>;
  where: Property_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Contacts_By_PkArgs = {
  _set?: Maybe<Property_Contacts_Set_Input>;
  pk_columns: Property_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: Maybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: Maybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars['name']>;
  _gt?: Maybe<Scalars['name']>;
  _gte?: Maybe<Scalars['name']>;
  _in?: Maybe<Array<Scalars['name']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['name']>;
  _lte?: Maybe<Scalars['name']>;
  _neq?: Maybe<Scalars['name']>;
  _nin?: Maybe<Array<Scalars['name']>>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  channelId: Scalars['String'];
  createdAt: Scalars['timestamp'];
  destArn: Scalars['String'];
  extras?: Maybe<Scalars['String']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  landingScreen: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  toUserId?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByTouserid?: Maybe<Users>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Notifications_Max_Order_By>;
  min?: Maybe<Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: Maybe<Array<Notifications_Bool_Exp>>;
  _not?: Maybe<Notifications_Bool_Exp>;
  _or?: Maybe<Array<Notifications_Bool_Exp>>;
  channelId?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  destArn?: Maybe<String_Comparison_Exp>;
  extras?: Maybe<String_Comparison_Exp>;
  fromUserId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  landingScreen?: Maybe<String_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  toUserId?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  userByTouserid?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint */
  Pk_6a72c3c0f683f6462415e653c3a = 'PK_6a72c3c0f683f6462415e653c3a'
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  destArn?: Maybe<Scalars['String']>;
  extras?: Maybe<Scalars['String']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  landingScreen?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toUserId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  userByTouserid?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  destArn?: Maybe<Scalars['String']>;
  extras?: Maybe<Scalars['String']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  landingScreen?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toUserId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  channelId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  destArn?: Maybe<Order_By>;
  extras?: Maybe<Order_By>;
  fromUserId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  landingScreen?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  toUserId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  destArn?: Maybe<Scalars['String']>;
  extras?: Maybe<Scalars['String']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  landingScreen?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toUserId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  channelId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  destArn?: Maybe<Order_By>;
  extras?: Maybe<Order_By>;
  fromUserId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  landingScreen?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  toUserId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns: Array<Notifications_Update_Column>;
  where?: Maybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  channelId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  destArn?: Maybe<Order_By>;
  extras?: Maybe<Order_By>;
  fromUserId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  landingScreen?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  toUserId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  userByTouserid?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DestArn = 'destArn',
  /** column name */
  Extras = 'extras',
  /** column name */
  FromUserId = 'fromUserId',
  /** column name */
  Id = 'id',
  /** column name */
  LandingScreen = 'landingScreen',
  /** column name */
  Message = 'message',
  /** column name */
  Title = 'title',
  /** column name */
  ToUserId = 'toUserId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  destArn?: Maybe<Scalars['String']>;
  extras?: Maybe<Scalars['String']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  landingScreen?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toUserId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DestArn = 'destArn',
  /** column name */
  Extras = 'extras',
  /** column name */
  FromUserId = 'fromUserId',
  /** column name */
  Id = 'id',
  /** column name */
  LandingScreen = 'landingScreen',
  /** column name */
  Message = 'message',
  /** column name */
  Title = 'title',
  /** column name */
  ToUserId = 'toUserId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "person_datastores" */
export type Person_Datastores = {
  __typename?: 'person_datastores';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  /** An array relationship */
  agency_person_datastores: Array<Agency_Person_Datastores>;
  /** An aggregate relationship */
  agency_person_datastores_aggregate: Agency_Person_Datastores_Aggregate;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  isPublic: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  persons: Array<Persons>;
  /** An aggregate relationship */
  persons_aggregate: Persons_Aggregate;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "person_datastores" */
export type Person_DatastoresAgency_Person_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


/** columns and relationships of "person_datastores" */
export type Person_DatastoresAgency_Person_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


/** columns and relationships of "person_datastores" */
export type Person_DatastoresPersonsArgs = {
  distinct_on?: Maybe<Array<Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Persons_Order_By>>;
  where?: Maybe<Persons_Bool_Exp>;
};


/** columns and relationships of "person_datastores" */
export type Person_DatastoresPersons_AggregateArgs = {
  distinct_on?: Maybe<Array<Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Persons_Order_By>>;
  where?: Maybe<Persons_Bool_Exp>;
};

/** aggregated selection of "person_datastores" */
export type Person_Datastores_Aggregate = {
  __typename?: 'person_datastores_aggregate';
  aggregate?: Maybe<Person_Datastores_Aggregate_Fields>;
  nodes: Array<Person_Datastores>;
};

/** aggregate fields of "person_datastores" */
export type Person_Datastores_Aggregate_Fields = {
  __typename?: 'person_datastores_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Person_Datastores_Max_Fields>;
  min?: Maybe<Person_Datastores_Min_Fields>;
};


/** aggregate fields of "person_datastores" */
export type Person_Datastores_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Person_Datastores_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "person_datastores" */
export type Person_Datastores_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Person_Datastores_Max_Order_By>;
  min?: Maybe<Person_Datastores_Min_Order_By>;
};

/** input type for inserting array relation for remote table "person_datastores" */
export type Person_Datastores_Arr_Rel_Insert_Input = {
  data: Array<Person_Datastores_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Person_Datastores_On_Conflict>;
};

/** Boolean expression to filter rows from the table "person_datastores". All fields are combined with a logical 'AND'. */
export type Person_Datastores_Bool_Exp = {
  _and?: Maybe<Array<Person_Datastores_Bool_Exp>>;
  _not?: Maybe<Person_Datastores_Bool_Exp>;
  _or?: Maybe<Array<Person_Datastores_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agency_person_datastores?: Maybe<Agency_Person_Datastores_Bool_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isPublic?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  ownerId?: Maybe<Uuid_Comparison_Exp>;
  persons?: Maybe<Persons_Bool_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "person_datastores" */
export enum Person_Datastores_Constraint {
  /** unique or primary key constraint */
  Pk_5e82abd14f721aa381019313041 = 'PK_5e82abd14f721aa381019313041',
  /** unique or primary key constraint */
  RelD3a30fb61514d3a9502befc123 = 'REL_d3a30fb61514d3a9502befc123'
}

/** input type for inserting data into table "person_datastores" */
export type Person_Datastores_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agency_person_datastores?: Maybe<Agency_Person_Datastores_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  persons?: Maybe<Persons_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Person_Datastores_Max_Fields = {
  __typename?: 'person_datastores_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "person_datastores" */
export type Person_Datastores_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Person_Datastores_Min_Fields = {
  __typename?: 'person_datastores_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "person_datastores" */
export type Person_Datastores_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "person_datastores" */
export type Person_Datastores_Mutation_Response = {
  __typename?: 'person_datastores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Person_Datastores>;
};

/** input type for inserting object relation for remote table "person_datastores" */
export type Person_Datastores_Obj_Rel_Insert_Input = {
  data: Person_Datastores_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Person_Datastores_On_Conflict>;
};

/** on conflict condition type for table "person_datastores" */
export type Person_Datastores_On_Conflict = {
  constraint: Person_Datastores_Constraint;
  update_columns: Array<Person_Datastores_Update_Column>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};

/** Ordering options when selecting data from "person_datastores". */
export type Person_Datastores_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agency_person_datastores_aggregate?: Maybe<Agency_Person_Datastores_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isPublic?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  persons_aggregate?: Maybe<Persons_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: person_datastores */
export type Person_Datastores_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "person_datastores" */
export enum Person_Datastores_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "person_datastores" */
export type Person_Datastores_Set_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "person_datastores" */
export enum Person_Datastores_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "persons" */
export type Persons = {
  __typename?: 'persons';
  createdAt: Scalars['timestamp'];
  datastoreId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  idCardImageKey?: Maybe<Scalars['String']>;
  /** An array relationship */
  incidents: Array<Incidents>;
  /** An aggregate relationship */
  incidents_aggregate: Incidents_Aggregate;
  knownToBeViolent: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  listedAs: Scalars['persons_listedas_enum'];
  /** An object relationship */
  person_datastore?: Maybe<Person_Datastores>;
  s3ImageKey: Scalars['String'];
  transcribeJobId?: Maybe<Scalars['String']>;
  trespassStatementAudioFileKey?: Maybe<Scalars['String']>;
  trespassStatementTranscript?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "persons" */
export type PersonsIncidentsArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


/** columns and relationships of "persons" */
export type PersonsIncidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};

/** aggregated selection of "persons" */
export type Persons_Aggregate = {
  __typename?: 'persons_aggregate';
  aggregate?: Maybe<Persons_Aggregate_Fields>;
  nodes: Array<Persons>;
};

/** aggregate fields of "persons" */
export type Persons_Aggregate_Fields = {
  __typename?: 'persons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Persons_Max_Fields>;
  min?: Maybe<Persons_Min_Fields>;
};


/** aggregate fields of "persons" */
export type Persons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Persons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "persons" */
export type Persons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Persons_Max_Order_By>;
  min?: Maybe<Persons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "persons" */
export type Persons_Arr_Rel_Insert_Input = {
  data: Array<Persons_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Persons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "persons". All fields are combined with a logical 'AND'. */
export type Persons_Bool_Exp = {
  _and?: Maybe<Array<Persons_Bool_Exp>>;
  _not?: Maybe<Persons_Bool_Exp>;
  _or?: Maybe<Array<Persons_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  datastoreId?: Maybe<Uuid_Comparison_Exp>;
  dateOfBirth?: Maybe<Date_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  idCardImageKey?: Maybe<String_Comparison_Exp>;
  incidents?: Maybe<Incidents_Bool_Exp>;
  knownToBeViolent?: Maybe<Boolean_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  listedAs?: Maybe<Persons_Listedas_Enum_Comparison_Exp>;
  person_datastore?: Maybe<Person_Datastores_Bool_Exp>;
  s3ImageKey?: Maybe<String_Comparison_Exp>;
  transcribeJobId?: Maybe<String_Comparison_Exp>;
  trespassStatementAudioFileKey?: Maybe<String_Comparison_Exp>;
  trespassStatementTranscript?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "persons" */
export enum Persons_Constraint {
  /** unique or primary key constraint */
  Pk_74278d8812a049233ce41440ac7 = 'PK_74278d8812a049233ce41440ac7'
}

/** input type for inserting data into table "persons" */
export type Persons_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCardImageKey?: Maybe<Scalars['String']>;
  incidents?: Maybe<Incidents_Arr_Rel_Insert_Input>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  listedAs?: Maybe<Scalars['persons_listedas_enum']>;
  person_datastore?: Maybe<Person_Datastores_Obj_Rel_Insert_Input>;
  s3ImageKey?: Maybe<Scalars['String']>;
  transcribeJobId?: Maybe<Scalars['String']>;
  trespassStatementAudioFileKey?: Maybe<Scalars['String']>;
  trespassStatementTranscript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};


/** Boolean expression to compare columns of type "persons_listedas_enum". All fields are combined with logical 'AND'. */
export type Persons_Listedas_Enum_Comparison_Exp = {
  _eq?: Maybe<Scalars['persons_listedas_enum']>;
  _gt?: Maybe<Scalars['persons_listedas_enum']>;
  _gte?: Maybe<Scalars['persons_listedas_enum']>;
  _in?: Maybe<Array<Scalars['persons_listedas_enum']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['persons_listedas_enum']>;
  _lte?: Maybe<Scalars['persons_listedas_enum']>;
  _neq?: Maybe<Scalars['persons_listedas_enum']>;
  _nin?: Maybe<Array<Scalars['persons_listedas_enum']>>;
};

/** aggregate max on columns */
export type Persons_Max_Fields = {
  __typename?: 'persons_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCardImageKey?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  transcribeJobId?: Maybe<Scalars['String']>;
  trespassStatementAudioFileKey?: Maybe<Scalars['String']>;
  trespassStatementTranscript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "persons" */
export type Persons_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  datastoreId?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCardImageKey?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  transcribeJobId?: Maybe<Order_By>;
  trespassStatementAudioFileKey?: Maybe<Order_By>;
  trespassStatementTranscript?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Persons_Min_Fields = {
  __typename?: 'persons_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCardImageKey?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  transcribeJobId?: Maybe<Scalars['String']>;
  trespassStatementAudioFileKey?: Maybe<Scalars['String']>;
  trespassStatementTranscript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "persons" */
export type Persons_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  datastoreId?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCardImageKey?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  transcribeJobId?: Maybe<Order_By>;
  trespassStatementAudioFileKey?: Maybe<Order_By>;
  trespassStatementTranscript?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "persons" */
export type Persons_Mutation_Response = {
  __typename?: 'persons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Persons>;
};

/** input type for inserting object relation for remote table "persons" */
export type Persons_Obj_Rel_Insert_Input = {
  data: Persons_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Persons_On_Conflict>;
};

/** on conflict condition type for table "persons" */
export type Persons_On_Conflict = {
  constraint: Persons_Constraint;
  update_columns: Array<Persons_Update_Column>;
  where?: Maybe<Persons_Bool_Exp>;
};

/** Ordering options when selecting data from "persons". */
export type Persons_Order_By = {
  createdAt?: Maybe<Order_By>;
  datastoreId?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idCardImageKey?: Maybe<Order_By>;
  incidents_aggregate?: Maybe<Incidents_Aggregate_Order_By>;
  knownToBeViolent?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  listedAs?: Maybe<Order_By>;
  person_datastore?: Maybe<Person_Datastores_Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  transcribeJobId?: Maybe<Order_By>;
  trespassStatementAudioFileKey?: Maybe<Order_By>;
  trespassStatementTranscript?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: persons */
export type Persons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "persons" */
export enum Persons_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatastoreId = 'datastoreId',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IdCardImageKey = 'idCardImageKey',
  /** column name */
  KnownToBeViolent = 'knownToBeViolent',
  /** column name */
  LastName = 'lastName',
  /** column name */
  ListedAs = 'listedAs',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  TranscribeJobId = 'transcribeJobId',
  /** column name */
  TrespassStatementAudioFileKey = 'trespassStatementAudioFileKey',
  /** column name */
  TrespassStatementTranscript = 'trespassStatementTranscript',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "persons" */
export type Persons_Set_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idCardImageKey?: Maybe<Scalars['String']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  listedAs?: Maybe<Scalars['persons_listedas_enum']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  transcribeJobId?: Maybe<Scalars['String']>;
  trespassStatementAudioFileKey?: Maybe<Scalars['String']>;
  trespassStatementTranscript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "persons" */
export enum Persons_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatastoreId = 'datastoreId',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IdCardImageKey = 'idCardImageKey',
  /** column name */
  KnownToBeViolent = 'knownToBeViolent',
  /** column name */
  LastName = 'lastName',
  /** column name */
  ListedAs = 'listedAs',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  TranscribeJobId = 'transcribeJobId',
  /** column name */
  TrespassStatementAudioFileKey = 'trespassStatementAudioFileKey',
  /** column name */
  TrespassStatementTranscript = 'trespassStatementTranscript',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "plates" */
export type Plates = {
  __typename?: 'plates';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  datastoreId?: Maybe<Scalars['uuid']>;
  displayNumber?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  knownToBeViolent: Scalars['Boolean'];
  listedAs: Scalars['plates_listedas_enum'];
  /** An object relationship */
  lpr_datastore?: Maybe<Lpr_Datastores>;
  /** An array relationship */
  lpr_images: Array<Lpr_Image>;
  /** An aggregate relationship */
  lpr_images_aggregate: Lpr_Image_Aggregate;
  /** An array relationship */
  lpr_incidents: Array<Lpr_Incidents>;
  /** An aggregate relationship */
  lpr_incidents_aggregate: Lpr_Incidents_Aggregate;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
  year?: Maybe<Scalars['String']>;
};


/** columns and relationships of "plates" */
export type PlatesLpr_ImagesArgs = {
  distinct_on?: Maybe<Array<Lpr_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Image_Order_By>>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};


/** columns and relationships of "plates" */
export type PlatesLpr_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Image_Order_By>>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};


/** columns and relationships of "plates" */
export type PlatesLpr_IncidentsArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


/** columns and relationships of "plates" */
export type PlatesLpr_Incidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};

/** aggregated selection of "plates" */
export type Plates_Aggregate = {
  __typename?: 'plates_aggregate';
  aggregate?: Maybe<Plates_Aggregate_Fields>;
  nodes: Array<Plates>;
};

/** aggregate fields of "plates" */
export type Plates_Aggregate_Fields = {
  __typename?: 'plates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Plates_Max_Fields>;
  min?: Maybe<Plates_Min_Fields>;
};


/** aggregate fields of "plates" */
export type Plates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plates" */
export type Plates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Plates_Max_Order_By>;
  min?: Maybe<Plates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "plates" */
export type Plates_Arr_Rel_Insert_Input = {
  data: Array<Plates_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Plates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "plates". All fields are combined with a logical 'AND'. */
export type Plates_Bool_Exp = {
  _and?: Maybe<Array<Plates_Bool_Exp>>;
  _not?: Maybe<Plates_Bool_Exp>;
  _or?: Maybe<Array<Plates_Bool_Exp>>;
  color?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  datastoreId?: Maybe<Uuid_Comparison_Exp>;
  displayNumber?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  knownToBeViolent?: Maybe<Boolean_Comparison_Exp>;
  listedAs?: Maybe<Plates_Listedas_Enum_Comparison_Exp>;
  lpr_datastore?: Maybe<Lpr_Datastores_Bool_Exp>;
  lpr_images?: Maybe<Lpr_Image_Bool_Exp>;
  lpr_incidents?: Maybe<Lpr_Incidents_Bool_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  number?: Maybe<String_Comparison_Exp>;
  s3ImageKey?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  year?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plates" */
export enum Plates_Constraint {
  /** unique or primary key constraint */
  Pk_9a8950ff576a33188d5afcbdbe6 = 'PK_9a8950ff576a33188d5afcbdbe6'
}

/** input type for inserting data into table "plates" */
export type Plates_Insert_Input = {
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  displayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  listedAs?: Maybe<Scalars['plates_listedas_enum']>;
  lpr_datastore?: Maybe<Lpr_Datastores_Obj_Rel_Insert_Input>;
  lpr_images?: Maybe<Lpr_Image_Arr_Rel_Insert_Input>;
  lpr_incidents?: Maybe<Lpr_Incidents_Arr_Rel_Insert_Input>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  year?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "plates_listedas_enum". All fields are combined with logical 'AND'. */
export type Plates_Listedas_Enum_Comparison_Exp = {
  _eq?: Maybe<Scalars['plates_listedas_enum']>;
  _gt?: Maybe<Scalars['plates_listedas_enum']>;
  _gte?: Maybe<Scalars['plates_listedas_enum']>;
  _in?: Maybe<Array<Scalars['plates_listedas_enum']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['plates_listedas_enum']>;
  _lte?: Maybe<Scalars['plates_listedas_enum']>;
  _neq?: Maybe<Scalars['plates_listedas_enum']>;
  _nin?: Maybe<Array<Scalars['plates_listedas_enum']>>;
};

/** aggregate max on columns */
export type Plates_Max_Fields = {
  __typename?: 'plates_max_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  displayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "plates" */
export type Plates_Max_Order_By = {
  color?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  datastoreId?: Maybe<Order_By>;
  displayNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Plates_Min_Fields = {
  __typename?: 'plates_min_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  displayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "plates" */
export type Plates_Min_Order_By = {
  color?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  datastoreId?: Maybe<Order_By>;
  displayNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "plates" */
export type Plates_Mutation_Response = {
  __typename?: 'plates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plates>;
};

/** input type for inserting object relation for remote table "plates" */
export type Plates_Obj_Rel_Insert_Input = {
  data: Plates_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Plates_On_Conflict>;
};

/** on conflict condition type for table "plates" */
export type Plates_On_Conflict = {
  constraint: Plates_Constraint;
  update_columns: Array<Plates_Update_Column>;
  where?: Maybe<Plates_Bool_Exp>;
};

/** Ordering options when selecting data from "plates". */
export type Plates_Order_By = {
  color?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  datastoreId?: Maybe<Order_By>;
  displayNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  knownToBeViolent?: Maybe<Order_By>;
  listedAs?: Maybe<Order_By>;
  lpr_datastore?: Maybe<Lpr_Datastores_Order_By>;
  lpr_images_aggregate?: Maybe<Lpr_Image_Aggregate_Order_By>;
  lpr_incidents_aggregate?: Maybe<Lpr_Incidents_Aggregate_Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  s3ImageKey?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: plates */
export type Plates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "plates" */
export enum Plates_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatastoreId = 'datastoreId',
  /** column name */
  DisplayNumber = 'displayNumber',
  /** column name */
  Id = 'id',
  /** column name */
  KnownToBeViolent = 'knownToBeViolent',
  /** column name */
  ListedAs = 'listedAs',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Number = 'number',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "plates" */
export type Plates_Set_Input = {
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  datastoreId?: Maybe<Scalars['uuid']>;
  displayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  listedAs?: Maybe<Scalars['plates_listedas_enum']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  year?: Maybe<Scalars['String']>;
};

/** update columns of table "plates" */
export enum Plates_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatastoreId = 'datastoreId',
  /** column name */
  DisplayNumber = 'displayNumber',
  /** column name */
  Id = 'id',
  /** column name */
  KnownToBeViolent = 'knownToBeViolent',
  /** column name */
  ListedAs = 'listedAs',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Number = 'number',
  /** column name */
  S3ImageKey = 's3ImageKey',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Year = 'year'
}

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['users_role_enum']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: Maybe<Array<Profile_Bool_Exp>>;
  _not?: Maybe<Profile_Bool_Exp>;
  _or?: Maybe<Array<Profile_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  avatarUrl?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  dateOfBirth?: Maybe<Date_Comparison_Exp>;
  deviceId?: Maybe<String_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fcmToken?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  nickName?: Maybe<String_Comparison_Exp>;
  role?: Maybe<Users_Role_Enum_Comparison_Exp>;
  snsEndpoint?: Maybe<String_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['users_role_enum']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** Ordering options when selecting data from "profile". */
export type Profile_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agencyId?: Maybe<Order_By>;
  avatarUrl?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  deviceId?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcmToken?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  nickName?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  snsEndpoint?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  DeviceId = 'deviceId',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FcmToken = 'fcmToken',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  NickName = 'nickName',
  /** column name */
  Role = 'role',
  /** column name */
  SnsEndpoint = 'snsEndpoint',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['users_role_enum']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "properties" */
export type Properties = {
  __typename?: 'properties';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  /** An object relationship */
  agency?: Maybe<Agencies>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An array relationship */
  incidents: Array<Incidents>;
  /** An aggregate relationship */
  incidents_aggregate: Incidents_Aggregate;
  location?: Maybe<Scalars['geometry']>;
  /** An array relationship */
  lpr_incidents: Array<Lpr_Incidents>;
  /** An aggregate relationship */
  lpr_incidents_aggregate: Lpr_Incidents_Aggregate;
  name: Scalars['String'];
  prefferedReportingMethod: Scalars['String'];
  /** An array relationship */
  property_contacts: Array<Property_Contacts>;
  /** An aggregate relationship */
  property_contacts_aggregate: Property_Contacts_Aggregate;
  reportingTime?: Maybe<Scalars['Int']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "properties" */
export type PropertiesIncidentsArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


/** columns and relationships of "properties" */
export type PropertiesIncidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


/** columns and relationships of "properties" */
export type PropertiesLpr_IncidentsArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


/** columns and relationships of "properties" */
export type PropertiesLpr_Incidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


/** columns and relationships of "properties" */
export type PropertiesProperty_ContactsArgs = {
  distinct_on?: Maybe<Array<Property_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Contacts_Order_By>>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};


/** columns and relationships of "properties" */
export type PropertiesProperty_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Property_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Contacts_Order_By>>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};

/** aggregated selection of "properties" */
export type Properties_Aggregate = {
  __typename?: 'properties_aggregate';
  aggregate?: Maybe<Properties_Aggregate_Fields>;
  nodes: Array<Properties>;
};

/** aggregate fields of "properties" */
export type Properties_Aggregate_Fields = {
  __typename?: 'properties_aggregate_fields';
  avg?: Maybe<Properties_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Properties_Max_Fields>;
  min?: Maybe<Properties_Min_Fields>;
  stddev?: Maybe<Properties_Stddev_Fields>;
  stddev_pop?: Maybe<Properties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Properties_Stddev_Samp_Fields>;
  sum?: Maybe<Properties_Sum_Fields>;
  var_pop?: Maybe<Properties_Var_Pop_Fields>;
  var_samp?: Maybe<Properties_Var_Samp_Fields>;
  variance?: Maybe<Properties_Variance_Fields>;
};


/** aggregate fields of "properties" */
export type Properties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Properties_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "properties" */
export type Properties_Aggregate_Order_By = {
  avg?: Maybe<Properties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Properties_Max_Order_By>;
  min?: Maybe<Properties_Min_Order_By>;
  stddev?: Maybe<Properties_Stddev_Order_By>;
  stddev_pop?: Maybe<Properties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Properties_Stddev_Samp_Order_By>;
  sum?: Maybe<Properties_Sum_Order_By>;
  var_pop?: Maybe<Properties_Var_Pop_Order_By>;
  var_samp?: Maybe<Properties_Var_Samp_Order_By>;
  variance?: Maybe<Properties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "properties" */
export type Properties_Arr_Rel_Insert_Input = {
  data: Array<Properties_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Properties_On_Conflict>;
};

/** aggregate avg on columns */
export type Properties_Avg_Fields = {
  __typename?: 'properties_avg_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "properties" */
export type Properties_Avg_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "properties". All fields are combined with a logical 'AND'. */
export type Properties_Bool_Exp = {
  _and?: Maybe<Array<Properties_Bool_Exp>>;
  _not?: Maybe<Properties_Bool_Exp>;
  _or?: Maybe<Array<Properties_Bool_Exp>>;
  address1?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  incidents?: Maybe<Incidents_Bool_Exp>;
  location?: Maybe<Geometry_Comparison_Exp>;
  lpr_incidents?: Maybe<Lpr_Incidents_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  prefferedReportingMethod?: Maybe<String_Comparison_Exp>;
  property_contacts?: Maybe<Property_Contacts_Bool_Exp>;
  reportingTime?: Maybe<Int_Comparison_Exp>;
  topicArn?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "properties" */
export enum Properties_Constraint {
  /** unique or primary key constraint */
  Pk_2d83bfa0b9fcd45dee1785af44d = 'PK_2d83bfa0b9fcd45dee1785af44d'
}

/** input type for incrementing numeric columns in table "properties" */
export type Properties_Inc_Input = {
  reportingTime?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "properties" */
export type Properties_Insert_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  incidents?: Maybe<Incidents_Arr_Rel_Insert_Input>;
  location?: Maybe<Scalars['geometry']>;
  lpr_incidents?: Maybe<Lpr_Incidents_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
  property_contacts?: Maybe<Property_Contacts_Arr_Rel_Insert_Input>;
  reportingTime?: Maybe<Scalars['Int']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Properties_Max_Fields = {
  __typename?: 'properties_max_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
  reportingTime?: Maybe<Scalars['Int']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "properties" */
export type Properties_Max_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  prefferedReportingMethod?: Maybe<Order_By>;
  reportingTime?: Maybe<Order_By>;
  topicArn?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Properties_Min_Fields = {
  __typename?: 'properties_min_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
  reportingTime?: Maybe<Scalars['Int']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "properties" */
export type Properties_Min_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  prefferedReportingMethod?: Maybe<Order_By>;
  reportingTime?: Maybe<Order_By>;
  topicArn?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "properties" */
export type Properties_Mutation_Response = {
  __typename?: 'properties_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Properties>;
};

/** input type for inserting object relation for remote table "properties" */
export type Properties_Obj_Rel_Insert_Input = {
  data: Properties_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Properties_On_Conflict>;
};

/** on conflict condition type for table "properties" */
export type Properties_On_Conflict = {
  constraint: Properties_Constraint;
  update_columns: Array<Properties_Update_Column>;
  where?: Maybe<Properties_Bool_Exp>;
};

/** Ordering options when selecting data from "properties". */
export type Properties_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  agency?: Maybe<Agencies_Order_By>;
  agencyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incidents_aggregate?: Maybe<Incidents_Aggregate_Order_By>;
  location?: Maybe<Order_By>;
  lpr_incidents_aggregate?: Maybe<Lpr_Incidents_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  prefferedReportingMethod?: Maybe<Order_By>;
  property_contacts_aggregate?: Maybe<Property_Contacts_Aggregate_Order_By>;
  reportingTime?: Maybe<Order_By>;
  topicArn?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: properties */
export type Properties_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "properties" */
export enum Properties_Select_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  PrefferedReportingMethod = 'prefferedReportingMethod',
  /** column name */
  ReportingTime = 'reportingTime',
  /** column name */
  TopicArn = 'topicArn',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "properties" */
export type Properties_Set_Input = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['geometry']>;
  name?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
  reportingTime?: Maybe<Scalars['Int']>;
  topicArn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Properties_Stddev_Fields = {
  __typename?: 'properties_stddev_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "properties" */
export type Properties_Stddev_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Properties_Stddev_Pop_Fields = {
  __typename?: 'properties_stddev_pop_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "properties" */
export type Properties_Stddev_Pop_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Properties_Stddev_Samp_Fields = {
  __typename?: 'properties_stddev_samp_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "properties" */
export type Properties_Stddev_Samp_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Properties_Sum_Fields = {
  __typename?: 'properties_sum_fields';
  reportingTime?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "properties" */
export type Properties_Sum_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** update columns of table "properties" */
export enum Properties_Update_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  PrefferedReportingMethod = 'prefferedReportingMethod',
  /** column name */
  ReportingTime = 'reportingTime',
  /** column name */
  TopicArn = 'topicArn',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Properties_Var_Pop_Fields = {
  __typename?: 'properties_var_pop_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "properties" */
export type Properties_Var_Pop_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Properties_Var_Samp_Fields = {
  __typename?: 'properties_var_samp_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "properties" */
export type Properties_Var_Samp_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Properties_Variance_Fields = {
  __typename?: 'properties_variance_fields';
  reportingTime?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "properties" */
export type Properties_Variance_Order_By = {
  reportingTime?: Maybe<Order_By>;
};

/** columns and relationships of "property_contacts" */
export type Property_Contacts = {
  __typename?: 'property_contacts';
  createdAt: Scalars['timestamp'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  /** An object relationship */
  property?: Maybe<Properties>;
  propertyId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "property_contacts" */
export type Property_Contacts_Aggregate = {
  __typename?: 'property_contacts_aggregate';
  aggregate?: Maybe<Property_Contacts_Aggregate_Fields>;
  nodes: Array<Property_Contacts>;
};

/** aggregate fields of "property_contacts" */
export type Property_Contacts_Aggregate_Fields = {
  __typename?: 'property_contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Property_Contacts_Max_Fields>;
  min?: Maybe<Property_Contacts_Min_Fields>;
};


/** aggregate fields of "property_contacts" */
export type Property_Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Property_Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "property_contacts" */
export type Property_Contacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Property_Contacts_Max_Order_By>;
  min?: Maybe<Property_Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "property_contacts" */
export type Property_Contacts_Arr_Rel_Insert_Input = {
  data: Array<Property_Contacts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Property_Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "property_contacts". All fields are combined with a logical 'AND'. */
export type Property_Contacts_Bool_Exp = {
  _and?: Maybe<Array<Property_Contacts_Bool_Exp>>;
  _not?: Maybe<Property_Contacts_Bool_Exp>;
  _or?: Maybe<Array<Property_Contacts_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  position?: Maybe<String_Comparison_Exp>;
  property?: Maybe<Properties_Bool_Exp>;
  propertyId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_contacts" */
export enum Property_Contacts_Constraint {
  /** unique or primary key constraint */
  Pk_5f4ed030119a5599b5cbc3d2606 = 'PK_5f4ed030119a5599b5cbc3d2606'
}

/** input type for inserting data into table "property_contacts" */
export type Property_Contacts_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  property?: Maybe<Properties_Obj_Rel_Insert_Input>;
  propertyId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Property_Contacts_Max_Fields = {
  __typename?: 'property_contacts_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "property_contacts" */
export type Property_Contacts_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  propertyId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Contacts_Min_Fields = {
  __typename?: 'property_contacts_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "property_contacts" */
export type Property_Contacts_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  propertyId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "property_contacts" */
export type Property_Contacts_Mutation_Response = {
  __typename?: 'property_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Contacts>;
};

/** on conflict condition type for table "property_contacts" */
export type Property_Contacts_On_Conflict = {
  constraint: Property_Contacts_Constraint;
  update_columns: Array<Property_Contacts_Update_Column>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "property_contacts". */
export type Property_Contacts_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  property?: Maybe<Properties_Order_By>;
  propertyId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: property_contacts */
export type Property_Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "property_contacts" */
export enum Property_Contacts_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  PropertyId = 'propertyId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "property_contacts" */
export type Property_Contacts_Set_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "property_contacts" */
export enum Property_Contacts_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  PropertyId = 'propertyId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "agencies" */
  agencies: Array<Agencies>;
  /** fetch aggregated fields from the table: "agencies" */
  agencies_aggregate: Agencies_Aggregate;
  /** fetch data from the table: "agencies" using primary key columns */
  agencies_by_pk?: Maybe<Agencies>;
  /** An array relationship */
  agency_lpr_datastores: Array<Agency_Lpr_Datastores>;
  /** An aggregate relationship */
  agency_lpr_datastores_aggregate: Agency_Lpr_Datastores_Aggregate;
  /** fetch data from the table: "agency_lpr_datastores" using primary key columns */
  agency_lpr_datastores_by_pk?: Maybe<Agency_Lpr_Datastores>;
  /** fetch data from the table: "agency_metadata" */
  agency_metadata: Array<Agency_Metadata>;
  /** An aggregate relationship */
  agency_metadata_aggregate: Agency_Metadata_Aggregate;
  /** fetch data from the table: "agency_metadata" using primary key columns */
  agency_metadata_by_pk?: Maybe<Agency_Metadata>;
  /** An array relationship */
  agency_person_datastores: Array<Agency_Person_Datastores>;
  /** An aggregate relationship */
  agency_person_datastores_aggregate: Agency_Person_Datastores_Aggregate;
  /** fetch data from the table: "agency_person_datastores" using primary key columns */
  agency_person_datastores_by_pk?: Maybe<Agency_Person_Datastores>;
  /** fetch data from the table: "agency_users_view" */
  agency_users_view: Array<Agency_Users_View>;
  /** fetch aggregated fields from the table: "agency_users_view" */
  agency_users_view_aggregate: Agency_Users_View_Aggregate;
  /** fetch data from the table: "ftp_user" */
  ftp_user: Array<Ftp_User>;
  /** fetch aggregated fields from the table: "ftp_user" */
  ftp_user_aggregate: Ftp_User_Aggregate;
  /** fetch data from the table: "ftp_user" using primary key columns */
  ftp_user_by_pk?: Maybe<Ftp_User>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table: "incident_type" */
  incident_type: Array<Incident_Type>;
  /** fetch aggregated fields from the table: "incident_type" */
  incident_type_aggregate: Incident_Type_Aggregate;
  /** fetch data from the table: "incident_type" using primary key columns */
  incident_type_by_pk?: Maybe<Incident_Type>;
  /** An array relationship */
  incidents: Array<Incidents>;
  /** An aggregate relationship */
  incidents_aggregate: Incidents_Aggregate;
  /** fetch data from the table: "incidents" using primary key columns */
  incidents_by_pk?: Maybe<Incidents>;
  /** An array relationship */
  lpr_datastores: Array<Lpr_Datastores>;
  /** An aggregate relationship */
  lpr_datastores_aggregate: Lpr_Datastores_Aggregate;
  /** fetch data from the table: "lpr_datastores" using primary key columns */
  lpr_datastores_by_pk?: Maybe<Lpr_Datastores>;
  /** fetch data from the table: "lpr_image" */
  lpr_image: Array<Lpr_Image>;
  /** fetch aggregated fields from the table: "lpr_image" */
  lpr_image_aggregate: Lpr_Image_Aggregate;
  /** fetch data from the table: "lpr_image" using primary key columns */
  lpr_image_by_pk?: Maybe<Lpr_Image>;
  /** fetch data from the table: "lpr_incident_type" */
  lpr_incident_type: Array<Lpr_Incident_Type>;
  /** fetch aggregated fields from the table: "lpr_incident_type" */
  lpr_incident_type_aggregate: Lpr_Incident_Type_Aggregate;
  /** fetch data from the table: "lpr_incident_type" using primary key columns */
  lpr_incident_type_by_pk?: Maybe<Lpr_Incident_Type>;
  /** An array relationship */
  lpr_incidents: Array<Lpr_Incidents>;
  /** An aggregate relationship */
  lpr_incidents_aggregate: Lpr_Incidents_Aggregate;
  /** fetch data from the table: "lpr_incidents" using primary key columns */
  lpr_incidents_by_pk?: Maybe<Lpr_Incidents>;
  /** fetch data from the table: "migrations" */
  migrations: Array<Migrations>;
  /** fetch aggregated fields from the table: "migrations" */
  migrations_aggregate: Migrations_Aggregate;
  /** fetch data from the table: "migrations" using primary key columns */
  migrations_by_pk?: Maybe<Migrations>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** An array relationship */
  person_datastores: Array<Person_Datastores>;
  /** An aggregate relationship */
  person_datastores_aggregate: Person_Datastores_Aggregate;
  /** fetch data from the table: "person_datastores" using primary key columns */
  person_datastores_by_pk?: Maybe<Person_Datastores>;
  /** An array relationship */
  persons: Array<Persons>;
  /** An aggregate relationship */
  persons_aggregate: Persons_Aggregate;
  /** fetch data from the table: "persons" using primary key columns */
  persons_by_pk?: Maybe<Persons>;
  /** An array relationship */
  plates: Array<Plates>;
  /** An aggregate relationship */
  plates_aggregate: Plates_Aggregate;
  /** fetch data from the table: "plates" using primary key columns */
  plates_by_pk?: Maybe<Plates>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** An array relationship */
  properties: Array<Properties>;
  /** An aggregate relationship */
  properties_aggregate: Properties_Aggregate;
  /** fetch data from the table: "properties" using primary key columns */
  properties_by_pk?: Maybe<Properties>;
  /** An array relationship */
  property_contacts: Array<Property_Contacts>;
  /** An aggregate relationship */
  property_contacts_aggregate: Property_Contacts_Aggregate;
  /** fetch data from the table: "property_contacts" using primary key columns */
  property_contacts_by_pk?: Maybe<Property_Contacts>;
  /** fetch data from the table: "raster_columns" */
  raster_columns: Array<Raster_Columns>;
  /** fetch aggregated fields from the table: "raster_columns" */
  raster_columns_aggregate: Raster_Columns_Aggregate;
  /** fetch data from the table: "raster_overviews" */
  raster_overviews: Array<Raster_Overviews>;
  /** fetch aggregated fields from the table: "raster_overviews" */
  raster_overviews_aggregate: Raster_Overviews_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAgenciesArgs = {
  distinct_on?: Maybe<Array<Agencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agencies_Order_By>>;
  where?: Maybe<Agencies_Bool_Exp>;
};


export type Query_RootAgencies_AggregateArgs = {
  distinct_on?: Maybe<Array<Agencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agencies_Order_By>>;
  where?: Maybe<Agencies_Bool_Exp>;
};


export type Query_RootAgencies_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAgency_Lpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


export type Query_RootAgency_Lpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


export type Query_RootAgency_Lpr_Datastores_By_PkArgs = {
  agenciesId: Scalars['uuid'];
  lprDatastoresId: Scalars['uuid'];
};


export type Query_RootAgency_MetadataArgs = {
  distinct_on?: Maybe<Array<Agency_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Metadata_Order_By>>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};


export type Query_RootAgency_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Metadata_Order_By>>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};


export type Query_RootAgency_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAgency_Person_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


export type Query_RootAgency_Person_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


export type Query_RootAgency_Person_Datastores_By_PkArgs = {
  agenciesId: Scalars['uuid'];
  personDatastoresId: Scalars['uuid'];
};


export type Query_RootAgency_Users_ViewArgs = {
  distinct_on?: Maybe<Array<Agency_Users_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Users_View_Order_By>>;
  where?: Maybe<Agency_Users_View_Bool_Exp>;
};


export type Query_RootAgency_Users_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Users_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Users_View_Order_By>>;
  where?: Maybe<Agency_Users_View_Bool_Exp>;
};


export type Query_RootFtp_UserArgs = {
  distinct_on?: Maybe<Array<Ftp_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ftp_User_Order_By>>;
  where?: Maybe<Ftp_User_Bool_Exp>;
};


export type Query_RootFtp_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Ftp_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ftp_User_Order_By>>;
  where?: Maybe<Ftp_User_Bool_Exp>;
};


export type Query_RootFtp_User_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootIncident_TypeArgs = {
  distinct_on?: Maybe<Array<Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incident_Type_Order_By>>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};


export type Query_RootIncident_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incident_Type_Order_By>>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};


export type Query_RootIncident_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIncidentsArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


export type Query_RootIncidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


export type Query_RootIncidents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Datastores_Order_By>>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};


export type Query_RootLpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Datastores_Order_By>>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};


export type Query_RootLpr_Datastores_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLpr_ImageArgs = {
  distinct_on?: Maybe<Array<Lpr_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Image_Order_By>>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};


export type Query_RootLpr_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Image_Order_By>>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};


export type Query_RootLpr_Image_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLpr_Incident_TypeArgs = {
  distinct_on?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incident_Type_Order_By>>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};


export type Query_RootLpr_Incident_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incident_Type_Order_By>>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};


export type Query_RootLpr_Incident_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLpr_IncidentsArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


export type Query_RootLpr_Incidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


export type Query_RootLpr_Incidents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMigrationsArgs = {
  distinct_on?: Maybe<Array<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Query_RootMigrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Query_RootMigrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPerson_DatastoresArgs = {
  distinct_on?: Maybe<Array<Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Person_Datastores_Order_By>>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};


export type Query_RootPerson_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Person_Datastores_Order_By>>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};


export type Query_RootPerson_Datastores_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPersonsArgs = {
  distinct_on?: Maybe<Array<Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Persons_Order_By>>;
  where?: Maybe<Persons_Bool_Exp>;
};


export type Query_RootPersons_AggregateArgs = {
  distinct_on?: Maybe<Array<Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Persons_Order_By>>;
  where?: Maybe<Persons_Bool_Exp>;
};


export type Query_RootPersons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlatesArgs = {
  distinct_on?: Maybe<Array<Plates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plates_Order_By>>;
  where?: Maybe<Plates_Bool_Exp>;
};


export type Query_RootPlates_AggregateArgs = {
  distinct_on?: Maybe<Array<Plates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plates_Order_By>>;
  where?: Maybe<Plates_Bool_Exp>;
};


export type Query_RootPlates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Query_RootPropertiesArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Properties_Order_By>>;
  where?: Maybe<Properties_Bool_Exp>;
};


export type Query_RootProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Properties_Order_By>>;
  where?: Maybe<Properties_Bool_Exp>;
};


export type Query_RootProperties_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProperty_ContactsArgs = {
  distinct_on?: Maybe<Array<Property_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Contacts_Order_By>>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};


export type Query_RootProperty_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Property_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Contacts_Order_By>>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};


export type Query_RootProperty_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRaster_ColumnsArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Query_RootRaster_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Query_RootRaster_OverviewsArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Query_RootRaster_Overviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "raster_columns" */
export type Raster_Columns = {
  __typename?: 'raster_columns';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  extent?: Maybe<Scalars['geometry']>;
  nodata_values?: Maybe<Scalars['_float8']>;
  num_bands?: Maybe<Scalars['Int']>;
  out_db?: Maybe<Scalars['_bool']>;
  pixel_types?: Maybe<Scalars['_text']>;
  r_raster_column?: Maybe<Scalars['name']>;
  r_table_catalog?: Maybe<Scalars['name']>;
  r_table_name?: Maybe<Scalars['name']>;
  r_table_schema?: Maybe<Scalars['name']>;
  regular_blocking?: Maybe<Scalars['Boolean']>;
  same_alignment?: Maybe<Scalars['Boolean']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  spatial_index?: Maybe<Scalars['Boolean']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "raster_columns" */
export type Raster_Columns_Aggregate = {
  __typename?: 'raster_columns_aggregate';
  aggregate?: Maybe<Raster_Columns_Aggregate_Fields>;
  nodes: Array<Raster_Columns>;
};

/** aggregate fields of "raster_columns" */
export type Raster_Columns_Aggregate_Fields = {
  __typename?: 'raster_columns_aggregate_fields';
  avg?: Maybe<Raster_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raster_Columns_Max_Fields>;
  min?: Maybe<Raster_Columns_Min_Fields>;
  stddev?: Maybe<Raster_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Raster_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raster_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Raster_Columns_Sum_Fields>;
  var_pop?: Maybe<Raster_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Raster_Columns_Var_Samp_Fields>;
  variance?: Maybe<Raster_Columns_Variance_Fields>;
};


/** aggregate fields of "raster_columns" */
export type Raster_Columns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Raster_Columns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Raster_Columns_Avg_Fields = {
  __typename?: 'raster_columns_avg_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "raster_columns". All fields are combined with a logical 'AND'. */
export type Raster_Columns_Bool_Exp = {
  _and?: Maybe<Array<Raster_Columns_Bool_Exp>>;
  _not?: Maybe<Raster_Columns_Bool_Exp>;
  _or?: Maybe<Array<Raster_Columns_Bool_Exp>>;
  blocksize_x?: Maybe<Int_Comparison_Exp>;
  blocksize_y?: Maybe<Int_Comparison_Exp>;
  extent?: Maybe<Geometry_Comparison_Exp>;
  nodata_values?: Maybe<_Float8_Comparison_Exp>;
  num_bands?: Maybe<Int_Comparison_Exp>;
  out_db?: Maybe<_Bool_Comparison_Exp>;
  pixel_types?: Maybe<_Text_Comparison_Exp>;
  r_raster_column?: Maybe<Name_Comparison_Exp>;
  r_table_catalog?: Maybe<Name_Comparison_Exp>;
  r_table_name?: Maybe<Name_Comparison_Exp>;
  r_table_schema?: Maybe<Name_Comparison_Exp>;
  regular_blocking?: Maybe<Boolean_Comparison_Exp>;
  same_alignment?: Maybe<Boolean_Comparison_Exp>;
  scale_x?: Maybe<Float8_Comparison_Exp>;
  scale_y?: Maybe<Float8_Comparison_Exp>;
  spatial_index?: Maybe<Boolean_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Raster_Columns_Max_Fields = {
  __typename?: 'raster_columns_max_fields';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  num_bands?: Maybe<Scalars['Int']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Raster_Columns_Min_Fields = {
  __typename?: 'raster_columns_min_fields';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  num_bands?: Maybe<Scalars['Int']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "raster_columns". */
export type Raster_Columns_Order_By = {
  blocksize_x?: Maybe<Order_By>;
  blocksize_y?: Maybe<Order_By>;
  extent?: Maybe<Order_By>;
  nodata_values?: Maybe<Order_By>;
  num_bands?: Maybe<Order_By>;
  out_db?: Maybe<Order_By>;
  pixel_types?: Maybe<Order_By>;
  r_raster_column?: Maybe<Order_By>;
  r_table_catalog?: Maybe<Order_By>;
  r_table_name?: Maybe<Order_By>;
  r_table_schema?: Maybe<Order_By>;
  regular_blocking?: Maybe<Order_By>;
  same_alignment?: Maybe<Order_By>;
  scale_x?: Maybe<Order_By>;
  scale_y?: Maybe<Order_By>;
  spatial_index?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
};

/** select columns of table "raster_columns" */
export enum Raster_Columns_Select_Column {
  /** column name */
  BlocksizeX = 'blocksize_x',
  /** column name */
  BlocksizeY = 'blocksize_y',
  /** column name */
  Extent = 'extent',
  /** column name */
  NodataValues = 'nodata_values',
  /** column name */
  NumBands = 'num_bands',
  /** column name */
  OutDb = 'out_db',
  /** column name */
  PixelTypes = 'pixel_types',
  /** column name */
  RRasterColumn = 'r_raster_column',
  /** column name */
  RTableCatalog = 'r_table_catalog',
  /** column name */
  RTableName = 'r_table_name',
  /** column name */
  RTableSchema = 'r_table_schema',
  /** column name */
  RegularBlocking = 'regular_blocking',
  /** column name */
  SameAlignment = 'same_alignment',
  /** column name */
  ScaleX = 'scale_x',
  /** column name */
  ScaleY = 'scale_y',
  /** column name */
  SpatialIndex = 'spatial_index',
  /** column name */
  Srid = 'srid'
}

/** aggregate stddev on columns */
export type Raster_Columns_Stddev_Fields = {
  __typename?: 'raster_columns_stddev_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Raster_Columns_Stddev_Pop_Fields = {
  __typename?: 'raster_columns_stddev_pop_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Raster_Columns_Stddev_Samp_Fields = {
  __typename?: 'raster_columns_stddev_samp_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Raster_Columns_Sum_Fields = {
  __typename?: 'raster_columns_sum_fields';
  blocksize_x?: Maybe<Scalars['Int']>;
  blocksize_y?: Maybe<Scalars['Int']>;
  num_bands?: Maybe<Scalars['Int']>;
  scale_x?: Maybe<Scalars['float8']>;
  scale_y?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Raster_Columns_Var_Pop_Fields = {
  __typename?: 'raster_columns_var_pop_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Raster_Columns_Var_Samp_Fields = {
  __typename?: 'raster_columns_var_samp_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Raster_Columns_Variance_Fields = {
  __typename?: 'raster_columns_variance_fields';
  blocksize_x?: Maybe<Scalars['Float']>;
  blocksize_y?: Maybe<Scalars['Float']>;
  num_bands?: Maybe<Scalars['Float']>;
  scale_x?: Maybe<Scalars['Float']>;
  scale_y?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "raster_overviews" */
export type Raster_Overviews = {
  __typename?: 'raster_overviews';
  o_raster_column?: Maybe<Scalars['name']>;
  o_table_catalog?: Maybe<Scalars['name']>;
  o_table_name?: Maybe<Scalars['name']>;
  o_table_schema?: Maybe<Scalars['name']>;
  overview_factor?: Maybe<Scalars['Int']>;
  r_raster_column?: Maybe<Scalars['name']>;
  r_table_catalog?: Maybe<Scalars['name']>;
  r_table_name?: Maybe<Scalars['name']>;
  r_table_schema?: Maybe<Scalars['name']>;
};

/** aggregated selection of "raster_overviews" */
export type Raster_Overviews_Aggregate = {
  __typename?: 'raster_overviews_aggregate';
  aggregate?: Maybe<Raster_Overviews_Aggregate_Fields>;
  nodes: Array<Raster_Overviews>;
};

/** aggregate fields of "raster_overviews" */
export type Raster_Overviews_Aggregate_Fields = {
  __typename?: 'raster_overviews_aggregate_fields';
  avg?: Maybe<Raster_Overviews_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raster_Overviews_Max_Fields>;
  min?: Maybe<Raster_Overviews_Min_Fields>;
  stddev?: Maybe<Raster_Overviews_Stddev_Fields>;
  stddev_pop?: Maybe<Raster_Overviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raster_Overviews_Stddev_Samp_Fields>;
  sum?: Maybe<Raster_Overviews_Sum_Fields>;
  var_pop?: Maybe<Raster_Overviews_Var_Pop_Fields>;
  var_samp?: Maybe<Raster_Overviews_Var_Samp_Fields>;
  variance?: Maybe<Raster_Overviews_Variance_Fields>;
};


/** aggregate fields of "raster_overviews" */
export type Raster_Overviews_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Raster_Overviews_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Raster_Overviews_Avg_Fields = {
  __typename?: 'raster_overviews_avg_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "raster_overviews". All fields are combined with a logical 'AND'. */
export type Raster_Overviews_Bool_Exp = {
  _and?: Maybe<Array<Raster_Overviews_Bool_Exp>>;
  _not?: Maybe<Raster_Overviews_Bool_Exp>;
  _or?: Maybe<Array<Raster_Overviews_Bool_Exp>>;
  o_raster_column?: Maybe<Name_Comparison_Exp>;
  o_table_catalog?: Maybe<Name_Comparison_Exp>;
  o_table_name?: Maybe<Name_Comparison_Exp>;
  o_table_schema?: Maybe<Name_Comparison_Exp>;
  overview_factor?: Maybe<Int_Comparison_Exp>;
  r_raster_column?: Maybe<Name_Comparison_Exp>;
  r_table_catalog?: Maybe<Name_Comparison_Exp>;
  r_table_name?: Maybe<Name_Comparison_Exp>;
  r_table_schema?: Maybe<Name_Comparison_Exp>;
};

/** aggregate max on columns */
export type Raster_Overviews_Max_Fields = {
  __typename?: 'raster_overviews_max_fields';
  overview_factor?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Raster_Overviews_Min_Fields = {
  __typename?: 'raster_overviews_min_fields';
  overview_factor?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "raster_overviews". */
export type Raster_Overviews_Order_By = {
  o_raster_column?: Maybe<Order_By>;
  o_table_catalog?: Maybe<Order_By>;
  o_table_name?: Maybe<Order_By>;
  o_table_schema?: Maybe<Order_By>;
  overview_factor?: Maybe<Order_By>;
  r_raster_column?: Maybe<Order_By>;
  r_table_catalog?: Maybe<Order_By>;
  r_table_name?: Maybe<Order_By>;
  r_table_schema?: Maybe<Order_By>;
};

/** select columns of table "raster_overviews" */
export enum Raster_Overviews_Select_Column {
  /** column name */
  ORasterColumn = 'o_raster_column',
  /** column name */
  OTableCatalog = 'o_table_catalog',
  /** column name */
  OTableName = 'o_table_name',
  /** column name */
  OTableSchema = 'o_table_schema',
  /** column name */
  OverviewFactor = 'overview_factor',
  /** column name */
  RRasterColumn = 'r_raster_column',
  /** column name */
  RTableCatalog = 'r_table_catalog',
  /** column name */
  RTableName = 'r_table_name',
  /** column name */
  RTableSchema = 'r_table_schema'
}

/** aggregate stddev on columns */
export type Raster_Overviews_Stddev_Fields = {
  __typename?: 'raster_overviews_stddev_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Raster_Overviews_Stddev_Pop_Fields = {
  __typename?: 'raster_overviews_stddev_pop_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Raster_Overviews_Stddev_Samp_Fields = {
  __typename?: 'raster_overviews_stddev_samp_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Raster_Overviews_Sum_Fields = {
  __typename?: 'raster_overviews_sum_fields';
  overview_factor?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Raster_Overviews_Var_Pop_Fields = {
  __typename?: 'raster_overviews_var_pop_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Raster_Overviews_Var_Samp_Fields = {
  __typename?: 'raster_overviews_var_samp_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Raster_Overviews_Variance_Fields = {
  __typename?: 'raster_overviews_variance_fields';
  overview_factor?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid: Scalars['Int'];
  srtext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: Maybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: Maybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: Maybe<String_Comparison_Exp>;
  auth_srid?: Maybe<Int_Comparison_Exp>;
  proj4text?: Maybe<String_Comparison_Exp>;
  srid?: Maybe<Int_Comparison_Exp>;
  srtext?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint */
  SpatialRefSysPkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns: Array<Spatial_Ref_Sys_Update_Column>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: Maybe<Order_By>;
  auth_srid?: Maybe<Order_By>;
  proj4text?: Maybe<Order_By>;
  srid?: Maybe<Order_By>;
  srtext?: Maybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars['Int'];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "agencies" */
  agencies: Array<Agencies>;
  /** fetch aggregated fields from the table: "agencies" */
  agencies_aggregate: Agencies_Aggregate;
  /** fetch data from the table: "agencies" using primary key columns */
  agencies_by_pk?: Maybe<Agencies>;
  /** An array relationship */
  agency_lpr_datastores: Array<Agency_Lpr_Datastores>;
  /** An aggregate relationship */
  agency_lpr_datastores_aggregate: Agency_Lpr_Datastores_Aggregate;
  /** fetch data from the table: "agency_lpr_datastores" using primary key columns */
  agency_lpr_datastores_by_pk?: Maybe<Agency_Lpr_Datastores>;
  /** fetch data from the table: "agency_metadata" */
  agency_metadata: Array<Agency_Metadata>;
  /** An aggregate relationship */
  agency_metadata_aggregate: Agency_Metadata_Aggregate;
  /** fetch data from the table: "agency_metadata" using primary key columns */
  agency_metadata_by_pk?: Maybe<Agency_Metadata>;
  /** An array relationship */
  agency_person_datastores: Array<Agency_Person_Datastores>;
  /** An aggregate relationship */
  agency_person_datastores_aggregate: Agency_Person_Datastores_Aggregate;
  /** fetch data from the table: "agency_person_datastores" using primary key columns */
  agency_person_datastores_by_pk?: Maybe<Agency_Person_Datastores>;
  /** fetch data from the table: "agency_users_view" */
  agency_users_view: Array<Agency_Users_View>;
  /** fetch aggregated fields from the table: "agency_users_view" */
  agency_users_view_aggregate: Agency_Users_View_Aggregate;
  /** fetch data from the table: "ftp_user" */
  ftp_user: Array<Ftp_User>;
  /** fetch aggregated fields from the table: "ftp_user" */
  ftp_user_aggregate: Ftp_User_Aggregate;
  /** fetch data from the table: "ftp_user" using primary key columns */
  ftp_user_by_pk?: Maybe<Ftp_User>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table: "incident_type" */
  incident_type: Array<Incident_Type>;
  /** fetch aggregated fields from the table: "incident_type" */
  incident_type_aggregate: Incident_Type_Aggregate;
  /** fetch data from the table: "incident_type" using primary key columns */
  incident_type_by_pk?: Maybe<Incident_Type>;
  /** An array relationship */
  incidents: Array<Incidents>;
  /** An aggregate relationship */
  incidents_aggregate: Incidents_Aggregate;
  /** fetch data from the table: "incidents" using primary key columns */
  incidents_by_pk?: Maybe<Incidents>;
  /** An array relationship */
  lpr_datastores: Array<Lpr_Datastores>;
  /** An aggregate relationship */
  lpr_datastores_aggregate: Lpr_Datastores_Aggregate;
  /** fetch data from the table: "lpr_datastores" using primary key columns */
  lpr_datastores_by_pk?: Maybe<Lpr_Datastores>;
  /** fetch data from the table: "lpr_image" */
  lpr_image: Array<Lpr_Image>;
  /** fetch aggregated fields from the table: "lpr_image" */
  lpr_image_aggregate: Lpr_Image_Aggregate;
  /** fetch data from the table: "lpr_image" using primary key columns */
  lpr_image_by_pk?: Maybe<Lpr_Image>;
  /** fetch data from the table: "lpr_incident_type" */
  lpr_incident_type: Array<Lpr_Incident_Type>;
  /** fetch aggregated fields from the table: "lpr_incident_type" */
  lpr_incident_type_aggregate: Lpr_Incident_Type_Aggregate;
  /** fetch data from the table: "lpr_incident_type" using primary key columns */
  lpr_incident_type_by_pk?: Maybe<Lpr_Incident_Type>;
  /** An array relationship */
  lpr_incidents: Array<Lpr_Incidents>;
  /** An aggregate relationship */
  lpr_incidents_aggregate: Lpr_Incidents_Aggregate;
  /** fetch data from the table: "lpr_incidents" using primary key columns */
  lpr_incidents_by_pk?: Maybe<Lpr_Incidents>;
  /** fetch data from the table: "migrations" */
  migrations: Array<Migrations>;
  /** fetch aggregated fields from the table: "migrations" */
  migrations_aggregate: Migrations_Aggregate;
  /** fetch data from the table: "migrations" using primary key columns */
  migrations_by_pk?: Maybe<Migrations>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** An array relationship */
  person_datastores: Array<Person_Datastores>;
  /** An aggregate relationship */
  person_datastores_aggregate: Person_Datastores_Aggregate;
  /** fetch data from the table: "person_datastores" using primary key columns */
  person_datastores_by_pk?: Maybe<Person_Datastores>;
  /** An array relationship */
  persons: Array<Persons>;
  /** An aggregate relationship */
  persons_aggregate: Persons_Aggregate;
  /** fetch data from the table: "persons" using primary key columns */
  persons_by_pk?: Maybe<Persons>;
  /** An array relationship */
  plates: Array<Plates>;
  /** An aggregate relationship */
  plates_aggregate: Plates_Aggregate;
  /** fetch data from the table: "plates" using primary key columns */
  plates_by_pk?: Maybe<Plates>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** An array relationship */
  properties: Array<Properties>;
  /** An aggregate relationship */
  properties_aggregate: Properties_Aggregate;
  /** fetch data from the table: "properties" using primary key columns */
  properties_by_pk?: Maybe<Properties>;
  /** An array relationship */
  property_contacts: Array<Property_Contacts>;
  /** An aggregate relationship */
  property_contacts_aggregate: Property_Contacts_Aggregate;
  /** fetch data from the table: "property_contacts" using primary key columns */
  property_contacts_by_pk?: Maybe<Property_Contacts>;
  /** fetch data from the table: "raster_columns" */
  raster_columns: Array<Raster_Columns>;
  /** fetch aggregated fields from the table: "raster_columns" */
  raster_columns_aggregate: Raster_Columns_Aggregate;
  /** fetch data from the table: "raster_overviews" */
  raster_overviews: Array<Raster_Overviews>;
  /** fetch aggregated fields from the table: "raster_overviews" */
  raster_overviews_aggregate: Raster_Overviews_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Subscription_RootAgenciesArgs = {
  distinct_on?: Maybe<Array<Agencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agencies_Order_By>>;
  where?: Maybe<Agencies_Bool_Exp>;
};


export type Subscription_RootAgencies_AggregateArgs = {
  distinct_on?: Maybe<Array<Agencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agencies_Order_By>>;
  where?: Maybe<Agencies_Bool_Exp>;
};


export type Subscription_RootAgencies_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAgency_Lpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


export type Subscription_RootAgency_Lpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Lpr_Datastores_Order_By>>;
  where?: Maybe<Agency_Lpr_Datastores_Bool_Exp>;
};


export type Subscription_RootAgency_Lpr_Datastores_By_PkArgs = {
  agenciesId: Scalars['uuid'];
  lprDatastoresId: Scalars['uuid'];
};


export type Subscription_RootAgency_MetadataArgs = {
  distinct_on?: Maybe<Array<Agency_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Metadata_Order_By>>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};


export type Subscription_RootAgency_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Metadata_Order_By>>;
  where?: Maybe<Agency_Metadata_Bool_Exp>;
};


export type Subscription_RootAgency_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAgency_Person_DatastoresArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


export type Subscription_RootAgency_Person_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Person_Datastores_Order_By>>;
  where?: Maybe<Agency_Person_Datastores_Bool_Exp>;
};


export type Subscription_RootAgency_Person_Datastores_By_PkArgs = {
  agenciesId: Scalars['uuid'];
  personDatastoresId: Scalars['uuid'];
};


export type Subscription_RootAgency_Users_ViewArgs = {
  distinct_on?: Maybe<Array<Agency_Users_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Users_View_Order_By>>;
  where?: Maybe<Agency_Users_View_Bool_Exp>;
};


export type Subscription_RootAgency_Users_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Agency_Users_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Agency_Users_View_Order_By>>;
  where?: Maybe<Agency_Users_View_Bool_Exp>;
};


export type Subscription_RootFtp_UserArgs = {
  distinct_on?: Maybe<Array<Ftp_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ftp_User_Order_By>>;
  where?: Maybe<Ftp_User_Bool_Exp>;
};


export type Subscription_RootFtp_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Ftp_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ftp_User_Order_By>>;
  where?: Maybe<Ftp_User_Bool_Exp>;
};


export type Subscription_RootFtp_User_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geography_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geography_Columns_Order_By>>;
  where?: Maybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Geometry_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Geometry_Columns_Order_By>>;
  where?: Maybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootIncident_TypeArgs = {
  distinct_on?: Maybe<Array<Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incident_Type_Order_By>>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};


export type Subscription_RootIncident_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incident_Type_Order_By>>;
  where?: Maybe<Incident_Type_Bool_Exp>;
};


export type Subscription_RootIncident_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIncidentsArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


export type Subscription_RootIncidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


export type Subscription_RootIncidents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLpr_DatastoresArgs = {
  distinct_on?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Datastores_Order_By>>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};


export type Subscription_RootLpr_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Datastores_Order_By>>;
  where?: Maybe<Lpr_Datastores_Bool_Exp>;
};


export type Subscription_RootLpr_Datastores_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLpr_ImageArgs = {
  distinct_on?: Maybe<Array<Lpr_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Image_Order_By>>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};


export type Subscription_RootLpr_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Image_Order_By>>;
  where?: Maybe<Lpr_Image_Bool_Exp>;
};


export type Subscription_RootLpr_Image_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLpr_Incident_TypeArgs = {
  distinct_on?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incident_Type_Order_By>>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};


export type Subscription_RootLpr_Incident_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incident_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incident_Type_Order_By>>;
  where?: Maybe<Lpr_Incident_Type_Bool_Exp>;
};


export type Subscription_RootLpr_Incident_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLpr_IncidentsArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


export type Subscription_RootLpr_Incidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


export type Subscription_RootLpr_Incidents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMigrationsArgs = {
  distinct_on?: Maybe<Array<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Subscription_RootMigrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Subscription_RootMigrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPerson_DatastoresArgs = {
  distinct_on?: Maybe<Array<Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Person_Datastores_Order_By>>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};


export type Subscription_RootPerson_Datastores_AggregateArgs = {
  distinct_on?: Maybe<Array<Person_Datastores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Person_Datastores_Order_By>>;
  where?: Maybe<Person_Datastores_Bool_Exp>;
};


export type Subscription_RootPerson_Datastores_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPersonsArgs = {
  distinct_on?: Maybe<Array<Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Persons_Order_By>>;
  where?: Maybe<Persons_Bool_Exp>;
};


export type Subscription_RootPersons_AggregateArgs = {
  distinct_on?: Maybe<Array<Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Persons_Order_By>>;
  where?: Maybe<Persons_Bool_Exp>;
};


export type Subscription_RootPersons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPlatesArgs = {
  distinct_on?: Maybe<Array<Plates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plates_Order_By>>;
  where?: Maybe<Plates_Bool_Exp>;
};


export type Subscription_RootPlates_AggregateArgs = {
  distinct_on?: Maybe<Array<Plates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plates_Order_By>>;
  where?: Maybe<Plates_Bool_Exp>;
};


export type Subscription_RootPlates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Subscription_RootPropertiesArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Properties_Order_By>>;
  where?: Maybe<Properties_Bool_Exp>;
};


export type Subscription_RootProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Properties_Order_By>>;
  where?: Maybe<Properties_Bool_Exp>;
};


export type Subscription_RootProperties_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProperty_ContactsArgs = {
  distinct_on?: Maybe<Array<Property_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Contacts_Order_By>>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};


export type Subscription_RootProperty_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Property_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Contacts_Order_By>>;
  where?: Maybe<Property_Contacts_Bool_Exp>;
};


export type Subscription_RootProperty_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRaster_ColumnsArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Subscription_RootRaster_Columns_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Columns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Columns_Order_By>>;
  where?: Maybe<Raster_Columns_Bool_Exp>;
};


export type Subscription_RootRaster_OverviewsArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Subscription_RootRaster_Overviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Raster_Overviews_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Raster_Overviews_Order_By>>;
  where?: Maybe<Raster_Overviews_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: Maybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: Maybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  agency?: Maybe<Agencies>;
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  disableNotifications: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  incidents: Array<Incidents>;
  /** An aggregate relationship */
  incidents_aggregate: Incidents_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  /** An array relationship */
  lpr_incidents: Array<Lpr_Incidents>;
  /** An aggregate relationship */
  lpr_incidents_aggregate: Lpr_Incidents_Aggregate;
  nickName?: Maybe<Scalars['String']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An array relationship */
  notificationsByTouserid: Array<Notifications>;
  /** An aggregate relationship */
  notificationsByTouserid_aggregate: Notifications_Aggregate;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  role: Scalars['users_role_enum'];
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "users" */
export type UsersIncidentsArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersIncidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Incidents_Order_By>>;
  where?: Maybe<Incidents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLpr_IncidentsArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLpr_Incidents_AggregateArgs = {
  distinct_on?: Maybe<Array<Lpr_Incidents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lpr_Incidents_Order_By>>;
  where?: Maybe<Lpr_Incidents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsByTouseridArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsByTouserid_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  agency?: Maybe<Agencies_Bool_Exp>;
  agencyId?: Maybe<Uuid_Comparison_Exp>;
  avatarUrl?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  dateOfBirth?: Maybe<Date_Comparison_Exp>;
  deviceId?: Maybe<String_Comparison_Exp>;
  disableNotifications?: Maybe<Boolean_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fcmToken?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  incidents?: Maybe<Incidents_Bool_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  lpr_incidents?: Maybe<Lpr_Incidents_Bool_Exp>;
  nickName?: Maybe<String_Comparison_Exp>;
  notifications?: Maybe<Notifications_Bool_Exp>;
  notificationsByTouserid?: Maybe<Notifications_Bool_Exp>;
  role?: Maybe<Users_Role_Enum_Comparison_Exp>;
  snsEndpoint?: Maybe<String_Comparison_Exp>;
  telephone?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  PkA3ffb1c0c8416b9fc6f907b7433 = 'PK_a3ffb1c0c8416b9fc6f907b7433',
  /** unique or primary key constraint */
  Uq_97672ac88f789774dd47f7c8be3 = 'UQ_97672ac88f789774dd47f7c8be3',
  /** unique or primary key constraint */
  UqAacbcbfc16077f6b485951adfb4 = 'UQ_aacbcbfc16077f6b485951adfb4'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  agency?: Maybe<Agencies_Obj_Rel_Insert_Input>;
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  disableNotifications?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  incidents?: Maybe<Incidents_Arr_Rel_Insert_Input>;
  lastName?: Maybe<Scalars['String']>;
  lpr_incidents?: Maybe<Lpr_Incidents_Arr_Rel_Insert_Input>;
  nickName?: Maybe<Scalars['String']>;
  notifications?: Maybe<Notifications_Arr_Rel_Insert_Input>;
  notificationsByTouserid?: Maybe<Notifications_Arr_Rel_Insert_Input>;
  role?: Maybe<Scalars['users_role_enum']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  agencyId?: Maybe<Order_By>;
  avatarUrl?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  deviceId?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcmToken?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  nickName?: Maybe<Order_By>;
  snsEndpoint?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  agencyId?: Maybe<Order_By>;
  avatarUrl?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  deviceId?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcmToken?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  nickName?: Maybe<Order_By>;
  snsEndpoint?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  agency?: Maybe<Agencies_Order_By>;
  agencyId?: Maybe<Order_By>;
  avatarUrl?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateOfBirth?: Maybe<Order_By>;
  deviceId?: Maybe<Order_By>;
  disableNotifications?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcmToken?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  incidents_aggregate?: Maybe<Incidents_Aggregate_Order_By>;
  lastName?: Maybe<Order_By>;
  lpr_incidents_aggregate?: Maybe<Lpr_Incidents_Aggregate_Order_By>;
  nickName?: Maybe<Order_By>;
  notificationsByTouserid_aggregate?: Maybe<Notifications_Aggregate_Order_By>;
  notifications_aggregate?: Maybe<Notifications_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
  snsEndpoint?: Maybe<Order_By>;
  telephone?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "users_role_enum". All fields are combined with logical 'AND'. */
export type Users_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Scalars['users_role_enum']>;
  _gt?: Maybe<Scalars['users_role_enum']>;
  _gte?: Maybe<Scalars['users_role_enum']>;
  _in?: Maybe<Array<Scalars['users_role_enum']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['users_role_enum']>;
  _lte?: Maybe<Scalars['users_role_enum']>;
  _neq?: Maybe<Scalars['users_role_enum']>;
  _nin?: Maybe<Array<Scalars['users_role_enum']>>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  DeviceId = 'deviceId',
  /** column name */
  DisableNotifications = 'disableNotifications',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FcmToken = 'fcmToken',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  NickName = 'nickName',
  /** column name */
  Role = 'role',
  /** column name */
  SnsEndpoint = 'snsEndpoint',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  agencyId?: Maybe<Scalars['uuid']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deviceId?: Maybe<Scalars['String']>;
  disableNotifications?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['users_role_enum']>;
  snsEndpoint?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  DeviceId = 'deviceId',
  /** column name */
  DisableNotifications = 'disableNotifications',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FcmToken = 'fcmToken',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  NickName = 'nickName',
  /** column name */
  Role = 'role',
  /** column name */
  SnsEndpoint = 'snsEndpoint',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GetAdminDashboardStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminDashboardStatsQuery = (
  { __typename?: 'query_root' }
  & { superadmins: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ), agencyadmins: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ), agents: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ), persons_aggregate: (
    { __typename?: 'persons_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'persons_aggregate_fields' }
      & Pick<Persons_Aggregate_Fields, 'count'>
    )> }
  ), plates_aggregate: (
    { __typename?: 'plates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'plates_aggregate_fields' }
      & Pick<Plates_Aggregate_Fields, 'count'>
    )> }
  ), incidents_aggregate: (
    { __typename?: 'incidents_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'incidents_aggregate_fields' }
      & Pick<Incidents_Aggregate_Fields, 'count'>
    )> }
  ), lpr_incidents_aggregate: (
    { __typename?: 'lpr_incidents_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'lpr_incidents_aggregate_fields' }
      & Pick<Lpr_Incidents_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListAgencyUserViewQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAgencyUserViewQuery = (
  { __typename?: 'query_root' }
  & { agency_users_view: Array<(
    { __typename?: 'agency_users_view' }
    & Pick<Agency_Users_View, 'user_count' | 'noOfAgents'>
  )> }
);

export type CreateAgencyMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  adminFirstName?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['agencies_subscription_enum']>;
  adminLastName?: Maybe<Scalars['String']>;
  adminEmail?: Maybe<Scalars['String']>;
  agentFirstName?: Maybe<Scalars['String']>;
  agentLastName?: Maybe<Scalars['String']>;
  agentEmail?: Maybe<Scalars['String']>;
  trespassStatement?: Maybe<Scalars['String']>;
  noOfAgents?: Maybe<Scalars['Int']>;
  propertyName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
}>;


export type CreateAgencyMutation = (
  { __typename?: 'mutation_root' }
  & { insert_agency_metadata_one?: Maybe<(
    { __typename?: 'agency_metadata' }
    & Pick<Agency_Metadata, 'trespassStatement' | 'noOfAgents'>
    & { agency?: Maybe<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'id' | 'name'>
      & { users: Array<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'firstName' | 'lastName' | 'role'>
      )>, properties: Array<(
        { __typename?: 'properties' }
        & Pick<Properties, 'name' | 'location' | 'prefferedReportingMethod'>
      )> }
    )> }
  )> }
);

export type SetupAgencyResourcesMutationVariables = Exact<{
  agencyId?: Maybe<Scalars['uuid']>;
}>;


export type SetupAgencyResourcesMutation = (
  { __typename?: 'mutation_root' }
  & { personDatastore?: Maybe<(
    { __typename?: 'person_datastores' }
    & Pick<Person_Datastores, 'id'>
  )>, lprDatastore?: Maybe<(
    { __typename?: 'lpr_datastores' }
    & Pick<Lpr_Datastores, 'id'>
  )> }
);

export type ListAgenciesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListAgenciesQuery = (
  { __typename?: 'query_root' }
  & { agencies: Array<(
    { __typename?: 'agencies' }
    & Pick<Agencies, 'id' | 'name' | 'disabled' | 'createdAt'>
    & { agency_metadata: Array<(
      { __typename?: 'agency_metadata' }
      & Pick<Agency_Metadata, 'noOfAgents'>
    )>, totalAgents: (
      { __typename?: 'users_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'users_aggregate_fields' }
        & Pick<Users_Aggregate_Fields, 'count'>
      )> }
    ), totalAdmins: (
      { __typename?: 'users_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'users_aggregate_fields' }
        & Pick<Users_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, totalAgencies: (
    { __typename?: 'agencies_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'agencies_aggregate_fields' }
      & Pick<Agencies_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpdateAgencyStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  disabled?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateAgencyStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_agencies_by_pk?: Maybe<(
    { __typename?: 'agencies' }
    & Pick<Agencies, 'id'>
  )> }
);

export type GetAgencyQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAgencyQuery = (
  { __typename?: 'query_root' }
  & { agencies_by_pk?: Maybe<(
    { __typename?: 'agencies' }
    & Pick<Agencies, 'name' | 'id' | 'subscription'>
    & { agency_metadata: Array<(
      { __typename?: 'agency_metadata' }
      & Pick<Agency_Metadata, 'noOfAgents' | 'trespassStatement'>
    )> }
  )> }
);

export type UpdateAgencyMutationVariables = Exact<{
  agencyId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  trespassStatement?: Maybe<Scalars['String']>;
  noOfAgents?: Maybe<Scalars['Int']>;
}>;


export type UpdateAgencyMutation = (
  { __typename?: 'mutation_root' }
  & { update_agencies?: Maybe<(
    { __typename?: 'agencies_mutation_response' }
    & Pick<Agencies_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'name'>
    )> }
  )>, update_agency_metadata?: Maybe<(
    { __typename?: 'agency_metadata_mutation_response' }
    & Pick<Agency_Metadata_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'agency_metadata' }
      & Pick<Agency_Metadata, 'noOfAgents' | 'agencyId' | 'trespassStatement'>
      & { agency?: Maybe<(
        { __typename?: 'agencies' }
        & Pick<Agencies, 'name' | 'id'>
      )> }
    )> }
  )> }
);

export type UpdateAgencySubscriptionMutationVariables = Exact<{
  id: Scalars['uuid'];
  subscription?: Maybe<Scalars['agencies_subscription_enum']>;
}>;


export type UpdateAgencySubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_agencies_by_pk?: Maybe<(
    { __typename?: 'agencies' }
    & Pick<Agencies, 'subscription'>
  )> }
);

export type ListAgentsQueryVariables = Exact<{
  agencyId?: Maybe<Scalars['uuid']>;
}>;


export type ListAgentsQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'firstName' | 'lastName' | 'email' | 'role' | 'id' | 'agencyId'>
  )>, totalAgents: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListAdminsQueryVariables = Exact<{
  agencyId?: Maybe<Scalars['uuid']>;
}>;


export type ListAdminsQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'firstName' | 'lastName' | 'email' | 'role' | 'id' | 'agencyId'>
  )>, totalAdmins: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListPropertiesByAgencyQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type ListPropertiesByAgencyQuery = (
  { __typename?: 'query_root' }
  & { properties: Array<(
    { __typename?: 'properties' }
    & Pick<Properties, 'id' | 'address1' | 'address2' | 'agencyId' | 'location' | 'prefferedReportingMethod' | 'reportingTime' | 'name' | 'createdAt' | 'updatedAt'>
  )>, properties_aggregate: (
    { __typename?: 'properties_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'properties_aggregate_fields' }
      & Pick<Properties_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type DeleteAgencyMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteAgencyMutation = (
  { __typename?: 'mutation_root' }
  & { delete_agencies_by_pk?: Maybe<(
    { __typename?: 'agencies' }
    & Pick<Agencies, 'id' | 'name' | 'subscription'>
  )> }
);

export type ListLprDataStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type ListLprDataStoresQuery = (
  { __typename?: 'query_root' }
  & { lpr_datastores: Array<(
    { __typename?: 'lpr_datastores' }
    & Pick<Lpr_Datastores, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { agency?: Maybe<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'id' | 'name'>
    )> }
  )> }
);

export type ListPersonDataStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPersonDataStoresQuery = (
  { __typename?: 'query_root' }
  & { person_datastores: Array<(
    { __typename?: 'person_datastores' }
    & Pick<Person_Datastores, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { agency?: Maybe<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'id' | 'name'>
    )> }
  )> }
);

export type AddPersonsDatastoreAccessMutationVariables = Exact<{
  agenciesId: Scalars['uuid'];
  personDatastoresId: Scalars['uuid'];
}>;


export type AddPersonsDatastoreAccessMutation = (
  { __typename?: 'mutation_root' }
  & { insert_agency_person_datastores_one?: Maybe<(
    { __typename?: 'agency_person_datastores' }
    & Pick<Agency_Person_Datastores, 'agenciesId' | 'personDatastoresId'>
  )> }
);

export type AddLprDatastoreAccessMutationVariables = Exact<{
  agenciesId: Scalars['uuid'];
  lprDatastoresId: Scalars['uuid'];
}>;


export type AddLprDatastoreAccessMutation = (
  { __typename?: 'mutation_root' }
  & { insert_agency_lpr_datastores_one?: Maybe<(
    { __typename?: 'agency_lpr_datastores' }
    & Pick<Agency_Lpr_Datastores, 'agenciesId' | 'lprDatastoresId'>
  )> }
);

export type GetOwnedPersonDataStoreQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOwnedPersonDataStoreQuery = (
  { __typename?: 'query_root' }
  & { person_datastores: Array<(
    { __typename?: 'person_datastores' }
    & Pick<Person_Datastores, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { agency?: Maybe<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'id' | 'name'>
    )> }
  )> }
);

export type GetOwnedLprDataStoreQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOwnedLprDataStoreQuery = (
  { __typename?: 'query_root' }
  & { lpr_datastores: Array<(
    { __typename?: 'lpr_datastores' }
    & Pick<Lpr_Datastores, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { agency?: Maybe<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'id' | 'name'>
    )> }
  )> }
);

export type ListFtpUsersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type ListFtpUsersQuery = (
  { __typename?: 'query_root' }
  & { ftp_user: Array<(
    { __typename?: 'ftp_user' }
    & Pick<Ftp_User, 'id' | 'username' | 'agencyId'>
  )> }
);

export type CreateFtpUserMutationVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type CreateFtpUserMutation = (
  { __typename?: 'mutation_root' }
  & { createFTPUser?: Maybe<(
    { __typename?: 'CreateFTPUserOutput' }
    & Pick<CreateFtpUserOutput, 'username'>
  )> }
);

export type UpdateFtpUserMutationVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type UpdateFtpUserMutation = (
  { __typename?: 'mutation_root' }
  & { updateFTPUser?: Maybe<(
    { __typename?: 'UpdateFTPUserOutput' }
    & Pick<UpdateFtpUserOutput, 'isUpdated'>
  )> }
);

export type IncidentTypeInfoFragment = (
  { __typename?: 'incident_type' }
  & Pick<Incident_Type, 'id' | 'name' | 'agencyId'>
);

export type ListIncidentTypesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Order_By>;
}>;


export type ListIncidentTypesQuery = (
  { __typename?: 'query_root' }
  & { incident_type: Array<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )>, incident_type_aggregate: (
    { __typename?: 'incident_type_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'incident_type_aggregate_fields' }
      & Pick<Incident_Type_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListIncidentTypesByAgencyQueryVariables = Exact<{
  agencyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Order_By>;
}>;


export type ListIncidentTypesByAgencyQuery = (
  { __typename?: 'query_root' }
  & { incident_type: Array<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )>, incident_type_aggregate: (
    { __typename?: 'incident_type_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'incident_type_aggregate_fields' }
      & Pick<Incident_Type_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListIncidentTypesBySuperQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Order_By>;
}>;


export type ListIncidentTypesBySuperQuery = (
  { __typename?: 'query_root' }
  & { incident_type: Array<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )>, incident_type_aggregate: (
    { __typename?: 'incident_type_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'incident_type_aggregate_fields' }
      & Pick<Incident_Type_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpdateIncidentTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateIncidentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_incident_type_by_pk?: Maybe<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )> }
);

export type AddIncidentTypeMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type AddIncidentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_incident_type_one?: Maybe<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )> }
);

export type FetchIncidentTypeInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchIncidentTypeInfoQuery = (
  { __typename?: 'query_root' }
  & { incident_type_by_pk?: Maybe<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )> }
);

export type DeleteIncidentTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteIncidentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_incident_type_by_pk?: Maybe<(
    { __typename?: 'incident_type' }
    & IncidentTypeInfoFragment
  )> }
);

export type GetIncidentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIncidentTypesQuery = (
  { __typename?: 'query_root' }
  & { incident_type: Array<(
    { __typename?: 'incident_type' }
    & Pick<Incident_Type, 'id' | 'agencyId' | 'name'>
  )> }
);

export type GetPlateIncidentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlateIncidentTypesQuery = (
  { __typename?: 'query_root' }
  & { lpr_incident_type: Array<(
    { __typename?: 'lpr_incident_type' }
    & Pick<Lpr_Incident_Type, 'id' | 'agencyId' | 'name'>
  )> }
);

export type ListIncidentsQueryVariables = Exact<{
  personId?: Maybe<Scalars['uuid']>;
  plateId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListIncidentsQuery = (
  { __typename?: 'query_root' }
  & { incidents: Array<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'id' | 'location' | 'createdAt' | 'accuracy'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'name'>
    )>, personByPerson?: Maybe<(
      { __typename?: 'persons' }
      & Pick<Persons, 'firstName' | 'lastName'>
    )>, user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'firstName' | 'lastName'>
    )> }
  )>, lpr_incidents: Array<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'id'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'name'>
    )>, plate?: Maybe<(
      { __typename?: 'plates' }
      & Pick<Plates, 'displayNumber'>
    )>, user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type ListIncidentsLocationQueryVariables = Exact<{
  personId?: Maybe<Scalars['uuid']>;
  plateId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListIncidentsLocationQuery = (
  { __typename?: 'query_root' }
  & { incidents: Array<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'id' | 'location'>
  )>, lpr_incidents: Array<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'id' | 'location'>
  )> }
);

export type AggregateIncidentsQueryVariables = Exact<{
  personId?: Maybe<Scalars['uuid']>;
  plateId?: Maybe<Scalars['uuid']>;
  propertyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AggregateIncidentsQuery = (
  { __typename?: 'query_root' }
  & { incidents_aggregate: (
    { __typename?: 'incidents_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'incidents_aggregate_fields' }
      & Pick<Incidents_Aggregate_Fields, 'count'>
    )> }
  ), lpr_incidents_aggregate: (
    { __typename?: 'lpr_incidents_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'lpr_incidents_aggregate_fields' }
      & Pick<Lpr_Incidents_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLprIncidentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLprIncidentTypesQuery = (
  { __typename?: 'query_root' }
  & { lpr_incident_type: Array<(
    { __typename?: 'lpr_incident_type' }
    & Pick<Lpr_Incident_Type, 'id' | 'agencyId' | 'name'>
  )> }
);

export type GetIncidentByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetIncidentByIdQuery = (
  { __typename?: 'query_root' }
  & { incidents_by_pk?: Maybe<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'propertyId' | 'typeId' | 'type' | 'accuracy' | 'note' | 's3ImageKey' | 'location'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'lastName' | 'firstName' | 'email'>
    )> }
  )> }
);

export type GetLprIncidentByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLprIncidentByIdQuery = (
  { __typename?: 'query_root' }
  & { lpr_incidents_by_pk?: Maybe<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'propertyId' | 'typeId' | 'type' | 'note' | 'location'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type AddPersonMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  datastoreId: Scalars['uuid'];
  propertyId: Scalars['uuid'];
  dateOfBirth?: Maybe<Scalars['date']>;
  listedAs?: Maybe<Scalars['persons_listedas_enum']>;
  idCardImage?: Maybe<Scalars['String']>;
  trespassStatementAudio?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geometry']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
}>;


export type AddPersonMutation = (
  { __typename?: 'mutation_root' }
  & { insert_persons_one?: Maybe<(
    { __typename?: 'persons' }
    & Pick<Persons, 'id' | 'firstName' | 'lastName' | 'dateOfBirth' | 'knownToBeViolent' | 's3ImageKey'>
    & { incidents: Array<(
      { __typename?: 'incidents' }
      & Pick<Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
      & { property?: Maybe<(
        { __typename?: 'properties' }
        & Pick<Properties, 'id'>
        & { property_contacts: Array<(
          { __typename?: 'property_contacts' }
          & Pick<Property_Contacts, 'id' | 'email'>
        )> }
      )> }
    )> }
  )> }
);

export type ListPersonQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPersonQuery = (
  { __typename?: 'query_root' }
  & { persons: Array<(
    { __typename?: 'persons' }
    & Pick<Persons, 'id' | 'firstName' | 'lastName' | 'knownToBeViolent' | 'listedAs' | 'createdAt' | 's3ImageKey'>
    & { incidents_aggregate: (
      { __typename?: 'incidents_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'incidents_aggregate_fields' }
        & Pick<Incidents_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, persons_aggregate: (
    { __typename?: 'persons_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'persons_aggregate_fields' }
      & Pick<Persons_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListPersonForPropertyFilterQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPersonForPropertyFilterQuery = (
  { __typename?: 'query_root' }
  & { persons: Array<(
    { __typename?: 'persons' }
    & Pick<Persons, 'id' | 'firstName' | 'lastName' | 'knownToBeViolent' | 'listedAs' | 'createdAt' | 's3ImageKey'>
    & { incidents_aggregate: (
      { __typename?: 'incidents_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'incidents_aggregate_fields' }
        & Pick<Incidents_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, persons_aggregate: (
    { __typename?: 'persons_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'persons_aggregate_fields' }
      & Pick<Persons_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListPersonForAgencyFilterQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['uuid']>;
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPersonForAgencyFilterQuery = (
  { __typename?: 'query_root' }
  & { persons: Array<(
    { __typename?: 'persons' }
    & Pick<Persons, 'id' | 'firstName' | 'lastName' | 'knownToBeViolent' | 'listedAs' | 'createdAt' | 's3ImageKey'>
    & { incidents_aggregate: (
      { __typename?: 'incidents_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'incidents_aggregate_fields' }
        & Pick<Incidents_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, persons_aggregate: (
    { __typename?: 'persons_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'persons_aggregate_fields' }
      & Pick<Persons_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type PersonInfoFragment = (
  { __typename?: 'persons' }
  & Pick<Persons, 'id' | 'firstName' | 'lastName' | 'knownToBeViolent' | 'listedAs' | 'createdAt' | 'updatedAt' | 's3ImageKey' | 'trespassStatementAudioFileKey' | 'trespassStatementTranscript'>
);

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['uuid'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  listAs?: Maybe<Scalars['persons_listedas_enum']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  trespassStatementTranscript?: Maybe<Scalars['String']>;
}>;


export type UpdatePersonMutation = (
  { __typename?: 'mutation_root' }
  & { update_persons_by_pk?: Maybe<(
    { __typename?: 'persons' }
    & PersonInfoFragment
  )> }
);

export type FetchPersonInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchPersonInfoQuery = (
  { __typename?: 'query_root' }
  & { persons_by_pk?: Maybe<(
    { __typename?: 'persons' }
    & PersonInfoFragment
  )> }
);

export type DeletePersonMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePersonMutation = (
  { __typename?: 'mutation_root' }
  & { delete_persons_by_pk?: Maybe<(
    { __typename?: 'persons' }
    & PersonInfoFragment
  )> }
);

export type PersonFragment = (
  { __typename?: 'persons' }
  & Pick<Persons, 'id' | 'firstName' | 'lastName' | 'knownToBeViolent' | 'datastoreId' | 'dateOfBirth' | 'idCardImageKey' | 'createdAt' | 'listedAs' | 's3ImageKey' | 'trespassStatementAudioFileKey'>
  & { person_datastore?: Maybe<(
    { __typename?: 'person_datastores' }
    & Pick<Person_Datastores, 'name' | 'id' | 'ownerId'>
    & { agency?: Maybe<(
      { __typename?: 'agencies' }
      & Pick<Agencies, 'name' | 'id'>
    )> }
  )>, incidents: Array<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'id' | 'location' | 'note' | 'type'>
  )> }
);

export type SearchFaceMutationVariables = Exact<{
  s3ImageKey: Scalars['String'];
}>;


export type SearchFaceMutation = (
  { __typename?: 'mutation_root' }
  & { searchFace?: Maybe<(
    { __typename?: 'SearchFaceOutput' }
    & Pick<SearchFaceOutput, 'accuracy' | 'faceFound'>
    & { info: (
      { __typename?: 'persons' }
      & PersonFragment
    ) }
  )> }
);

export type AddPersonIncidentMutationVariables = Exact<{
  s3ImageKey?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geometry']>;
  propertyId: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
}>;


export type AddPersonIncidentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_incidents_one?: Maybe<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'id'>
      & { property_contacts: Array<(
        { __typename?: 'property_contacts' }
        & Pick<Property_Contacts, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdatePersonIncidentMutationVariables = Exact<{
  iid: Scalars['uuid'];
  s3ImageKey?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geometry']>;
  propertyId: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
}>;


export type UpdatePersonIncidentMutation = (
  { __typename?: 'mutation_root' }
  & { update_incidents_by_pk?: Maybe<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'id'>
      & { property_contacts: Array<(
        { __typename?: 'property_contacts' }
        & Pick<Property_Contacts, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type DeletePersonIncidentMutationVariables = Exact<{
  iid: Scalars['uuid'];
}>;


export type DeletePersonIncidentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_incidents_by_pk?: Maybe<(
    { __typename?: 'incidents' }
    & Pick<Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'id'>
      & { property_contacts: Array<(
        { __typename?: 'property_contacts' }
        & Pick<Property_Contacts, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type LprIncidentTypeInfoFragment = (
  { __typename?: 'lpr_incident_type' }
  & Pick<Lpr_Incident_Type, 'id' | 'name' | 'agencyId'>
);

export type ListLprIncidentTypesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Order_By>;
}>;


export type ListLprIncidentTypesQuery = (
  { __typename?: 'query_root' }
  & { lpr_incident_type: Array<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )>, lpr_incident_type_aggregate: (
    { __typename?: 'lpr_incident_type_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'lpr_incident_type_aggregate_fields' }
      & Pick<Lpr_Incident_Type_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListLprIncidentTypesByAgencyQueryVariables = Exact<{
  agencyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Order_By>;
}>;


export type ListLprIncidentTypesByAgencyQuery = (
  { __typename?: 'query_root' }
  & { lpr_incident_type: Array<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )>, lpr_incident_type_aggregate: (
    { __typename?: 'lpr_incident_type_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'lpr_incident_type_aggregate_fields' }
      & Pick<Lpr_Incident_Type_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListLprIncidentTypesBySuperQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Order_By>;
}>;


export type ListLprIncidentTypesBySuperQuery = (
  { __typename?: 'query_root' }
  & { lpr_incident_type: Array<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )>, lpr_incident_type_aggregate: (
    { __typename?: 'lpr_incident_type_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'lpr_incident_type_aggregate_fields' }
      & Pick<Lpr_Incident_Type_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpdateLprIncidentTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateLprIncidentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_lpr_incident_type_by_pk?: Maybe<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )> }
);

export type AddLprIncidentTypeMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type AddLprIncidentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_lpr_incident_type_one?: Maybe<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )> }
);

export type FetchLprIncidentTypeInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchLprIncidentTypeInfoQuery = (
  { __typename?: 'query_root' }
  & { lpr_incident_type_by_pk?: Maybe<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )> }
);

export type DeleteLprIncidentTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLprIncidentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_lpr_incident_type_by_pk?: Maybe<(
    { __typename?: 'lpr_incident_type' }
    & LprIncidentTypeInfoFragment
  )> }
);

export type ListPlatesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPlatesQuery = (
  { __typename?: 'query_root' }
  & { plates: Array<(
    { __typename?: 'plates' }
    & Pick<Plates, 'id' | 'number' | 'displayNumber' | 'knownToBeViolent' | 'listedAs' | 'createdAt' | 's3ImageKey'>
    & { lpr_incidents_aggregate: (
      { __typename?: 'lpr_incidents_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'lpr_incidents_aggregate_fields' }
        & Pick<Lpr_Incidents_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, plates_aggregate: (
    { __typename?: 'plates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'plates_aggregate_fields' }
      & Pick<Plates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListPlatesForPropertyFilterQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPlatesForPropertyFilterQuery = (
  { __typename?: 'query_root' }
  & { plates: Array<(
    { __typename?: 'plates' }
    & Pick<Plates, 'id' | 'number' | 'knownToBeViolent' | 'displayNumber' | 'listedAs' | 'createdAt' | 's3ImageKey'>
    & { lpr_incidents_aggregate: (
      { __typename?: 'lpr_incidents_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'lpr_incidents_aggregate_fields' }
        & Pick<Lpr_Incidents_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, plates_aggregate: (
    { __typename?: 'plates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'plates_aggregate_fields' }
      & Pick<Plates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListPlatesForAgencyFilterQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['uuid']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPlatesForAgencyFilterQuery = (
  { __typename?: 'query_root' }
  & { plates: Array<(
    { __typename?: 'plates' }
    & Pick<Plates, 'id' | 'number' | 'knownToBeViolent' | 'displayNumber' | 'listedAs' | 'createdAt' | 's3ImageKey'>
    & { lpr_incidents_aggregate: (
      { __typename?: 'lpr_incidents_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'lpr_incidents_aggregate_fields' }
        & Pick<Lpr_Incidents_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, plates_aggregate: (
    { __typename?: 'plates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'plates_aggregate_fields' }
      & Pick<Plates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type PlateInfoFragment = (
  { __typename?: 'plates' }
  & Pick<Plates, 'id' | 'number' | 'knownToBeViolent' | 'displayNumber' | 'listedAs' | 'createdAt' | 'updatedAt' | 's3ImageKey'>
  & { lpr_images: Array<(
    { __typename?: 'lpr_image' }
    & Pick<Lpr_Image, 'id' | 'plateId' | 's3ImageKey'>
  )> }
);

export type UpdatePlateMutationVariables = Exact<{
  id: Scalars['uuid'];
  displayNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  listAs?: Maybe<Scalars['plates_listedas_enum']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePlateMutation = (
  { __typename?: 'mutation_root' }
  & { update_plates_by_pk?: Maybe<(
    { __typename?: 'plates' }
    & PlateInfoFragment
  )> }
);

export type FetchPlateInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchPlateInfoQuery = (
  { __typename?: 'query_root' }
  & { plates_by_pk?: Maybe<(
    { __typename?: 'plates' }
    & PlateInfoFragment
  )> }
);

export type DeletePlateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePlateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_plates_by_pk?: Maybe<(
    { __typename?: 'plates' }
    & PlateInfoFragment
  )> }
);

export type PlateFragment = (
  { __typename?: 'plates' }
  & Pick<Plates, 'id' | 'displayNumber' | 'knownToBeViolent' | 'datastoreId' | 'createdAt' | 'listedAs' | 'number' | 'make' | 'model' | 'color' | 'year' | 's3ImageKey' | 'updatedAt'>
  & { lpr_incidents: Array<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'id' | 'location' | 'note' | 'type'>
  )> }
);

export type AddPlateMutationVariables = Exact<{
  number: Scalars['String'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  knownToBeViolent?: Maybe<Scalars['Boolean']>;
  displayNumber: Scalars['String'];
  s3ImageKey?: Maybe<Scalars['String']>;
  lpr_images: Array<Lpr_Image_Insert_Input> | Lpr_Image_Insert_Input;
  datastoreId: Scalars['uuid'];
  propertyId: Scalars['uuid'];
  listedAs?: Maybe<Scalars['plates_listedas_enum']>;
  location?: Maybe<Scalars['geometry']>;
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
}>;


export type AddPlateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_plates_one?: Maybe<(
    { __typename?: 'plates' }
    & Pick<Plates, 'id' | 'number' | 'knownToBeViolent' | 'displayNumber' | 'make' | 'model' | 'color' | 'year' | 's3ImageKey'>
    & { lpr_incidents: Array<(
      { __typename?: 'lpr_incidents' }
      & Pick<Lpr_Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
      & { property?: Maybe<(
        { __typename?: 'properties' }
        & Pick<Properties, 'id'>
        & { property_contacts: Array<(
          { __typename?: 'property_contacts' }
          & Pick<Property_Contacts, 'id' | 'email'>
        )> }
      )> }
    )> }
  )> }
);

export type AddPlateIncidentMutationVariables = Exact<{
  s3ImageKey?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geometry']>;
  propertyId: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
}>;


export type AddPlateIncidentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_lpr_incidents_one?: Maybe<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'id'>
      & { property_contacts: Array<(
        { __typename?: 'property_contacts' }
        & Pick<Property_Contacts, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdatePlateIncidentMutationVariables = Exact<{
  iid: Scalars['uuid'];
  s3ImageKey?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geometry']>;
  propertyId: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
}>;


export type UpdatePlateIncidentMutation = (
  { __typename?: 'mutation_root' }
  & { update_lpr_incidents_by_pk?: Maybe<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'id'>
      & { property_contacts: Array<(
        { __typename?: 'property_contacts' }
        & Pick<Property_Contacts, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type SearchPlateMutationVariables = Exact<{
  s3ImageKey: Scalars['String'];
}>;


export type SearchPlateMutation = (
  { __typename?: 'mutation_root' }
  & { searchPlate?: Maybe<(
    { __typename?: 'SearchPlateOutput' }
    & Pick<SearchPlateOutput, 'textDetections' | 'plateId'>
    & { plate: (
      { __typename?: 'plates' }
      & PlateFragment
    ) }
  )> }
);

export type DeletePlateIncidentMutationVariables = Exact<{
  iid: Scalars['uuid'];
}>;


export type DeletePlateIncidentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_lpr_incidents_by_pk?: Maybe<(
    { __typename?: 'lpr_incidents' }
    & Pick<Lpr_Incidents, 'id' | 'location' | 'type' | 's3ImageKey' | 'propertyId'>
    & { property?: Maybe<(
      { __typename?: 'properties' }
      & Pick<Properties, 'id'>
      & { property_contacts: Array<(
        { __typename?: 'property_contacts' }
        & Pick<Property_Contacts, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type ProfileResponseFragment = (
  { __typename?: 'profile' }
  & Pick<Profile, 'firstName' | 'lastName' | 'agencyId' | 'avatarUrl' | 'createdAt' | 'dateOfBirth' | 'email' | 'id' | 'nickName' | 'role' | 'telephone' | 'timezone' | 'updatedAt'>
  & { agency?: Maybe<(
    { __typename?: 'agencies' }
    & Pick<Agencies, 'name'>
  )> }
);

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = (
  { __typename?: 'query_root' }
  & { profile: Array<(
    { __typename?: 'profile' }
    & ProfileResponseFragment
  )> }
);

export type PropertyInfoFragment = (
  { __typename?: 'properties' }
  & Pick<Properties, 'id' | 'name' | 'createdAt' | 'address1' | 'address2' | 'prefferedReportingMethod'>
);

export type ListAllPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllPropertiesQuery = (
  { __typename?: 'query_root' }
  & { properties: Array<(
    { __typename?: 'properties' }
    & Pick<Properties, 'id' | 'name' | 'createdAt'>
  )> }
);

export type ListPropertiesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPropertiesQuery = (
  { __typename?: 'query_root' }
  & { properties: Array<(
    { __typename?: 'properties' }
    & Pick<Properties, 'id' | 'name' | 'createdAt'>
  )>, properties_aggregate: (
    { __typename?: 'properties_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'properties_aggregate_fields' }
      & Pick<Properties_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListPropertiesForAgencyFilterQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  agencyId: Scalars['uuid'];
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPropertiesForAgencyFilterQuery = (
  { __typename?: 'query_root' }
  & { properties: Array<(
    { __typename?: 'properties' }
    & Pick<Properties, 'id' | 'name' | 'createdAt'>
  )> }
);

export type CreatePropertyMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['uuid']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
}>;


export type CreatePropertyMutation = (
  { __typename?: 'mutation_root' }
  & { insert_properties_one?: Maybe<(
    { __typename?: 'properties' }
    & PropertyInfoFragment
  )> }
);

export type UpdatePropertyMutationVariables = Exact<{
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  prefferedReportingMethod?: Maybe<Scalars['String']>;
}>;


export type UpdatePropertyMutation = (
  { __typename?: 'mutation_root' }
  & { update_properties_by_pk?: Maybe<(
    { __typename?: 'properties' }
    & PropertyInfoFragment
  )> }
);

export type DeletePropertyMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePropertyMutation = (
  { __typename?: 'mutation_root' }
  & { delete_properties_by_pk?: Maybe<(
    { __typename?: 'properties' }
    & PropertyInfoFragment
  )> }
);

export type FetchPropertyInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchPropertyInfoQuery = (
  { __typename?: 'query_root' }
  & { properties_by_pk?: Maybe<(
    { __typename?: 'properties' }
    & PropertyInfoFragment
  )> }
);

export type GeneratePropertyReportMutationVariables = Exact<{
  propertyId: Scalars['uuid'];
}>;


export type GeneratePropertyReportMutation = (
  { __typename?: 'mutation_root' }
  & { generateReport?: Maybe<(
    { __typename?: 'GenerateReportOutput' }
    & Pick<GenerateReportOutput, 'message'>
  )> }
);

export type PropertyContactInfoFragment = (
  { __typename?: 'property_contacts' }
  & Pick<Property_Contacts, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'createdAt' | 'position'>
);

export type ListPropertyContactsQueryVariables = Exact<{
  propertyId: Scalars['uuid'];
  query?: Maybe<Scalars['String']>;
  order?: Maybe<Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPropertyContactsQuery = (
  { __typename?: 'query_root' }
  & { property_contacts: Array<(
    { __typename?: 'property_contacts' }
    & PropertyContactInfoFragment
  )>, property_contacts_aggregate: (
    { __typename?: 'property_contacts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'property_contacts_aggregate_fields' }
      & Pick<Property_Contacts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CreatePropertyContactMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
}>;


export type CreatePropertyContactMutation = (
  { __typename?: 'mutation_root' }
  & { insert_property_contacts_one?: Maybe<(
    { __typename?: 'property_contacts' }
    & PropertyContactInfoFragment
  )> }
);

export type UpdatePropertyContactMutationVariables = Exact<{
  id: Scalars['uuid'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
}>;


export type UpdatePropertyContactMutation = (
  { __typename?: 'mutation_root' }
  & { update_property_contacts_by_pk?: Maybe<(
    { __typename?: 'property_contacts' }
    & PropertyContactInfoFragment
  )> }
);

export type DeletePropertyContactMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePropertyContactMutation = (
  { __typename?: 'mutation_root' }
  & { delete_property_contacts_by_pk?: Maybe<(
    { __typename?: 'property_contacts' }
    & PropertyContactInfoFragment
  )> }
);

export type FetchPropertyContactQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchPropertyContactQuery = (
  { __typename?: 'query_root' }
  & { property_contacts_by_pk?: Maybe<(
    { __typename?: 'property_contacts' }
    & PropertyContactInfoFragment
  )> }
);

export type ListUsersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  order?: Maybe<Order_By>;
  role?: Maybe<Scalars['users_role_enum']>;
}>;


export type ListUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'firstName' | 'lastName' | 'email' | 'dateOfBirth' | 'createdAt' | 'avatarUrl' | 'agencyId' | 'role' | 'disabled' | 'deviceId'>
  )>, users_aggregate: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UserInfoFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'role' | 'disabled' | 'deviceId' | 'disableNotifications'>
);

export type CreateUserMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['users_role_enum']>;
  agencyId?: Maybe<Scalars['uuid']>;
  disableNotifications?: Maybe<Scalars['Boolean']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one?: Maybe<(
    { __typename?: 'users' }
    & UserInfoFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  disableNotifications?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserInfoFragment
  )> }
);

export type UnlinkDeviceMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UnlinkDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserInfoFragment
  )> }
);

export type UpdateUserStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  disabled?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserInfoFragment
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'mutation_root' }
  & { delete_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserInfoFragment
  )> }
);

export type FetchUserInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type FetchUserInfoQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserInfoFragment
  )> }
);

export const IncidentTypeInfoFragmentDoc = gql`
    fragment IncidentTypeInfo on incident_type {
  id
  name
  agencyId
}
    `;
export const PersonInfoFragmentDoc = gql`
    fragment PersonInfo on persons {
  id
  firstName
  lastName
  knownToBeViolent
  listedAs
  createdAt
  updatedAt
  s3ImageKey
  trespassStatementAudioFileKey
  trespassStatementTranscript
}
    `;
export const PersonFragmentDoc = gql`
    fragment Person on persons {
  id
  firstName
  lastName
  knownToBeViolent
  datastoreId
  dateOfBirth
  idCardImageKey
  createdAt
  listedAs
  s3ImageKey
  person_datastore {
    name
    id
    ownerId
    agency {
      name
      id
    }
  }
  trespassStatementAudioFileKey
  incidents(order_by: {createdAt: desc}, limit: 1) {
    id
    location
    note
    type
  }
}
    `;
export const LprIncidentTypeInfoFragmentDoc = gql`
    fragment LPRIncidentTypeInfo on lpr_incident_type {
  id
  name
  agencyId
}
    `;
export const PlateInfoFragmentDoc = gql`
    fragment PlateInfo on plates {
  id
  number
  knownToBeViolent
  displayNumber
  listedAs
  createdAt
  updatedAt
  s3ImageKey
  lpr_images {
    id
    plateId
    s3ImageKey
  }
}
    `;
export const PlateFragmentDoc = gql`
    fragment Plate on plates {
  id
  displayNumber
  knownToBeViolent
  datastoreId
  createdAt
  listedAs
  number
  make
  model
  color
  year
  s3ImageKey
  updatedAt
  lpr_incidents(order_by: {createdAt: desc}, limit: 1) {
    id
    location
    note
    type
  }
}
    `;
export const ProfileResponseFragmentDoc = gql`
    fragment ProfileResponse on profile {
  firstName
  lastName
  agencyId
  avatarUrl
  createdAt
  dateOfBirth
  email
  id
  nickName
  role
  telephone
  timezone
  updatedAt
  agency {
    name
  }
}
    `;
export const PropertyInfoFragmentDoc = gql`
    fragment PropertyInfo on properties {
  id
  name
  createdAt
  address1
  address2
  prefferedReportingMethod
}
    `;
export const PropertyContactInfoFragmentDoc = gql`
    fragment PropertyContactInfo on property_contacts {
  id
  firstName
  lastName
  email
  phone
  createdAt
  position
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on users {
  id
  firstName
  lastName
  email
  createdAt
  role
  disabled
  deviceId
  disableNotifications
}
    `;
export const GetAdminDashboardStatsDocument = gql`
    query GetAdminDashboardStats {
  superadmins: users_aggregate(where: {role: {_eq: "superadmins"}}) {
    aggregate {
      count
    }
  }
  agencyadmins: users_aggregate(where: {role: {_eq: "agencyadmins"}}) {
    aggregate {
      count
    }
  }
  agents: users_aggregate(where: {role: {_eq: "agents"}}) {
    aggregate {
      count
    }
  }
  persons_aggregate {
    aggregate {
      count
    }
  }
  plates_aggregate {
    aggregate {
      count
    }
  }
  incidents_aggregate {
    aggregate {
      count
    }
  }
  lpr_incidents_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAdminDashboardStatsQuery__
 *
 * To run a query within a React component, call `useGetAdminDashboardStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDashboardStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDashboardStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDashboardStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminDashboardStatsQuery, GetAdminDashboardStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminDashboardStatsQuery, GetAdminDashboardStatsQueryVariables>(GetAdminDashboardStatsDocument, options);
      }
export function useGetAdminDashboardStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDashboardStatsQuery, GetAdminDashboardStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminDashboardStatsQuery, GetAdminDashboardStatsQueryVariables>(GetAdminDashboardStatsDocument, options);
        }
export type GetAdminDashboardStatsQueryHookResult = ReturnType<typeof useGetAdminDashboardStatsQuery>;
export type GetAdminDashboardStatsLazyQueryHookResult = ReturnType<typeof useGetAdminDashboardStatsLazyQuery>;
export type GetAdminDashboardStatsQueryResult = Apollo.QueryResult<GetAdminDashboardStatsQuery, GetAdminDashboardStatsQueryVariables>;
export function refetchGetAdminDashboardStatsQuery(variables?: GetAdminDashboardStatsQueryVariables) {
      return { query: GetAdminDashboardStatsDocument, variables: variables }
    }
export const ListAgencyUserViewDocument = gql`
    query ListAgencyUserView {
  agency_users_view {
    user_count
    noOfAgents
  }
}
    `;

/**
 * __useListAgencyUserViewQuery__
 *
 * To run a query within a React component, call `useListAgencyUserViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencyUserViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencyUserViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAgencyUserViewQuery(baseOptions?: Apollo.QueryHookOptions<ListAgencyUserViewQuery, ListAgencyUserViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAgencyUserViewQuery, ListAgencyUserViewQueryVariables>(ListAgencyUserViewDocument, options);
      }
export function useListAgencyUserViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAgencyUserViewQuery, ListAgencyUserViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAgencyUserViewQuery, ListAgencyUserViewQueryVariables>(ListAgencyUserViewDocument, options);
        }
export type ListAgencyUserViewQueryHookResult = ReturnType<typeof useListAgencyUserViewQuery>;
export type ListAgencyUserViewLazyQueryHookResult = ReturnType<typeof useListAgencyUserViewLazyQuery>;
export type ListAgencyUserViewQueryResult = Apollo.QueryResult<ListAgencyUserViewQuery, ListAgencyUserViewQueryVariables>;
export function refetchListAgencyUserViewQuery(variables?: ListAgencyUserViewQueryVariables) {
      return { query: ListAgencyUserViewDocument, variables: variables }
    }
export const CreateAgencyDocument = gql`
    mutation CreateAgency($name: String, $adminFirstName: String, $subscription: agencies_subscription_enum, $adminLastName: String, $adminEmail: String, $agentFirstName: String, $agentLastName: String, $agentEmail: String, $trespassStatement: String, $noOfAgents: Int, $propertyName: String, $address1: String, $address2: String, $prefferedReportingMethod: String) {
  insert_agency_metadata_one(
    object: {trespassStatement: $trespassStatement, noOfAgents: $noOfAgents, agency: {data: {name: $name, subscription: $subscription, users: {data: [{firstName: $adminFirstName, lastName: $adminLastName, email: $adminEmail, role: "agencyadmins"}, {firstName: $agentFirstName, lastName: $agentLastName, email: $agentEmail, role: "agents"}]}, properties: {data: {name: $propertyName, address1: $address1, address2: $address2, prefferedReportingMethod: $prefferedReportingMethod}}}}}
  ) {
    trespassStatement
    noOfAgents
    agency {
      id
      name
      users {
        id
        firstName
        lastName
        role
      }
      properties {
        name
        location
        prefferedReportingMethod
      }
    }
  }
}
    `;
export type CreateAgencyMutationFn = Apollo.MutationFunction<CreateAgencyMutation, CreateAgencyMutationVariables>;

/**
 * __useCreateAgencyMutation__
 *
 * To run a mutation, you first call `useCreateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgencyMutation, { data, loading, error }] = useCreateAgencyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      adminFirstName: // value for 'adminFirstName'
 *      subscription: // value for 'subscription'
 *      adminLastName: // value for 'adminLastName'
 *      adminEmail: // value for 'adminEmail'
 *      agentFirstName: // value for 'agentFirstName'
 *      agentLastName: // value for 'agentLastName'
 *      agentEmail: // value for 'agentEmail'
 *      trespassStatement: // value for 'trespassStatement'
 *      noOfAgents: // value for 'noOfAgents'
 *      propertyName: // value for 'propertyName'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      prefferedReportingMethod: // value for 'prefferedReportingMethod'
 *   },
 * });
 */
export function useCreateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgencyMutation, CreateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgencyMutation, CreateAgencyMutationVariables>(CreateAgencyDocument, options);
      }
export type CreateAgencyMutationHookResult = ReturnType<typeof useCreateAgencyMutation>;
export type CreateAgencyMutationResult = Apollo.MutationResult<CreateAgencyMutation>;
export type CreateAgencyMutationOptions = Apollo.BaseMutationOptions<CreateAgencyMutation, CreateAgencyMutationVariables>;
export const SetupAgencyResourcesDocument = gql`
    mutation SetupAgencyResources($agencyId: uuid) {
  personDatastore: insert_person_datastores_one(
    object: {isPublic: false, ownerId: $agencyId, agency_person_datastores: {data: {agenciesId: $agencyId}}}
  ) {
    id
  }
  lprDatastore: insert_lpr_datastores_one(
    object: {isPublic: false, ownerId: $agencyId, agency_lpr_datastores: {data: {agenciesId: $agencyId}}}
  ) {
    id
  }
}
    `;
export type SetupAgencyResourcesMutationFn = Apollo.MutationFunction<SetupAgencyResourcesMutation, SetupAgencyResourcesMutationVariables>;

/**
 * __useSetupAgencyResourcesMutation__
 *
 * To run a mutation, you first call `useSetupAgencyResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupAgencyResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupAgencyResourcesMutation, { data, loading, error }] = useSetupAgencyResourcesMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useSetupAgencyResourcesMutation(baseOptions?: Apollo.MutationHookOptions<SetupAgencyResourcesMutation, SetupAgencyResourcesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupAgencyResourcesMutation, SetupAgencyResourcesMutationVariables>(SetupAgencyResourcesDocument, options);
      }
export type SetupAgencyResourcesMutationHookResult = ReturnType<typeof useSetupAgencyResourcesMutation>;
export type SetupAgencyResourcesMutationResult = Apollo.MutationResult<SetupAgencyResourcesMutation>;
export type SetupAgencyResourcesMutationOptions = Apollo.BaseMutationOptions<SetupAgencyResourcesMutation, SetupAgencyResourcesMutationVariables>;
export const ListAgenciesDocument = gql`
    query listAgencies($limit: Int, $offset: Int) {
  agencies(limit: $limit, offset: $offset) {
    id
    name
    disabled
    createdAt
    agency_metadata {
      noOfAgents
    }
    totalAgents: users_aggregate(where: {role: {_eq: "agents"}}) {
      aggregate {
        count
      }
    }
    totalAdmins: users_aggregate(where: {role: {_eq: "agencyadmins"}}) {
      aggregate {
        count
      }
    }
  }
  totalAgencies: agencies_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListAgenciesQuery__
 *
 * To run a query within a React component, call `useListAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgenciesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListAgenciesQuery(baseOptions?: Apollo.QueryHookOptions<ListAgenciesQuery, ListAgenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAgenciesQuery, ListAgenciesQueryVariables>(ListAgenciesDocument, options);
      }
export function useListAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAgenciesQuery, ListAgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAgenciesQuery, ListAgenciesQueryVariables>(ListAgenciesDocument, options);
        }
export type ListAgenciesQueryHookResult = ReturnType<typeof useListAgenciesQuery>;
export type ListAgenciesLazyQueryHookResult = ReturnType<typeof useListAgenciesLazyQuery>;
export type ListAgenciesQueryResult = Apollo.QueryResult<ListAgenciesQuery, ListAgenciesQueryVariables>;
export function refetchListAgenciesQuery(variables?: ListAgenciesQueryVariables) {
      return { query: ListAgenciesDocument, variables: variables }
    }
export const UpdateAgencyStatusDocument = gql`
    mutation UpdateAgencyStatus($id: uuid!, $disabled: Boolean) {
  update_agencies_by_pk(pk_columns: {id: $id}, _set: {disabled: $disabled}) {
    id
  }
}
    `;
export type UpdateAgencyStatusMutationFn = Apollo.MutationFunction<UpdateAgencyStatusMutation, UpdateAgencyStatusMutationVariables>;

/**
 * __useUpdateAgencyStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyStatusMutation, { data, loading, error }] = useUpdateAgencyStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useUpdateAgencyStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencyStatusMutation, UpdateAgencyStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencyStatusMutation, UpdateAgencyStatusMutationVariables>(UpdateAgencyStatusDocument, options);
      }
export type UpdateAgencyStatusMutationHookResult = ReturnType<typeof useUpdateAgencyStatusMutation>;
export type UpdateAgencyStatusMutationResult = Apollo.MutationResult<UpdateAgencyStatusMutation>;
export type UpdateAgencyStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAgencyStatusMutation, UpdateAgencyStatusMutationVariables>;
export const GetAgencyDocument = gql`
    query GetAgency($id: uuid!) {
  agencies_by_pk(id: $id) {
    name
    id
    subscription
    agency_metadata {
      noOfAgents
      trespassStatement
    }
  }
}
    `;

/**
 * __useGetAgencyQuery__
 *
 * To run a query within a React component, call `useGetAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAgencyQuery(baseOptions: Apollo.QueryHookOptions<GetAgencyQuery, GetAgencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgencyQuery, GetAgencyQueryVariables>(GetAgencyDocument, options);
      }
export function useGetAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgencyQuery, GetAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgencyQuery, GetAgencyQueryVariables>(GetAgencyDocument, options);
        }
export type GetAgencyQueryHookResult = ReturnType<typeof useGetAgencyQuery>;
export type GetAgencyLazyQueryHookResult = ReturnType<typeof useGetAgencyLazyQuery>;
export type GetAgencyQueryResult = Apollo.QueryResult<GetAgencyQuery, GetAgencyQueryVariables>;
export function refetchGetAgencyQuery(variables?: GetAgencyQueryVariables) {
      return { query: GetAgencyDocument, variables: variables }
    }
export const UpdateAgencyDocument = gql`
    mutation updateAgency($agencyId: uuid!, $name: String, $trespassStatement: String, $noOfAgents: Int) {
  update_agencies(where: {id: {_eq: $agencyId}}, _set: {name: $name}) {
    affected_rows
    returning {
      name
    }
  }
  update_agency_metadata(
    where: {agencyId: {_eq: $agencyId}}
    _set: {noOfAgents: $noOfAgents, trespassStatement: $trespassStatement}
  ) {
    affected_rows
    returning {
      noOfAgents
      agencyId
      trespassStatement
      agency {
        name
        id
      }
    }
  }
}
    `;
export type UpdateAgencyMutationFn = Apollo.MutationFunction<UpdateAgencyMutation, UpdateAgencyMutationVariables>;

/**
 * __useUpdateAgencyMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyMutation, { data, loading, error }] = useUpdateAgencyMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      name: // value for 'name'
 *      trespassStatement: // value for 'trespassStatement'
 *      noOfAgents: // value for 'noOfAgents'
 *   },
 * });
 */
export function useUpdateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencyMutation, UpdateAgencyMutationVariables>(UpdateAgencyDocument, options);
      }
export type UpdateAgencyMutationHookResult = ReturnType<typeof useUpdateAgencyMutation>;
export type UpdateAgencyMutationResult = Apollo.MutationResult<UpdateAgencyMutation>;
export type UpdateAgencyMutationOptions = Apollo.BaseMutationOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>;
export const UpdateAgencySubscriptionDocument = gql`
    mutation updateAgencySubscription($id: uuid!, $subscription: agencies_subscription_enum) {
  update_agencies_by_pk(
    pk_columns: {id: $id}
    _set: {subscription: $subscription}
  ) {
    subscription
  }
}
    `;
export type UpdateAgencySubscriptionMutationFn = Apollo.MutationFunction<UpdateAgencySubscriptionMutation, UpdateAgencySubscriptionMutationVariables>;

/**
 * __useUpdateAgencySubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateAgencySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencySubscriptionMutation, { data, loading, error }] = useUpdateAgencySubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useUpdateAgencySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencySubscriptionMutation, UpdateAgencySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencySubscriptionMutation, UpdateAgencySubscriptionMutationVariables>(UpdateAgencySubscriptionDocument, options);
      }
export type UpdateAgencySubscriptionMutationHookResult = ReturnType<typeof useUpdateAgencySubscriptionMutation>;
export type UpdateAgencySubscriptionMutationResult = Apollo.MutationResult<UpdateAgencySubscriptionMutation>;
export type UpdateAgencySubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateAgencySubscriptionMutation, UpdateAgencySubscriptionMutationVariables>;
export const ListAgentsDocument = gql`
    query ListAgents($agencyId: uuid) {
  users(where: {_and: [{agencyId: {_eq: $agencyId}}, {role: {_eq: "agents"}}]}) {
    firstName
    lastName
    email
    role
    id
    agencyId
  }
  totalAgents: users_aggregate(
    where: {_and: [{agencyId: {_eq: $agencyId}}, {role: {_eq: "agents"}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListAgentsQuery__
 *
 * To run a query within a React component, call `useListAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgentsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListAgentsQuery(baseOptions?: Apollo.QueryHookOptions<ListAgentsQuery, ListAgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAgentsQuery, ListAgentsQueryVariables>(ListAgentsDocument, options);
      }
export function useListAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAgentsQuery, ListAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAgentsQuery, ListAgentsQueryVariables>(ListAgentsDocument, options);
        }
export type ListAgentsQueryHookResult = ReturnType<typeof useListAgentsQuery>;
export type ListAgentsLazyQueryHookResult = ReturnType<typeof useListAgentsLazyQuery>;
export type ListAgentsQueryResult = Apollo.QueryResult<ListAgentsQuery, ListAgentsQueryVariables>;
export function refetchListAgentsQuery(variables?: ListAgentsQueryVariables) {
      return { query: ListAgentsDocument, variables: variables }
    }
export const ListAdminsDocument = gql`
    query ListAdmins($agencyId: uuid) {
  users(
    where: {_and: [{agencyId: {_eq: $agencyId}}, {role: {_eq: "agencyadmins"}}]}
  ) {
    firstName
    lastName
    email
    role
    id
    agencyId
  }
  totalAdmins: users_aggregate(
    where: {_and: [{agencyId: {_eq: $agencyId}}, {role: {_eq: "agencyadmins"}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListAdminsQuery__
 *
 * To run a query within a React component, call `useListAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListAdminsQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminsQuery, ListAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminsQuery, ListAdminsQueryVariables>(ListAdminsDocument, options);
      }
export function useListAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminsQuery, ListAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminsQuery, ListAdminsQueryVariables>(ListAdminsDocument, options);
        }
export type ListAdminsQueryHookResult = ReturnType<typeof useListAdminsQuery>;
export type ListAdminsLazyQueryHookResult = ReturnType<typeof useListAdminsLazyQuery>;
export type ListAdminsQueryResult = Apollo.QueryResult<ListAdminsQuery, ListAdminsQueryVariables>;
export function refetchListAdminsQuery(variables?: ListAdminsQueryVariables) {
      return { query: ListAdminsDocument, variables: variables }
    }
export const ListPropertiesByAgencyDocument = gql`
    query ListPropertiesByAgency($id: uuid) {
  properties(where: {agencyId: {_eq: $id}}) {
    id
    address1
    address2
    agencyId
    location
    prefferedReportingMethod
    reportingTime
    name
    createdAt
    updatedAt
  }
  properties_aggregate(where: {_and: [{agencyId: {_eq: $id}}]}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPropertiesByAgencyQuery__
 *
 * To run a query within a React component, call `useListPropertiesByAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPropertiesByAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPropertiesByAgencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListPropertiesByAgencyQuery(baseOptions?: Apollo.QueryHookOptions<ListPropertiesByAgencyQuery, ListPropertiesByAgencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPropertiesByAgencyQuery, ListPropertiesByAgencyQueryVariables>(ListPropertiesByAgencyDocument, options);
      }
export function useListPropertiesByAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPropertiesByAgencyQuery, ListPropertiesByAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPropertiesByAgencyQuery, ListPropertiesByAgencyQueryVariables>(ListPropertiesByAgencyDocument, options);
        }
export type ListPropertiesByAgencyQueryHookResult = ReturnType<typeof useListPropertiesByAgencyQuery>;
export type ListPropertiesByAgencyLazyQueryHookResult = ReturnType<typeof useListPropertiesByAgencyLazyQuery>;
export type ListPropertiesByAgencyQueryResult = Apollo.QueryResult<ListPropertiesByAgencyQuery, ListPropertiesByAgencyQueryVariables>;
export function refetchListPropertiesByAgencyQuery(variables?: ListPropertiesByAgencyQueryVariables) {
      return { query: ListPropertiesByAgencyDocument, variables: variables }
    }
export const DeleteAgencyDocument = gql`
    mutation deleteAgency($id: uuid!) {
  delete_agencies_by_pk(id: $id) {
    id
    name
    subscription
  }
}
    `;
export type DeleteAgencyMutationFn = Apollo.MutationFunction<DeleteAgencyMutation, DeleteAgencyMutationVariables>;

/**
 * __useDeleteAgencyMutation__
 *
 * To run a mutation, you first call `useDeleteAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgencyMutation, { data, loading, error }] = useDeleteAgencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAgencyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAgencyMutation, DeleteAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAgencyMutation, DeleteAgencyMutationVariables>(DeleteAgencyDocument, options);
      }
export type DeleteAgencyMutationHookResult = ReturnType<typeof useDeleteAgencyMutation>;
export type DeleteAgencyMutationResult = Apollo.MutationResult<DeleteAgencyMutation>;
export type DeleteAgencyMutationOptions = Apollo.BaseMutationOptions<DeleteAgencyMutation, DeleteAgencyMutationVariables>;
export const ListLprDataStoresDocument = gql`
    query ListLprDataStores {
  lpr_datastores {
    id
    agency {
      id
      name
    }
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListLprDataStoresQuery__
 *
 * To run a query within a React component, call `useListLprDataStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLprDataStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLprDataStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLprDataStoresQuery(baseOptions?: Apollo.QueryHookOptions<ListLprDataStoresQuery, ListLprDataStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLprDataStoresQuery, ListLprDataStoresQueryVariables>(ListLprDataStoresDocument, options);
      }
export function useListLprDataStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLprDataStoresQuery, ListLprDataStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLprDataStoresQuery, ListLprDataStoresQueryVariables>(ListLprDataStoresDocument, options);
        }
export type ListLprDataStoresQueryHookResult = ReturnType<typeof useListLprDataStoresQuery>;
export type ListLprDataStoresLazyQueryHookResult = ReturnType<typeof useListLprDataStoresLazyQuery>;
export type ListLprDataStoresQueryResult = Apollo.QueryResult<ListLprDataStoresQuery, ListLprDataStoresQueryVariables>;
export function refetchListLprDataStoresQuery(variables?: ListLprDataStoresQueryVariables) {
      return { query: ListLprDataStoresDocument, variables: variables }
    }
export const ListPersonDataStoresDocument = gql`
    query ListPersonDataStores {
  person_datastores {
    id
    agency {
      id
      name
    }
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useListPersonDataStoresQuery__
 *
 * To run a query within a React component, call `useListPersonDataStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonDataStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPersonDataStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPersonDataStoresQuery(baseOptions?: Apollo.QueryHookOptions<ListPersonDataStoresQuery, ListPersonDataStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPersonDataStoresQuery, ListPersonDataStoresQueryVariables>(ListPersonDataStoresDocument, options);
      }
export function useListPersonDataStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPersonDataStoresQuery, ListPersonDataStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPersonDataStoresQuery, ListPersonDataStoresQueryVariables>(ListPersonDataStoresDocument, options);
        }
export type ListPersonDataStoresQueryHookResult = ReturnType<typeof useListPersonDataStoresQuery>;
export type ListPersonDataStoresLazyQueryHookResult = ReturnType<typeof useListPersonDataStoresLazyQuery>;
export type ListPersonDataStoresQueryResult = Apollo.QueryResult<ListPersonDataStoresQuery, ListPersonDataStoresQueryVariables>;
export function refetchListPersonDataStoresQuery(variables?: ListPersonDataStoresQueryVariables) {
      return { query: ListPersonDataStoresDocument, variables: variables }
    }
export const AddPersonsDatastoreAccessDocument = gql`
    mutation AddPersonsDatastoreAccess($agenciesId: uuid!, $personDatastoresId: uuid!) {
  insert_agency_person_datastores_one(
    object: {agenciesId: $agenciesId, personDatastoresId: $personDatastoresId}
  ) {
    agenciesId
    personDatastoresId
  }
}
    `;
export type AddPersonsDatastoreAccessMutationFn = Apollo.MutationFunction<AddPersonsDatastoreAccessMutation, AddPersonsDatastoreAccessMutationVariables>;

/**
 * __useAddPersonsDatastoreAccessMutation__
 *
 * To run a mutation, you first call `useAddPersonsDatastoreAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonsDatastoreAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonsDatastoreAccessMutation, { data, loading, error }] = useAddPersonsDatastoreAccessMutation({
 *   variables: {
 *      agenciesId: // value for 'agenciesId'
 *      personDatastoresId: // value for 'personDatastoresId'
 *   },
 * });
 */
export function useAddPersonsDatastoreAccessMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonsDatastoreAccessMutation, AddPersonsDatastoreAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPersonsDatastoreAccessMutation, AddPersonsDatastoreAccessMutationVariables>(AddPersonsDatastoreAccessDocument, options);
      }
export type AddPersonsDatastoreAccessMutationHookResult = ReturnType<typeof useAddPersonsDatastoreAccessMutation>;
export type AddPersonsDatastoreAccessMutationResult = Apollo.MutationResult<AddPersonsDatastoreAccessMutation>;
export type AddPersonsDatastoreAccessMutationOptions = Apollo.BaseMutationOptions<AddPersonsDatastoreAccessMutation, AddPersonsDatastoreAccessMutationVariables>;
export const AddLprDatastoreAccessDocument = gql`
    mutation AddLprDatastoreAccess($agenciesId: uuid!, $lprDatastoresId: uuid!) {
  insert_agency_lpr_datastores_one(
    object: {agenciesId: $agenciesId, lprDatastoresId: $lprDatastoresId}
  ) {
    agenciesId
    lprDatastoresId
  }
}
    `;
export type AddLprDatastoreAccessMutationFn = Apollo.MutationFunction<AddLprDatastoreAccessMutation, AddLprDatastoreAccessMutationVariables>;

/**
 * __useAddLprDatastoreAccessMutation__
 *
 * To run a mutation, you first call `useAddLprDatastoreAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLprDatastoreAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLprDatastoreAccessMutation, { data, loading, error }] = useAddLprDatastoreAccessMutation({
 *   variables: {
 *      agenciesId: // value for 'agenciesId'
 *      lprDatastoresId: // value for 'lprDatastoresId'
 *   },
 * });
 */
export function useAddLprDatastoreAccessMutation(baseOptions?: Apollo.MutationHookOptions<AddLprDatastoreAccessMutation, AddLprDatastoreAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLprDatastoreAccessMutation, AddLprDatastoreAccessMutationVariables>(AddLprDatastoreAccessDocument, options);
      }
export type AddLprDatastoreAccessMutationHookResult = ReturnType<typeof useAddLprDatastoreAccessMutation>;
export type AddLprDatastoreAccessMutationResult = Apollo.MutationResult<AddLprDatastoreAccessMutation>;
export type AddLprDatastoreAccessMutationOptions = Apollo.BaseMutationOptions<AddLprDatastoreAccessMutation, AddLprDatastoreAccessMutationVariables>;
export const GetOwnedPersonDataStoreDocument = gql`
    query getOwnedPersonDataStore($id: uuid!) {
  person_datastores(where: {agency: {id: {_eq: $id}}}) {
    id
    agency {
      id
      name
    }
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetOwnedPersonDataStoreQuery__
 *
 * To run a query within a React component, call `useGetOwnedPersonDataStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnedPersonDataStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnedPersonDataStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnedPersonDataStoreQuery(baseOptions: Apollo.QueryHookOptions<GetOwnedPersonDataStoreQuery, GetOwnedPersonDataStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnedPersonDataStoreQuery, GetOwnedPersonDataStoreQueryVariables>(GetOwnedPersonDataStoreDocument, options);
      }
export function useGetOwnedPersonDataStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnedPersonDataStoreQuery, GetOwnedPersonDataStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnedPersonDataStoreQuery, GetOwnedPersonDataStoreQueryVariables>(GetOwnedPersonDataStoreDocument, options);
        }
export type GetOwnedPersonDataStoreQueryHookResult = ReturnType<typeof useGetOwnedPersonDataStoreQuery>;
export type GetOwnedPersonDataStoreLazyQueryHookResult = ReturnType<typeof useGetOwnedPersonDataStoreLazyQuery>;
export type GetOwnedPersonDataStoreQueryResult = Apollo.QueryResult<GetOwnedPersonDataStoreQuery, GetOwnedPersonDataStoreQueryVariables>;
export function refetchGetOwnedPersonDataStoreQuery(variables?: GetOwnedPersonDataStoreQueryVariables) {
      return { query: GetOwnedPersonDataStoreDocument, variables: variables }
    }
export const GetOwnedLprDataStoreDocument = gql`
    query getOwnedLprDataStore($id: uuid!) {
  lpr_datastores(where: {agency: {id: {_eq: $id}}}) {
    id
    agency {
      id
      name
    }
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetOwnedLprDataStoreQuery__
 *
 * To run a query within a React component, call `useGetOwnedLprDataStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnedLprDataStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnedLprDataStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnedLprDataStoreQuery(baseOptions: Apollo.QueryHookOptions<GetOwnedLprDataStoreQuery, GetOwnedLprDataStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnedLprDataStoreQuery, GetOwnedLprDataStoreQueryVariables>(GetOwnedLprDataStoreDocument, options);
      }
export function useGetOwnedLprDataStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnedLprDataStoreQuery, GetOwnedLprDataStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnedLprDataStoreQuery, GetOwnedLprDataStoreQueryVariables>(GetOwnedLprDataStoreDocument, options);
        }
export type GetOwnedLprDataStoreQueryHookResult = ReturnType<typeof useGetOwnedLprDataStoreQuery>;
export type GetOwnedLprDataStoreLazyQueryHookResult = ReturnType<typeof useGetOwnedLprDataStoreLazyQuery>;
export type GetOwnedLprDataStoreQueryResult = Apollo.QueryResult<GetOwnedLprDataStoreQuery, GetOwnedLprDataStoreQueryVariables>;
export function refetchGetOwnedLprDataStoreQuery(variables?: GetOwnedLprDataStoreQueryVariables) {
      return { query: GetOwnedLprDataStoreDocument, variables: variables }
    }
export const ListFtpUsersDocument = gql`
    query ListFTPUsers($query: String) {
  ftp_user(where: {username: {_ilike: $query}}) {
    id
    username
    agencyId
  }
}
    `;

/**
 * __useListFtpUsersQuery__
 *
 * To run a query within a React component, call `useListFtpUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFtpUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFtpUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useListFtpUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListFtpUsersQuery, ListFtpUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFtpUsersQuery, ListFtpUsersQueryVariables>(ListFtpUsersDocument, options);
      }
export function useListFtpUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFtpUsersQuery, ListFtpUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFtpUsersQuery, ListFtpUsersQueryVariables>(ListFtpUsersDocument, options);
        }
export type ListFtpUsersQueryHookResult = ReturnType<typeof useListFtpUsersQuery>;
export type ListFtpUsersLazyQueryHookResult = ReturnType<typeof useListFtpUsersLazyQuery>;
export type ListFtpUsersQueryResult = Apollo.QueryResult<ListFtpUsersQuery, ListFtpUsersQueryVariables>;
export function refetchListFtpUsersQuery(variables?: ListFtpUsersQueryVariables) {
      return { query: ListFtpUsersDocument, variables: variables }
    }
export const CreateFtpUserDocument = gql`
    mutation CreateFTPUser($username: String, $password: String) {
  createFTPUser(params: {password: $password, username: $username}) {
    username
  }
}
    `;
export type CreateFtpUserMutationFn = Apollo.MutationFunction<CreateFtpUserMutation, CreateFtpUserMutationVariables>;

/**
 * __useCreateFtpUserMutation__
 *
 * To run a mutation, you first call `useCreateFtpUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFtpUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFtpUserMutation, { data, loading, error }] = useCreateFtpUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateFtpUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateFtpUserMutation, CreateFtpUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFtpUserMutation, CreateFtpUserMutationVariables>(CreateFtpUserDocument, options);
      }
export type CreateFtpUserMutationHookResult = ReturnType<typeof useCreateFtpUserMutation>;
export type CreateFtpUserMutationResult = Apollo.MutationResult<CreateFtpUserMutation>;
export type CreateFtpUserMutationOptions = Apollo.BaseMutationOptions<CreateFtpUserMutation, CreateFtpUserMutationVariables>;
export const UpdateFtpUserDocument = gql`
    mutation UpdateFTPUser($username: String, $password: String) {
  updateFTPUser(params: {password: $password, username: $username}) {
    isUpdated
  }
}
    `;
export type UpdateFtpUserMutationFn = Apollo.MutationFunction<UpdateFtpUserMutation, UpdateFtpUserMutationVariables>;

/**
 * __useUpdateFtpUserMutation__
 *
 * To run a mutation, you first call `useUpdateFtpUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFtpUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFtpUserMutation, { data, loading, error }] = useUpdateFtpUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateFtpUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFtpUserMutation, UpdateFtpUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFtpUserMutation, UpdateFtpUserMutationVariables>(UpdateFtpUserDocument, options);
      }
export type UpdateFtpUserMutationHookResult = ReturnType<typeof useUpdateFtpUserMutation>;
export type UpdateFtpUserMutationResult = Apollo.MutationResult<UpdateFtpUserMutation>;
export type UpdateFtpUserMutationOptions = Apollo.BaseMutationOptions<UpdateFtpUserMutation, UpdateFtpUserMutationVariables>;
export const ListIncidentTypesDocument = gql`
    query ListIncidentTypes($query: String, $limit: Int, $offset: Int, $order: order_by) {
  incident_type(
    where: {name: {_ilike: $query}}
    limit: $limit
    offset: $offset
    order_by: {createdAt: $order}
  ) {
    ...IncidentTypeInfo
  }
  incident_type_aggregate(where: {name: {_ilike: $query}}) {
    aggregate {
      count
    }
  }
}
    ${IncidentTypeInfoFragmentDoc}`;

/**
 * __useListIncidentTypesQuery__
 *
 * To run a query within a React component, call `useListIncidentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListIncidentTypesQuery(baseOptions?: Apollo.QueryHookOptions<ListIncidentTypesQuery, ListIncidentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIncidentTypesQuery, ListIncidentTypesQueryVariables>(ListIncidentTypesDocument, options);
      }
export function useListIncidentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIncidentTypesQuery, ListIncidentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIncidentTypesQuery, ListIncidentTypesQueryVariables>(ListIncidentTypesDocument, options);
        }
export type ListIncidentTypesQueryHookResult = ReturnType<typeof useListIncidentTypesQuery>;
export type ListIncidentTypesLazyQueryHookResult = ReturnType<typeof useListIncidentTypesLazyQuery>;
export type ListIncidentTypesQueryResult = Apollo.QueryResult<ListIncidentTypesQuery, ListIncidentTypesQueryVariables>;
export function refetchListIncidentTypesQuery(variables?: ListIncidentTypesQueryVariables) {
      return { query: ListIncidentTypesDocument, variables: variables }
    }
export const ListIncidentTypesByAgencyDocument = gql`
    query ListIncidentTypesByAgency($agencyId: uuid, $limit: Int, $offset: Int, $order: order_by) {
  incident_type(
    where: {agencyId: {_eq: $agencyId}}
    limit: $limit
    offset: $offset
    order_by: {createdAt: $order}
  ) {
    ...IncidentTypeInfo
  }
  incident_type_aggregate(where: {agencyId: {_eq: $agencyId}}) {
    aggregate {
      count
    }
  }
}
    ${IncidentTypeInfoFragmentDoc}`;

/**
 * __useListIncidentTypesByAgencyQuery__
 *
 * To run a query within a React component, call `useListIncidentTypesByAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentTypesByAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentTypesByAgencyQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListIncidentTypesByAgencyQuery(baseOptions?: Apollo.QueryHookOptions<ListIncidentTypesByAgencyQuery, ListIncidentTypesByAgencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIncidentTypesByAgencyQuery, ListIncidentTypesByAgencyQueryVariables>(ListIncidentTypesByAgencyDocument, options);
      }
export function useListIncidentTypesByAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIncidentTypesByAgencyQuery, ListIncidentTypesByAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIncidentTypesByAgencyQuery, ListIncidentTypesByAgencyQueryVariables>(ListIncidentTypesByAgencyDocument, options);
        }
export type ListIncidentTypesByAgencyQueryHookResult = ReturnType<typeof useListIncidentTypesByAgencyQuery>;
export type ListIncidentTypesByAgencyLazyQueryHookResult = ReturnType<typeof useListIncidentTypesByAgencyLazyQuery>;
export type ListIncidentTypesByAgencyQueryResult = Apollo.QueryResult<ListIncidentTypesByAgencyQuery, ListIncidentTypesByAgencyQueryVariables>;
export function refetchListIncidentTypesByAgencyQuery(variables?: ListIncidentTypesByAgencyQueryVariables) {
      return { query: ListIncidentTypesByAgencyDocument, variables: variables }
    }
export const ListIncidentTypesBySuperDocument = gql`
    query ListIncidentTypesBySuper($limit: Int, $offset: Int, $order: order_by) {
  incident_type(
    where: {agencyId: {_is_null: true}}
    limit: $limit
    offset: $offset
    order_by: {createdAt: $order}
  ) {
    ...IncidentTypeInfo
  }
  incident_type_aggregate(where: {agencyId: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    ${IncidentTypeInfoFragmentDoc}`;

/**
 * __useListIncidentTypesBySuperQuery__
 *
 * To run a query within a React component, call `useListIncidentTypesBySuperQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentTypesBySuperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentTypesBySuperQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListIncidentTypesBySuperQuery(baseOptions?: Apollo.QueryHookOptions<ListIncidentTypesBySuperQuery, ListIncidentTypesBySuperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIncidentTypesBySuperQuery, ListIncidentTypesBySuperQueryVariables>(ListIncidentTypesBySuperDocument, options);
      }
export function useListIncidentTypesBySuperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIncidentTypesBySuperQuery, ListIncidentTypesBySuperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIncidentTypesBySuperQuery, ListIncidentTypesBySuperQueryVariables>(ListIncidentTypesBySuperDocument, options);
        }
export type ListIncidentTypesBySuperQueryHookResult = ReturnType<typeof useListIncidentTypesBySuperQuery>;
export type ListIncidentTypesBySuperLazyQueryHookResult = ReturnType<typeof useListIncidentTypesBySuperLazyQuery>;
export type ListIncidentTypesBySuperQueryResult = Apollo.QueryResult<ListIncidentTypesBySuperQuery, ListIncidentTypesBySuperQueryVariables>;
export function refetchListIncidentTypesBySuperQuery(variables?: ListIncidentTypesBySuperQueryVariables) {
      return { query: ListIncidentTypesBySuperDocument, variables: variables }
    }
export const UpdateIncidentTypeDocument = gql`
    mutation UpdateIncidentType($id: uuid!, $name: String) {
  update_incident_type_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...IncidentTypeInfo
  }
}
    ${IncidentTypeInfoFragmentDoc}`;
export type UpdateIncidentTypeMutationFn = Apollo.MutationFunction<UpdateIncidentTypeMutation, UpdateIncidentTypeMutationVariables>;

/**
 * __useUpdateIncidentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentTypeMutation, { data, loading, error }] = useUpdateIncidentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateIncidentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncidentTypeMutation, UpdateIncidentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncidentTypeMutation, UpdateIncidentTypeMutationVariables>(UpdateIncidentTypeDocument, options);
      }
export type UpdateIncidentTypeMutationHookResult = ReturnType<typeof useUpdateIncidentTypeMutation>;
export type UpdateIncidentTypeMutationResult = Apollo.MutationResult<UpdateIncidentTypeMutation>;
export type UpdateIncidentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateIncidentTypeMutation, UpdateIncidentTypeMutationVariables>;
export const AddIncidentTypeDocument = gql`
    mutation AddIncidentType($name: String) {
  insert_incident_type_one(object: {name: $name}) {
    ...IncidentTypeInfo
  }
}
    ${IncidentTypeInfoFragmentDoc}`;
export type AddIncidentTypeMutationFn = Apollo.MutationFunction<AddIncidentTypeMutation, AddIncidentTypeMutationVariables>;

/**
 * __useAddIncidentTypeMutation__
 *
 * To run a mutation, you first call `useAddIncidentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIncidentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIncidentTypeMutation, { data, loading, error }] = useAddIncidentTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddIncidentTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddIncidentTypeMutation, AddIncidentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIncidentTypeMutation, AddIncidentTypeMutationVariables>(AddIncidentTypeDocument, options);
      }
export type AddIncidentTypeMutationHookResult = ReturnType<typeof useAddIncidentTypeMutation>;
export type AddIncidentTypeMutationResult = Apollo.MutationResult<AddIncidentTypeMutation>;
export type AddIncidentTypeMutationOptions = Apollo.BaseMutationOptions<AddIncidentTypeMutation, AddIncidentTypeMutationVariables>;
export const FetchIncidentTypeInfoDocument = gql`
    query FetchIncidentTypeInfo($id: uuid!) {
  incident_type_by_pk(id: $id) {
    ...IncidentTypeInfo
  }
}
    ${IncidentTypeInfoFragmentDoc}`;

/**
 * __useFetchIncidentTypeInfoQuery__
 *
 * To run a query within a React component, call `useFetchIncidentTypeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIncidentTypeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIncidentTypeInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchIncidentTypeInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchIncidentTypeInfoQuery, FetchIncidentTypeInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIncidentTypeInfoQuery, FetchIncidentTypeInfoQueryVariables>(FetchIncidentTypeInfoDocument, options);
      }
export function useFetchIncidentTypeInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIncidentTypeInfoQuery, FetchIncidentTypeInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIncidentTypeInfoQuery, FetchIncidentTypeInfoQueryVariables>(FetchIncidentTypeInfoDocument, options);
        }
export type FetchIncidentTypeInfoQueryHookResult = ReturnType<typeof useFetchIncidentTypeInfoQuery>;
export type FetchIncidentTypeInfoLazyQueryHookResult = ReturnType<typeof useFetchIncidentTypeInfoLazyQuery>;
export type FetchIncidentTypeInfoQueryResult = Apollo.QueryResult<FetchIncidentTypeInfoQuery, FetchIncidentTypeInfoQueryVariables>;
export function refetchFetchIncidentTypeInfoQuery(variables?: FetchIncidentTypeInfoQueryVariables) {
      return { query: FetchIncidentTypeInfoDocument, variables: variables }
    }
export const DeleteIncidentTypeDocument = gql`
    mutation DeleteIncidentType($id: uuid!) {
  delete_incident_type_by_pk(id: $id) {
    ...IncidentTypeInfo
  }
}
    ${IncidentTypeInfoFragmentDoc}`;
export type DeleteIncidentTypeMutationFn = Apollo.MutationFunction<DeleteIncidentTypeMutation, DeleteIncidentTypeMutationVariables>;

/**
 * __useDeleteIncidentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteIncidentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncidentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncidentTypeMutation, { data, loading, error }] = useDeleteIncidentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIncidentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncidentTypeMutation, DeleteIncidentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncidentTypeMutation, DeleteIncidentTypeMutationVariables>(DeleteIncidentTypeDocument, options);
      }
export type DeleteIncidentTypeMutationHookResult = ReturnType<typeof useDeleteIncidentTypeMutation>;
export type DeleteIncidentTypeMutationResult = Apollo.MutationResult<DeleteIncidentTypeMutation>;
export type DeleteIncidentTypeMutationOptions = Apollo.BaseMutationOptions<DeleteIncidentTypeMutation, DeleteIncidentTypeMutationVariables>;
export const GetIncidentTypesDocument = gql`
    query getIncidentTypes {
  incident_type {
    id
    agencyId
    name
  }
}
    `;

/**
 * __useGetIncidentTypesQuery__
 *
 * To run a query within a React component, call `useGetIncidentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIncidentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetIncidentTypesQuery, GetIncidentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentTypesQuery, GetIncidentTypesQueryVariables>(GetIncidentTypesDocument, options);
      }
export function useGetIncidentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentTypesQuery, GetIncidentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentTypesQuery, GetIncidentTypesQueryVariables>(GetIncidentTypesDocument, options);
        }
export type GetIncidentTypesQueryHookResult = ReturnType<typeof useGetIncidentTypesQuery>;
export type GetIncidentTypesLazyQueryHookResult = ReturnType<typeof useGetIncidentTypesLazyQuery>;
export type GetIncidentTypesQueryResult = Apollo.QueryResult<GetIncidentTypesQuery, GetIncidentTypesQueryVariables>;
export function refetchGetIncidentTypesQuery(variables?: GetIncidentTypesQueryVariables) {
      return { query: GetIncidentTypesDocument, variables: variables }
    }
export const GetPlateIncidentTypesDocument = gql`
    query getPlateIncidentTypes {
  lpr_incident_type {
    id
    agencyId
    name
  }
}
    `;

/**
 * __useGetPlateIncidentTypesQuery__
 *
 * To run a query within a React component, call `useGetPlateIncidentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlateIncidentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlateIncidentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlateIncidentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlateIncidentTypesQuery, GetPlateIncidentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlateIncidentTypesQuery, GetPlateIncidentTypesQueryVariables>(GetPlateIncidentTypesDocument, options);
      }
export function useGetPlateIncidentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlateIncidentTypesQuery, GetPlateIncidentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlateIncidentTypesQuery, GetPlateIncidentTypesQueryVariables>(GetPlateIncidentTypesDocument, options);
        }
export type GetPlateIncidentTypesQueryHookResult = ReturnType<typeof useGetPlateIncidentTypesQuery>;
export type GetPlateIncidentTypesLazyQueryHookResult = ReturnType<typeof useGetPlateIncidentTypesLazyQuery>;
export type GetPlateIncidentTypesQueryResult = Apollo.QueryResult<GetPlateIncidentTypesQuery, GetPlateIncidentTypesQueryVariables>;
export function refetchGetPlateIncidentTypesQuery(variables?: GetPlateIncidentTypesQueryVariables) {
      return { query: GetPlateIncidentTypesDocument, variables: variables }
    }
export const ListIncidentsDocument = gql`
    query ListIncidents($personId: uuid, $plateId: uuid, $propertyId: uuid, $limit: Int, $offset: Int) {
  incidents(
    where: {_or: [{person: {_eq: $personId}}, {propertyId: {_eq: $propertyId}}]}
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc}
  ) {
    id
    location
    createdAt
    accuracy
    property {
      name
    }
    personByPerson {
      firstName
      lastName
    }
    user {
      id
      firstName
      lastName
    }
  }
  lpr_incidents(where: {plateId: {_eq: $plateId}}, limit: $limit, offset: $offset) {
    id
    property {
      name
    }
    plate {
      displayNumber
    }
    user {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useListIncidentsQuery__
 *
 * To run a query within a React component, call `useListIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      plateId: // value for 'plateId'
 *      propertyId: // value for 'propertyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListIncidentsQuery(baseOptions?: Apollo.QueryHookOptions<ListIncidentsQuery, ListIncidentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIncidentsQuery, ListIncidentsQueryVariables>(ListIncidentsDocument, options);
      }
export function useListIncidentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIncidentsQuery, ListIncidentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIncidentsQuery, ListIncidentsQueryVariables>(ListIncidentsDocument, options);
        }
export type ListIncidentsQueryHookResult = ReturnType<typeof useListIncidentsQuery>;
export type ListIncidentsLazyQueryHookResult = ReturnType<typeof useListIncidentsLazyQuery>;
export type ListIncidentsQueryResult = Apollo.QueryResult<ListIncidentsQuery, ListIncidentsQueryVariables>;
export function refetchListIncidentsQuery(variables?: ListIncidentsQueryVariables) {
      return { query: ListIncidentsDocument, variables: variables }
    }
export const ListIncidentsLocationDocument = gql`
    query ListIncidentsLocation($personId: uuid, $plateId: uuid, $propertyId: uuid, $limit: Int, $offset: Int) {
  incidents(
    where: {_or: [{person: {_eq: $personId}}, {propertyId: {_eq: $propertyId}}]}
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc}
  ) {
    id
    location
  }
  lpr_incidents(
    where: {_or: [{plateId: {_eq: $plateId}}, {propertyId: {_eq: $propertyId}}]}
    limit: $limit
    offset: $offset
    order_by: {createdAt: desc}
  ) {
    id
    location
  }
}
    `;

/**
 * __useListIncidentsLocationQuery__
 *
 * To run a query within a React component, call `useListIncidentsLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentsLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentsLocationQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      plateId: // value for 'plateId'
 *      propertyId: // value for 'propertyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListIncidentsLocationQuery(baseOptions?: Apollo.QueryHookOptions<ListIncidentsLocationQuery, ListIncidentsLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIncidentsLocationQuery, ListIncidentsLocationQueryVariables>(ListIncidentsLocationDocument, options);
      }
export function useListIncidentsLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIncidentsLocationQuery, ListIncidentsLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIncidentsLocationQuery, ListIncidentsLocationQueryVariables>(ListIncidentsLocationDocument, options);
        }
export type ListIncidentsLocationQueryHookResult = ReturnType<typeof useListIncidentsLocationQuery>;
export type ListIncidentsLocationLazyQueryHookResult = ReturnType<typeof useListIncidentsLocationLazyQuery>;
export type ListIncidentsLocationQueryResult = Apollo.QueryResult<ListIncidentsLocationQuery, ListIncidentsLocationQueryVariables>;
export function refetchListIncidentsLocationQuery(variables?: ListIncidentsLocationQueryVariables) {
      return { query: ListIncidentsLocationDocument, variables: variables }
    }
export const AggregateIncidentsDocument = gql`
    query AggregateIncidents($personId: uuid, $plateId: uuid, $propertyId: uuid, $limit: Int, $offset: Int) {
  incidents_aggregate(
    where: {_or: [{person: {_eq: $personId}}, {propertyId: {_eq: $propertyId}}]}
    limit: $limit
    offset: $offset
  ) {
    aggregate {
      count
    }
  }
  lpr_incidents_aggregate(where: {plateId: {_eq: $plateId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAggregateIncidentsQuery__
 *
 * To run a query within a React component, call `useAggregateIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateIncidentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      plateId: // value for 'plateId'
 *      propertyId: // value for 'propertyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAggregateIncidentsQuery(baseOptions?: Apollo.QueryHookOptions<AggregateIncidentsQuery, AggregateIncidentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateIncidentsQuery, AggregateIncidentsQueryVariables>(AggregateIncidentsDocument, options);
      }
export function useAggregateIncidentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateIncidentsQuery, AggregateIncidentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateIncidentsQuery, AggregateIncidentsQueryVariables>(AggregateIncidentsDocument, options);
        }
export type AggregateIncidentsQueryHookResult = ReturnType<typeof useAggregateIncidentsQuery>;
export type AggregateIncidentsLazyQueryHookResult = ReturnType<typeof useAggregateIncidentsLazyQuery>;
export type AggregateIncidentsQueryResult = Apollo.QueryResult<AggregateIncidentsQuery, AggregateIncidentsQueryVariables>;
export function refetchAggregateIncidentsQuery(variables?: AggregateIncidentsQueryVariables) {
      return { query: AggregateIncidentsDocument, variables: variables }
    }
export const GetLprIncidentTypesDocument = gql`
    query getLPRIncidentTypes {
  lpr_incident_type {
    id
    agencyId
    name
  }
}
    `;

/**
 * __useGetLprIncidentTypesQuery__
 *
 * To run a query within a React component, call `useGetLprIncidentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLprIncidentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLprIncidentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLprIncidentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetLprIncidentTypesQuery, GetLprIncidentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLprIncidentTypesQuery, GetLprIncidentTypesQueryVariables>(GetLprIncidentTypesDocument, options);
      }
export function useGetLprIncidentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLprIncidentTypesQuery, GetLprIncidentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLprIncidentTypesQuery, GetLprIncidentTypesQueryVariables>(GetLprIncidentTypesDocument, options);
        }
export type GetLprIncidentTypesQueryHookResult = ReturnType<typeof useGetLprIncidentTypesQuery>;
export type GetLprIncidentTypesLazyQueryHookResult = ReturnType<typeof useGetLprIncidentTypesLazyQuery>;
export type GetLprIncidentTypesQueryResult = Apollo.QueryResult<GetLprIncidentTypesQuery, GetLprIncidentTypesQueryVariables>;
export function refetchGetLprIncidentTypesQuery(variables?: GetLprIncidentTypesQueryVariables) {
      return { query: GetLprIncidentTypesDocument, variables: variables }
    }
export const GetIncidentByIdDocument = gql`
    query getIncidentById($id: uuid!) {
  incidents_by_pk(id: $id) {
    propertyId
    typeId
    type
    accuracy
    note
    s3ImageKey
    location
    user {
      lastName
      firstName
      email
    }
  }
}
    `;

/**
 * __useGetIncidentByIdQuery__
 *
 * To run a query within a React component, call `useGetIncidentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIncidentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetIncidentByIdQuery, GetIncidentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentByIdQuery, GetIncidentByIdQueryVariables>(GetIncidentByIdDocument, options);
      }
export function useGetIncidentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentByIdQuery, GetIncidentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentByIdQuery, GetIncidentByIdQueryVariables>(GetIncidentByIdDocument, options);
        }
export type GetIncidentByIdQueryHookResult = ReturnType<typeof useGetIncidentByIdQuery>;
export type GetIncidentByIdLazyQueryHookResult = ReturnType<typeof useGetIncidentByIdLazyQuery>;
export type GetIncidentByIdQueryResult = Apollo.QueryResult<GetIncidentByIdQuery, GetIncidentByIdQueryVariables>;
export function refetchGetIncidentByIdQuery(variables?: GetIncidentByIdQueryVariables) {
      return { query: GetIncidentByIdDocument, variables: variables }
    }
export const GetLprIncidentByIdDocument = gql`
    query getLPRIncidentById($id: uuid!) {
  lpr_incidents_by_pk(id: $id) {
    propertyId
    typeId
    type
    note
    location
    user {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetLprIncidentByIdQuery__
 *
 * To run a query within a React component, call `useGetLprIncidentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLprIncidentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLprIncidentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLprIncidentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLprIncidentByIdQuery, GetLprIncidentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLprIncidentByIdQuery, GetLprIncidentByIdQueryVariables>(GetLprIncidentByIdDocument, options);
      }
export function useGetLprIncidentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLprIncidentByIdQuery, GetLprIncidentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLprIncidentByIdQuery, GetLprIncidentByIdQueryVariables>(GetLprIncidentByIdDocument, options);
        }
export type GetLprIncidentByIdQueryHookResult = ReturnType<typeof useGetLprIncidentByIdQuery>;
export type GetLprIncidentByIdLazyQueryHookResult = ReturnType<typeof useGetLprIncidentByIdLazyQuery>;
export type GetLprIncidentByIdQueryResult = Apollo.QueryResult<GetLprIncidentByIdQuery, GetLprIncidentByIdQueryVariables>;
export function refetchGetLprIncidentByIdQuery(variables?: GetLprIncidentByIdQueryVariables) {
      return { query: GetLprIncidentByIdDocument, variables: variables }
    }
export const AddPersonDocument = gql`
    mutation AddPerson($firstName: String, $lastName: String, $knownToBeViolent: Boolean, $s3ImageKey: String, $datastoreId: uuid!, $propertyId: uuid!, $dateOfBirth: date, $listedAs: persons_listedas_enum, $idCardImage: String, $trespassStatementAudio: String, $location: geometry, $type: String, $typeId: uuid, $note: String) {
  insert_persons_one(
    object: {firstName: $firstName, lastName: $lastName, knownToBeViolent: $knownToBeViolent, s3ImageKey: $s3ImageKey, datastoreId: $datastoreId, dateOfBirth: $dateOfBirth, listedAs: $listedAs, idCardImageKey: $idCardImage, trespassStatementAudioFileKey: $trespassStatementAudio, incidents: {data: {typeId: $typeId, location: $location, type: $type, note: $note, s3ImageKey: $s3ImageKey, propertyId: $propertyId}}}
  ) {
    id
    firstName
    lastName
    dateOfBirth
    knownToBeViolent
    s3ImageKey
    incidents {
      id
      location
      type
      s3ImageKey
      propertyId
      property {
        id
        property_contacts {
          id
          email
        }
      }
    }
  }
}
    `;
export type AddPersonMutationFn = Apollo.MutationFunction<AddPersonMutation, AddPersonMutationVariables>;

/**
 * __useAddPersonMutation__
 *
 * To run a mutation, you first call `useAddPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonMutation, { data, loading, error }] = useAddPersonMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      knownToBeViolent: // value for 'knownToBeViolent'
 *      s3ImageKey: // value for 's3ImageKey'
 *      datastoreId: // value for 'datastoreId'
 *      propertyId: // value for 'propertyId'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      listedAs: // value for 'listedAs'
 *      idCardImage: // value for 'idCardImage'
 *      trespassStatementAudio: // value for 'trespassStatementAudio'
 *      location: // value for 'location'
 *      type: // value for 'type'
 *      typeId: // value for 'typeId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddPersonMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonMutation, AddPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPersonMutation, AddPersonMutationVariables>(AddPersonDocument, options);
      }
export type AddPersonMutationHookResult = ReturnType<typeof useAddPersonMutation>;
export type AddPersonMutationResult = Apollo.MutationResult<AddPersonMutation>;
export type AddPersonMutationOptions = Apollo.BaseMutationOptions<AddPersonMutation, AddPersonMutationVariables>;
export const ListPersonDocument = gql`
    query ListPerson($query: String, $order: order_by, $limit: Int, $offset: Int) {
  persons(
    where: {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    id
    firstName
    lastName
    knownToBeViolent
    listedAs
    createdAt
    s3ImageKey
    incidents_aggregate {
      aggregate {
        count
      }
    }
  }
  persons_aggregate(
    where: {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPersonQuery__
 *
 * To run a query within a React component, call `useListPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPersonQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPersonQuery(baseOptions?: Apollo.QueryHookOptions<ListPersonQuery, ListPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPersonQuery, ListPersonQueryVariables>(ListPersonDocument, options);
      }
export function useListPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPersonQuery, ListPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPersonQuery, ListPersonQueryVariables>(ListPersonDocument, options);
        }
export type ListPersonQueryHookResult = ReturnType<typeof useListPersonQuery>;
export type ListPersonLazyQueryHookResult = ReturnType<typeof useListPersonLazyQuery>;
export type ListPersonQueryResult = Apollo.QueryResult<ListPersonQuery, ListPersonQueryVariables>;
export function refetchListPersonQuery(variables?: ListPersonQueryVariables) {
      return { query: ListPersonDocument, variables: variables }
    }
export const ListPersonForPropertyFilterDocument = gql`
    query ListPersonForPropertyFilter($query: String, $propertyId: uuid, $order: order_by, $limit: Int, $offset: Int) {
  persons(
    where: {_and: [{incidents: {propertyId: {_eq: $propertyId}}}, {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}]}
    distinct_on: [id]
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    id
    firstName
    lastName
    knownToBeViolent
    listedAs
    createdAt
    s3ImageKey
    incidents_aggregate {
      aggregate {
        count
      }
    }
  }
  persons_aggregate(
    where: {_and: [{incidents: {propertyId: {_eq: $propertyId}}}, {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPersonForPropertyFilterQuery__
 *
 * To run a query within a React component, call `useListPersonForPropertyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonForPropertyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPersonForPropertyFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      propertyId: // value for 'propertyId'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPersonForPropertyFilterQuery(baseOptions?: Apollo.QueryHookOptions<ListPersonForPropertyFilterQuery, ListPersonForPropertyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPersonForPropertyFilterQuery, ListPersonForPropertyFilterQueryVariables>(ListPersonForPropertyFilterDocument, options);
      }
export function useListPersonForPropertyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPersonForPropertyFilterQuery, ListPersonForPropertyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPersonForPropertyFilterQuery, ListPersonForPropertyFilterQueryVariables>(ListPersonForPropertyFilterDocument, options);
        }
export type ListPersonForPropertyFilterQueryHookResult = ReturnType<typeof useListPersonForPropertyFilterQuery>;
export type ListPersonForPropertyFilterLazyQueryHookResult = ReturnType<typeof useListPersonForPropertyFilterLazyQuery>;
export type ListPersonForPropertyFilterQueryResult = Apollo.QueryResult<ListPersonForPropertyFilterQuery, ListPersonForPropertyFilterQueryVariables>;
export function refetchListPersonForPropertyFilterQuery(variables?: ListPersonForPropertyFilterQueryVariables) {
      return { query: ListPersonForPropertyFilterDocument, variables: variables }
    }
export const ListPersonForAgencyFilterDocument = gql`
    query ListPersonForAgencyFilter($query: String, $agencyId: uuid, $order: order_by, $limit: Int, $offset: Int) {
  persons(
    where: {_and: [{person_datastore: {agency: {id: {_eq: $agencyId}}}}, {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}]}
    distinct_on: [id]
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    id
    firstName
    lastName
    knownToBeViolent
    listedAs
    createdAt
    s3ImageKey
    incidents_aggregate {
      aggregate {
        count
      }
    }
  }
  persons_aggregate(
    where: {_and: [{person_datastore: {agency: {id: {_eq: $agencyId}}}}, {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPersonForAgencyFilterQuery__
 *
 * To run a query within a React component, call `useListPersonForAgencyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonForAgencyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPersonForAgencyFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      agencyId: // value for 'agencyId'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPersonForAgencyFilterQuery(baseOptions?: Apollo.QueryHookOptions<ListPersonForAgencyFilterQuery, ListPersonForAgencyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPersonForAgencyFilterQuery, ListPersonForAgencyFilterQueryVariables>(ListPersonForAgencyFilterDocument, options);
      }
export function useListPersonForAgencyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPersonForAgencyFilterQuery, ListPersonForAgencyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPersonForAgencyFilterQuery, ListPersonForAgencyFilterQueryVariables>(ListPersonForAgencyFilterDocument, options);
        }
export type ListPersonForAgencyFilterQueryHookResult = ReturnType<typeof useListPersonForAgencyFilterQuery>;
export type ListPersonForAgencyFilterLazyQueryHookResult = ReturnType<typeof useListPersonForAgencyFilterLazyQuery>;
export type ListPersonForAgencyFilterQueryResult = Apollo.QueryResult<ListPersonForAgencyFilterQuery, ListPersonForAgencyFilterQueryVariables>;
export function refetchListPersonForAgencyFilterQuery(variables?: ListPersonForAgencyFilterQueryVariables) {
      return { query: ListPersonForAgencyFilterDocument, variables: variables }
    }
export const UpdatePersonDocument = gql`
    mutation UpdatePerson($id: uuid!, $firstName: String, $lastName: String, $listAs: persons_listedas_enum, $knownToBeViolent: Boolean, $trespassStatementTranscript: String) {
  update_persons_by_pk(
    pk_columns: {id: $id}
    _set: {firstName: $firstName, lastName: $lastName, listedAs: $listAs, trespassStatementTranscript: $trespassStatementTranscript, knownToBeViolent: $knownToBeViolent}
  ) {
    ...PersonInfo
  }
}
    ${PersonInfoFragmentDoc}`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      listAs: // value for 'listAs'
 *      knownToBeViolent: // value for 'knownToBeViolent'
 *      trespassStatementTranscript: // value for 'trespassStatementTranscript'
 *   },
 * });
 */
export function useUpdatePersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options);
      }
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>;
export const FetchPersonInfoDocument = gql`
    query FetchPersonInfo($id: uuid!) {
  persons_by_pk(id: $id) {
    ...PersonInfo
  }
}
    ${PersonInfoFragmentDoc}`;

/**
 * __useFetchPersonInfoQuery__
 *
 * To run a query within a React component, call `useFetchPersonInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPersonInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPersonInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchPersonInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchPersonInfoQuery, FetchPersonInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPersonInfoQuery, FetchPersonInfoQueryVariables>(FetchPersonInfoDocument, options);
      }
export function useFetchPersonInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPersonInfoQuery, FetchPersonInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPersonInfoQuery, FetchPersonInfoQueryVariables>(FetchPersonInfoDocument, options);
        }
export type FetchPersonInfoQueryHookResult = ReturnType<typeof useFetchPersonInfoQuery>;
export type FetchPersonInfoLazyQueryHookResult = ReturnType<typeof useFetchPersonInfoLazyQuery>;
export type FetchPersonInfoQueryResult = Apollo.QueryResult<FetchPersonInfoQuery, FetchPersonInfoQueryVariables>;
export function refetchFetchPersonInfoQuery(variables?: FetchPersonInfoQueryVariables) {
      return { query: FetchPersonInfoDocument, variables: variables }
    }
export const DeletePersonDocument = gql`
    mutation deletePerson($id: uuid!) {
  delete_persons_by_pk(id: $id) {
    ...PersonInfo
  }
}
    ${PersonInfoFragmentDoc}`;
export type DeletePersonMutationFn = Apollo.MutationFunction<DeletePersonMutation, DeletePersonMutationVariables>;

/**
 * __useDeletePersonMutation__
 *
 * To run a mutation, you first call `useDeletePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonMutation, { data, loading, error }] = useDeletePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonMutation(baseOptions?: Apollo.MutationHookOptions<DeletePersonMutation, DeletePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePersonMutation, DeletePersonMutationVariables>(DeletePersonDocument, options);
      }
export type DeletePersonMutationHookResult = ReturnType<typeof useDeletePersonMutation>;
export type DeletePersonMutationResult = Apollo.MutationResult<DeletePersonMutation>;
export type DeletePersonMutationOptions = Apollo.BaseMutationOptions<DeletePersonMutation, DeletePersonMutationVariables>;
export const SearchFaceDocument = gql`
    mutation SearchFace($s3ImageKey: String!) {
  searchFace(params: {s3ImageKey: $s3ImageKey}) {
    accuracy
    faceFound
    info {
      ...Person
    }
  }
}
    ${PersonFragmentDoc}`;
export type SearchFaceMutationFn = Apollo.MutationFunction<SearchFaceMutation, SearchFaceMutationVariables>;

/**
 * __useSearchFaceMutation__
 *
 * To run a mutation, you first call `useSearchFaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchFaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchFaceMutation, { data, loading, error }] = useSearchFaceMutation({
 *   variables: {
 *      s3ImageKey: // value for 's3ImageKey'
 *   },
 * });
 */
export function useSearchFaceMutation(baseOptions?: Apollo.MutationHookOptions<SearchFaceMutation, SearchFaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchFaceMutation, SearchFaceMutationVariables>(SearchFaceDocument, options);
      }
export type SearchFaceMutationHookResult = ReturnType<typeof useSearchFaceMutation>;
export type SearchFaceMutationResult = Apollo.MutationResult<SearchFaceMutation>;
export type SearchFaceMutationOptions = Apollo.BaseMutationOptions<SearchFaceMutation, SearchFaceMutationVariables>;
export const AddPersonIncidentDocument = gql`
    mutation AddPersonIncident($s3ImageKey: String, $location: geometry, $propertyId: uuid!, $type: String, $typeId: uuid, $id: uuid!, $note: String) {
  insert_incidents_one(
    object: {location: $location, type: $type, typeId: $typeId, note: $note, s3ImageKey: $s3ImageKey, person: $id, propertyId: $propertyId}
  ) {
    id
    location
    type
    s3ImageKey
    propertyId
    property {
      id
      property_contacts {
        id
        email
      }
    }
  }
}
    `;
export type AddPersonIncidentMutationFn = Apollo.MutationFunction<AddPersonIncidentMutation, AddPersonIncidentMutationVariables>;

/**
 * __useAddPersonIncidentMutation__
 *
 * To run a mutation, you first call `useAddPersonIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonIncidentMutation, { data, loading, error }] = useAddPersonIncidentMutation({
 *   variables: {
 *      s3ImageKey: // value for 's3ImageKey'
 *      location: // value for 'location'
 *      propertyId: // value for 'propertyId'
 *      type: // value for 'type'
 *      typeId: // value for 'typeId'
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddPersonIncidentMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonIncidentMutation, AddPersonIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPersonIncidentMutation, AddPersonIncidentMutationVariables>(AddPersonIncidentDocument, options);
      }
export type AddPersonIncidentMutationHookResult = ReturnType<typeof useAddPersonIncidentMutation>;
export type AddPersonIncidentMutationResult = Apollo.MutationResult<AddPersonIncidentMutation>;
export type AddPersonIncidentMutationOptions = Apollo.BaseMutationOptions<AddPersonIncidentMutation, AddPersonIncidentMutationVariables>;
export const UpdatePersonIncidentDocument = gql`
    mutation UpdatePersonIncident($iid: uuid!, $s3ImageKey: String, $location: geometry, $propertyId: uuid!, $type: String, $typeId: uuid, $id: uuid!, $note: String) {
  update_incidents_by_pk(
    pk_columns: {id: $iid}
    _set: {location: $location, type: $type, typeId: $typeId, note: $note, s3ImageKey: $s3ImageKey, person: $id, propertyId: $propertyId}
  ) {
    id
    location
    type
    s3ImageKey
    propertyId
    property {
      id
      property_contacts {
        id
        email
      }
    }
  }
}
    `;
export type UpdatePersonIncidentMutationFn = Apollo.MutationFunction<UpdatePersonIncidentMutation, UpdatePersonIncidentMutationVariables>;

/**
 * __useUpdatePersonIncidentMutation__
 *
 * To run a mutation, you first call `useUpdatePersonIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonIncidentMutation, { data, loading, error }] = useUpdatePersonIncidentMutation({
 *   variables: {
 *      iid: // value for 'iid'
 *      s3ImageKey: // value for 's3ImageKey'
 *      location: // value for 'location'
 *      propertyId: // value for 'propertyId'
 *      type: // value for 'type'
 *      typeId: // value for 'typeId'
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdatePersonIncidentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonIncidentMutation, UpdatePersonIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonIncidentMutation, UpdatePersonIncidentMutationVariables>(UpdatePersonIncidentDocument, options);
      }
export type UpdatePersonIncidentMutationHookResult = ReturnType<typeof useUpdatePersonIncidentMutation>;
export type UpdatePersonIncidentMutationResult = Apollo.MutationResult<UpdatePersonIncidentMutation>;
export type UpdatePersonIncidentMutationOptions = Apollo.BaseMutationOptions<UpdatePersonIncidentMutation, UpdatePersonIncidentMutationVariables>;
export const DeletePersonIncidentDocument = gql`
    mutation deletePersonIncident($iid: uuid!) {
  delete_incidents_by_pk(id: $iid) {
    id
    location
    type
    s3ImageKey
    propertyId
    property {
      id
      property_contacts {
        id
        email
      }
    }
  }
}
    `;
export type DeletePersonIncidentMutationFn = Apollo.MutationFunction<DeletePersonIncidentMutation, DeletePersonIncidentMutationVariables>;

/**
 * __useDeletePersonIncidentMutation__
 *
 * To run a mutation, you first call `useDeletePersonIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonIncidentMutation, { data, loading, error }] = useDeletePersonIncidentMutation({
 *   variables: {
 *      iid: // value for 'iid'
 *   },
 * });
 */
export function useDeletePersonIncidentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePersonIncidentMutation, DeletePersonIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePersonIncidentMutation, DeletePersonIncidentMutationVariables>(DeletePersonIncidentDocument, options);
      }
export type DeletePersonIncidentMutationHookResult = ReturnType<typeof useDeletePersonIncidentMutation>;
export type DeletePersonIncidentMutationResult = Apollo.MutationResult<DeletePersonIncidentMutation>;
export type DeletePersonIncidentMutationOptions = Apollo.BaseMutationOptions<DeletePersonIncidentMutation, DeletePersonIncidentMutationVariables>;
export const ListLprIncidentTypesDocument = gql`
    query ListLPRIncidentTypes($query: String, $limit: Int, $offset: Int, $order: order_by) {
  lpr_incident_type(
    where: {name: {_ilike: $query}}
    limit: $limit
    offset: $offset
    order_by: {createdAt: $order}
  ) {
    ...LPRIncidentTypeInfo
  }
  lpr_incident_type_aggregate(where: {name: {_ilike: $query}}) {
    aggregate {
      count
    }
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;

/**
 * __useListLprIncidentTypesQuery__
 *
 * To run a query within a React component, call `useListLprIncidentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLprIncidentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLprIncidentTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListLprIncidentTypesQuery(baseOptions?: Apollo.QueryHookOptions<ListLprIncidentTypesQuery, ListLprIncidentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLprIncidentTypesQuery, ListLprIncidentTypesQueryVariables>(ListLprIncidentTypesDocument, options);
      }
export function useListLprIncidentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLprIncidentTypesQuery, ListLprIncidentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLprIncidentTypesQuery, ListLprIncidentTypesQueryVariables>(ListLprIncidentTypesDocument, options);
        }
export type ListLprIncidentTypesQueryHookResult = ReturnType<typeof useListLprIncidentTypesQuery>;
export type ListLprIncidentTypesLazyQueryHookResult = ReturnType<typeof useListLprIncidentTypesLazyQuery>;
export type ListLprIncidentTypesQueryResult = Apollo.QueryResult<ListLprIncidentTypesQuery, ListLprIncidentTypesQueryVariables>;
export function refetchListLprIncidentTypesQuery(variables?: ListLprIncidentTypesQueryVariables) {
      return { query: ListLprIncidentTypesDocument, variables: variables }
    }
export const ListLprIncidentTypesByAgencyDocument = gql`
    query ListLPRIncidentTypesByAgency($agencyId: uuid, $limit: Int, $offset: Int, $order: order_by) {
  lpr_incident_type(
    where: {agencyId: {_eq: $agencyId}}
    limit: $limit
    offset: $offset
    order_by: {createdAt: $order}
  ) {
    ...LPRIncidentTypeInfo
  }
  lpr_incident_type_aggregate(where: {agencyId: {_eq: $agencyId}}) {
    aggregate {
      count
    }
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;

/**
 * __useListLprIncidentTypesByAgencyQuery__
 *
 * To run a query within a React component, call `useListLprIncidentTypesByAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLprIncidentTypesByAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLprIncidentTypesByAgencyQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListLprIncidentTypesByAgencyQuery(baseOptions?: Apollo.QueryHookOptions<ListLprIncidentTypesByAgencyQuery, ListLprIncidentTypesByAgencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLprIncidentTypesByAgencyQuery, ListLprIncidentTypesByAgencyQueryVariables>(ListLprIncidentTypesByAgencyDocument, options);
      }
export function useListLprIncidentTypesByAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLprIncidentTypesByAgencyQuery, ListLprIncidentTypesByAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLprIncidentTypesByAgencyQuery, ListLprIncidentTypesByAgencyQueryVariables>(ListLprIncidentTypesByAgencyDocument, options);
        }
export type ListLprIncidentTypesByAgencyQueryHookResult = ReturnType<typeof useListLprIncidentTypesByAgencyQuery>;
export type ListLprIncidentTypesByAgencyLazyQueryHookResult = ReturnType<typeof useListLprIncidentTypesByAgencyLazyQuery>;
export type ListLprIncidentTypesByAgencyQueryResult = Apollo.QueryResult<ListLprIncidentTypesByAgencyQuery, ListLprIncidentTypesByAgencyQueryVariables>;
export function refetchListLprIncidentTypesByAgencyQuery(variables?: ListLprIncidentTypesByAgencyQueryVariables) {
      return { query: ListLprIncidentTypesByAgencyDocument, variables: variables }
    }
export const ListLprIncidentTypesBySuperDocument = gql`
    query ListLPRIncidentTypesBySuper($limit: Int, $offset: Int, $order: order_by) {
  lpr_incident_type(
    where: {agencyId: {_is_null: true}}
    limit: $limit
    offset: $offset
    order_by: {createdAt: $order}
  ) {
    ...LPRIncidentTypeInfo
  }
  lpr_incident_type_aggregate(where: {agencyId: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;

/**
 * __useListLprIncidentTypesBySuperQuery__
 *
 * To run a query within a React component, call `useListLprIncidentTypesBySuperQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLprIncidentTypesBySuperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLprIncidentTypesBySuperQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListLprIncidentTypesBySuperQuery(baseOptions?: Apollo.QueryHookOptions<ListLprIncidentTypesBySuperQuery, ListLprIncidentTypesBySuperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLprIncidentTypesBySuperQuery, ListLprIncidentTypesBySuperQueryVariables>(ListLprIncidentTypesBySuperDocument, options);
      }
export function useListLprIncidentTypesBySuperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLprIncidentTypesBySuperQuery, ListLprIncidentTypesBySuperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLprIncidentTypesBySuperQuery, ListLprIncidentTypesBySuperQueryVariables>(ListLprIncidentTypesBySuperDocument, options);
        }
export type ListLprIncidentTypesBySuperQueryHookResult = ReturnType<typeof useListLprIncidentTypesBySuperQuery>;
export type ListLprIncidentTypesBySuperLazyQueryHookResult = ReturnType<typeof useListLprIncidentTypesBySuperLazyQuery>;
export type ListLprIncidentTypesBySuperQueryResult = Apollo.QueryResult<ListLprIncidentTypesBySuperQuery, ListLprIncidentTypesBySuperQueryVariables>;
export function refetchListLprIncidentTypesBySuperQuery(variables?: ListLprIncidentTypesBySuperQueryVariables) {
      return { query: ListLprIncidentTypesBySuperDocument, variables: variables }
    }
export const UpdateLprIncidentTypeDocument = gql`
    mutation UpdateLPRIncidentType($id: uuid!, $name: String) {
  update_lpr_incident_type_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...LPRIncidentTypeInfo
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;
export type UpdateLprIncidentTypeMutationFn = Apollo.MutationFunction<UpdateLprIncidentTypeMutation, UpdateLprIncidentTypeMutationVariables>;

/**
 * __useUpdateLprIncidentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateLprIncidentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLprIncidentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLprIncidentTypeMutation, { data, loading, error }] = useUpdateLprIncidentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateLprIncidentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLprIncidentTypeMutation, UpdateLprIncidentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLprIncidentTypeMutation, UpdateLprIncidentTypeMutationVariables>(UpdateLprIncidentTypeDocument, options);
      }
export type UpdateLprIncidentTypeMutationHookResult = ReturnType<typeof useUpdateLprIncidentTypeMutation>;
export type UpdateLprIncidentTypeMutationResult = Apollo.MutationResult<UpdateLprIncidentTypeMutation>;
export type UpdateLprIncidentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateLprIncidentTypeMutation, UpdateLprIncidentTypeMutationVariables>;
export const AddLprIncidentTypeDocument = gql`
    mutation AddLPRIncidentType($name: String) {
  insert_lpr_incident_type_one(object: {name: $name}) {
    ...LPRIncidentTypeInfo
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;
export type AddLprIncidentTypeMutationFn = Apollo.MutationFunction<AddLprIncidentTypeMutation, AddLprIncidentTypeMutationVariables>;

/**
 * __useAddLprIncidentTypeMutation__
 *
 * To run a mutation, you first call `useAddLprIncidentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLprIncidentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLprIncidentTypeMutation, { data, loading, error }] = useAddLprIncidentTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddLprIncidentTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddLprIncidentTypeMutation, AddLprIncidentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLprIncidentTypeMutation, AddLprIncidentTypeMutationVariables>(AddLprIncidentTypeDocument, options);
      }
export type AddLprIncidentTypeMutationHookResult = ReturnType<typeof useAddLprIncidentTypeMutation>;
export type AddLprIncidentTypeMutationResult = Apollo.MutationResult<AddLprIncidentTypeMutation>;
export type AddLprIncidentTypeMutationOptions = Apollo.BaseMutationOptions<AddLprIncidentTypeMutation, AddLprIncidentTypeMutationVariables>;
export const FetchLprIncidentTypeInfoDocument = gql`
    query FetchLPRIncidentTypeInfo($id: uuid!) {
  lpr_incident_type_by_pk(id: $id) {
    ...LPRIncidentTypeInfo
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;

/**
 * __useFetchLprIncidentTypeInfoQuery__
 *
 * To run a query within a React component, call `useFetchLprIncidentTypeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLprIncidentTypeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLprIncidentTypeInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchLprIncidentTypeInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchLprIncidentTypeInfoQuery, FetchLprIncidentTypeInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchLprIncidentTypeInfoQuery, FetchLprIncidentTypeInfoQueryVariables>(FetchLprIncidentTypeInfoDocument, options);
      }
export function useFetchLprIncidentTypeInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchLprIncidentTypeInfoQuery, FetchLprIncidentTypeInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchLprIncidentTypeInfoQuery, FetchLprIncidentTypeInfoQueryVariables>(FetchLprIncidentTypeInfoDocument, options);
        }
export type FetchLprIncidentTypeInfoQueryHookResult = ReturnType<typeof useFetchLprIncidentTypeInfoQuery>;
export type FetchLprIncidentTypeInfoLazyQueryHookResult = ReturnType<typeof useFetchLprIncidentTypeInfoLazyQuery>;
export type FetchLprIncidentTypeInfoQueryResult = Apollo.QueryResult<FetchLprIncidentTypeInfoQuery, FetchLprIncidentTypeInfoQueryVariables>;
export function refetchFetchLprIncidentTypeInfoQuery(variables?: FetchLprIncidentTypeInfoQueryVariables) {
      return { query: FetchLprIncidentTypeInfoDocument, variables: variables }
    }
export const DeleteLprIncidentTypeDocument = gql`
    mutation DeleteLPRIncidentType($id: uuid!) {
  delete_lpr_incident_type_by_pk(id: $id) {
    ...LPRIncidentTypeInfo
  }
}
    ${LprIncidentTypeInfoFragmentDoc}`;
export type DeleteLprIncidentTypeMutationFn = Apollo.MutationFunction<DeleteLprIncidentTypeMutation, DeleteLprIncidentTypeMutationVariables>;

/**
 * __useDeleteLprIncidentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteLprIncidentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLprIncidentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLprIncidentTypeMutation, { data, loading, error }] = useDeleteLprIncidentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLprIncidentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLprIncidentTypeMutation, DeleteLprIncidentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLprIncidentTypeMutation, DeleteLprIncidentTypeMutationVariables>(DeleteLprIncidentTypeDocument, options);
      }
export type DeleteLprIncidentTypeMutationHookResult = ReturnType<typeof useDeleteLprIncidentTypeMutation>;
export type DeleteLprIncidentTypeMutationResult = Apollo.MutationResult<DeleteLprIncidentTypeMutation>;
export type DeleteLprIncidentTypeMutationOptions = Apollo.BaseMutationOptions<DeleteLprIncidentTypeMutation, DeleteLprIncidentTypeMutationVariables>;
export const ListPlatesDocument = gql`
    query ListPlates($query: String, $order: order_by, $limit: Int, $offset: Int) {
  plates(
    where: {number: {_ilike: $query}}
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    id
    number
    displayNumber
    knownToBeViolent
    listedAs
    createdAt
    s3ImageKey
    lpr_incidents_aggregate {
      aggregate {
        count
      }
    }
  }
  plates_aggregate(where: {number: {_ilike: $query}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPlatesQuery__
 *
 * To run a query within a React component, call `useListPlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPlatesQuery(baseOptions?: Apollo.QueryHookOptions<ListPlatesQuery, ListPlatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPlatesQuery, ListPlatesQueryVariables>(ListPlatesDocument, options);
      }
export function useListPlatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPlatesQuery, ListPlatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPlatesQuery, ListPlatesQueryVariables>(ListPlatesDocument, options);
        }
export type ListPlatesQueryHookResult = ReturnType<typeof useListPlatesQuery>;
export type ListPlatesLazyQueryHookResult = ReturnType<typeof useListPlatesLazyQuery>;
export type ListPlatesQueryResult = Apollo.QueryResult<ListPlatesQuery, ListPlatesQueryVariables>;
export function refetchListPlatesQuery(variables?: ListPlatesQueryVariables) {
      return { query: ListPlatesDocument, variables: variables }
    }
export const ListPlatesForPropertyFilterDocument = gql`
    query ListPlatesForPropertyFilter($query: String, $propertyId: uuid, $limit: Int, $offset: Int) {
  plates(
    where: {_and: [{lpr_incidents: {propertyId: {_eq: $propertyId}}}, {number: {_ilike: $query}}]}
    distinct_on: [id]
    limit: $limit
    offset: $offset
  ) {
    id
    number
    knownToBeViolent
    displayNumber
    listedAs
    createdAt
    s3ImageKey
    lpr_incidents_aggregate {
      aggregate {
        count
      }
    }
  }
  plates_aggregate(
    where: {_and: [{lpr_incidents: {propertyId: {_eq: $propertyId}}}, {number: {_ilike: $query}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPlatesForPropertyFilterQuery__
 *
 * To run a query within a React component, call `useListPlatesForPropertyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlatesForPropertyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlatesForPropertyFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      propertyId: // value for 'propertyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPlatesForPropertyFilterQuery(baseOptions?: Apollo.QueryHookOptions<ListPlatesForPropertyFilterQuery, ListPlatesForPropertyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPlatesForPropertyFilterQuery, ListPlatesForPropertyFilterQueryVariables>(ListPlatesForPropertyFilterDocument, options);
      }
export function useListPlatesForPropertyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPlatesForPropertyFilterQuery, ListPlatesForPropertyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPlatesForPropertyFilterQuery, ListPlatesForPropertyFilterQueryVariables>(ListPlatesForPropertyFilterDocument, options);
        }
export type ListPlatesForPropertyFilterQueryHookResult = ReturnType<typeof useListPlatesForPropertyFilterQuery>;
export type ListPlatesForPropertyFilterLazyQueryHookResult = ReturnType<typeof useListPlatesForPropertyFilterLazyQuery>;
export type ListPlatesForPropertyFilterQueryResult = Apollo.QueryResult<ListPlatesForPropertyFilterQuery, ListPlatesForPropertyFilterQueryVariables>;
export function refetchListPlatesForPropertyFilterQuery(variables?: ListPlatesForPropertyFilterQueryVariables) {
      return { query: ListPlatesForPropertyFilterDocument, variables: variables }
    }
export const ListPlatesForAgencyFilterDocument = gql`
    query ListPlatesForAgencyFilter($query: String, $agencyId: uuid, $limit: Int, $offset: Int) {
  plates(
    where: {_and: [{lpr_datastore: {agency: {id: {_eq: $agencyId}}}}, {number: {_ilike: $query}}]}
    distinct_on: [id]
    limit: $limit
    offset: $offset
  ) {
    id
    number
    knownToBeViolent
    displayNumber
    listedAs
    createdAt
    s3ImageKey
    lpr_incidents_aggregate {
      aggregate {
        count
      }
    }
  }
  plates_aggregate(
    where: {_and: [{lpr_datastore: {agency: {id: {_eq: $agencyId}}}}, {number: {_ilike: $query}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPlatesForAgencyFilterQuery__
 *
 * To run a query within a React component, call `useListPlatesForAgencyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlatesForAgencyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlatesForAgencyFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      agencyId: // value for 'agencyId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPlatesForAgencyFilterQuery(baseOptions?: Apollo.QueryHookOptions<ListPlatesForAgencyFilterQuery, ListPlatesForAgencyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPlatesForAgencyFilterQuery, ListPlatesForAgencyFilterQueryVariables>(ListPlatesForAgencyFilterDocument, options);
      }
export function useListPlatesForAgencyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPlatesForAgencyFilterQuery, ListPlatesForAgencyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPlatesForAgencyFilterQuery, ListPlatesForAgencyFilterQueryVariables>(ListPlatesForAgencyFilterDocument, options);
        }
export type ListPlatesForAgencyFilterQueryHookResult = ReturnType<typeof useListPlatesForAgencyFilterQuery>;
export type ListPlatesForAgencyFilterLazyQueryHookResult = ReturnType<typeof useListPlatesForAgencyFilterLazyQuery>;
export type ListPlatesForAgencyFilterQueryResult = Apollo.QueryResult<ListPlatesForAgencyFilterQuery, ListPlatesForAgencyFilterQueryVariables>;
export function refetchListPlatesForAgencyFilterQuery(variables?: ListPlatesForAgencyFilterQueryVariables) {
      return { query: ListPlatesForAgencyFilterDocument, variables: variables }
    }
export const UpdatePlateDocument = gql`
    mutation UpdatePlate($id: uuid!, $displayNumber: String, $number: String, $listAs: plates_listedas_enum, $knownToBeViolent: Boolean) {
  update_plates_by_pk(
    pk_columns: {id: $id}
    _set: {displayNumber: $displayNumber, number: $number, listedAs: $listAs, knownToBeViolent: $knownToBeViolent}
  ) {
    ...PlateInfo
  }
}
    ${PlateInfoFragmentDoc}`;
export type UpdatePlateMutationFn = Apollo.MutationFunction<UpdatePlateMutation, UpdatePlateMutationVariables>;

/**
 * __useUpdatePlateMutation__
 *
 * To run a mutation, you first call `useUpdatePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlateMutation, { data, loading, error }] = useUpdatePlateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayNumber: // value for 'displayNumber'
 *      number: // value for 'number'
 *      listAs: // value for 'listAs'
 *      knownToBeViolent: // value for 'knownToBeViolent'
 *   },
 * });
 */
export function useUpdatePlateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlateMutation, UpdatePlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlateMutation, UpdatePlateMutationVariables>(UpdatePlateDocument, options);
      }
export type UpdatePlateMutationHookResult = ReturnType<typeof useUpdatePlateMutation>;
export type UpdatePlateMutationResult = Apollo.MutationResult<UpdatePlateMutation>;
export type UpdatePlateMutationOptions = Apollo.BaseMutationOptions<UpdatePlateMutation, UpdatePlateMutationVariables>;
export const FetchPlateInfoDocument = gql`
    query FetchPlateInfo($id: uuid!) {
  plates_by_pk(id: $id) {
    ...PlateInfo
  }
}
    ${PlateInfoFragmentDoc}`;

/**
 * __useFetchPlateInfoQuery__
 *
 * To run a query within a React component, call `useFetchPlateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPlateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPlateInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchPlateInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchPlateInfoQuery, FetchPlateInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPlateInfoQuery, FetchPlateInfoQueryVariables>(FetchPlateInfoDocument, options);
      }
export function useFetchPlateInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPlateInfoQuery, FetchPlateInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPlateInfoQuery, FetchPlateInfoQueryVariables>(FetchPlateInfoDocument, options);
        }
export type FetchPlateInfoQueryHookResult = ReturnType<typeof useFetchPlateInfoQuery>;
export type FetchPlateInfoLazyQueryHookResult = ReturnType<typeof useFetchPlateInfoLazyQuery>;
export type FetchPlateInfoQueryResult = Apollo.QueryResult<FetchPlateInfoQuery, FetchPlateInfoQueryVariables>;
export function refetchFetchPlateInfoQuery(variables?: FetchPlateInfoQueryVariables) {
      return { query: FetchPlateInfoDocument, variables: variables }
    }
export const DeletePlateDocument = gql`
    mutation deletePlate($id: uuid!) {
  delete_plates_by_pk(id: $id) {
    ...PlateInfo
  }
}
    ${PlateInfoFragmentDoc}`;
export type DeletePlateMutationFn = Apollo.MutationFunction<DeletePlateMutation, DeletePlateMutationVariables>;

/**
 * __useDeletePlateMutation__
 *
 * To run a mutation, you first call `useDeletePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlateMutation, { data, loading, error }] = useDeletePlateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlateMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlateMutation, DeletePlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlateMutation, DeletePlateMutationVariables>(DeletePlateDocument, options);
      }
export type DeletePlateMutationHookResult = ReturnType<typeof useDeletePlateMutation>;
export type DeletePlateMutationResult = Apollo.MutationResult<DeletePlateMutation>;
export type DeletePlateMutationOptions = Apollo.BaseMutationOptions<DeletePlateMutation, DeletePlateMutationVariables>;
export const AddPlateDocument = gql`
    mutation AddPlate($number: String!, $make: String, $model: String, $color: String, $year: String, $knownToBeViolent: Boolean, $displayNumber: String!, $s3ImageKey: String, $lpr_images: [lpr_image_insert_input!]!, $datastoreId: uuid!, $propertyId: uuid!, $listedAs: plates_listedas_enum, $location: geometry, $type: String, $typeId: uuid, $note: String) {
  insert_plates_one(
    object: {number: $number, make: $make, model: $model, color: $color, year: $year, knownToBeViolent: $knownToBeViolent, displayNumber: $displayNumber, s3ImageKey: $s3ImageKey, datastoreId: $datastoreId, listedAs: $listedAs, lpr_incidents: {data: {typeId: $typeId, location: $location, type: $type, note: $note, s3ImageKey: $s3ImageKey, propertyId: $propertyId}}, lpr_images: {data: $lpr_images}}
  ) {
    id
    number
    knownToBeViolent
    displayNumber
    make
    model
    color
    year
    s3ImageKey
    lpr_incidents {
      id
      location
      type
      s3ImageKey
      propertyId
      property {
        id
        property_contacts {
          id
          email
        }
      }
    }
  }
}
    `;
export type AddPlateMutationFn = Apollo.MutationFunction<AddPlateMutation, AddPlateMutationVariables>;

/**
 * __useAddPlateMutation__
 *
 * To run a mutation, you first call `useAddPlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlateMutation, { data, loading, error }] = useAddPlateMutation({
 *   variables: {
 *      number: // value for 'number'
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      color: // value for 'color'
 *      year: // value for 'year'
 *      knownToBeViolent: // value for 'knownToBeViolent'
 *      displayNumber: // value for 'displayNumber'
 *      s3ImageKey: // value for 's3ImageKey'
 *      lpr_images: // value for 'lpr_images'
 *      datastoreId: // value for 'datastoreId'
 *      propertyId: // value for 'propertyId'
 *      listedAs: // value for 'listedAs'
 *      location: // value for 'location'
 *      type: // value for 'type'
 *      typeId: // value for 'typeId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddPlateMutation(baseOptions?: Apollo.MutationHookOptions<AddPlateMutation, AddPlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlateMutation, AddPlateMutationVariables>(AddPlateDocument, options);
      }
export type AddPlateMutationHookResult = ReturnType<typeof useAddPlateMutation>;
export type AddPlateMutationResult = Apollo.MutationResult<AddPlateMutation>;
export type AddPlateMutationOptions = Apollo.BaseMutationOptions<AddPlateMutation, AddPlateMutationVariables>;
export const AddPlateIncidentDocument = gql`
    mutation AddPlateIncident($s3ImageKey: String, $location: geometry, $propertyId: uuid!, $type: String, $typeId: uuid, $id: uuid!, $note: String) {
  insert_lpr_incidents_one(
    object: {location: $location, type: $type, typeId: $typeId, note: $note, s3ImageKey: $s3ImageKey, plateId: $id, propertyId: $propertyId}
  ) {
    id
    location
    type
    s3ImageKey
    propertyId
    property {
      id
      property_contacts {
        id
        email
      }
    }
  }
}
    `;
export type AddPlateIncidentMutationFn = Apollo.MutationFunction<AddPlateIncidentMutation, AddPlateIncidentMutationVariables>;

/**
 * __useAddPlateIncidentMutation__
 *
 * To run a mutation, you first call `useAddPlateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlateIncidentMutation, { data, loading, error }] = useAddPlateIncidentMutation({
 *   variables: {
 *      s3ImageKey: // value for 's3ImageKey'
 *      location: // value for 'location'
 *      propertyId: // value for 'propertyId'
 *      type: // value for 'type'
 *      typeId: // value for 'typeId'
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddPlateIncidentMutation(baseOptions?: Apollo.MutationHookOptions<AddPlateIncidentMutation, AddPlateIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlateIncidentMutation, AddPlateIncidentMutationVariables>(AddPlateIncidentDocument, options);
      }
export type AddPlateIncidentMutationHookResult = ReturnType<typeof useAddPlateIncidentMutation>;
export type AddPlateIncidentMutationResult = Apollo.MutationResult<AddPlateIncidentMutation>;
export type AddPlateIncidentMutationOptions = Apollo.BaseMutationOptions<AddPlateIncidentMutation, AddPlateIncidentMutationVariables>;
export const UpdatePlateIncidentDocument = gql`
    mutation UpdatePlateIncident($iid: uuid!, $s3ImageKey: String, $location: geometry, $propertyId: uuid!, $type: String, $typeId: uuid, $id: uuid!, $note: String) {
  update_lpr_incidents_by_pk(
    pk_columns: {id: $iid}
    _set: {location: $location, type: $type, typeId: $typeId, note: $note, s3ImageKey: $s3ImageKey, plateId: $id, propertyId: $propertyId}
  ) {
    id
    location
    type
    s3ImageKey
    propertyId
    property {
      id
      property_contacts {
        id
        email
      }
    }
  }
}
    `;
export type UpdatePlateIncidentMutationFn = Apollo.MutationFunction<UpdatePlateIncidentMutation, UpdatePlateIncidentMutationVariables>;

/**
 * __useUpdatePlateIncidentMutation__
 *
 * To run a mutation, you first call `useUpdatePlateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlateIncidentMutation, { data, loading, error }] = useUpdatePlateIncidentMutation({
 *   variables: {
 *      iid: // value for 'iid'
 *      s3ImageKey: // value for 's3ImageKey'
 *      location: // value for 'location'
 *      propertyId: // value for 'propertyId'
 *      type: // value for 'type'
 *      typeId: // value for 'typeId'
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdatePlateIncidentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlateIncidentMutation, UpdatePlateIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlateIncidentMutation, UpdatePlateIncidentMutationVariables>(UpdatePlateIncidentDocument, options);
      }
export type UpdatePlateIncidentMutationHookResult = ReturnType<typeof useUpdatePlateIncidentMutation>;
export type UpdatePlateIncidentMutationResult = Apollo.MutationResult<UpdatePlateIncidentMutation>;
export type UpdatePlateIncidentMutationOptions = Apollo.BaseMutationOptions<UpdatePlateIncidentMutation, UpdatePlateIncidentMutationVariables>;
export const SearchPlateDocument = gql`
    mutation SearchPlate($s3ImageKey: String!) {
  searchPlate(params: {s3ImageKey: $s3ImageKey}) {
    textDetections
    plateId
    plate {
      ...Plate
    }
  }
}
    ${PlateFragmentDoc}`;
export type SearchPlateMutationFn = Apollo.MutationFunction<SearchPlateMutation, SearchPlateMutationVariables>;

/**
 * __useSearchPlateMutation__
 *
 * To run a mutation, you first call `useSearchPlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchPlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchPlateMutation, { data, loading, error }] = useSearchPlateMutation({
 *   variables: {
 *      s3ImageKey: // value for 's3ImageKey'
 *   },
 * });
 */
export function useSearchPlateMutation(baseOptions?: Apollo.MutationHookOptions<SearchPlateMutation, SearchPlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchPlateMutation, SearchPlateMutationVariables>(SearchPlateDocument, options);
      }
export type SearchPlateMutationHookResult = ReturnType<typeof useSearchPlateMutation>;
export type SearchPlateMutationResult = Apollo.MutationResult<SearchPlateMutation>;
export type SearchPlateMutationOptions = Apollo.BaseMutationOptions<SearchPlateMutation, SearchPlateMutationVariables>;
export const DeletePlateIncidentDocument = gql`
    mutation deletePlateIncident($iid: uuid!) {
  delete_lpr_incidents_by_pk(id: $iid) {
    id
    location
    type
    s3ImageKey
    propertyId
    property {
      id
      property_contacts {
        id
        email
      }
    }
  }
}
    `;
export type DeletePlateIncidentMutationFn = Apollo.MutationFunction<DeletePlateIncidentMutation, DeletePlateIncidentMutationVariables>;

/**
 * __useDeletePlateIncidentMutation__
 *
 * To run a mutation, you first call `useDeletePlateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlateIncidentMutation, { data, loading, error }] = useDeletePlateIncidentMutation({
 *   variables: {
 *      iid: // value for 'iid'
 *   },
 * });
 */
export function useDeletePlateIncidentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlateIncidentMutation, DeletePlateIncidentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlateIncidentMutation, DeletePlateIncidentMutationVariables>(DeletePlateIncidentDocument, options);
      }
export type DeletePlateIncidentMutationHookResult = ReturnType<typeof useDeletePlateIncidentMutation>;
export type DeletePlateIncidentMutationResult = Apollo.MutationResult<DeletePlateIncidentMutation>;
export type DeletePlateIncidentMutationOptions = Apollo.BaseMutationOptions<DeletePlateIncidentMutation, DeletePlateIncidentMutationVariables>;
export const ProfileDocument = gql`
    query Profile {
  profile {
    ...ProfileResponse
  }
}
    ${ProfileResponseFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export function refetchProfileQuery(variables?: ProfileQueryVariables) {
      return { query: ProfileDocument, variables: variables }
    }
export const ListAllPropertiesDocument = gql`
    query ListAllProperties {
  properties {
    id
    name
    createdAt
  }
}
    `;

/**
 * __useListAllPropertiesQuery__
 *
 * To run a query within a React component, call `useListAllPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>(ListAllPropertiesDocument, options);
      }
export function useListAllPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>(ListAllPropertiesDocument, options);
        }
export type ListAllPropertiesQueryHookResult = ReturnType<typeof useListAllPropertiesQuery>;
export type ListAllPropertiesLazyQueryHookResult = ReturnType<typeof useListAllPropertiesLazyQuery>;
export type ListAllPropertiesQueryResult = Apollo.QueryResult<ListAllPropertiesQuery, ListAllPropertiesQueryVariables>;
export function refetchListAllPropertiesQuery(variables?: ListAllPropertiesQueryVariables) {
      return { query: ListAllPropertiesDocument, variables: variables }
    }
export const ListPropertiesDocument = gql`
    query ListProperties($query: String, $order: order_by, $limit: Int, $offset: Int) {
  properties(
    where: {name: {_ilike: $query}}
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    id
    name
    createdAt
  }
  properties_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListPropertiesQuery__
 *
 * To run a query within a React component, call `useListPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPropertiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<ListPropertiesQuery, ListPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPropertiesQuery, ListPropertiesQueryVariables>(ListPropertiesDocument, options);
      }
export function useListPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPropertiesQuery, ListPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPropertiesQuery, ListPropertiesQueryVariables>(ListPropertiesDocument, options);
        }
export type ListPropertiesQueryHookResult = ReturnType<typeof useListPropertiesQuery>;
export type ListPropertiesLazyQueryHookResult = ReturnType<typeof useListPropertiesLazyQuery>;
export type ListPropertiesQueryResult = Apollo.QueryResult<ListPropertiesQuery, ListPropertiesQueryVariables>;
export function refetchListPropertiesQuery(variables?: ListPropertiesQueryVariables) {
      return { query: ListPropertiesDocument, variables: variables }
    }
export const ListPropertiesForAgencyFilterDocument = gql`
    query ListPropertiesForAgencyFilter($query: String, $agencyId: uuid!, $order: order_by, $limit: Int, $offset: Int) {
  properties(
    where: {agencyId: {_eq: $agencyId}}
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    id
    name
    createdAt
  }
}
    `;

/**
 * __useListPropertiesForAgencyFilterQuery__
 *
 * To run a query within a React component, call `useListPropertiesForAgencyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPropertiesForAgencyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPropertiesForAgencyFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      agencyId: // value for 'agencyId'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPropertiesForAgencyFilterQuery(baseOptions: Apollo.QueryHookOptions<ListPropertiesForAgencyFilterQuery, ListPropertiesForAgencyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPropertiesForAgencyFilterQuery, ListPropertiesForAgencyFilterQueryVariables>(ListPropertiesForAgencyFilterDocument, options);
      }
export function useListPropertiesForAgencyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPropertiesForAgencyFilterQuery, ListPropertiesForAgencyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPropertiesForAgencyFilterQuery, ListPropertiesForAgencyFilterQueryVariables>(ListPropertiesForAgencyFilterDocument, options);
        }
export type ListPropertiesForAgencyFilterQueryHookResult = ReturnType<typeof useListPropertiesForAgencyFilterQuery>;
export type ListPropertiesForAgencyFilterLazyQueryHookResult = ReturnType<typeof useListPropertiesForAgencyFilterLazyQuery>;
export type ListPropertiesForAgencyFilterQueryResult = Apollo.QueryResult<ListPropertiesForAgencyFilterQuery, ListPropertiesForAgencyFilterQueryVariables>;
export function refetchListPropertiesForAgencyFilterQuery(variables?: ListPropertiesForAgencyFilterQueryVariables) {
      return { query: ListPropertiesForAgencyFilterDocument, variables: variables }
    }
export const CreatePropertyDocument = gql`
    mutation CreateProperty($name: String, $agencyId: uuid, $address1: String, $address2: String, $prefferedReportingMethod: String) {
  insert_properties_one(
    object: {name: $name, agencyId: $agencyId, address1: $address1, address2: $address2, prefferedReportingMethod: $prefferedReportingMethod}
  ) {
    ...PropertyInfo
  }
}
    ${PropertyInfoFragmentDoc}`;
export type CreatePropertyMutationFn = Apollo.MutationFunction<CreatePropertyMutation, CreatePropertyMutationVariables>;

/**
 * __useCreatePropertyMutation__
 *
 * To run a mutation, you first call `useCreatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyMutation, { data, loading, error }] = useCreatePropertyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      agencyId: // value for 'agencyId'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      prefferedReportingMethod: // value for 'prefferedReportingMethod'
 *   },
 * });
 */
export function useCreatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyMutation, CreatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyMutation, CreatePropertyMutationVariables>(CreatePropertyDocument, options);
      }
export type CreatePropertyMutationHookResult = ReturnType<typeof useCreatePropertyMutation>;
export type CreatePropertyMutationResult = Apollo.MutationResult<CreatePropertyMutation>;
export type CreatePropertyMutationOptions = Apollo.BaseMutationOptions<CreatePropertyMutation, CreatePropertyMutationVariables>;
export const UpdatePropertyDocument = gql`
    mutation UpdateProperty($id: uuid!, $name: String, $address1: String, $address2: String, $prefferedReportingMethod: String) {
  update_properties_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, address1: $address1, address2: $address2, prefferedReportingMethod: $prefferedReportingMethod}
  ) {
    ...PropertyInfo
  }
}
    ${PropertyInfoFragmentDoc}`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      prefferedReportingMethod: // value for 'prefferedReportingMethod'
 *   },
 * });
 */
export function useUpdatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options);
      }
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>;
export const DeletePropertyDocument = gql`
    mutation DeleteProperty($id: uuid!) {
  delete_properties_by_pk(id: $id) {
    ...PropertyInfo
  }
}
    ${PropertyInfoFragmentDoc}`;
export type DeletePropertyMutationFn = Apollo.MutationFunction<DeletePropertyMutation, DeletePropertyMutationVariables>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyMutation, DeletePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePropertyMutation, DeletePropertyMutationVariables>(DeletePropertyDocument, options);
      }
export type DeletePropertyMutationHookResult = ReturnType<typeof useDeletePropertyMutation>;
export type DeletePropertyMutationResult = Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<DeletePropertyMutation, DeletePropertyMutationVariables>;
export const FetchPropertyInfoDocument = gql`
    query FetchPropertyInfo($id: uuid!) {
  properties_by_pk(id: $id) {
    ...PropertyInfo
  }
}
    ${PropertyInfoFragmentDoc}`;

/**
 * __useFetchPropertyInfoQuery__
 *
 * To run a query within a React component, call `useFetchPropertyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPropertyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPropertyInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchPropertyInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchPropertyInfoQuery, FetchPropertyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPropertyInfoQuery, FetchPropertyInfoQueryVariables>(FetchPropertyInfoDocument, options);
      }
export function useFetchPropertyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPropertyInfoQuery, FetchPropertyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPropertyInfoQuery, FetchPropertyInfoQueryVariables>(FetchPropertyInfoDocument, options);
        }
export type FetchPropertyInfoQueryHookResult = ReturnType<typeof useFetchPropertyInfoQuery>;
export type FetchPropertyInfoLazyQueryHookResult = ReturnType<typeof useFetchPropertyInfoLazyQuery>;
export type FetchPropertyInfoQueryResult = Apollo.QueryResult<FetchPropertyInfoQuery, FetchPropertyInfoQueryVariables>;
export function refetchFetchPropertyInfoQuery(variables?: FetchPropertyInfoQueryVariables) {
      return { query: FetchPropertyInfoDocument, variables: variables }
    }
export const GeneratePropertyReportDocument = gql`
    mutation GeneratePropertyReport($propertyId: uuid!) {
  generateReport(params: {propertyId: $propertyId}) {
    message
  }
}
    `;
export type GeneratePropertyReportMutationFn = Apollo.MutationFunction<GeneratePropertyReportMutation, GeneratePropertyReportMutationVariables>;

/**
 * __useGeneratePropertyReportMutation__
 *
 * To run a mutation, you first call `useGeneratePropertyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePropertyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePropertyReportMutation, { data, loading, error }] = useGeneratePropertyReportMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGeneratePropertyReportMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePropertyReportMutation, GeneratePropertyReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePropertyReportMutation, GeneratePropertyReportMutationVariables>(GeneratePropertyReportDocument, options);
      }
export type GeneratePropertyReportMutationHookResult = ReturnType<typeof useGeneratePropertyReportMutation>;
export type GeneratePropertyReportMutationResult = Apollo.MutationResult<GeneratePropertyReportMutation>;
export type GeneratePropertyReportMutationOptions = Apollo.BaseMutationOptions<GeneratePropertyReportMutation, GeneratePropertyReportMutationVariables>;
export const ListPropertyContactsDocument = gql`
    query ListPropertyContacts($propertyId: uuid!, $query: String, $order: order_by, $limit: Int, $offset: Int) {
  property_contacts(
    where: {_and: [{propertyId: {_eq: $propertyId}}, {_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}]}
    order_by: {createdAt: $order}
    limit: $limit
    offset: $offset
  ) {
    ...PropertyContactInfo
  }
  property_contacts_aggregate {
    aggregate {
      count
    }
  }
}
    ${PropertyContactInfoFragmentDoc}`;

/**
 * __useListPropertyContactsQuery__
 *
 * To run a query within a React component, call `useListPropertyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPropertyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPropertyContactsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPropertyContactsQuery(baseOptions: Apollo.QueryHookOptions<ListPropertyContactsQuery, ListPropertyContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPropertyContactsQuery, ListPropertyContactsQueryVariables>(ListPropertyContactsDocument, options);
      }
export function useListPropertyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPropertyContactsQuery, ListPropertyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPropertyContactsQuery, ListPropertyContactsQueryVariables>(ListPropertyContactsDocument, options);
        }
export type ListPropertyContactsQueryHookResult = ReturnType<typeof useListPropertyContactsQuery>;
export type ListPropertyContactsLazyQueryHookResult = ReturnType<typeof useListPropertyContactsLazyQuery>;
export type ListPropertyContactsQueryResult = Apollo.QueryResult<ListPropertyContactsQuery, ListPropertyContactsQueryVariables>;
export function refetchListPropertyContactsQuery(variables?: ListPropertyContactsQueryVariables) {
      return { query: ListPropertyContactsDocument, variables: variables }
    }
export const CreatePropertyContactDocument = gql`
    mutation CreatePropertyContact($firstName: String, $lastName: String, $propertyId: uuid, $email: String, $phone: String, $position: String) {
  insert_property_contacts_one(
    object: {firstName: $firstName, lastName: $lastName, propertyId: $propertyId, email: $email, phone: $phone, position: $position}
  ) {
    ...PropertyContactInfo
  }
}
    ${PropertyContactInfoFragmentDoc}`;
export type CreatePropertyContactMutationFn = Apollo.MutationFunction<CreatePropertyContactMutation, CreatePropertyContactMutationVariables>;

/**
 * __useCreatePropertyContactMutation__
 *
 * To run a mutation, you first call `useCreatePropertyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyContactMutation, { data, loading, error }] = useCreatePropertyContactMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      propertyId: // value for 'propertyId'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreatePropertyContactMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyContactMutation, CreatePropertyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyContactMutation, CreatePropertyContactMutationVariables>(CreatePropertyContactDocument, options);
      }
export type CreatePropertyContactMutationHookResult = ReturnType<typeof useCreatePropertyContactMutation>;
export type CreatePropertyContactMutationResult = Apollo.MutationResult<CreatePropertyContactMutation>;
export type CreatePropertyContactMutationOptions = Apollo.BaseMutationOptions<CreatePropertyContactMutation, CreatePropertyContactMutationVariables>;
export const UpdatePropertyContactDocument = gql`
    mutation UpdatePropertyContact($id: uuid!, $firstName: String, $lastName: String, $propertyId: uuid, $email: String, $phone: String, $position: String) {
  update_property_contacts_by_pk(
    pk_columns: {id: $id}
    _set: {firstName: $firstName, lastName: $lastName, propertyId: $propertyId, email: $email, phone: $phone, position: $position}
  ) {
    ...PropertyContactInfo
  }
}
    ${PropertyContactInfoFragmentDoc}`;
export type UpdatePropertyContactMutationFn = Apollo.MutationFunction<UpdatePropertyContactMutation, UpdatePropertyContactMutationVariables>;

/**
 * __useUpdatePropertyContactMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyContactMutation, { data, loading, error }] = useUpdatePropertyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      propertyId: // value for 'propertyId'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdatePropertyContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyContactMutation, UpdatePropertyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyContactMutation, UpdatePropertyContactMutationVariables>(UpdatePropertyContactDocument, options);
      }
export type UpdatePropertyContactMutationHookResult = ReturnType<typeof useUpdatePropertyContactMutation>;
export type UpdatePropertyContactMutationResult = Apollo.MutationResult<UpdatePropertyContactMutation>;
export type UpdatePropertyContactMutationOptions = Apollo.BaseMutationOptions<UpdatePropertyContactMutation, UpdatePropertyContactMutationVariables>;
export const DeletePropertyContactDocument = gql`
    mutation DeletePropertyContact($id: uuid!) {
  delete_property_contacts_by_pk(id: $id) {
    ...PropertyContactInfo
  }
}
    ${PropertyContactInfoFragmentDoc}`;
export type DeletePropertyContactMutationFn = Apollo.MutationFunction<DeletePropertyContactMutation, DeletePropertyContactMutationVariables>;

/**
 * __useDeletePropertyContactMutation__
 *
 * To run a mutation, you first call `useDeletePropertyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyContactMutation, { data, loading, error }] = useDeletePropertyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyContactMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyContactMutation, DeletePropertyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePropertyContactMutation, DeletePropertyContactMutationVariables>(DeletePropertyContactDocument, options);
      }
export type DeletePropertyContactMutationHookResult = ReturnType<typeof useDeletePropertyContactMutation>;
export type DeletePropertyContactMutationResult = Apollo.MutationResult<DeletePropertyContactMutation>;
export type DeletePropertyContactMutationOptions = Apollo.BaseMutationOptions<DeletePropertyContactMutation, DeletePropertyContactMutationVariables>;
export const FetchPropertyContactDocument = gql`
    query FetchPropertyContact($id: uuid!) {
  property_contacts_by_pk(id: $id) {
    ...PropertyContactInfo
  }
}
    ${PropertyContactInfoFragmentDoc}`;

/**
 * __useFetchPropertyContactQuery__
 *
 * To run a query within a React component, call `useFetchPropertyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPropertyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPropertyContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchPropertyContactQuery(baseOptions: Apollo.QueryHookOptions<FetchPropertyContactQuery, FetchPropertyContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPropertyContactQuery, FetchPropertyContactQueryVariables>(FetchPropertyContactDocument, options);
      }
export function useFetchPropertyContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPropertyContactQuery, FetchPropertyContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPropertyContactQuery, FetchPropertyContactQueryVariables>(FetchPropertyContactDocument, options);
        }
export type FetchPropertyContactQueryHookResult = ReturnType<typeof useFetchPropertyContactQuery>;
export type FetchPropertyContactLazyQueryHookResult = ReturnType<typeof useFetchPropertyContactLazyQuery>;
export type FetchPropertyContactQueryResult = Apollo.QueryResult<FetchPropertyContactQuery, FetchPropertyContactQueryVariables>;
export function refetchFetchPropertyContactQuery(variables?: FetchPropertyContactQueryVariables) {
      return { query: FetchPropertyContactDocument, variables: variables }
    }
export const ListUsersDocument = gql`
    query ListUsers($query: String, $order: order_by, $role: users_role_enum) {
  users(
    where: {_and: [{_or: [{firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}, {role: {_eq: $role}}]}
    order_by: {createdAt: $order}
  ) {
    id
    firstName
    lastName
    email
    dateOfBirth
    createdAt
    avatarUrl
    agencyId
    role
    disabled
    deviceId
  }
  users_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export function refetchListUsersQuery(variables?: ListUsersQueryVariables) {
      return { query: ListUsersDocument, variables: variables }
    }
export const CreateUserDocument = gql`
    mutation CreateUser($email: String, $firstName: String, $lastName: String, $role: users_role_enum, $agencyId: uuid, $disableNotifications: Boolean) {
  insert_users_one(
    object: {email: $email, firstName: $firstName, lastName: $lastName, role: $role, agencyId: $agencyId, disableNotifications: $disableNotifications}
  ) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      agencyId: // value for 'agencyId'
 *      disableNotifications: // value for 'disableNotifications'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: uuid!, $firstName: String, $lastName: String, $disableNotifications: Boolean) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {firstName: $firstName, lastName: $lastName, disableNotifications: $disableNotifications}
  ) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      disableNotifications: // value for 'disableNotifications'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UnlinkDeviceDocument = gql`
    mutation UnlinkDevice($id: uuid!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {deviceId: null}) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UnlinkDeviceMutationFn = Apollo.MutationFunction<UnlinkDeviceMutation, UnlinkDeviceMutationVariables>;

/**
 * __useUnlinkDeviceMutation__
 *
 * To run a mutation, you first call `useUnlinkDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkDeviceMutation, { data, loading, error }] = useUnlinkDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlinkDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkDeviceMutation, UnlinkDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkDeviceMutation, UnlinkDeviceMutationVariables>(UnlinkDeviceDocument, options);
      }
export type UnlinkDeviceMutationHookResult = ReturnType<typeof useUnlinkDeviceMutation>;
export type UnlinkDeviceMutationResult = Apollo.MutationResult<UnlinkDeviceMutation>;
export type UnlinkDeviceMutationOptions = Apollo.BaseMutationOptions<UnlinkDeviceMutation, UnlinkDeviceMutationVariables>;
export const UpdateUserStatusDocument = gql`
    mutation UpdateUserStatus($id: uuid!, $disabled: Boolean) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {disabled: $disabled}) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(UpdateUserStatusDocument, options);
      }
export type UpdateUserStatusMutationHookResult = ReturnType<typeof useUpdateUserStatusMutation>;
export type UpdateUserStatusMutationResult = Apollo.MutationResult<UpdateUserStatusMutation>;
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: uuid!) {
  delete_users_by_pk(id: $id) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const FetchUserInfoDocument = gql`
    query FetchUserInfo($id: uuid!) {
  users_by_pk(id: $id) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useFetchUserInfoQuery__
 *
 * To run a query within a React component, call `useFetchUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserInfoQuery(baseOptions: Apollo.QueryHookOptions<FetchUserInfoQuery, FetchUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserInfoQuery, FetchUserInfoQueryVariables>(FetchUserInfoDocument, options);
      }
export function useFetchUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserInfoQuery, FetchUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserInfoQuery, FetchUserInfoQueryVariables>(FetchUserInfoDocument, options);
        }
export type FetchUserInfoQueryHookResult = ReturnType<typeof useFetchUserInfoQuery>;
export type FetchUserInfoLazyQueryHookResult = ReturnType<typeof useFetchUserInfoLazyQuery>;
export type FetchUserInfoQueryResult = Apollo.QueryResult<FetchUserInfoQuery, FetchUserInfoQueryVariables>;
export function refetchFetchUserInfoQuery(variables?: FetchUserInfoQueryVariables) {
      return { query: FetchUserInfoDocument, variables: variables }
    }