import UpdatePlate from "views/Admin/Plates/PlateForm/UpdatePlate";
import PlateIncidents from "views/Admin/Plates/PlateForm/PlateIncidents";
import Tab from "components/atoms/Tab/Tab";

const Update = () => (
  <Tab
    tabs={[
      {
        name: "Overview",
        component: <UpdatePlate />,
      },
      {
        name: "Incidents",
        component: <PlateIncidents />,
      },
    ]}
  />
);

export default Update;
