import React, { ChangeEvent } from "react";
import styles from "./Switch.module.scss";
interface SwitchProps {
  isOn: boolean;
  handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const Switch = (props: SwitchProps) => {
  return (
    <>
      <input
        checked={props.isOn}
        onChange={props.handleToggle}
        className={styles.react_switch_checkbox}
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className={`${styles.react_switch_label} ${
          props.isOn ? styles.react_switch_on : styles.react_switch_off
        }`}
        htmlFor={`react-switch-new`}
      >
        <span className={styles.react_switch_button} />
      </label>
    </>
  );
};

export default Switch;
