import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Media,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
} from "reactstrap";
import {
  useListAllPropertiesQuery,
  useGetPlateIncidentTypesQuery,
  useGetOwnedLprDataStoreQuery,
  useSearchPlateMutation,
  useAddPlateMutation,
  ListPlatesDocument,
  useSearchFaceMutation,
} from "../../../../generated/graphql";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Field, Formik } from "formik";
import { ListAs } from "utils/constants";
import Storage from "@aws-amplify/storage";
import { v4 } from "uuid";
import toast from "react-hot-toast";
import Lightbox from "react-image-lightbox";
import AppContext from "contexts/AppContext";
import { useQuery } from "hooks/query";
import Loading from "../../../../assets/img/icons/common/Loading.gif";

const AddPlate = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isImageOpened, openImage] = useState(false);
  const [plateImageSrc, setPlateImageSrc] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [searchPlate, { loading: searchingPlate }] = useSearchPlateMutation();
  const { user } = useContext(AppContext);
  const { data: properties } = useListAllPropertiesQuery({ variables: {} });
  const { data: incidentTypes } = useGetPlateIncidentTypesQuery({
    variables: {},
  });
  const { data: datastore } = useGetOwnedLprDataStoreQuery({
    variables: { id: user?.agencyId },
  });
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const LIMIT = 10;
  const [addPlateMutation, { loading: addingPlate }] = useAddPlateMutation({
    refetchQueries: [
      {
        query: ListPlatesDocument,
        variables: {
          query: `%%`,
          limit: LIMIT,
          offset: page * LIMIT,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "plates",
      });
    },
  });

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [plate, setPlate] = useState<any | undefined>(undefined);
  const [searching, setSearching] = useState(true);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>A Possible Match was Found!</ModalHeader>
        <ModalBody>
          {plate?.displayNumber} could possibly be the Tresspasser
        </ModalBody>
        <ModalFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>Do you want to go to the Plate's page?</div>
          <div>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                history.push(`/admin/plates/${plate?.id}`);
              }}
            >
              Yes
            </Button>{" "}
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {isImageOpened && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => openImage(false)} />
      )}
      <Row>
        <Col className="order-xl-1">
          <Formik
            enableReinitialize={true}
            initialValues={{
              number: "",
              displayNumber: "",
              listedAs: ListAs.NONE.toString(),
              model: "",
              make: "",
              color: "",
              year: "",
              knownToBeViolent: false,
              property: properties ? properties.properties[0].id : "",
              typeId: incidentTypes
                ? incidentTypes.lpr_incident_type[0].id
                : "",
              datastoreId: datastore?.lpr_datastores[0].id,
              note: "",
              plateImage: "",
              lpr_images: [],
            }}
            onSubmit={(values) => {
              const action = (async () => {
                let lprImageKey = `temp/${v4()}.jpg`;
                const {
                  number,
                  displayNumber,
                  listedAs,
                  model,
                  color,
                  year,
                  property,
                  make,
                  typeId,
                  datastoreId,
                  note,
                } = values;
                await Storage.put(
                  lprImageKey,
                  await (
                    await fetch(URL.createObjectURL(values.plateImage))
                  ).blob()
                );
                const s3ImageKey = `public/${lprImageKey}`;
                await addPlateMutation({
                  variables: {
                    number,
                    displayNumber,
                    listedAs,
                    model,
                    make,
                    color,
                    year,
                    knownToBeViolent: values.knownToBeViolent,
                    location: null,
                    propertyId: property,
                    typeId,
                    type: null,
                    datastoreId,
                    note,
                    s3ImageKey,
                    lpr_images: [],
                  },
                });
                history.goBack();
              })();
              toast.promise(action, {
                success: "Successfully Added!",
                loading: "Adding...",
                error: "Failed to add!",
              });
            }}
          >
            {({ values, setFieldValue, handleChange, submitForm }) => (
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {id ? "Update" : "Add new Plate Details"}
                      </h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        type="submit"
                        onClick={submitForm}
                        size="sm"
                        disabled={searching || !addingPlate ? false : true}
                      >
                        {!addingPlate ? "Save" : "Saving..."}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <h6 className="heading-small text-muted mb-4">
                      Vehicle Details
                    </h6>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Plate Image
                        </label>
                        <Media
                          middle
                          style={{
                            borderRadius: 20,
                            width: "100%",
                            objectFit: "contain",
                            height: "20vh",
                            cursor: "pointer",
                            marginTop: "1rem",
                          }}
                          src={searchingPlate ? Loading : plateImageSrc}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        />
                        <Input
                          accept="image/*"
                          onChange={(e) => {
                            setSearching(true);
                            const action = (async () => {
                              if (e.target.files) {
                                if (e.target.files[0]) {
                                  try {
                                    setPlateImageSrc(
                                      await URL.createObjectURL(
                                        e.target.files[0]
                                      )
                                    );
                                    setFieldValue(
                                      "plateImage",
                                      e.target.files[0]
                                    );
                                    let lprImageKey = `temp/${v4()}.jpg`;
                                    await Storage.put(
                                      lprImageKey,
                                      await (
                                        await fetch(
                                          await URL.createObjectURL(
                                            e.target.files[0]
                                          )
                                        )
                                      ).blob()
                                    );
                                    const s3ImageKey = `public/${lprImageKey}`;
                                    const res = await searchPlate({
                                      variables: { s3ImageKey },
                                    });
                                    if (res.data?.searchPlate?.plate) {
                                      toast.success("Plate Found!");
                                      setSearching(true);
                                      setPlate(res.data.searchPlate.plate);
                                      toggle();
                                    } else setSearching(false);
                                  } catch (e) {
                                    toast.error("Failed to Upload the Image!");
                                  }
                                } else {
                                  setPlateImageSrc("");
                                }
                              }
                            })();
                          }}
                          type="file"
                        />
                      </Col>

                      {!searching ? (
                        <Col lg="6">
                          <Row>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Display Number
                              </label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id="displayNumber"
                                name="displayNumber"
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue(
                                    "number",
                                    `${e.target.value
                                      .replace(/[^A-Z0-9]/gi, "")
                                      .toUpperCase()}`
                                  );
                                }}
                                placeholder="LPY 87-98"
                                type="text"
                                required={true}
                              />
                            </Col>
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Number
                              </label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id="number"
                                name="number"
                                placeholder="LPY8798"
                                type="text"
                                disabled
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <label className="form-control-label">
                                Model
                              </label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id="model"
                                name="model"
                                placeholder="Model"
                                type="text"
                                required
                              />
                            </Col>
                            <Col lg="6">
                              <label className="form-control-label">Make</label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id="make"
                                name="make"
                                placeholder="Make"
                                type="text"
                                required
                              />
                            </Col>
                            <Col lg="6">
                              <label className="form-control-label">Year</label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id="year"
                                name="year"
                                placeholder="2010"
                                type="text"
                                required
                              />
                            </Col>
                            <Col lg="6">
                              <label className="form-control-label">
                                Color
                              </label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id="color"
                                name="color"
                                placeholder="Blue"
                                type="text"
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                List As
                              </label>
                              <Input
                                type="select"
                                onChange={(e) =>
                                  setFieldValue("listedAs", e.target.value)
                                }
                                value={values.listedAs}
                                name="listedAs"
                              >
                                {[
                                  {
                                    name: "None",
                                    value: ListAs.NONE,
                                  },
                                  {
                                    name: "Not Authorized",
                                    value: ListAs.BOLO,
                                  },
                                  {
                                    name: "Authorized",
                                    value: ListAs.WHITELIST,
                                  },
                                ].map((option) => (
                                  <option value={option.value.toString()}>
                                    {option.name}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6" style={{ padding: "10px 0 0 35px" }}>
                              <Input
                                type={"checkbox"}
                                checked={values.knownToBeViolent}
                                onChange={(e) =>
                                  setFieldValue(
                                    "knownToBeViolent",
                                    e.target.checked
                                  )
                                }
                              />
                              <Label check>Known to be violent</Label>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>

                  {!searching ? (
                    <>
                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Incident Details
                        </h6>
                        <Row>
                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Property
                            </label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setFieldValue("property", e.target.value)
                              }
                              value={values.property}
                              name="properties"
                              required
                            >
                              {properties?.properties
                                .map((i) => ({ name: i.name, value: i.id }))
                                .map((option) => (
                                  <option value={option.value.toString()}>
                                    {option.name}
                                  </option>
                                ))}
                            </Input>
                          </Col>

                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Incident Type
                            </label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setFieldValue("typeId", e.target.value)
                              }
                              value={values.typeId}
                              name="incidentTypes"
                              required
                            >
                              {incidentTypes?.lpr_incident_type
                                .map((i) => ({ name: i.name, value: i.id }))
                                .map((option) => (
                                  <option value={option.value.toString()}>
                                    {option.name}
                                  </option>
                                ))}
                            </Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Note
                            </label>
                            <Input
                              tag={Field}
                              className="form-control-alternative"
                              id="note"
                              name="note"
                              placeholder="Please enter a note (optional)"
                              type="textarea"
                              required
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default AddPlate;
