import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Media,
  Label
} from "reactstrap";
import {useListAllPropertiesQuery, useGetIncidentTypesQuery, useAddPersonMutation, useGetOwnedPersonDataStoreQuery, useSearchFaceMutation, ListPersonDocument} from "../../../generated/graphql"
import { withRouter, useParams } from "react-router";
import {Storage} from "aws-amplify"
import { Field, Formik } from "formik";
import { ListAs } from "utils/constants";
import {v4} from 'uuid'
import toast from "react-hot-toast";
import AppContext from "contexts/AppContext";
import { useQuery } from "hooks/query";
import Loading from "../../../assets/img/icons/common/Loading.gif"

const AddTrespasser = withRouter(({ history }) => {
  const { id } = useParams<{ id: string }>();
  const [personImageSrc, setPersonImageSrc] = useState("")
  const [idCardImageSrc, setidCardImageSrc] = useState("")
  const { data : properties } = useListAllPropertiesQuery({variables: {}})
  const { data : incidentTypes } = useGetIncidentTypesQuery({variables: {}})
  const { user } = useContext(AppContext);
  const {data : datastore} = useGetOwnedPersonDataStoreQuery({variables: {id: user?.agencyId}})
  const [searchFace, { loading: searchingFace }] = useSearchFaceMutation();
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const LIMIT = 10
  const [addPersonMutation, { data: addPersonMutationResponse }] = useAddPersonMutation({
    refetchQueries: [
      {
        query: ListPersonDocument,
        variables: {
          query: `%%`,
          limit: LIMIT,
          offset: page * LIMIT,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "persons",
      });
    },
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [person, setPerson] = useState<any | undefined>(undefined);
  const [searching, setSearching] = useState(true)
  return (
    <>
    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>A Possible Match was Found!</ModalHeader>
        <ModalBody>
            {person?.firstName} {person?.lastName} could possibly be the Tresspasser
        </ModalBody>
        <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
          Do you want to go to the Subject's page?
          </div>
          <div>
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              history.push(`/admin/subjects/${person?.id}`)
            }}
          >
            Yes
          </Button>{" "}
          <Button
            size="sm"
            color="secondary"
            onClick={() => { toggle()
            }}
          >
            Cancel
          </Button>
          </div>
        </ModalFooter>
    </Modal>
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
            listedAs: ListAs.NONE.toString(),
            dateOfBirth: "",
            knownToBeViolent: false,
            property: properties?.properties[0].id,
            typeId: incidentTypes?.incident_type[0].id,
            note: "",
            subjectImage: "",
            idCardImage: "",
          }}
          onSubmit={(values) => {
            const action = (async () => {
              let subjectImageKey = `temp/${v4()}.jpg`
              let idCardImageKey = `temp/${v4()}.jpg`
              await Storage.put(subjectImageKey, await (await fetch(await URL.createObjectURL(values.subjectImage))).blob())
              if(values.idCardImage)await Storage.put(idCardImageKey, await (await fetch(await URL.createObjectURL(values.idCardImage))).blob())
              const s3ImageKey = `public/${subjectImageKey}`
              const idCardImage = values.idCardImage?`public/${idCardImageKey}`:""
              const res = await addPersonMutation({variables: {
                firstName: values.firstName,
                lastName: values.lastName,
                dateOfBirth: values.dateOfBirth?values.dateOfBirth:null,
                knownToBeViolent: values.knownToBeViolent,
                listedAs: values.listedAs,
                propertyId: values.property,
                type: null,
                typeId: values.typeId,
                s3ImageKey: s3ImageKey,
                idCardImage: idCardImage,
                note: values.note?values.note:"",
                trespassStatementAudio: "",
                datastoreId: datastore?.person_datastores[0].id
              }  })
              history.goBack();
            })();
            toast.promise(action, {
              success: "Successfully Added!",
              loading: "Adding...",
              error: "Failed to Add!",
            });
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm, resetForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {id ? "Update" : "Add new Subject Details"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={searching}
                    >
                      {"Save"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                <h6 className="heading-small text-muted mb-4">Subject Details</h6>
                <Row>
                <Col lg='6'>
                <Row>
                  <Col lg="6">
                    <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Subject Image
                      </label>
                      <Media
                          middle
                          style={{
                            borderRadius: 20,
                            width: "100%",
                            objectFit: "contain",
                            height: "20vh",
                            cursor: "pointer",
                            marginTop: "1rem",
                          }}
                          src={searchingFace?Loading:personImageSrc}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        />
                      <Input
                       accept= 'image/*'
                       onChange = {(e) => {
                         setSearching(true)
                         const action = (async () => {
                         if(e.target.files){

                          if(e.target.files[0]) {
                            try{
                              setPersonImageSrc(await URL.createObjectURL(e.target.files[0]))
                              setFieldValue('subjectImage', e.target.files[0])
                              let subjectImageKey = `temp/${v4()}.jpg`
                              await Storage.put(subjectImageKey, await (await fetch(await URL.createObjectURL(e.target.files[0]))).blob())
                              const s3ImageKey = `public/${subjectImageKey}`
                              const res = await searchFace({variables: {s3ImageKey}})
                              if(!res.data?.searchFace?.faceFound){
                                resetForm()
                                setSearching(true)
                                toast.error('No face was found. Try another Image!')
                              }
                              else if(res.data?.searchFace?.info){
                                resetForm()
                                setSearching(true)
                                setPerson(res.data.searchFace.info)
                                toggle()
                                toast.success('Face Found!')
                                }
                                else {
                                  setSearching(false)
                                }
                            }
                            catch(e){
                              toast.error('Failed to Upload the Image!')
                            }
                          }
                          else {
                            setPersonImageSrc("")
                          }
                         }
                        })();
                      }}
                       type="file" />
                  </Col>
                  {!searching?
                  <Col lg="6">
                  <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Id Card Image
                      </label>
                      <Media
                          middle
                          style={{
                            borderRadius: 20,
                            width: "100%",
                            objectFit: "contain",
                            height: "20vh",
                            cursor: "pointer",
                            marginTop: "1rem",
                          }}
                          src={idCardImageSrc}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        />
                      <Input
                       accept= 'image/*'
                       onChange = {async e => {
                        if(e.target.files){
                          setFieldValue('idCardImage', e.target.files[0])
                          if(e.target.files[0]) {
                            setidCardImageSrc(await URL.createObjectURL(e.target.files[0]))
                          }
                          else {
                            setidCardImageSrc("")
                          }
                        }
                       }}
                       type="file" />
                  </Col> : <></>}
                </Row>
                </Col>
                {
                  !searching?<Col lg='6'>
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        First name
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="firstName"
                        name="firstName"
                        placeholder="Tom"
                        type="text"
                        required
                      />
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Last Name
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="lastName"
                        name="lastName"
                        placeholder="Hanks"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Date of Birth
                      </label>
                      <Input
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        onChange={e => setFieldValue('dateOfBirth', e.target.value)}
                      />
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        List As
                      </label>
                      <Input
                        type="select"
                        onChange={(e) => setFieldValue("listedAs", e.target.value)}
                        value={values.listedAs}
                        name="listedAs"
                      >
                        {[
                          {
                            name: "None",
                            value: ListAs.NONE,
                          },
                          {
                            name: "Not Authorized",
                            value: ListAs.BOLO,
                          },
                          {
                            name: "Authorized",
                            value: ListAs.WHITELIST,
                          },
                        ].map((option) => (
                          <option value={option.value.toString()}>
                            {option.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{padding: "10px 0 0 35px"}}>
                      <Input
                        type={"checkbox"}
                        checked={values.knownToBeViolent}
                        onChange={(e) => setFieldValue("knownToBeViolent", e.target.checked)}
                      />
                      <Label check>Known to be violent</Label>
                    </Col>
                  </Row>
                  </Col> : <></> }
                  </Row>
                </div>

                {!searching?<><hr className="my-4" />
                <div className="pl-lg-4">
                <h6 className="heading-small text-muted mb-4">Incident Details</h6>
                  <Row>
                    <Col lg="6">
                      <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Property
                      </label>
                      <Input
                          type="select"
                          onChange={(e) => setFieldValue("property", e.target.value)}
                          value={values.property}
                          name="properties"
                          required
                        >
                          {properties?.properties.map( i => ({ name: i.name, value: i.id })).map( option => (
                            <option value={option.value.toString()}>
                              {option.name}
                            </option>
                          ))}
                        </Input>
                    </Col>

                    <Col lg="6">
                      <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Incident Type
                      </label>
                      <Input
                          type="select"
                          onChange={(e) => setFieldValue("typeId", e.target.value)}
                          value={values.typeId}
                          name="incidentTypes"
                          required
                        >

                          {incidentTypes?.incident_type.map( i => ({name: i.name, value: i.id})).map( option => (
                            <option value={option.value.toString()}>
                              {option.name}
                            </option>
                          ))}
                        </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Note
                        </label>
                        <Input
                          tag={Field}
                          className="form-control-alternative"
                          id="note"
                          name="note"
                          placeholder="Please enter a note (optional)"
                          type="textarea"
                          required
                        />
                    </Col>
                  </Row>
                </div> </> : <></>}
              </CardBody>
            </Card>
          )}
        </Formik>
      </Col>
    </Row>
    </>
  );
});

export default AddTrespasser;
