import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useFetchPropertyInfoLazyQuery,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  ListPropertiesDocument,
  Order_By,
} from "generated/graphql";
import { useParams } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import AppContext from "contexts/AppContext";
import { InputType } from "zlib";

type FormProps = {
  title: string;
  name: string;
  placeHolder?: string;
  id: string;
  required: boolean;
  type?: InputType;
  responsive?: object;
};

const AddProperty = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const agencyId = new URLSearchParams(window.location.search).get("agencyid");
  const [fetchPropety, { data }] = useFetchPropertyInfoLazyQuery();

  const { user } = useContext(AppContext);

  const [addPropertyMutation, { loading: adding }] = useCreatePropertyMutation({
    refetchQueries: [
      {
        query: ListPropertiesDocument,
        variables: {
          query: `%%`,
          order: Order_By.Desc,
          limit: 10,
          offset: 0,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "properties",
      });
    },
  });

  const [updatePropertyMutation, { loading: updating }] =
    useUpdatePropertyMutation({
      refetchQueries: [
        {
          query: ListPropertiesDocument,
          variables: {
            query: `%%`,
            order: Order_By.Desc,
            limit: 10,
            offset: 0,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "properties",
        });
      },
    });

  useEffect(() => {
    if (id)
      fetchPropety({
        variables: {
          id,
        },
      });
  }, [fetchPropety, id]);

  return (
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            prefferedReportingMethod: "EMAIL",
            ...data?.properties_by_pk,
          }}
          onSubmit={(values) => {
            const action = (async () => {
              if (id) {
                await updatePropertyMutation({
                  variables: {
                    id,
                    ...values,
                  },
                });
              } else {
                await addPropertyMutation({
                  variables: {
                    ...values,
                    agencyId: user?.agencyId ? user.agencyId : agencyId,
                  },
                });
              }
              history.goBack();
            })();
            toast.promise(action, {
              success: id ? "Successfully Updated!" : "Successfully Added!",
              loading: id ? "Updating..." : "Adding...",
              error: id ? "Failed to update!" : "Failed to add!",
            });
          }}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {id ? "Update" : "Add new Property Details"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={!adding && !updating ? false : true}
                    >
                      {!adding && !updating ? "Save" : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    {[
                      {
                        title: "Name",
                        name: "name",
                        placeHolder: "Ex: Saint's Apartment",
                        id: "name",
                        required: true,
                        type: "text",
                      },
                      {
                        title: "Address Line 1",
                        name: "address1",
                        id: "address1",
                        required: true,
                        type: "text",
                      },
                      {
                        title: "Address Line 2",
                        name: "address2",
                        id: "address2",
                        required: true,
                        type: "text",
                      },
                    ].map(
                      ({
                        name,
                        title,
                        placeHolder,
                        type,
                        id,
                        required,
                        responsive = {
                          lg: 6,
                          sm: 12,
                        },
                      }: FormProps) => (
                        <Col {...responsive}>
                          <label
                            className="form-control-label"
                            htmlFor={`input-${name}`}
                          >
                            {title}
                          </label>
                          <Input
                            tag={Field}
                            className="form-control-alternative"
                            id={id}
                            name={name}
                            placeholder={placeHolder}
                            // @ts-ignore
                            type={type}
                            required={required}
                          />
                        </Col>
                      )
                    )}
                    <Col lg="6" sm="12">
                      <label
                        className="form-control-label"
                        htmlFor="input-prefferedReportingMethod"
                      >
                        Preferred Reporting Method
                      </label>
                      <Input
                        type="select"
                        onChange={(e) =>
                          setFieldValue(
                            "prefferedReportingMethod",
                            e.target.value
                          )
                        }
                        value={values.prefferedReportingMethod}
                        name="prefferedReportingMethod"
                      >
                        {[
                          {
                            name: "EMAIL",
                            value: "EMAIL",
                          },
                          {
                            name: "SMS",
                            value: "SMS",
                          },
                        ].map((option) => (
                          <option value={option.value.toString()}>
                            {option.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default AddProperty;
