import Table, { ColumnDefinitionType } from "components/atoms/Table";
import { 
  useListAdminsQuery,
  useDeleteUserMutation,
  ListUsersDocument,
  Order_By
} from "generated/graphql";
import toast from "react-hot-toast";
import { useQuery } from "hooks/query";
import { useHistory, useParams } from "react-router";
import { useContext } from "react";
import AppContext from "contexts/AppContext";

type AdminRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  agencyId: string;
};

const COLUMNS: ColumnDefinitionType<AdminRow, keyof AdminRow>[] = [
  {
    key: "firstName",
    header: "First Name",
  },
  {
    key: "lastName",
    header: "Last Name",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "role",
    header: "Role",
  },
];

const Admins = () => {
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { id } = useParams<{ id: string }>();

  const { data } = useListAdminsQuery({
    variables: {
      agencyId: id,
    },
  });
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [
      {
        query: ListUsersDocument,
        variables: {
          query: ``,
          order: Order_By.Desc,
          role: user?.role === "agencyadmins" ? "agencyadmins" : "superadmins",
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "users",
      });
    },
  })

  const LIMIT = 10;

  return (
    <Table
      columns={COLUMNS}
      data={
        data?.users.map((item) => {
          return {
            id: item.id,
            agencyId: item.agencyId,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            role: item.role,
          };
        }) || []
      }
      itemActions={[
        {
          heading: "Delete",
          text: "Delete",
          color: "danger",
          requiresConfirmation: true,
          setConfirmationMessage: ({ firstName, lastName }) =>
            `Are you sure you want to Delete ${firstName} ${lastName}?`,
          onClick: ({ id }) => {
            toast.promise(
              deleteUser({
                variables: {
                  id,
                },
              }),
              {
                loading: "Deleting...",
                success: "Deleted successfully!",
                error: "Failed to delete!",
              }
            );
          },
        }
      ]}
      onClickItem={(item) => {
        history.push(`/admin/users/${item.id}`);
      }}
      actions={[
        {
          text: "Add",
          href: `/admin/users/add?agencyid=${id}`,
          color: "primary",
        }
      ]}
      title="Admins"
      itemsPerPage={data?.totalAdmins.aggregate?.count || 1}
      totalPages={Math.ceil((data?.totalAdmins.aggregate?.count || 0) / LIMIT)}
    />
  );
};

export default Admins;
