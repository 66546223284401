import { useState } from "react";
import Table, {
  ColumnDefinitionType,
  TableActionType,
} from "components/atoms/Table";
import toast from "react-hot-toast";
import {
  Order_By,
  useListPropertiesQuery,
  useDeletePropertyMutation,
  ListPropertiesDocument,
  useGeneratePropertyReportMutation,
  useListFtpUsersQuery,
} from "generated/graphql";
import { useHistory } from "react-router";
import { useQuery } from "hooks/query";

type Row = {
  id: string;
  name: string;
};

const COLUMNS: ColumnDefinitionType<Row, keyof Row>[] = [
  {
    key: "name",
    header: "Name",
  },
];

const LIMIT = 10;

const List = () => {
  const [search, setSearch] = useState("");
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const history = useHistory();

  const { data } = useListFtpUsersQuery({
    variables: {
      query: `%${search}%`,
    },
  });

  return (
    <Table
      title="FTP User"
      columns={COLUMNS}
      data={
        data?.ftp_user.map((item) => ({
          id: item.id,
          name: `${item.username}`,
          agencyId: `${item.agencyId}`,
        })) || []
      }
      onClickItem={(item) => {
        history.push(`/admin/ftpuser/${item.id}`);
      }}
      itemActions={[]}
      actions={[
        {
          text: "Add",
          href: "/admin/ftpuser/add",
          color: "primary",
        },
        {
          text: "Search",
          type: TableActionType.TEXT,
          onChange: (e) => {
            setSearch(e.target.value);
          },
        },
      ]}
      itemsPerPage={data?.ftp_user?.length || 1}
      totalPages={Math.ceil((data?.ftp_user?.length || 0) / LIMIT)}
    />
  );
};

export default List;
