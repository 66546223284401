import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  ListIncidentsDocument,
  useFetchPlateInfoLazyQuery,
  useGetLprIncidentTypesQuery,
  useListAllPropertiesQuery,
  useAddPlateIncidentMutation,
  useUpdatePlateIncidentMutation,
  useGetLprIncidentByIdLazyQuery,
  GetLprIncidentByIdDocument,
  Order_By,
  ListPlatesDocument,
  useListIncidentsLocationLazyQuery,
} from "generated/graphql";
import { withRouter, useParams } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import Map from "components/atoms/Map/Map";

const AddPlateIncident = withRouter(({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { iid } = useParams<{ iid: string }>();
  const lprIncidentsData = useGetLprIncidentTypesQuery();
  const propertiesData = useListAllPropertiesQuery();
  const [defaultPropertyId, setDefaultPropertyId] = useState("");
  const [defaultIncidentId, setDefaultIncidentId] = useState("");
  const [fetchLocations, { data: locations }] =
    useListIncidentsLocationLazyQuery();
  const [addPlateIncidentMutation, { loading: addingIncident }] =
    useAddPlateIncidentMutation({
      refetchQueries: [
        {
          query: ListIncidentsDocument,
          variables: {
            plateId: id,
            limit: 10,
            offset: 0,
          },
        },
        {
          query: ListPlatesDocument,
          variables: {
            query: `%%`,
            order: Order_By.Desc,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "lpr_incidents",
        });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "plates",
        });
      },
    });

  const [getLPRIncidentById, data] = useGetLprIncidentByIdLazyQuery();

  useEffect(() => {
    if (iid) {
      getLPRIncidentById({
        variables: { id: iid },
      });
    }
    fetchLocations({
      variables: {
        plateId: id,
      },
    });
    setDefaultIncidentId(lprIncidentsData.data?.lpr_incident_type[0].id);
    setDefaultPropertyId(propertiesData.data?.properties[0].id);
  }, [
    id,
    getLPRIncidentById,
    iid,
    lprIncidentsData,
    propertiesData,
    fetchLocations,
    locations,
  ]);

  const [updatePlateIncidentMutation, { loading: updating }] =
    useUpdatePlateIncidentMutation({
      refetchQueries: [
        {
          query: ListIncidentsDocument,
          variables: {
            plateId: id,
            limit: 10,
            offset: 0,
          },
        },
        {
          query: GetLprIncidentByIdDocument,
          variables: {
            id: iid,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "lpr_incidents",
        });
      },
    });

  const agentName = `${data.data?.lpr_incidents_by_pk?.user?.firstName} ${data.data?.lpr_incidents_by_pk?.user?.lastName}`;

  return (
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            property:
              data.data?.lpr_incidents_by_pk?.propertyId || defaultPropertyId,
            type:
              data.data?.lpr_incidents_by_pk?.typeId ||
              data.data?.lpr_incidents_by_pk?.type ||
              defaultIncidentId,
            note: iid ? data.data?.lpr_incidents_by_pk?.note : "",
            location: data?.data?.lpr_incidents_by_pk?.location,
          }}
          onSubmit={(values) => {
            const action = (async () => {
              const { property, type, note } = values;
              if (iid) {
                let variables = {
                  iid,
                  note,
                  typeId: type,
                  propertyId: property,
                  id,
                  location: data?.data?.lpr_incidents_by_pk?.location,
                };
                await updatePlateIncidentMutation({
                  variables,
                  refetchQueries: [
                    {
                      query: ListIncidentsDocument,
                      variables: {
                        plateId: id,
                        limit: 10,
                        offset: 0,
                      },
                    },
                    {
                      query: GetLprIncidentByIdDocument,
                      variables: {
                        id: iid,
                      },
                    },
                  ],
                  update: (cache) => {
                    cache.evict({
                      id: "ROOT_QUERY",
                      fieldName: "lpr_incidents",
                    });
                  },
                });
              } else {
                await addPlateIncidentMutation({
                  variables: {
                    note,
                    typeId: type,
                    propertyId: property,
                    id,
                  },
                  refetchQueries: [
                    {
                      query: ListIncidentsDocument,
                      variables: {
                        plateId: id,
                        limit: 10,
                        offset: 0,
                      },
                    },
                    {
                      query: ListPlatesDocument,
                      variables: {
                        query: `%%`,
                        order: Order_By.Desc,
                      },
                    },
                  ],
                  update: (cache) => {
                    cache.evict({
                      id: "ROOT_QUERY",
                      fieldName: "lpr_incidents",
                    });
                    cache.evict({
                      id: "ROOT_QUERY",
                      fieldName: "plates",
                    });
                  },
                });
              }
              history.goBack();
            })();
            toast
              .promise(action, {
                success: `Successfully ${iid ? "Updated" : "Added"}!`,
                loading: `${iid ? "Updating" : "Adding"}...`,
                error: `Failed to ${iid ? "Update" : "Add"}!`,
              })
              .catch((e) => console.log(e));
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {iid ? "Update" : "Add new Incident"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={!addingIncident && !updating ? false : true}
                    >
                      {!addingIncident && !updating ? "Save" : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Property
                      </label>
                      <Input
                        type="select"
                        onChange={(e) =>
                          setFieldValue("property", e.target.value)
                        }
                        value={values.property}
                        name="property"
                      >
                        {propertiesData.data?.properties.map((option) => (
                          <option value={option.id}>{option.name}</option>
                        ))}
                      </Input>
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Incident Type
                      </label>
                      <Input
                        type="select"
                        onChange={(e) => setFieldValue("type", e.target.value)}
                        value={values.type}
                        name="type"
                      >
                        {lprIncidentsData.data?.lpr_incident_type.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        )}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Note
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="note"
                        name="note"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    {data.data?.lpr_incidents_by_pk?.user ? (
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Agent{" "}
                        </label>
                        <Input
                          readOnly
                          value={agentName}
                          tag={Field}
                          className="form-control-alternative"
                          id="note"
                          name="note"
                          type="text"
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
        {data.data?.lpr_incidents_by_pk?.location && (
          <Map
            locations={[data?.data?.lpr_incidents_by_pk?.location.coordinates]}
          />
        )}
      </Col>
    </Row>
  );
});

export default AddPlateIncident;
