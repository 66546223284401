export enum ListAs {
  BOLO = 2,
  WHITELIST = 1,
  NONE = 0,
}

export const ListAsEnumToString = {
  [ListAs.BOLO.toString()]: "BOLO",
  [ListAs.WHITELIST.toString()]: "Permitted",
  [ListAs.NONE.toString()]: "--",
}