// reactstrap components
import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label } from "reactstrap";

type RadioOption = {
  label: string;
  value: string;
};

interface RadioProps {
  label?: string;
  options: RadioOption[];
  name: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}

const Radio = (props: RadioProps): JSX.Element => {
  const [selected, setSelected] = useState(props.defaultValue);

  useEffect(() => {
    if (!selected) return;
    props.onChange(selected);
  }, [selected]);

  return (
    <div>
      <label className="form-control-label" htmlFor={`input-${props.name}`}>
        {props.label}
      </label>
      <FormGroup tag="fieldset">
        {props.options?.map(({ label, value }) => (
          <div onClick={() => setSelected(value)} id={`input-${props.name}`}>
            <Label check>
              <Input
                checked={selected === value}
                type="radio"
                value={value}
                name={`input-${props.name}`}
              />{" "}
              {label}
            </Label>
          </div>
        ))}
      </FormGroup>
    </div>
  );
};

export default Radio;
