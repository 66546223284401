import React, { useState } from "react";
import styles from "./Modal.module.scss";
import {
  Button,
  Modal as ReactModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

interface ModalProps<T> {
  message?: String | null;
  isVisible: boolean;
  onSubmit: (item: T) => void;
  onCancel: () => void;
  item: T;
  confirmationKey?: String | null;
}
const Modal = <T extends object>(props: ModalProps<T>) => {
  const { item, message, isVisible, onCancel, onSubmit, confirmationKey } =
    props;
  const [keyInput, setKeyInput] = useState<String | null>();
  return (
    <div>
      <ReactModal isOpen={isVisible} toggle={onCancel}>
        <ModalHeader className={styles.padding}>Alert!</ModalHeader>
        <ModalBody className={styles.padding}>
          <p>{message}</p>
          {confirmationKey ? (
            <>
              <p></p>
              <Input
                onChange={(e) => setKeyInput(e.target.value)}
                placeholder={String(confirmationKey)}
              ></Input>
            </>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter className={styles.padding}>
          <Button
            size="sm"
            color="danger"
            disabled={confirmationKey ? keyInput !== confirmationKey : false}
            onClick={() => {
              onSubmit(item);
              onCancel();
            }}
          >
            Yes
          </Button>{" "}
          <Button
            size="sm"
            color="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ReactModal>
    </div>
  );
};

export default Modal;
