import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useAddLprIncidentTypeMutation,
  useUpdateLprIncidentTypeMutation,
  useFetchLprIncidentTypeInfoLazyQuery,
  ListLprIncidentTypesDocument,
  Order_By,
  ListLprIncidentTypesBySuperDocument,
  ListLprIncidentTypesByAgencyDocument,
} from "generated/graphql";
import { withRouter, useParams, useHistory } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import { useQuery } from "hooks/query";
import AppContext from "contexts/AppContext";

const AddLPRIncident = withRouter(() => {
  const history = useHistory();
  const query = useQuery();
  const { id } = useParams<{ id: string }>();
  const [fetchUsers, { data }] = useFetchLprIncidentTypeInfoLazyQuery();
  const LIMIT = 10;
  const { user } = useContext(AppContext);
  const page = parseInt(query.get("page") || "1") - 1;

  const [addLprIncidentTypeMutation, { loading: addingIncidentType }] =
    useAddLprIncidentTypeMutation({
      refetchQueries: [
        {
          query: ListLprIncidentTypesDocument,
          variables: {
            query: `%%`,
            limit: LIMIT,
            offset: page * LIMIT,
            order: Order_By.Desc,
          },
        },
        {
          query: ListLprIncidentTypesBySuperDocument,
          variables: {
            limit: LIMIT,
            offset: page * LIMIT,
            order: Order_By.Desc,
          },
        },
        {
          query: ListLprIncidentTypesByAgencyDocument,
          variables: {
            agencyId: user?.agencyId,
            limit: LIMIT,
            offset: page * LIMIT,
            order: Order_By.Desc,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "lpr_incident_types",
        });
      },
    });

  const [updateLprIncidentTypeMutation, { loading: updatingIncidentType }] =
    useUpdateLprIncidentTypeMutation();

  useEffect(() => {
    if (id)
      fetchUsers({
        variables: {
          id,
        },
      });
  }, [fetchUsers, id]);

  return (
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: data?.lpr_incident_type_by_pk?.name?.toString(),
          }}
          onSubmit={(values) => {
            const action = (async () => {
              const { name } = values;
              if (id) {
                await updateLprIncidentTypeMutation({
                  variables: {
                    id,
                    name,
                  },
                });
              } else {
                await addLprIncidentTypeMutation({
                  variables: {
                    name,
                  },
                });
              }
              history.goBack();
            })();
            toast.promise(action, {
              success: "Successfully Added!",
              loading: "Adding...",
              error: "Failed to add!",
            });
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Incident Type for Plate</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={
                        (data?.lpr_incident_type_by_pk?.agencyId === null &&
                          user?.role === "agencyadmins") ||
                        (!addingIncidentType && !updatingIncidentType
                          ? false
                          : true)
                      }
                    >
                      {!addingIncidentType && !updatingIncidentType
                        ? "Save"
                        : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Incident Name
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="name"
                        name="name"
                        placeholder="Name"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            "incidentValue",
                            `${e.target.value
                              .replace(/[^A-Z0-9]/gi, "")
                              .toUpperCase()}`
                          );
                        }}
                        required
                      />
                    </Col>
                    <Col>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Incident Display Value
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="incidentValue"
                        name="incidentValue"
                        type="text"
                        disabled
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </Col>
    </Row>
  );
});

export default AddLPRIncident;
