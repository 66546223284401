import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// reactstrap components
import { Container } from "reactstrap";

// core components
import { chartOptions, parseOptions } from "variables/charts.js";

const Index = (props) => {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <Container className="mt--7" fluid></Container>
    </>
  );
};

export default Index;
