/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// styles
import styles from "./Sidebar.module.scss";

// assets
import closeIcon from "assets/img/icons/common/close.svg";
import menuIcon from "assets/img/icons/common/menu.png";

// reactstrap components
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const openCollapse = () => {
    setCollapseOpen(true);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return prop.link ? (
        <NavItem key={key}>
          <NavLink
            to={window.location}
            tag={NavLinkRRD}
            onClick={() => {
              closeCollapse;
              window.open(prop.link, "_blank");
            }}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
            <i
              style={{ marginLeft: "auto", marginRight: 0 }}
              className="fas fa-external-link-alt"
            />
          </NavLink>
        </NavItem>
      ) : (
        <NavItem key={key} className={styles.mob_nav_item}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className={`navbar-vertical fixed-left navbar-light bg-white
      ${collapseOpen ? styles.mobile_nav : ""}
      `}
      expand="md"
      id="sidenav-main"
    >
      <img
        src={closeIcon}
        alt="close"
        className={`${styles.mob_close_nav}`}
        onClick={closeCollapse}
      />
      <img
        src={menuIcon}
        alt="open_mob_nav"
        className={`${styles.mob_open_nav} ${
          collapseOpen ? styles.hide : styles.show
        } `}
        onClick={() => {
          openCollapse();
        }}
      />
      <Container fluid>
        {/* Brand */}
        {logo ? (
          <NavbarBrand
            className={`pt-0 ${styles.mobile_nav_brand}`}
            {...navbarBrandProps}
          >
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
              onClick={(e) => {
                e.stopPropagation();
                closeCollapse();
              }}
            />
          </NavbarBrand>
        ) : null}
        {/* Collapse */}

        <Collapse navbar className={styles.mobile_inner}>
          {/* Form */}
          <Form className="mt-4 mb-3 d-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar className={styles.mobile_nav_links}>
            {createLinks(routes)}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
