import AddProperty from "./PropertyForm/AddProperty";
import ContactProperty from "./PropertyForm/ContactProperty";
import PropertyTraspassers from "./PropertyForm/PropertyTraspassers";
import Tab from "components/atoms/Tab/Tab";

const UpdatePropertyContact = () => <Tab
    tabs={[
      {
        name: 'Overview',
        component: <AddProperty/>,
      },
      {
        name: 'Contacts',
        component: <ContactProperty/>,
      },
      {
        name: 'Incidents',
        component: <PropertyTraspassers/>,
      },
    ]}
  />

export default UpdatePropertyContact;
