/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PropsWithChildren, useContext } from "react";
import { useLocation, Switch, Redirect, Route } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Header from "components/Headers/AdminHeader";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes, { adminRoutes, agencyAdminRoutes, FacescopeRoute } from "routes";
import { useGetAdminDashboardStatsQuery } from "generated/graphql";
import AppContext from "contexts/AppContext";

const Admin = (props: PropsWithChildren<any>) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const { data } = useGetAdminDashboardStatsQuery();
  const { user } = useContext(AppContext);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    // @ts-ignore
    document.scrollingElement.scrollTop = 0;
    // @ts-ignore
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = () => {
    let allRoutes: FacescopeRoute[] = [];
    if (user?.role === "agencyadmins") {
      allRoutes = [...routes, ...agencyAdminRoutes];
    } else if (user?.role === "agents") {
      allRoutes = [...routes];
    } else {
      allRoutes = [...routes, ...adminRoutes];
    }

    return allRoutes
      .filter(({ layout }) => layout === "/admin")
      .sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderB - orderA);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={getRoutes().filter((route) => !route.hideNav)}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/facescope-logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText()} />
        {
          <Header
            stats={[
              ...(user?.role === "agencyadmins"
                ? [
                    {
                      name: "Agents",
                      value: data?.agents.aggregate?.count,
                      icon: "fas fa-users",
                      bg: "danger",
                      weight: 0.5,
                      to: "/admin/officers",
                    },
                    {
                      name: "Admins",
                      value: data?.agencyadmins.aggregate?.count,
                      icon: "fas fa-users",
                      bg: "danger",
                      weight: 0.5,
                      to: "/admin/users",
                    },
                  ]
                : [
                    {
                      name: "Admins",
                      value: data?.superadmins.aggregate?.count,
                      icon: "fas fa-users",
                      bg: "danger",
                      to: "/admin/users",
                    },
                  ]),
              {
                name: "Persons",
                value: data?.persons_aggregate.aggregate?.count,
                icon: "fas fa-street-view",
                bg: "warning",
                to: "/admin/subjects",
              },
              {
                name: "Plates",
                value: data?.plates_aggregate.aggregate?.count,
                icon: "fas fa-credit-card",
                bg: "yellow",
                to: "/admin/plates",
              },
            ]}
          />
        }
        <Container className="mt--7" fluid>
          <Switch>
            {getRoutes().map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  exact={prop.exact}
                  key={key}
                />
              );
            })}
            <Redirect from="*" to="/admin/index" />
          </Switch>
        </Container>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
