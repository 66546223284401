import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useFetchPropertyInfoLazyQuery,
  Order_By,
  useListPropertyContactsQuery,
  useCreatePropertyContactMutation,
  ListPropertyContactsDocument,
  useDeletePropertyContactMutation,
} from "generated/graphql";
import { useParams } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import { InputType } from "zlib";
import Table, { ColumnDefinitionType } from "components/atoms/Table/Table";

type FormProps = {
  title: string;
  name: string;
  placeHolder?: string;
  id: string;
  required: boolean;
  type?: InputType;
  responsive?: object;
};

type PropertyContactsTableRow = {
  id: string;
  name: string;
  email: string;
  phone: string;
};

const COLUMNS: ColumnDefinitionType<
  PropertyContactsTableRow,
  keyof PropertyContactsTableRow
>[] = [
  {
    key: "name",
    header: "Name",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "phone",
    header: "Phone",
  },
];

const AddContact = () => {
  const { id } = useParams<{ id: string }>();
  const [fetchPropety, { data }] = useFetchPropertyInfoLazyQuery();
  const { data: propertyContacts } = useListPropertyContactsQuery({
    variables: {
      propertyId: id,
      query: `%%`,
      order: Order_By.Desc,
      limit: 100,
      offset: 0,
    },
  });

  const [deleteContact] = useDeletePropertyContactMutation({
    refetchQueries: [
      {
        query: ListPropertyContactsDocument,
        variables: {
        propertyId: id,
        query: `%%`,
        order: Order_By.Desc,
        limit: 10,
        offset: 0,
      },
    }
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "property_contacts",
      });
    },
  })

  const [
    createPropertyContactInfoMutation,
    { loading: addingPropertyContact },
  ] = useCreatePropertyContactMutation({
    refetchQueries: [
      {
        query: ListPropertyContactsDocument,
        variables: {
          propertyId: id,
          query: `%%`,
          order: Order_By.Desc,
          limit: 10,
          offset: 0,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "property_contacts",
      });
    },
  });

  useEffect(() => {
    if (id)
      fetchPropety({
        variables: {
          id,
        },
      });
  }, [fetchPropety, id]);

  return (
    <>
      {id && (
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Contacts</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    designation: "",
                    propertyId: id,
                  }}
                  onSubmit={(values) => {
                    const action = (async () => {
                      await createPropertyContactInfoMutation({
                        variables: {
                          ...values,
                        },
                      });
                    })();
                    toast.promise(action, {
                      success: "Successfully Added Contact!",
                      loading: "Adding...",
                      error: "Failed to add!",
                    });
                  }}
                >
                  {({ submitForm }) => (
                    <div className="pl-lg-4">
                      <Row>
                        {[
                          {
                            title: "First Name",
                            name: "firstName",
                            placeHolder: "Ex: Mark",
                            id: "firstName",
                            required: true,
                            type: "text",
                          },
                          {
                            title: "Last Name",
                            name: "lastName",
                            placeHolder: "Ex: Cuban",
                            id: "lastName",
                            required: true,
                            type: "text",
                          },
                          {
                            title: "Email",
                            name: "email",
                            placeHolder: "Ex: test@bluewavetech.info",
                            id: "email",
                            required: true,
                            type: "email",
                          },
                          {
                            title: "Phone",
                            name: "phone",
                            placeHolder: "Ex: +17895672",
                            id: "phone",
                            required: true,
                            type: "text",
                          },
                          {
                            title: "Designation",
                            name: "position",
                            placeHolder: "Ex: Tech Manager",
                            id: "position",
                            required: true,
                            type: "text",
                          },
                        ].map(
                          ({
                            name,
                            title,
                            placeHolder,
                            type,
                            id,
                            required,
                            responsive = {
                              lg: 6,
                              sm: 12,
                            },
                          }: FormProps) => (
                            <Col {...responsive}>
                              <label
                                className="form-control-label"
                                htmlFor={`input-${name}`}
                              >
                                {title}
                              </label>
                              <Input
                                tag={Field}
                                className="form-control-alternative"
                                id={id}
                                name={name}
                                placeholder={placeHolder}
                                // @ts-ignore
                                type={type}
                                required={required}
                              />
                            </Col>
                          )
                        )}
                      </Row>
                      <Button
                        className="my-2"
                        color="primary"
                        type="submit"
                        disabled={addingPropertyContact}
                        onClick={submitForm}
                        size="sm"
                      >
                        {!addingPropertyContact ? "Add" : "Adding..."}
                      </Button>
                    </div>
                  )}
                </Formik>
                {(propertyContacts?.property_contacts?.length || 0) > 0 && (
                  <>
                    <hr className="my-4" />
                    <Table
                      itemActions={[{
                        heading: "Delete",
                        text: "Delete",
                        color: "danger",
                        requiresConfirmation: true,
                        setConfirmationMessage: ({ name }) =>
                          `Are you sure you want to Delete ${name}?`,
                        onClick: ({ id }) => {
                          toast.promise(
                            deleteContact({
                              variables: {
                                id,
                              },
                            }),
                            {
                              loading: "Deleting...",
                              success: "Deleted successfully!",
                              error: "Failed to delete!",
                            }
                          );
                        },
                      }]}
                      columns={COLUMNS}
                      data={
                        propertyContacts?.property_contacts.map((item) => ({
                          id: item.id,
                          name: `${item.firstName} ${item.lastName}`,
                          email: `${item.email}`,
                          phone: `${item.phone}`,
                          createdAt: item.createdAt,
                        })) || []
                      }
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AddContact;
