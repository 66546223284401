import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

type StatsCard = {
  name: string;
  icon?: string;
  value: string | number | undefined;
  bg?: string;
  weight?: number;
  to?: string;
}

const Header = ({ stats }: { stats?: StatsCard[] }) => (
  <>
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          {/* Card stats */}
          <Row>
            {
              stats?.map(({ name, icon="fas fa-chart-bar", value, bg="danger", weight=1, to }) => <Col tag={to ? Link : undefined} to={to} key={name} sm="6" lg="4" xl="3">
                  <Card className="card-stats mb-4">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {name}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{value}</span>
                        </div>
                        <Col className="col-auto">
                          <div className={`icon icon-shape bg-${bg} text-white rounded-circle shadow`}>
                            <i className={icon} />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              
              )
            }
          </Row>
        </div>
      </Container>
    </div>
  </>
);

export default Header;
