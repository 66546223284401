import Table, { ColumnDefinitionType } from "components/atoms/Table";
import {
  Order_By,
  useListAgenciesQuery,
  useDeleteAgencyMutation,
} from "generated/graphql";
import { useQuery } from "hooks/query";
import { toast } from "react-hot-toast";
import { ArrowFunction } from "typescript";
import {
  useUpdateAgencyStatusMutation,
  ListAgenciesDocument,
} from "../../../generated/graphql";
import { useHistory } from "react-router";

type AgencyRow = {
  id: string;
  name: string;
  totalAgents: number;
  totalAdmins: number;
  registeredOn: string;
  disabled: boolean;
  buttonText: string;
};

const COLUMNS: ColumnDefinitionType<AgencyRow, keyof AgencyRow>[] = [
  {
    key: "name",
    header: "Name",
  },
  {
    key: "totalAgents",
    header: "Total Officers",
  },
  {
    key: "totalAdmins",
    header: "Total Admins",
  },
  {
    key: "registeredOn",
    header: "Registered",
  },
];

const LIMIT = 10;

const Agencies = () => {
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const { data } = useListAgenciesQuery({
    variables: {
      limit: LIMIT,
      offset: page * LIMIT,
    },
  });
  const [updateAgencyStatus] = useUpdateAgencyStatusMutation();
  const history = useHistory();
  const [deleteAgency] = useDeleteAgencyMutation();

  return (
    <Table
      title="Agencies"
      columns={COLUMNS}
      data={
        data?.agencies.map((item) => {
          return {
            id: item.id,
            name: item.name,
            totalAgents: item.totalAgents.aggregate?.count,
            totalAdmins: item.totalAdmins.aggregate?.count,
            registeredOn: item.createdAt,
            buttonText: item.disabled ? "Enable" : "Disable",
            disabled: item.disabled,
          };
        }) || []
      }
      onClickItem={(item) => {
        history.push(`/admin/agencies/${item.id}`);
      }}
      itemActions={[
        {
          key: "buttonText",
          heading: "Options",
          color: "primary",
          requiresConfirmation: true,
          setConfirmationMessage: ({ buttonText }) =>
            `Are you sure you want to ${buttonText} this Agency?`,
          onClick: ({ id, disabled }) => {
            disabled = disabled ? false : true;
            toast.promise(
              updateAgencyStatus({
                variables: {
                  id,
                  disabled,
                },
                refetchQueries: [
                  {
                    query: ListAgenciesDocument,
                    variables: {
                      query: `%%`,
                      order: Order_By.Desc,
                      limit: 10,
                      offset: 0,
                    },
                  },
                ],
                update: (cache) => {
                  cache.evict({
                    id: "ROOT_QUERY",
                    fieldName: "agencies",
                  });
                },
              }),
              {
                loading: "Updating Status...",
                success: "Updated Status successfully!",
                error: "Failed to Update!",
              }
            );
          },
        },
        {
          heading: "Delete",
          text: "Delete",
          color: "danger",
          requiresConfirmation: true,
          confirmationKey: ({ name }) => `${name}`,
          setConfirmationMessage: ({ name }) =>
            `Enter the agency name below to delete ${name}:`,
          onClick: ({ id }) => {
            const action = (async () => {
              await deleteAgency({
                variables: {
                  id,
                },
              });
            })();
            toast.promise(action, {
              error: "Failed to Delete!",
              loading: "Deleting...!",
              success: "Deleted Successfully!",
            });
          },
        },
      ]}
      itemsPerPage={data?.totalAgencies.aggregate?.count || 1}
      totalPages={Math.ceil(
        (data?.totalAgencies.aggregate?.count || 0) / LIMIT
      )}
      actions={[
        {
          text: "Add",
          href: "/admin/agencies/add",
          color: "primary",
        },
      ]}
    />
  );
};

export default Agencies;
