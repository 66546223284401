// reactstrap components
import { SyntheticEvent } from "react";
import { Form as RSForm, FormProps } from "reactstrap";

interface Props extends FormProps {
  onFinish: (e: SyntheticEvent, data: object) => void;
}

const Form = ({ children, onFinish, ...props }: Props) => {
  function onSubmit(e: SyntheticEvent) {
    const data = Object.values(e.target)
      .filter(({ id }) => id)
      .reduce((acc, { id, value }) => ({ ...acc, [id.slice(6)]: value }), {});
    onFinish(e, data);
  }

  return (
    <RSForm onSubmit={onSubmit} {...props}>
      {children}
    </RSForm>
  );
};

export default Form;
