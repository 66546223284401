import React, { useEffect, useState } from "react";
import Form from "components/atoms/Form";
import { SyntheticEvent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useCreateAgencyMutation,
  useSetupAgencyResourcesMutation,
} from "generated/graphql";
import { withRouter } from "react-router";
import toast from "react-hot-toast";
import Radio from "components/atoms/Radio/Radio";

const AddAgency = withRouter(({ history }) => {
  const [
    createAgencyMutation,
    { loading: savingAgency, error: createAgencyError },
  ] = useCreateAgencyMutation();

  const [setupAgencyResourcesMutation, { loading: settingUpResources }] =
    useSetupAgencyResourcesMutation();

  const [selectedPlan, setSelectedPlan] = useState("PREMIER");

  async function onSubmit(e: SyntheticEvent, data: any) {
    e.preventDefault();
    const promise = async () => {
      const agency = await createAgencyMutation({
        variables: {
          name: data.name,
          agentFirstName: data["agent-first-name"],
          agentLastName: data["agent-last-name"],
          agentEmail: data["agent-email"],
          adminFirstName: data["admin-first-name"],
          adminLastName: data["admin-last-name"],
          adminEmail: data["admin-email"],
          noOfAgents: parseInt(data["agents"]),
          trespassStatement: data["trespass"],
          propertyName: data["property-name"],
          address1: data["address-1"] ?? "",
          address2: data["address-2"] ?? "",
          prefferedReportingMethod: data["preffered-reporting-method"],
          subscription: selectedPlan,
        },
      });

      await setupAgencyResourcesMutation({
        variables: {
          agencyId: agency.data?.insert_agency_metadata_one?.agency?.id,
        },
      });
    };

    toast.promise(promise(), {
      success: "Successfully Added",
      loading: "Adding..",
      error: "Failed to Add",
    });
    history.goBack();
  }
  return (
    <Row>
      <Col className="order-xl-1">
        <Form onFinish={onSubmit}>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add a new Agency</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    type="submit"
                    size="sm"
                    disabled={
                      !savingAgency && !settingUpResources ? false : true
                    }
                  >
                    {!savingAgency && !settingUpResources
                      ? "Save"
                      : "Saving..."}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <h6 className="heading-small text-muted mb-4">Agency Details</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="Ex. London Security Prevention"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        No. of Officers
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-agents"
                        placeholder="20"
                        type="number"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Trespass Statement
                      </label>
                      <Input
                        id="input-trespass"
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Radio
                      onChange={(selected) => {
                        setSelectedPlan(selected ?? "PREMIER");
                      }}
                      label="Choose a subscription"
                      options={[
                        {
                          label: "Premier",
                          value: "PREMIER",
                        },
                        {
                          label: "Premium",
                          value: "PREMIUM",
                        },
                        {
                          label: "Pro",
                          value: "PRO",
                        },
                      ]}
                      defaultValue={"PREMIER"}
                      name="subscription"
                    />
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
              <h6 className="heading-small text-muted mb-4">Admin Account</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-admin-first-name"
                      >
                        First name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-admin-first-name"
                        placeholder="John"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-admin-last-name"
                      >
                        Last name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-admin-last-name"
                        placeholder="Doe"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-admin-email"
                      >
                        Email address
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-admin-email"
                        placeholder="john@example.com"
                        type="email"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
              <h6 className="heading-small text-muted mb-4">Officer Account</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-agent-first-name"
                      >
                        First name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-agent-first-name"
                        placeholder="John"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-agent-last-name"
                      >
                        Last name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-agent-last-name"
                        placeholder="Doe"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-agent-email"
                      >
                        Email address
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-agent-email"
                        placeholder="john@example.com"
                        type="email"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
              <h6 className="heading-small text-muted mb-4">Properties</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-property-name"
                      >
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-property-name"
                        placeholder="Ex: Saint's Apartment"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-address-1"
                      >
                        Address 1
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-address-1"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-address-2"
                      >
                        Address 2
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-address-2"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-preffered-reporting-method"
                      >
                        Preffered Reporting Method
                      </label>
                      <Input
                        type="select"
                        name="prefferedReportingMethod"
                        id="input-preffered-reporting-method"
                      >
                        <option value="Email">Email</option>
                        <option value="SMS">SMS</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right" xs="12">
                    <Button color="primary" type="submit" size="sm">
                      {!savingAgency && !settingUpResources
                        ? "Save"
                        : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Form>
      </Col>
    </Row>
  );
});

export default AddAgency;
