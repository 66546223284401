import React, { PropsWithChildren } from "react";
import { useLocation, Switch, Redirect, Route } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Header from "components/Headers/UserHeader";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes, { FacescopeRoute } from "routes";

const Admin = (props: PropsWithChildren<any>) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    // @ts-ignore
    document.scrollingElement.scrollTop = 0;
    // @ts-ignore
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes: FacescopeRoute[]) =>
    routes.map((prop, key) => {
      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            exact={prop.exact}
            key={key}
          />
        );
      }
      return null;
    });

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes.filter((route) => !route.hideNav)}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/facescope-logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText()} />
        <Header />
        <Container className="mt--7" fluid>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
        </Container>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
