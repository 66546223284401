import { useEffect } from "react";
import Form from "components/atoms/Form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useGetAgencyLazyQuery,
  useUpdateAgencyMutation,
  useUpdateAgencySubscriptionMutation,
  ListAgenciesDocument
} from "generated/graphql";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Radio from "components/atoms/Radio/Radio";

const UpdateAgency = withRouter(({ history }) => {
  const [fetchAgency, { data, loading }] = useGetAgencyLazyQuery();
  const [updateAgencyMetadataMutation, { loading: updating }] =
    useUpdateAgencyMutation({
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "agencies",
        });
      },
    });

  const { id } = useParams<{ id: string }>();
  
  const [updateAgencySubscriptionMutation] =
    useUpdateAgencySubscriptionMutation({
      refetchQueries: [
        {
          query: ListAgenciesDocument,
          variables: {
            id: id,
          }
        }
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "agencies",
        });
      },
    });

  useEffect(() => {
    if (id)
      fetchAgency({
        variables: {
          id: id,
        },
      });
  }, [fetchAgency, id]);

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
        <Form
          onFinish={(e, data: any) => {
            e.preventDefault();
            const action = (async () => {
              if (id) {
                await updateAgencyMetadataMutation({
                  variables: {
                    agencyId: id,
                    noOfAgents: parseInt(data.agents),
                    trespassStatement: data.trespass,
                    name: data.name,
                  },
                });
              }
              history.goBack();
            })();
            toast.promise(action, {
              success: "Successfully Updated!",
              loading: "Updating...",
              error: "Failed to update!",
            });
          }}
        >
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Update an Agency</h3>
              </Col>
              <Col className="text-right" xs="4">
                <Button color="primary" type="submit" size="sm">
                  Save
                </Button>
              </Col>
            </Row>
          </CardHeader>
          {loading ? (
            <div>Please wait...</div>
          ) : (
            <>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Agency Details
                </h6>
                <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Ex. London Security Prevention"
                            type="text"
                            defaultValue={data?.agencies_by_pk?.name}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            No. of Officers
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-agents"
                            placeholder="20"
                            type="number"
                            defaultValue={
                              data?.agencies_by_pk?.agency_metadata[0]
                                .noOfAgents
                            }
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-trespass"
                          >
                            Trespas Statement
                          </label>
                          <Input
                            id="input-trespass"
                            className="form-control-alternative"
                            placeholder="A few words about you ..."
                            rows="4"
                            defaultValue={
                              data?.agencies_by_pk?.agency_metadata[0]
                                .trespassStatement
                            }
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                </div>
              </CardBody>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">Subscription</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <Radio
                        onChange={(selected) => {
                          if(selected!==data?.agencies_by_pk?.subscription){
                            toast.promise(
                              updateAgencySubscriptionMutation({
                                variables: {
                                  id: id,
                                  subscription: selected,
                                },
                              }),
                              {
                                success: `Subscription updated to ${selected}`,
                                loading: "Updating subscription...",
                                error: "Failed to update!",
                              }
                            );
                          }
                        }}
                        label="Choose a subscription"
                        options={[
                          {
                            label: "Premier",
                            value: "PREMIER",
                          },
                          {
                            label: "Premium",
                            value: "PREMIUM",
                          },
                          {
                            label: "Pro",
                            value: "PRO",
                          },
                        ]}
                        defaultValue={data?.agencies_by_pk?.subscription}
                        name="subscription"
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </>
          )}
          </Form>
        </Card>
      </Col>
    </Row>
  );
});

export default UpdateAgency;
