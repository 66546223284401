import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";

import Avatar from "react-avatar";
import {
  ListIncidentsDocument,
  ListAgentsDocument,
  useFetchPersonInfoLazyQuery,
  useGetIncidentTypesQuery,
  useListAgentsQuery,
  useListAllPropertiesQuery,
  useAddPersonIncidentMutation,
  useUpdatePersonIncidentMutation,
  useGetIncidentByIdLazyQuery,
  ListPersonDocument,
  Order_By,
  useListIncidentsLocationLazyQuery,
  GetIncidentByIdDocument,
} from "generated/graphql";
import { withRouter, useParams } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import Storage from "@aws-amplify/storage";
import Map from "components/atoms/Map/Map";
import Lightbox from "react-image-lightbox";

const AddTrespasserIncident = withRouter(({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { iid } = useParams<{ iid: string }>();
  // TODO: renaming this to id and updating the implementation was causing
  //             a bug of fields not automatically populating. Please look into it later
  const [isImageOpened, openImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [fetchPersons, { data: person_data }] = useFetchPersonInfoLazyQuery();
  const [fetchLocations, { data: locations }] =
    useListIncidentsLocationLazyQuery();
  const [incidentImage, setIncidentImage] = useState<String | null>(null);
  const incidentsData = useGetIncidentTypesQuery();
  const propertiesData = useListAllPropertiesQuery();
  const [defaultPropertyId, setDefaultPropertyId] = useState("");
  const [defaultIncidentId, setDefaultIncidentId] = useState("");
  const [incidentcord, setIncidentCord] = useState([]);

  const [addPersonIncidentMutation, { loading: addingIncident }] =
    useAddPersonIncidentMutation({
      refetchQueries: [
        {
          query: ListIncidentsDocument,
          variables: {
            personId: id,
            limit: 10,
            offset: 0,
          },
        },
        {
          query: ListPersonDocument,
          variables: {
            query: `%%`,
            order: Order_By.Desc,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "incidents",
        });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "persons",
        });
      },
    });

  const [updatePersonIncidentMutation, { loading: updating }] =
    useUpdatePersonIncidentMutation({
      refetchQueries: [
        {
          query: ListIncidentsDocument,
          variables: {
            personId: id,
            limit: 10,
            offset: 0,
          },
        },
      ],
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "incidents",
        });
      },
    });
  const [getIncidentById, data] = useGetIncidentByIdLazyQuery();

  const agentName = `${data?.data?.incidents_by_pk?.user?.firstName} ${data?.data?.incidents_by_pk?.user?.lastName}`;
  const accuracy = data?.data?.incidents_by_pk?.accuracy
    ? `${data?.data?.incidents_by_pk?.accuracy?.toFixed(2)}`
    : "";

  useEffect(() => {
    (async () => {
      if (id) {
        fetchPersons({
          variables: {
            id,
          },
        });
        fetchLocations({
          variables: {
            personId: id,
          },
        });
        const plate = data.data?.incidents_by_pk?.s3ImageKey
          ? (
              await Storage.get(
                data.data?.incidents_by_pk.s3ImageKey.replace("public/", "")
              )
            ).toString()
          : null;
        setIncidentImage(plate);
      }
    })();
  }, [fetchPersons, id, data, locations]);

  useEffect(() => {
    if (id)
      fetchPersons({
        variables: {
          id,
        },
      });
    if (iid) {
      getIncidentById({
        variables: { id: iid },
      });
    }

    setDefaultIncidentId(incidentsData.data?.incident_type[0].id);
    setDefaultPropertyId(propertiesData.data?.properties[0].id);
  }, [fetchPersons, id, getIncidentById, iid, incidentsData, propertiesData]);

  return (
    <Row>
      {isImageOpened && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => openImage(false)} />
      )}
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            property:
              data.data?.incidents_by_pk?.propertyId || defaultPropertyId,
            type:
              data.data?.incidents_by_pk?.typeId ||
              data.data?.incidents_by_pk?.type ||
              defaultIncidentId,
            note: iid ? data.data?.incidents_by_pk?.note : "",
            location: data?.data?.incidents_by_pk?.location,
          }}
          onSubmit={(values) => {
            const action = (async () => {
              const { property, type, note } = values;
              if (iid) {
                let variables = {
                  iid,
                  note,
                  typeId: type,
                  propertyId: property,
                  id,
                  location: data?.data?.incidents_by_pk?.location,
                };
                await updatePersonIncidentMutation({
                  variables,
                  refetchQueries: [
                    {
                      query: ListIncidentsDocument,
                      variables: {
                        personId: id,
                        limit: 10,
                        offset: 0,
                      },
                    },
                    {
                      query: GetIncidentByIdDocument,
                      variables: {
                        id: iid,
                      },
                    },
                  ],
                  update: (cache) => {
                    cache.evict({
                      id: "ROOT_QUERY",
                      fieldName: "incidents",
                    });
                  },
                });
              } else {
                await addPersonIncidentMutation({
                  variables: {
                    note,
                    typeId: type,
                    propertyId: property,
                    id,
                  },
                  refetchQueries: [
                    {
                      query: ListIncidentsDocument,
                      variables: {
                        personId: id,
                        limit: 10,
                        offset: 0,
                      },
                    },
                    {
                      query: ListPersonDocument,
                      variables: {
                        query: `%%`,
                        order: Order_By.Desc,
                      },
                    },
                  ],
                  update: (cache) => {
                    cache.evict({
                      id: "ROOT_QUERY",
                      fieldName: "incidents",
                    });
                    cache.evict({
                      id: "ROOT_QUERY",
                      fieldName: "persons",
                    });
                  },
                });
              }
              history.goBack();
            })();
            toast
              .promise(action, {
                success: `Successfully ${iid ? "Updated" : "Added"}!`,
                loading: `${iid ? "Updating" : "Adding"}...`,
                error: `Failed to ${iid ? "Update" : "Add"}!`,
              })
              .catch((e) => console.log(e));
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {iid ? "Update" : "Add new Incident"}
                    </h3>
                  </Col>

                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={!addingIncident && !updating ? false : true}
                    >
                      {!addingIncident && !updating ? "Save" : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Property
                      </label>
                      <Input
                        type="select"
                        onChange={(e) =>
                          setFieldValue("property", e.target.value)
                        }
                        value={values.property}
                        name="property"
                      >
                        {propertiesData.data?.properties.map((option) => (
                          <option value={option.id}>{option.name}</option>
                        ))}
                      </Input>
                    </Col>

                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Incident Type
                      </label>
                      <Input
                        type="select"
                        onChange={(e) => setFieldValue("type", e.target.value)}
                        value={values.type}
                        name="type"
                      >
                        {incidentsData.data?.incident_type.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Note
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="note"
                        name="note"
                        type="text"
                      />
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Accuracy{" "}
                      </label>
                      <Input
                        readOnly
                        value={accuracy}
                        tag={Field}
                        className="form-control-alternative"
                        id="accuracy"
                        name="accuracy"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Incident Image
                      </label>
                      <Col xs="1">
                        <Avatar
                          src={String(incidentImage)}
                          size="64"
                          color="red"
                          round
                          onClick={(e) => {
                            openImage(true);
                            e.preventDefault();
                            e.stopPropagation();
                            setImageSrc(String(incidentImage));
                          }}
                        />
                      </Col>
                    </Col>
                    {data.data?.incidents_by_pk?.user ? (
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Agent{" "}
                        </label>
                        <Input
                          readOnly
                          value={agentName}
                          tag={Field}
                          className="form-control-alternative"
                          id="note"
                          name="note"
                          type="text"
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>

        {data?.data?.incidents_by_pk?.location && (
          <Map
            locations={[data?.data?.incidents_by_pk?.location.coordinates]}
          />
        )}
      </Col>
    </Row>
  );
});

export default AddTrespasserIncident;
