import { useState } from "react";
import Table, {
  ColumnDefinitionType,
  TableActionType,
} from "components/atoms/Table";
import toast from "react-hot-toast";
import {
  Order_By,
  useListPropertiesByAgencyQuery,
  useDeletePropertyMutation,
  ListPropertiesDocument,
  useGeneratePropertyReportMutation,
} from "generated/graphql";
import { useHistory } from "react-router";
import { useQuery } from "hooks/query";
import { useParams } from "react-router-dom";

type Row = {
  id: string;
  name: string;
};

const COLUMNS: ColumnDefinitionType<Row, keyof Row>[] = [
  {
    key: "name",
    header: "Name",
  },
];

const LIMIT = 10;

const List = () => {
  const [search, setSearch] = useState("");
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data } = useListPropertiesByAgencyQuery({
    variables: {
      id: id,
    },
  });
  const [deleteProperty] = useDeletePropertyMutation({
    refetchQueries: [
      {
        query: ListPropertiesDocument,
        variables: {
          query: `%${search}%`,
          order: Order_By.Desc,
          limit: LIMIT,
          offset: page * LIMIT,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "properties",
      });
    },
  });
  const [generateReport] = useGeneratePropertyReportMutation();

  return (
    <Table
      title="Properties"
      columns={COLUMNS}
      data={
        data?.properties.map((item) => ({
          id: item.id,
          name: `${item.name}`,
          createdAt: item.createdAt,
        })) || []
      }
      onClickItem={(item) => {
        history.push(`/admin/properties/${item.id}`);
        console.log("Clicker");
        console.log(item);
        console.log(window.location.href);
      }}
      itemActions={[
        {
          heading: "Delete",
          text: "Delete",
          color: "danger",
          requiresConfirmation: true,
          setConfirmationMessage: ({ name }) =>
            `Are you sure you want to Delete ${name}?`,
          onClick: ({ id }) => {
            toast.promise(
              deleteProperty({
                variables: {
                  id,
                },
              }),
              {
                loading: "Deleting...",
                success: "Deleted successfully!",
                error: "Failed to delete!",
              }
            );
          },
        },
        {
          heading: "Generate Report",
          text: "Generate Report",
          color: "warning",
          onClick: ({ id: propertyId }) => {
            toast.promise(
              generateReport({
                variables: {
                  propertyId,
                },
              }),
              {
                loading: "Generating reports...",
                success: "Emails will be sent shortly!",
                error: "Failed to generate report!",
              }
            );
          },
        },
      ]}
      actions={[
        {
          text: "Add",
          href: `/admin/properties/add?agencyid=${id}`,
          color: "primary",
        },
        {
          text: "Search",
          type: TableActionType.TEXT,
          onChange: (e) => {
            setSearch(e.target.value);
          },
        },
      ]}
      itemsPerPage={data?.properties_aggregate?.aggregate?.count || 1}
      totalPages={Math.ceil(
        (data?.properties_aggregate.aggregate?.count || 0) / LIMIT
      )}
    />
  );
};

export default List;
