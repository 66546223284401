import UpdateAgencyForm from "views/Admin/Agencies/AgencyTabs/Update";
import ListAgents from "views/Admin/Agencies/AgencyTabs/ListAgents";
import ListAdmins from "views/Admin/Agencies/AgencyTabs/ListAdmins";
import ListProperties from "views/Admin/Agencies/AgencyTabs/ListProperties";
import Tab from "components/atoms/Tab/Tab";

const UpdateAgency = () => (
  <Tab
    tabs={[
      {
        name: "Update Agency",
        component: <UpdateAgencyForm />,
      },
      {
        name: "Admins",
        component: <ListAdmins />,
      },
      {
        name: "Agents",
        component: <ListAgents />,
      },
      {
        name: "Properties",
        component: <ListProperties />,
      },
    ]}
  />
);

export default UpdateAgency;
