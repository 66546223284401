import React from "react";
import {
  useListIncidentsQuery,
  useAggregateIncidentsQuery,
} from "generated/graphql";
import { useParams } from "react-router";
import { useQuery } from "hooks/query";
import Table, { ColumnDefinitionType } from "components/atoms/Table/Table";

type PersonRow = {
  id: string;
  property: string;
  firstName: string;
  lastName: string;
};

const PERSONSCOLUMNS: ColumnDefinitionType<PersonRow, keyof PersonRow>[] = [
  {
    key: "property",
    header: "Property",
  },
  {
    key: "firstName",
    header: "firstName",
  },
  {
    key: "lastName",
    header: "lastName",
  },
];

const PropertyTraspassers = () => {
  const queryParam = useQuery();
  const page = parseInt(queryParam.get("page") || "1") - 1;
  const LIMIT = 10;
  const { id } = useParams<{ id: string }>();

  const { data: propertyIncidents } = useListIncidentsQuery({
    variables: {
      propertyId: `${id}`,
      limit: 10,
      offset: 0,
    },
  });
  const { data: aggregateIncidents } = useAggregateIncidentsQuery({
    variables: {
      propertyId: `${id}`,
    },
  });

  return (
    <div>
      <Table
        title="Incidents"
        columns={PERSONSCOLUMNS}
        data={
          propertyIncidents?.incidents.map((incident) => ({
            id: incident.id,
            property: incident.property?.name,
            lastName: incident.personByPerson?.lastName,
            firstName: incident.personByPerson?.firstName,
          })) || []
        }
        itemsPerPage={
          aggregateIncidents?.incidents_aggregate.aggregate?.count || 1
        }
        totalPages={Math.ceil(
          (aggregateIncidents?.incidents_aggregate.aggregate?.count || 0) /
            LIMIT
        )}
      />
    </div>
  );
};

export default PropertyTraspassers;
