import { useContext, useEffect, useState } from "react";
import Table, {
  ColumnDefinitionType,
  TableActionType,
} from "components/atoms/Table";
import {
  ListPersonDocument,
  Order_By,
  useListAgenciesQuery,
  useListPropertiesForAgencyFilterQuery,
  useListPersonLazyQuery,
  useDeletePersonMutation,
  useListPersonForAgencyFilterQuery,
  useListPersonForPropertyFilterQuery,
} from "generated/graphql";
import toast from "react-hot-toast";
import { withRouter, useHistory } from "react-router";
import { ListAsEnumToString } from "utils/constants";
import { useQuery } from "hooks/query";
import Storage from "@aws-amplify/storage";
import AppContext from "contexts/AppContext";

type Row = {
  id: string;
  name: string;
  listedAs: string;
  totalIncidents: number | undefined;
  createdAt: string;
  image: string;
};

const COLUMNS: ColumnDefinitionType<Row, keyof Row>[] = [
  {
    key: "image",
    header: "Image",
  },
  {
    key: "name",
    header: "Name",
  },
  {
    key: "listedAs",
    header: "Listed As",
  },
  {
    key: "totalIncidents",
    header: "Total Incidents",
  },
  {
    key: "createdAt",
    header: "Added On",
  },
];

const LIMIT = 10;

const List = () => {
  const queryParam = useQuery();
  const history = useHistory();
  const page = parseInt(queryParam.get("page") || "1") - 1;
  const [useQueries, setUseQueries] = useState("");
  const [query, setQuery] = useState("");
  const [property, setProperty] = useState({
    id: "",
    name: "Property",
  });
  const { user } = useContext(AppContext);
  const [agency, setAgency] = useState("");
  const [getPersons, { data, loading }] = useListPersonLazyQuery({
    variables: {
      limit: LIMIT,
      offset: page * LIMIT,
      query: `%${query}%`,
      order: Order_By.Desc,
    },
  });
  const {
    data: listPersonsForPropertyFilter,
    loading: loadingListPersonsForPropertyFilter,
  } = useListPersonForPropertyFilterQuery({
    variables: {
      limit: LIMIT,
      offset: page * LIMIT,
      propertyId: property.id,
      query: `%${query}%`,
    },
  });
  const {
    data: listPersonsForAgencyFilter,
    loading: loadinglistPersonsForAgencyFilter,
  } = useListPersonForAgencyFilterQuery({
    variables: {
      limit: LIMIT,
      offset: page * LIMIT,
      agencyId: agency,
      query: `%${query}%`,
    },
  });
  const { data: listAgency } = useListAgenciesQuery();
  const { data: listPropertiesForSubjects } =
    useListPropertiesForAgencyFilterQuery({
      variables: {
        agencyId: agency,
      },
    });

  const [deletePerson] = useDeletePersonMutation({
    refetchQueries: [
      {
        query: ListPersonDocument,
        variables: {
          limit: LIMIT,
          offset: page * LIMIT,
          query: `%${query}%`,
          order: Order_By.Desc,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "persons",
      });
    },
  });

  const [itemData, setItemData] = useState<Array<any>>([]);
  useEffect(() => {
    (async () => {
      if (user?.role === "agencyadmins") {
        setAgency(user.agencyId);
      }

      getPersons();

      switch (useQueries) {
        case "agency": {
          let temp = await Promise.all(
            listPersonsForAgencyFilter?.persons.map(async (item) => ({
              id: item.id,
              image: item.s3ImageKey && (await Storage.get(item.s3ImageKey)),
              name: `${item.firstName} ${item.lastName}`,
              listedAs: ListAsEnumToString[`${item.listedAs}`],
              totalIncidents: item.incidents_aggregate.aggregate?.count,
              createdAt: item.createdAt,
            })) || []
          );

          setItemData(temp);
          break;
        }
        case "property": {
          let temp = await Promise.all(
            listPersonsForPropertyFilter?.persons.map(async (item) => ({
              id: item.id,
              image: item.s3ImageKey && (await Storage.get(item.s3ImageKey)),
              name: `${item.firstName} ${item.lastName}`,
              listedAs: ListAsEnumToString[`${item.listedAs}`],
              totalIncidents: item.incidents_aggregate.aggregate?.count,
              createdAt: item.createdAt,
            })) || []
          );

          setItemData(temp);
          break;
        }
        default: {
          let temp = await Promise.all(
            data?.persons.map(async (item) => ({
              id: item.id,
              name: `${item.firstName} ${item.lastName}`,
              image: item.s3ImageKey && (await Storage.get(item.s3ImageKey)),
              listedAs: ListAsEnumToString[`${item.listedAs}`],
              totalIncidents: item.incidents_aggregate.aggregate?.count,
              createdAt: item.createdAt,
            })) || []
          );

          setItemData(temp);
          break;
        }
      }
    })();
  }, [
    data,
    listPersonsForPropertyFilter,
    listPersonsForAgencyFilter,
    listPropertiesForSubjects,
  ]);

  return (
    <Table
      title="Persons"
      columns={COLUMNS}
      data={itemData}
      itemActions={[
        {
          heading: "Delete",
          text: "Delete",
          color: "danger",
          requiresConfirmation: true,
          setConfirmationMessage: ({ name }) =>
            `Are you sure you want to Delete ${name}?`,
          onClick: ({ id }) => {
            toast.promise(
              deletePerson({
                variables: {
                  id,
                },
              }),
              {
                loading: "Deleting...",
                success: "Deleted successfully!",
                error: "Failed to delete!",
              }
            );
          },
        },
      ]}
      actions={[
        {
          text: "Add",
          href: "/admin/subjects/add",
          color: "primary",
          hide: user?.role === "superadmins",
        },
        {
          text: "Properties",
          type: TableActionType.SELECT,
          options: listPropertiesForSubjects?.properties.map(
            ({ name, id }) => ({
              label: name,
              value: id,
            })
          ),
          value: { label: property.name, value: property.id },

          onSelectChange: (value) => {
            if (value) {
              console.log(value);
              setProperty({ name: value.label || "--", id: value?.value });
              setUseQueries("property");
              history.replace({
                search: "",
              });
            }
          },
        },
        {
          text: "Agencies",
          type: TableActionType.SELECT,
          hide: user?.role === "agencyadmins" ? true : false,
          options: listAgency?.agencies.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          onSelectChange: (value) => {
            if (value) {
              setAgency(value?.value);
              setProperty({ ...property, name: "" });
              setUseQueries("agency");
              history.replace({
                search: "",
              });
            }
          },
        },
        {
          text: "Search",
          type: TableActionType.TEXT,
          onChange: (e) => {
            setQuery(e.target.value);
          },
        },
      ]}
      onClickItem={(item) => {
        history.push(`/admin/subjects/${item.id}`);
      }}
      dataLoading={
        loading ||
        loadingListPersonsForPropertyFilter ||
        loadinglistPersonsForAgencyFilter
      }
      itemsPerPage={
        useQueries === "agency"
          ? listPersonsForAgencyFilter?.persons_aggregate.aggregate?.count || 1
          : useQueries === "property"
          ? listPersonsForPropertyFilter?.persons_aggregate.aggregate?.count ||
            1
          : data?.persons_aggregate.aggregate?.count || 1
      }
      totalPages={Math.ceil(
        useQueries === "agency"
          ? (listPersonsForAgencyFilter?.persons_aggregate.aggregate?.count ||
              0) / LIMIT
          : useQueries === "property"
          ? (listPersonsForPropertyFilter?.persons_aggregate.aggregate?.count ||
              0) / LIMIT
          : (data?.persons_aggregate.aggregate?.count || 0) / LIMIT
      )}
    />
  );
};

export default List;
