/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Agencies from "views/Admin/Agencies";
import Trespassers from "views/Admin/Trespassers";
import UpdateTrespassers from "views/Admin/Trespassers/Update";
import Plates from "views/Admin/Plates";
import AddPlate from "views/Admin/Plates/Add";
import UpdatePlate from "views/Admin/Plates/Update";
import Users from "views/Admin/Users";
import AddUsers from "views/Admin/Users/Add";
import AddAgents from "views/Admin/Agents/Add";
import Agents from "views/Admin/Agents";
import AddFTPuser from "views/Admin/FTPuser/Add";
import FTPuser from "views/Admin/FTPuser";
import UpdateAgency from "views/Admin/Agencies/Update";
import Properties from "views/Admin/Properties";
import AddProperties from "views/Admin/Properties/Add";
import AddIncidentsType from "views/Admin/IncidentTypes/AddPersonIncidentType";
import AddLPRIncidentType from "views/Admin/IncidentTypes/AddLPRIncidentType";
import UpdateProperties from "views/Admin/Properties/Update";
import AddAgencies from "views/Admin/Agencies/Add";
import Dashboard from "views/Admin/Dashboard";
import IncidentsTypes from "views/Admin/IncidentTypes";
import Datastores from "views/Admin/Datastores";
import AddDataStoreAccess from "views/Admin/Datastores/Add";
import AddTrespasserIncident from "views/Admin/Trespassers/AddIncident";
import { ComponentClass, FunctionComponent } from "react";
import AddTrespasser from "views/Admin/Trespassers/Add";
import AddPlateIncident from "./views/Admin/Plates/AddPlateIncident";

export interface FacescopeRoute {
  path?: string;
  name: string;
  icon: string;
  component?: FunctionComponent | ComponentClass;
  layout: string;
  exact?: boolean;
  hideNav?: boolean;
  order?: number;
  link?: string;
}

export const agencyAdminRoutes: FacescopeRoute[] = [
  {
    path: "/officers/add",
    name: "Add Officer",
    icon: "ni ni-atom text-red",
    component: AddAgents,
    layout: "/admin",
    hideNav: true,
    exact: true,
  },
  {
    path: "/officers/:id",
    name: "Update Officer",
    icon: "ni ni-atom text-red",
    component: AddAgents,
    layout: "/admin",
    hideNav: true,
    exact: true,
  },
  {
    path: "/officers",
    name: "Officers",
    icon: "ni ni-atom text-red",
    component: Agents,
    layout: "/admin",
    exact: true,
    order: 8,
  },

  {
    path: "/ftpuser/add",
    name: "Add FTPuser",
    icon: "ni ni-atom text-red",
    component: AddFTPuser,
    layout: "/admin",
    hideNav: true,
    exact: true,
  },
  {
    path: "/ftpuser/:id",
    name: "Update FTPuser",
    icon: "ni ni-atom text-red",
    component: AddFTPuser,
    layout: "/admin",
    hideNav: true,
    exact: true,
  },
  {
    path: "/ftpuser",
    name: "FTPuser",
    icon: "ni ni-atom text-red",
    component: FTPuser,
    layout: "/admin",
    exact: true,
    order: 8,
  },
  {
    path: "/properties",
    name: "Properties",
    icon: "ni ni-atom text-red",
    component: Properties,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/properties/add",
    name: "Properties",
    icon: "ni ni-atom text-red",
    component: AddProperties,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/properties/:id",
    name: "Properties",
    icon: "ni ni-atom text-red",
    component: UpdateProperties,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/subject/:id/incident/add",
    name: "Add Trespasser Incident",
    icon: "ni ni-atom text-red",
    component: AddTrespasserIncident,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/plate/:id/incident/add",
    name: "Add Plate Incident",
    icon: "ni ni-atom text-red",
    component: AddPlateIncident,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/plate/:id/incident/:iid",
    name: "Update Plate Incident",
    icon: "ni ni-atom text-red",
    component: AddPlateIncident,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/subject/:id/incident/:iid",
    name: "Update Trespasser Incident",
    icon: "ni ni-atom text-red",
    component: AddTrespasserIncident,
    layout: "/admin",
    hideNav: true,
  },
  {
    link: "https://bluewavetech.io/my-account/",
    name: "Billing",
    icon: "fa fa-credit-card text-red",
    order: -1,
    layout: "/admin",
    exact: true,
  },
];

export const adminRoutes: FacescopeRoute[] = [
  {
    path: "/agencies/add",
    name: "Add Agency",
    icon: "ni ni-atom text-red",
    component: AddAgencies,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/agencies/:id",
    name: "Agencies",
    icon: "ni ni-atom text red",
    component: UpdateAgency,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/agencies",
    name: "Agencies",
    icon: "ni ni-atom text-red",
    component: Agencies,
    layout: "/admin",
    exact: true,
    order: 8,
  },
  {
    path: "/properties/add",
    name: "Properties",
    icon: "ni ni-atom text-red",
    component: AddProperties,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/properties/:id",
    name: "Properties",
    icon: "ni ni-atom text-red",
    component: UpdateProperties,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/officers/add",
    name: "Add Officer",
    icon: "ni ni-atom text-red",
    component: AddAgents,
    layout: "/admin",
    hideNav: true,
    exact: true,
  },
];

const routes: FacescopeRoute[] = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
    order: 10,
  },
  {
    path: "/subjects/add",
    name: "Persons",
    icon: "ni ni-atom text-red",
    component: AddTrespasser,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/subjects",
    name: "Persons",
    icon: "ni ni-atom text-red",
    component: Trespassers,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/subjects/:id",
    name: "Persons",
    icon: "ni ni-atom text-red",
    component: UpdateTrespassers,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/plates",
    name: "Plates",
    icon: "ni ni-atom text-red",
    component: Plates,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/plates/add",
    name: "Plate",
    icon: "ni ni-atom text-red",
    component: AddPlate,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/plates/:id",
    name: "Plate",
    icon: "ni ni-atom text-red",
    component: UpdatePlate,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/users",
    name: "Admins",
    icon: "ni ni-atom text-red",
    component: Users,
    order: 9,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/users/add",
    name: "Admins",
    icon: "ni ni-atom text-red",
    component: AddUsers,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/users/:id",
    name: "Admins",
    icon: "ni ni-atom text-red",
    component: AddUsers,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/datastores",
    name: "Datastores",
    icon: "ni ni-atom text-red",
    component: Datastores,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/datastores/:type/:id",
    name: "Grant Access",
    icon: "ni ni-atom text-red",
    component: AddDataStoreAccess,
    layout: "/admin",
    exact: true,
    hideNav: true,
  },
  {
    path: "/incidents-type",
    name: "Incidents Type",
    icon: "ni ni-atom text-red",
    component: IncidentsTypes,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/incidents-type/add/:id",
    name: "Update Incident Type",
    icon: "ni ni-atom text-red",
    component: AddIncidentsType,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/incidents-type/add",
    name: "Add Incident Type",
    icon: "ni ni-atom text-red",
    component: AddIncidentsType,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/incidents-type/lpr-add/:id",
    name: "Update LPR Incident Type",
    icon: "ni ni-atom text-red",
    component: AddLPRIncidentType,
    layout: "/admin",
    hideNav: true,
  },
  {
    path: "/incidents-type/lpr-add",
    name: "Add LPR Incident Type",
    icon: "ni ni-atom text-red",
    component: AddLPRIncidentType,
    layout: "/admin",
    hideNav: true,
  },
];

export default routes;
