import Table, { ColumnDefinitionType } from "components/atoms/Table";
import { 
  useListAgentsQuery,
  useDeleteUserMutation,
  ListAgentsDocument,
  Order_By
} from "generated/graphql";
import { useQuery } from "hooks/query";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router";

type AgentRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  agencyId: string;
};

const COLUMNS: ColumnDefinitionType<AgentRow, keyof AgentRow>[] = [
  {
    key: "firstName",
    header: "First Name",
  },
  {
    key: "lastName",
    header: "Last Name",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "role",
    header: "Role",
  },
];

const Agents = () => {
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { data } = useListAgentsQuery({
    variables: {
      agencyId: id,
    },
  });

  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [
      {
        query: ListAgentsDocument,
        variables: {
          agencyId: id
        },
      },
    ],
  });
  const LIMIT = 10;

  return (
    <Table
    itemActions={[
      {
        heading: "Delete",
        text: "Delete",
        color: "danger",
        requiresConfirmation: true,
        setConfirmationMessage: ({ firstName, lastName }) =>
          `Are you sure you want to Delete ${firstName} ${lastName}?`,
        onClick: ({ id }) => {
          toast.promise(
            deleteUser({
              variables: {
                id,
              },
            }),
            {
              loading: "Deleting...",
              success: "Deleted successfully!",
              error: "Failed to delete!",
            }
          );
        },
      },
    ]}
      columns={COLUMNS}
      data={
        data?.users.map((item) => {
          return {
            id: item.id,
            agencyId: item.agencyId,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            role: item.role,
          };
        }) || []
      }
      actions={[
        {
          text: "Add",
          href: `/admin/officers/add?agencyid=${id}`,
          color: "primary",
        }
      ]}
      onClickItem={(item) => {
        history.push(`/admin/users/${item.id}`);
      }}
      title="Agents"
      itemsPerPage={data?.totalAgents.aggregate?.count || 1}
      totalPages={Math.ceil((data?.totalAgents.aggregate?.count || 0) / LIMIT)}
    />
  );
};

export default Agents;
