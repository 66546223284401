import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Media,
  Row,
  Col,
  Label,
} from "reactstrap";
import {
  useAddPlateMutation,
  useUpdatePlateMutation,
  useFetchPlateInfoLazyQuery,
  useListIncidentsLocationLazyQuery,
} from "generated/graphql";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Field, Formik } from "formik";
import { ListAs } from "utils/constants";
import Storage from "@aws-amplify/storage";
import toast from "react-hot-toast";
import Avatar from "react-avatar";
import { borderRadius } from "react-select/src/theme";
import Lightbox from "react-image-lightbox";
import Map from "components/atoms/Map/Map";

const UpdatePlate = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isImageOpened, openImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [vehicleImages, setVehcileImages] = useState<String[] | []>([]);
  const [plateImage, setPlateImage] = useState<String | null>(null);
  const [fetchLocations, { data: locations }] =
    useListIncidentsLocationLazyQuery();
  const [fetchPlate, { data }] = useFetchPlateInfoLazyQuery();

  const [addPlateMutation, { loading: addingPlate }] = useAddPlateMutation();

  const [updatePlateMutation, { loading: updatingPlate }] =
    useUpdatePlateMutation();
  useEffect(() => {
    (async () => {
      if (id) {
        fetchPlate({
          variables: {
            id,
          },
        });
        fetchLocations({
          variables: {
            plateId: id,
          },
        });
        const plate = data?.plates_by_pk?.s3ImageKey
          ? (await Storage.get(data.plates_by_pk.s3ImageKey)).toString()
          : null;
        setPlateImage(plate);
        const urls = await Promise.all(
          data?.plates_by_pk?.lpr_images.map(async (i) => {
            return (
              await Storage.get(i.s3ImageKey.replace("public/", ""))
            ).toString();
          }) || []
        );
        setVehcileImages(urls);
      }
    })();
  }, [fetchPlate, id, data]);

  return (
    <>
      {isImageOpened && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => openImage(false)} />
      )}
      <Row>
        <Col className="order-xl-1">
          <Formik
            enableReinitialize={true}
            initialValues={{
              number: data?.plates_by_pk?.number?.toString(),
              displayNumber: data?.plates_by_pk?.displayNumber?.toString(),
              listedAs: data?.plates_by_pk?.listedAs ?? ListAs.NONE.toString(),
              knownToBeViolent: data?.plates_by_pk?.knownToBeViolent,
            }}
            onSubmit={(values) => {
              const action = (async () => {
                const { number, displayNumber, listedAs } = values;
                if (id) {
                  await updatePlateMutation({
                    variables: {
                      id,
                      number,
                      displayNumber,
                      listAs: listedAs,
                      knownToBeViolent: values.knownToBeViolent,
                    },
                  });
                }
                history.goBack();
              })();
              toast.promise(action, {
                success: "Successfully Added!",
                loading: "Adding...",
                error: "Failed to add!",
              });
            }}
          >
            {({ values, setFieldValue, handleChange, submitForm }) => (
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {id ? "Update" : "Add new Plate Details"}
                      </h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        type="submit"
                        onClick={submitForm}
                        size="sm"
                        disabled={!addingPlate && !updatingPlate ? false : true}
                      >
                        {!addingPlate && !updatingPlate ? "Save" : "Saving..."}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <Media
                          middle
                          style={{
                            borderRadius: 20,
                            width: "100%",
                            objectFit: "cover",
                            height: "20vh",
                            cursor: "pointer",
                            marginTop: "1rem",
                          }}
                          src={String(plateImage)}
                          onClick={(e) => {
                            openImage(true);
                            e.preventDefault();
                            e.stopPropagation();
                            setImageSrc(String(plateImage));
                          }}
                        />
                      </Col>
                      <Col lg="9">
                        <Row>
                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Display Number
                            </label>
                            <Input
                              tag={Field}
                              className="form-control-alternative"
                              id="displayNumber"
                              name="displayNumber"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  "number",
                                  `${e.target.value
                                    .replace(/[^A-Z0-9]/gi, "")
                                    .toUpperCase()}`
                                );
                              }}
                              placeholder="LPY 87-98"
                              type="text"
                              required
                            />
                          </Col>
                          <Col lg="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Number
                            </label>
                            <Input
                              tag={Field}
                              className="form-control-alternative"
                              id="number"
                              name="number"
                              placeholder="LPY8798"
                              disabled
                              type="text"
                              required
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              List As
                            </label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setFieldValue("listedAs", e.target.value)
                              }
                              value={values.listedAs}
                              name="listedAs"
                            >
                              {[
                                {
                                  name: "None",
                                  value: ListAs.NONE,
                                },
                                {
                                  name: "Not Authorized",
                                  value: ListAs.BOLO,
                                },
                                {
                                  name: "Authorized",
                                  value: ListAs.WHITELIST,
                                },
                              ].map((option) => (
                                <option value={option.value.toString()}>
                                  {option.name}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" style={{ padding: "10px 0 0 35px" }}>
                            <Input
                              type={"checkbox"}
                              checked={values.knownToBeViolent}
                              onChange={(e) =>
                                setFieldValue(
                                  "knownToBeViolent",
                                  e.target.checked
                                )
                              }
                            />
                            <Label check>Known to be violent</Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {id ? (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Vehicle Images
                      </h6>
                      <Row>
                        {vehicleImages.length > 0 ? (
                          vehicleImages.map((image: String) => (
                            <Col xs={12} sm={4} md={4} lg={2}>
                              <Media
                                middle
                                style={{
                                  borderRadius: 20,
                                  width: "100%",
                                  cursor: "pointer",
                                  marginTop: "1rem",
                                }}
                                src={String(image)}
                                onClick={(e) => {
                                  openImage(true);
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setImageSrc(String(image));
                                }}
                              />
                            </Col>
                          ))
                        ) : (
                          <Col>
                            <p>There are no Images for this Vehicle</p>
                          </Col>
                        )}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            )}
          </Formik>
          <Map
            locations={locations?.lpr_incidents
              .map((i) => i.location?.coordinates)
              .filter((i) => i != null)}
          />
        </Col>
      </Row>
    </>
  );
};

export default UpdatePlate;
