import AddPerson from "views/Admin/Trespassers/TrespasserForm/AddTrespasser";
import TrespasserIncident from "views/Admin/Trespassers/TrespasserForm/TrespasserIncidents";

import Tab from "components/atoms/Tab/Tab";

const UpdatePerson = () => (
  <Tab
    tabs={[
      {
        name: "Overview",
        component: <AddPerson />,
      },
      {
        name: "Incidents",
        component: <TrespasserIncident />,
      },
    ]}
  />
);

export default UpdatePerson;
