import React, { Component, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import menuIcon from "assets/img/icons/common/menu.png";
import MarkerIcon from "assets/img/icons/common/marker.png";

const Marker = ({ lat, lng }: { lat: number; lng: number }) => (
  <div>
    <img src={MarkerIcon} alt="" />
  </div>
);

const Map = ({ locations }: { locations: number[][] | undefined }) => {
  const [cords, setCords] =
    useState<null | { long: number; lat: number }>(null);

  useEffect(() => {
    console.log(locations, "Locations");

    if (locations && locations.length > 0) {
      const [cordFromLocation] = locations;
      setCords({
        long: cordFromLocation[0],
        lat: cordFromLocation[1],
      });
    }
  }, [locations]);

  return (
    <div style={{ height: "400px", width: "100%" }}>
      {cords && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
          }}
          defaultCenter={{ lng: cords?.lat || 35, lat: cords?.long || 55 }}
          defaultZoom={10}
        >
          {locations?.map((i) =>
            i ? <Marker lat={Number(i[0])} lng={i[1]} /> : <></>
          )}
        </GoogleMapReact>
      )}
    </div>
  );
};

export default Map;
