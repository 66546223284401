/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:01d5d77c-f82e-4d2a-a987-76c2d299e2f7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JeEskW3jt",
    "aws_user_pools_web_client_id": "2o7a8r42e5eejrmojcfc00775e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "actions",
            "endpoint": "https://5pu0grc9a2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "facescope-datastore83336-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
