import PersonIncidentsTypes from "views/Admin/IncidentTypes/PersonIncidentsTypes";
import PlateIncidentsTypes from "views/Admin/IncidentTypes/PlateIncidentsTypes";
import Tab from "components/atoms/Tab/Tab";

const IncidentTypes = () => (
  <Tab
    tabs={[
      {
        name: "Person",
        component: <PersonIncidentsTypes />,
      },
      {
        name: "Plates",
        component: <PlateIncidentsTypes />,
      },
    ]}
  />
);

export default IncidentTypes;
