import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useFetchUserInfoLazyQuery,
  useListAgencyUserViewQuery,
  ListAgencyUserViewDocument,
  ListUsersDocument,
  Order_By,
} from "generated/graphql";
import { withRouter, useParams, useHistory } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import AppContext from "contexts/AppContext";
import Switch from "components/atoms/Switch/Switch";

const AddUser = withRouter(() => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const agencyId = new URLSearchParams(window.location.search).get("agencyid");
  const [fetchUsers, { data }] = useFetchUserInfoLazyQuery();

  const [disableNotifications, setDisableNotifications] = useState(false);

  const handleToggle = () => {
    setDisableNotifications(!disableNotifications);
  };

  const { user } = useContext(AppContext);

  const [addUserMutation, { loading: adding }] = useCreateUserMutation({
    refetchQueries: [
      {
        query: ListUsersDocument,
        variables: {
          query: ``,
          order: Order_By.Desc,
          role: user?.role === "agencyadmins" ? "agencyadmins" : "superadmins",
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "users",
      });
    },
  });

  const [updateUserMutation, { loading: updating }] = useUpdateUserMutation();

  const { data: agencyUserView } = useListAgencyUserViewQuery();

  useEffect(() => {
    if (id) {
      fetchUsers({
        variables: {
          id,
        },
      });

      setDisableNotifications(
        data?.users_by_pk?.disableNotifications
          ? data?.users_by_pk?.disableNotifications
          : false
      );
    }
  }, [fetchUsers, id, data]);

  return (
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: data?.users_by_pk?.firstName?.toString(),
            lastName: data?.users_by_pk?.lastName?.toString(),
            email: data?.users_by_pk?.email.toString(),
            disableNotifications: data?.users_by_pk?.disableNotifications,
          }}
          onSubmit={(values) => {
            const action = (async () => {
              const { firstName, lastName, email } = values;
              if (id) {
                await updateUserMutation({
                  variables: {
                    id,
                    firstName,
                    lastName,
                    disableNotifications,
                  },
                });
              } else {
                if (
                  user?.role === "superadmins" ||
                  (agencyUserView?.agency_users_view[0].noOfAgents &&
                    agencyUserView?.agency_users_view[0].user_count <
                      agencyUserView?.agency_users_view[0].noOfAgents)
                ) {
                  await addUserMutation({
                    variables: {
                      firstName,
                      lastName,
                      email,
                      disableNotifications,
                      role:
                        user?.role === "agencyadmins"
                          ? "agencyadmins"
                          : agencyId
                          ? "agencyadmins"
                          : "superadmins",
                      agencyId: user?.agencyId ? user.agencyId : agencyId,
                    },
                  });
                } else {
                  toast.error("Can't add more users");
                }
              }
              history.goBack();
            })();
            if (
              agencyUserView?.agency_users_view[0].noOfAgents &&
              agencyUserView?.agency_users_view[0].user_count <
                agencyUserView?.agency_users_view[0].noOfAgents
            ) {
              toast.promise(action, {
                success: `Successfully ${id ? "Updated" : "Added"}!`,
                loading: `${id ? "Updating" : "Adding"}...`,
                error: `Failed to ${id ? "Update" : "Add"}!`,
              });
            }
          }}
        >
          {({ submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {id ? "Update" : "Add new User Details"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={!adding && !updating ? false : true}
                    >
                      {!adding && !updating ? "Save" : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        First Name
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="firstName"
                        name="firstName"
                        placeholder="John"
                        type="text"
                        required
                      />
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Last Name
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="lastName"
                        name="lastName"
                        placeholder="Doe"
                        type="text"
                        required
                      />
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Email
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="email"
                        name="email"
                        placeholder="john@doe.com"
                        disabled={!!id}
                        type="text"
                        required
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Disable Notifications
                      </label>

                      <Switch
                        isOn={disableNotifications}
                        handleToggle={handleToggle}
                        key={id}
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </Col>
    </Row>
  );
});

export default AddUser;
