import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import {
  useUpdatePersonMutation,
  useFetchPersonInfoLazyQuery,
  useListIncidentsLocationLazyQuery,
} from "generated/graphql";
import Map from "components/atoms/Map/Map";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Field, Formik } from "formik";
import { ListAs } from "utils/constants";
import toast from "react-hot-toast";
import Avatar from "react-avatar";
import Lightbox from "react-image-lightbox";
import Storage from "@aws-amplify/storage";
import ReactAudioPlayer from "react-audio-player";

const AddPerson = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [fetchPersons, { data }] = useFetchPersonInfoLazyQuery();
  const [fetchLocations, { data: locations }] =
    useListIncidentsLocationLazyQuery();
  const [personImage, setPersonImage] = useState<String | null>(null);
  const [isImageOpened, openImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [audioStatement, setAudioStatement] = useState<String | null>(null);

  const [updatePersonMutation, { loading: updatingPlate }] =
    useUpdatePersonMutation();

  useEffect(() => {
    (async () => {
      if (id) {
        fetchPersons({
          variables: {
            id,
          },
        });
        fetchLocations({
          variables: {
            personId: id,
          },
        });
        const plate = data?.persons_by_pk?.s3ImageKey
          ? (await Storage.get(data.persons_by_pk.s3ImageKey)).toString()
          : null;
        setPersonImage(plate);

        const audio = data?.persons_by_pk?.trespassStatementAudioFileKey
          ? (
              await Storage.get(
                data.persons_by_pk.trespassStatementAudioFileKey
              )
            ).toString()
          : null;
        setAudioStatement(audio);
      }
    })();
  }, [fetchPersons, id, data, locations]);

  return (
    <Row>
      {isImageOpened && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => openImage(false)} />
      )}
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: data?.persons_by_pk?.firstName?.toString(),
            lastName: data?.persons_by_pk?.lastName?.toString(),
            listedAs: data?.persons_by_pk?.listedAs ?? ListAs.NONE.toString(),
            knownToBeViolent: data?.persons_by_pk?.knownToBeViolent,
            audioTranscirpt: data?.persons_by_pk?.trespassStatementTranscript,
          }}
          onSubmit={(values) => {
            const action = (async () => {
              const { firstName, lastName, listedAs } = values;
              if (id) {
                await updatePersonMutation({
                  variables: {
                    id,
                    firstName,
                    lastName,
                    listAs: listedAs,
                    knownToBeViolent: values.knownToBeViolent,
                    trespassStatementTranscript: values.audioTranscirpt,
                  },
                });
              }
              history.goBack();
            })();
            toast.promise(action, {
              success: "Successfully Updated!",
              loading: "Updating...",
              error: "Failed to update!",
            });
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="1">
                    <Avatar
                      src={String(personImage)}
                      size="32"
                      color="red"
                      round
                      onClick={(e) => {
                        openImage(true);
                        e.preventDefault();
                        e.stopPropagation();
                        setImageSrc(String(personImage));
                      }}
                    />
                  </Col>
                  <Col xs="9">
                    <h3 className="mb-0">
                      {id ? "Update" : "Add new Plate Details"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="2">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={!updatingPlate ? false : true}
                    >
                      {!updatingPlate ? "Save" : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="3">
                      <Avatar
                        src={String(personImage)}
                        style={{ width: "100%", objectFit: "cover" }}
                        color="red"
                        round
                        onClick={(e) => {
                          openImage(true);
                          e.preventDefault();
                          e.stopPropagation();
                          setImageSrc(String(personImage));
                        }}
                      />
                    </Col>
                    <Col lg="9">
                      <Row>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            First name
                          </label>
                          <Input
                            tag={Field}
                            className="form-control-alternative"
                            id="firstName"
                            name="firstName"
                            placeholder="Tom"
                            type="text"
                            required
                          />
                        </Col>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Last Name
                          </label>
                          <Input
                            tag={Field}
                            className="form-control-alternative"
                            id="lastName"
                            name="lastName"
                            placeholder="Hanks"
                            type="text"
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            List As
                          </label>
                          <Input
                            type="select"
                            onChange={(e) =>
                              setFieldValue("listedAs", e.target.value)
                            }
                            value={values.listedAs}
                            name="listedAs"
                          >
                            {[
                              {
                                name: "None",
                                value: ListAs.NONE,
                              },
                              {
                                name: "Not Authorized",
                                value: ListAs.BOLO,
                              },
                              {
                                name: "Authorized",
                                value: ListAs.WHITELIST,
                              },
                            ].map((option) => (
                              <option value={option.value.toString()}>
                                {option.name}
                              </option>
                            ))}
                          </Input>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" style={{ padding: "10px 0 0 35px" }}>
                          <Input
                            type={"checkbox"}
                            checked={values.knownToBeViolent}
                            onChange={(e) =>
                              setFieldValue(
                                "knownToBeViolent",
                                e.target.checked
                              )
                            }
                          />
                          <Label check>Known to be violent</Label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {id ? (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Trespasser Details
                      </h6>
                      {audioStatement ? (
                        <Row>
                          <Col xs={12} lg={6}>
                            <label className="form-control-label">
                              Audio Statement
                            </label>
                            <ReactAudioPlayer
                              style={{ width: "100%" }}
                              src={String(audioStatement)}
                              controls
                            />
                          </Col>
                          <Col xs={12} lg={6}>
                            <label className="form-control-label">
                              Audio Transcript
                            </label>
                            <Input
                              id="audioTranscript"
                              name="audioTranscript"
                              placeholder=""
                              value={values.audioTranscirpt || ""}
                              onChange={(e) => {
                                setFieldValue(
                                  "audioTranscirpt",
                                  e.target.value
                                );
                              }}
                              type="textarea"
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Col>
                          <p>There is no Audio Statement for this Subject</p>
                        </Col>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
        <div>
          <Map
            locations={locations?.incidents
              .map((i) => i.location?.coordinates)
              .filter((i) => i != null)}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AddPerson;
