import Table, {
  ColumnDefinitionType,
  TableActionType,
} from "components/atoms/Table";
import AppContext from "contexts/AppContext";
import {
  useListLprDataStoresQuery,
  useListPersonDataStoresQuery,
} from "generated/graphql";
import { useContext, useState } from "react";
import { withRouter } from "react-router";

type Row = {
  id: string;
  owner: string;
  name: string;
  isOwner: string;
};

const COLUMNS: ColumnDefinitionType<Row, keyof Row>[] = [
  {
    key: "id",
    header: "Datastore ID",
  },
  {
    key: "owner",
    header: "Owner",
  },
  {
    key: "name",
    header: "Name",
  },
  {
    key: "isOwner",
    header: "Owned",
  },
];

const LIMIT = 10;

const List = withRouter(({ history }) => {
  const [selected, setSelected] = useState<string | undefined>("Persons");
  const { data: d1 } = useListLprDataStoresQuery({ variables: {} });
  const { data: d2 } = useListPersonDataStoresQuery({ variables: {} });
  const { user } = useContext(AppContext);

  return (
    <Table
      title="Datastores"
      columns={COLUMNS}
      data={
        selected === "Persons"
          ? d2?.person_datastores.map((item) => ({
              id: item.id,
              name: item.name,
              owner: item.agency?.name,
              isOwner: item.agency?.id === user?.agencyId ? "Yes" : "No",
            })) || []
          : d1?.lpr_datastores.map((item) => ({
              id: item.id,
              name: item.name,
              owner: item.agency?.name,
              isOwner: item.agency?.id === user?.agencyId ? "Yes" : "No",
            })) || []
      }
      itemActions={[
        {
          heading: "Datastore Access",
          text: "Grant Access",
          color: "primary",
          onClick: (item) => {
            history.push(`/admin/datastores/${selected}/${item.id}/`);
          },
        },
      ]}
      actions={[
        {
          text: "",
          type: TableActionType.SELECT,
          options: [
            { label: "Persons", value: "Persons" },
            { label: "Plates", value: "Plates" },
          ],
          onSelectChange: (e) => {
            setSelected(e?.value);
          },
        },
      ]}
    />
  );
});

export default List;
