import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";

import AdminLayout from "layouts/Admin";
import UserLayout from "layouts/User";
import AuthLayout from "layouts/Auth";
import awsconfig from "./aws-exports";
import "./App.scss";

import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  concat,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { useEffect, useState } from "react";
import { Toaster } from 'react-hot-toast';
import { AppProvider } from "contexts/AppContext";

Amplify.configure(awsconfig);

const App = () => {
  const [token, setToken] = useState("");
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(
      authMiddleware,
      new HttpLink({ uri: process.env.REACT_APP_SCHEMA_ENDPOINT })
    ),
  });

  useEffect(() => {
    ;(async () => {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      // @ts-ignore
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        console.log("Refreshing token...")
        setToken(session.getIdToken().getJwtToken());
      });
    })()
  }, []);

  return (
    token?.length > 0 ? <ApolloProvider client={client}>
      <AppProvider>
        <BrowserRouter>
          <Toaster/>
          <Switch>
            <Route path="/user" render={(props) => <UserLayout {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="/" to="/admin/index" />
          </Switch>
        </BrowserRouter>
      </AppProvider>
    </ApolloProvider> : <div/>
  );
};

export default withAuthenticator(App);
