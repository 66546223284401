import { ReactNode, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "./Tab.module.scss";

type Tab = {
  name: string;
  component?: ReactNode;
}

type TabProps = {
  tabs: Tab[];
};

const TabView = (props: TabProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggle = setActiveTab;
  
  return (
    <div className={styles.Tab}>
      <Nav
        className="nav-fill flex-column flex-md-row"
        id="tabs-icons-text"
        tabs
        role="tablist"
      >
        {
          props.tabs.map((tab, index) => <NavItem>
            <NavLink
              aria-selected={activeTab === index}
              className={`mb-sm-3 mb-md-0 ${activeTab === index ? 'active' : ''}`}
              onClick={() => {
                toggle(index);
              }}
            >
              {tab.name}
            </NavLink>
          </NavItem>)
        }
      </Nav>
      {
        props.tabs.map(({ component }, index) => <TabContent activeTab={activeTab}>
          <TabPane tabId={index}>
            {component}
          </TabPane>
        </TabContent>)
      }
    </div>
  );
};

export default TabView;
