import { Container, Row, Col } from "reactstrap";

const UserHeader = () => {
  // This below should be replaced by a ContextAPI call
  const {
    firstName = "Jesse",
    image = `url(${
      require("../../assets/img/theme/profile-cover.jpg").default
    })`,
  } = {};

  return (
    <div
      className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style={{
        minHeight: "600px",
        backgroundImage: image,
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    >
      {/* Mask */}
      <span className="mask bg-gradient-default opacity-8" />
      {/* Header container */}
      <Container className="d-flex align-items-center" fluid>
        <Row>
          <Col lg="7" md="10">
            <h1 className="display-2 text-white">Hello {firstName}</h1>
            <p className="text-white mt-0 mb-5">
              Here you can manage your account, update your information or
              account credentials.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserHeader;
