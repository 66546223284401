import { useState, useContext } from "react";
import Table, {
  ColumnDefinitionType,
  TableActionType,
} from "components/atoms/Table";
import AppContext from "contexts/AppContext";
import toast from "react-hot-toast";
import {
  Order_By,
  useListUsersQuery,
  useDeleteUserMutation,
  useUnlinkDeviceMutation,
  ListUsersDocument,
  useUpdateUserStatusMutation,
} from "generated/graphql";
import { useHistory } from "react-router";

type Row = {
  id: string;
  name: string;
  email: string;
  createdAt: string;
  disabled: boolean;
  buttonText: string;
  deviceId: string;
};

const COLUMNS: ColumnDefinitionType<Row, keyof Row>[] = [
  {
    key: "name",
    header: "Name",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "createdAt",
    header: "Added On",
  },
];

const LIMIT = 10;

const List = () => {
  const [query, setQuery] = useState("");
  const { user } = useContext(AppContext);
  const history = useHistory();

  const { data, loading } = useListUsersQuery({
    variables: {
      query: `%${query}%`,
      order: Order_By.Desc,
      role: user?.role === "agencyadmins" ? "agencyadmins" : "superadmins",
    },
  });
  const [unlinkDevice] = useUnlinkDeviceMutation();
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [
      {
        query: ListUsersDocument,
        variables: {
          query: `%${query}%`,
          order: Order_By.Desc,
          role: user?.role === "agencyadmins" ? "agencyadmins" : "superadmins",
        },
      },
    ],
  });

  return (
    <Table
      title="Admins"
      columns={COLUMNS}
      dataLoading={loading}
      data={
        data?.users.map((item) => ({
          id: item.id,
          name: `${item.firstName} ${item.lastName}`,
          email: item.email,
          createdAt: item.createdAt,
          disabled: item.disabled,
          buttonText: item.disabled ? "Enable" : "Disable",
          deviceId: item.deviceId,
        })) || []
      }
      onClickItem={(item) => {
        history.push(`/admin/users/${item.id}`);
      }}
      itemActions={[
        {
          heading: "Delete",
          text: "Delete",
          color: "danger",
          requiresConfirmation: true,
          setConfirmationMessage: ({ name }) =>
            `Are you sure you want to Delete ${name}?`,
          onClick: ({ id }) => {
            toast.promise(
              deleteUser({
                variables: {
                  id,
                },
              }),
              {
                loading: "Deleting...",
                success: "Deleted successfully!",
                error: "Failed to delete!",
              }
            );
          },
        },
        {
          heading: "Status",
          key: "buttonText",
          color: "primary",
          requiresConfirmation: true,
          setConfirmationMessage: ({ disabled, name }) =>
            disabled
              ? `Do you want to enable ${name}?`
              : `Do you want to disable ${name}?`,
          onClick: ({ id, disabled }) => {
            disabled = disabled ? false : true;
            toast.promise(
              updateUserStatus({
                variables: {
                  id,
                  disabled,
                },
                refetchQueries: [
                  {
                    query: ListUsersDocument,
                    variables: {
                      query: `%%`,
                      order: Order_By.Desc,
                      limit: 10,
                      offset: 0,
                    },
                  },
                ],
                update: (cache) => {
                  cache.evict({
                    id: "ROOT_QUERY",
                    fieldName: "users",
                  });
                },
              }),
              {
                loading: "Updating Status...",
                success: "Updated Status successfully!",
                error: "Failed to Update!",
              }
            );
          },
        },
        {
          heading: "Device Status",
          text: "Unlink Device",
          isDisabled: ({ deviceId }) => !deviceId,
          color: "danger",
          onClick: ({ id }) => {
            toast.promise(
              unlinkDevice({
                variables: {
                  id,
                },
              }),
              {
                loading: "Unlinking device...",
                success: "Device unlinked!",
                error: "Could not unlink device!",
              }
            );
          },
        },
      ]}
      actions={[
        {
          text: "Add",
          href: "/admin/users/add",
          color: "primary",
        },
        {
          text: "Search",
          type: TableActionType.TEXT,
          onChange: (e) => {
            setQuery(e.target.value);
          },
        },
      ]}
      itemsPerPage={data?.users_aggregate?.aggregate?.count || 1}
      totalPages={Math.ceil(
        (data?.users_aggregate.aggregate?.count || 0) / LIMIT
      )}
    />
  );
};

export default List;
