import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useAddLprDatastoreAccessMutation,
  useAddPersonsDatastoreAccessMutation,
} from "generated/graphql";
import { withRouter, useParams } from "react-router";
import { Field, Formik } from "formik";
import { ListAs } from "utils/constants";
import toast from "react-hot-toast";

interface RouteParams {
  id: string;
  type: string;
}
interface Values {
  agencyId: string;
}
const AddDataStoreAccess = withRouter(({ history }) => {
  const [addLprDatastoreAccessMutation, { loading: addingLprAccess }] =
    useAddLprDatastoreAccessMutation();
  const [addPersonsDatastoreAccessMutation, { loading: addingPersonAccess }] =
    useAddPersonsDatastoreAccessMutation();
  let routeParams: RouteParams;
  routeParams = useParams();
  return (
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{ agencyId: "" }}
          onSubmit={(values) => {
            const action = (async () => {
              if (routeParams.type === "Persons") {
                console.log("Persons");
                await addPersonsDatastoreAccessMutation({
                  variables: {
                    agenciesId: values.agencyId,
                    personDatastoresId: routeParams.id,
                  },
                });
              } else {
                console.log("Plates");
                await addLprDatastoreAccessMutation({
                  variables: {
                    agenciesId: values.agencyId,
                    lprDatastoresId: routeParams.id,
                  },
                });
              }
              history.goBack();
            })();
            toast.promise(action, {
              success: "Successfully Added!",
              loading: "Adding...",
              error: "Failed to add!",
            });
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      Grant Datastore Access to an Agency
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                    >
                      Grant Access
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-agency-id"
                      >
                        Enter Agency ID
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="agencyId"
                        name="agencyId"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder="Agency ID"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </Col>
    </Row>
  );
});

export default AddDataStoreAccess;
