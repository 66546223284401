import { useContext, useEffect, useState } from "react";
import Table, {
  ColumnDefinitionType,
  TableActionType,
  TableCheckActionValue,
} from "components/atoms/Table";
import toast from "react-hot-toast";
import {
  ListIncidentTypesDocument,
  Order_By,
  useDeleteIncidentTypeMutation,
  useListIncidentTypesLazyQuery,
  useListIncidentTypesByAgencyLazyQuery,
  useListIncidentTypesBySuperLazyQuery,
  ListIncidentsDocument,
  ListIncidentTypesBySuperDocument,
  ListIncidentTypesByAgencyDocument,
} from "generated/graphql";
import { useHistory } from "react-router";
import { useQuery } from "hooks/query";
import AppContext from "../../../contexts/AppContext";

type Row = {
  id: string;
  name: string;
  agencyId: string;
};

const COLUMNS: ColumnDefinitionType<Row, keyof Row>[] = [
  {
    key: "name",
    header: "Name",
  },
];

const LIMIT = 10;

const List = () => {
  const [search, setSearch] = useState("");
  const query = useQuery();
  const page = parseInt(query.get("page") || "1") - 1;
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [seeAll, setSeeAll] = useState(false);

  const [getIncidentTypesByAgency, { data: incidentTypesByAgency }] =
    useListIncidentTypesByAgencyLazyQuery();

  const [getIncidentTypesBySuper, { data: incidentTypesBySuperadmin }] =
    useListIncidentTypesBySuperLazyQuery();

  const [getAllIncidentTypes, { data: allIncidentTypes }] =
    useListIncidentTypesLazyQuery();

  useEffect(() => {
    if (!user) return;
    if (user?.role === "superadmins") {
      getIncidentTypesBySuper({
        variables: {
          limit: LIMIT,
          offset: page * LIMIT,
          order: Order_By.Desc,
        },
      });
    } else {
      if (!seeAll && user?.role === "agencyadmins") {
        getIncidentTypesByAgency({
          variables: {
            agencyId: user?.agencyId,
            limit: LIMIT,
            offset: page * LIMIT,
            order: Order_By.Desc,
          },
        });
      } else {
        getAllIncidentTypes({
          variables: {
            query: `%${search}%`,
            limit: LIMIT,
            offset: page * LIMIT,
            order: Order_By.Desc,
          },
        });
      }
    }
  }, [seeAll, page, user?.agencyId]);

  const [deleteIncidentType] = useDeleteIncidentTypeMutation({
    refetchQueries: [
      {
        query: ListIncidentTypesDocument,
        variables: {
          query: `%${search}%`,
          limit: LIMIT,
          offset: page * LIMIT,
          order: Order_By.Desc,
        },
      },
      {
        query: ListIncidentTypesBySuperDocument,
        variables: {
          limit: LIMIT,
          offset: page * LIMIT,
          order: Order_By.Desc,
        },
      },
      {
        query: ListIncidentTypesByAgencyDocument,
        variables: {
          agencyId: user?.agencyId,
          limit: LIMIT,
          offset: page * LIMIT,
          order: Order_By.Desc,
        },
      },
    ],

    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "incident_types",
      });
    },
  });

  return (
    <Table
      title="Incident Type"
      columns={COLUMNS}
      data={
        (user?.role === "superadmins"
          ? incidentTypesBySuperadmin
          : seeAll || user?.role === "superadmins"
          ? allIncidentTypes
          : incidentTypesByAgency
        )?.incident_type.map((item) => ({
          id: item.id,
          name: `${item.name}`,
          agencyId: item.agencyId,
        })) || []
      }
      onClickItem={(item) => {
        history.push(`/admin/incidents-type/add/${item.id}`);
      }}
      itemActions={[
        {
          heading: "Delete",
          text: "Delete",
          color: "danger",
          requiresConfirmation: true,
          isDisabled: ({ agencyId }) =>
            agencyId === null && user?.agencyId !== null,
          confirmationKey: ({ name }) => `${name}`,
          setConfirmationMessage: ({ name }) =>
            `Note that deleting this Incident Type will also delete any associated incident from Persons and Plates.
            If you are sure then enter the Incident name below to delete ${name}:`,
          onClick: ({ id }) => {
            console.log("id: ", id);
            toast.promise(
              deleteIncidentType({
                variables: {
                  id,
                },
              }),
              {
                loading: "Deleting...",
                success: "Deleted successfully!",
                error: "Failed to delete!",
              }
            );
            console.log(
              incidentTypesByAgency?.incident_type.map((res) => {
                return res;
              })
            );
          },
        },
      ]}
      actions={[
        {
          text: "See All",
          type: TableActionType.CHECK,
          hide: user?.role === "superadmins",
          onChange: (e) => {
            setSeeAll(e.target.checked);
          },
          value: {
            value: seeAll
              ? TableCheckActionValue.CHECKED
              : TableCheckActionValue.UNCHECKED,
          },
          tooltip:
            'Choosing "See All" will show incident types from both agency and from Bluewave"',
        },
        {
          text: "Add",
          href: "/admin/incidents-type/add",
          color: "primary",
        },
        {
          text: "Search",
          type: TableActionType.TEXT,
          onChange: (e) => {
            setSearch(e.target.value);
          },
        },
      ]}
      itemsPerPage={
        user?.role === "superadmins"
          ? incidentTypesBySuperadmin?.incident_type_aggregate?.aggregate
              ?.count || 1
          : seeAll
          ? allIncidentTypes?.incident_type_aggregate?.aggregate?.count || 1
          : incidentTypesByAgency?.incident_type_aggregate?.aggregate?.count ||
            1
      }
      totalPages={
        user?.role === "superadmins"
          ? Math.ceil(
              (incidentTypesBySuperadmin?.incident_type_aggregate.aggregate
                ?.count || 0) / LIMIT
            )
          : seeAll
          ? Math.ceil(
              (allIncidentTypes?.incident_type_aggregate.aggregate?.count ||
                0) / LIMIT
            )
          : Math.ceil(
              (incidentTypesByAgency?.incident_type_aggregate.aggregate
                ?.count || 0) / LIMIT
            )
      }
    />
  );
};

export default List;
