import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useFetchUserInfoLazyQuery,
  useListAgencyUserViewQuery,
  ListUsersDocument,
  Order_By,
  useCreateFtpUserMutation,
  ListFtpUsersDocument,
  useUpdateFtpUserMutation,
} from "generated/graphql";
import { withRouter, useParams, useHistory } from "react-router";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import AppContext from "contexts/AppContext";

const AddFTPuser = withRouter(() => {
  const [allowAddUser, setAllowAddUser] = useState(true);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [fetchUsers, { data }] = useFetchUserInfoLazyQuery();
  const agencyId = new URLSearchParams(window.location.search).get("agencyid");
  const { user } = useContext(AppContext);

  const [addUserMutation, { loading: adding }] = useCreateFtpUserMutation({
    refetchQueries: [
      {
        query: ListFtpUsersDocument,
        variables: {
          query: `%%`,
          order: Order_By.Desc,
        },
      },
    ],
    update: (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "users",
      });
    },
  });

  const [updateFTPUserMutation, { loading: updating }] =
    useUpdateFtpUserMutation();

  const { data: agencyUserView } = useListAgencyUserViewQuery();

  useEffect(() => {
    if (id)
      fetchUsers({
        variables: {
          id,
        },
      });
  }, [fetchUsers, id]);

  return (
    <Row>
      <Col className="order-xl-1">
        <Formik
          enableReinitialize={true}
          initialValues={{
            username: data?.users_by_pk?.toString(),
            password: data?.users_by_pk?.toString(),
          }}
          onSubmit={(values) => {
            const action = (async () => {
              const { username, password } = values;
              if (id) {
                await updateFTPUserMutation({
                  variables: {
                    username: username,
                    password: password,
                  },
                });
              } else {
                if (allowAddUser || user?.role === "superadmins") {
                  await addUserMutation({
                    variables: {
                      username,
                      password,
                    },
                  });
                } else {
                  toast.error("Sorry! You've reached your user limit!");
                }
              }
              history.goBack();
            })();
            if (allowAddUser || user?.role === "superadmins") {
              toast.promise(action, {
                success: `Successfully ${id ? "Updated" : "Added"}!`,
                loading: `${id ? "Updating" : "Adding"}...`,
                error: `Failed to ${id ? "Update" : "Add"}!`,
              });
            }
          }}
        >
          {({ values, setFieldValue, handleChange, submitForm }) => (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {id ? "Update" : "Add new FTPuser"}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitForm}
                      size="sm"
                      disabled={!adding && !updating ? false : true}
                    >
                      {!adding && !updating ? "Save" : "Saving..."}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Username
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="username"
                        name="username"
                        placeholder="John"
                        type="text"
                        required
                      />
                    </Col>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Password
                      </label>
                      <Input
                        tag={Field}
                        className="form-control-alternative"
                        id="password"
                        name="password"
                        placeholder="password"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}
        </Formik>
      </Col>
    </Row>
  );
});

export default AddFTPuser;
